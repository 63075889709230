import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '@shopify/polaris';
import axios from 'axios';
import Calendar from '../Graphs/Calendar';
import SignedInLessons from '../Shared/SignedInLessons';

const Home = () => {
  const [userData, setUserData] = useState({});

  const { t } = useTranslation();

  const fetchUserData = useCallback(() => {
    axios.get('/v1/participations')
      .then((res) => {
        setUserData(res.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);
  return (
    <Page
      title={t('navigation.home')}
      separator
    >
      <div style={{ height: '200px' }}>
        <Calendar data={userData.currentYear || []} />
      </div>
      {userData.upcomingEvents ? (
        <SignedInLessons
          fetchUserData={fetchUserData}
          setUserData={setUserData}
          pastEvents={userData.pastEvents}
          upcomingEvents={userData.upcomingEvents}
        />
      ) : null}
    </Page>
  );
};

export default Home;
