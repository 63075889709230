import React from 'react';
import { Badge } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

const TextBadgeStatus = (props) => {
  const { t } = useTranslation();

  const text = props.active ? t('shared.active') : t('client.inactive');
  const type = props.active ? 'success' : 'critical';
  return <Badge status={type} size="small">{text}</Badge>;
};

export default TextBadgeStatus;
