import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@shopify/polaris';
import RRuleCreator from '../Shared/RRuleCreator';
import Banners from '../Shared/Banners';

const RecurrenceWizard = (props) => {
  const [banner, setBanner] = useState([]);

  const { t } = useTranslation();

  const validateBeforeClose = () => {
    setBanner([]);

    if (!props.rruleData.count && !props.rruleData.endTime) {
      setBanner([{ title: t('calendar.number_of_events_or_end_time'), status: 'critical' }]);
      return;
    }

    props.dismissBanner([]);

    props.close();
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      title={t('events.recurrence')}
      secondaryActions={[{
        content: t('shared.save'),
        onAction: validateBeforeClose
      }]}
    >
      <Modal.Section>
        <Banners banners={banner} onDismissBanner={() => setBanner([])} />
        <RRuleCreator rruleData={props.rruleData} event={props.event} handleRruleDataChange={props.handleRruleDataChange} />
      </Modal.Section>
    </Modal>
  );
};

export default RecurrenceWizard;
