/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Banner, Button, Card, Checkbox, Form, FormLayout, Icon, Label, Modal, Stack, TextField } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { CirclePlusMajor, DeleteMajor } from '@shopify/polaris-icons';

const CustomFields = (props) => {
  const [show, setShow] = useState(true);
  const [error, setError] = useState(false);
  const [customFields, setCustomFields] = useState(props.customFields || []);

  const handleCustomFieldChange = (idx) => (field) => (value) => {
    const temp = [...customFields];
    temp[idx][field] = value;
    if (field === 'editable_by_client' && value) {
      temp[idx].viewable_by_client = true;
    }

    if (field === 'viewable_by_client' && !value && temp[idx].editable_by_client === true) {
      temp[idx].editable_by_client = false;
    }
    setCustomFields(temp);
  };

  const { t } = useTranslation();

  const closeModal = () => {
    setShow(false);
    props.close();
  };

  const onSubmit = () => {
    setError(false);

    if (customFields.some((customField) => !customField.label && customField._destroy !== '1')) {
      setError(true);
      return;
    }

    props.handleSubmit(customFields);
    props.close();
  };

  const deleteCustomField = (id) => {
    const temp = [...customFields];
    temp[id]._destroy = '1';
    setCustomFields([...temp]);
  };

  const displayCustomFields = () => customFields.map((customField, idx) => {
    if (customField._destroy === '1') return '';
    return (
      <Card.Section>
        <Stack key={idx} alignment="center">
          <TextField
            type="text"
            label={t('shared.label')}
            value={customField.label}
            onChange={handleCustomFieldChange(idx)('label')}
          />
          <TextField
            type="text"
            label={t('shared.value')}
            value={customField.value}
            onChange={handleCustomFieldChange(idx)('value')}
          />
          <Stack.Item fill>
            <Label>{props.editableTitle}</Label>
            <Checkbox
              checked={customField.editable_by_client}
              onChange={handleCustomFieldChange(idx)('editable_by_client')}
              label={t('custom_fields.editable')}
            />
            <br />
            <Checkbox
              checked={customField.viewable_by_client}
              onChange={handleCustomFieldChange(idx)('viewable_by_client')}
              label={t('custom_fields.viewable')}
            />
          </Stack.Item>
          <Button plain icon={DeleteMajor} destructive onClick={() => deleteCustomField(idx)} />
        </Stack>
      </Card.Section>
    );
  });

  const addCustomField = () => {
    setCustomFields([...customFields, {}]);
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title={props.title}
      primaryAction={{
        content: t('shared.save'),
        onAction: onSubmit
      }}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <FormLayout>
            {error ? (
              <Banner title="Error" status="warning" onDismiss={() => setError(false)}>
                <p>{t('custom_fields.labels_error')}</p>
              </Banner>
            ) : null}
            <Card>
              {displayCustomFields()}
            </Card>
            <Button plain onClick={() => addCustomField()}>
              <Icon color="success" source={CirclePlusMajor} />
            </Button>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

export default CustomFields;
