import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Card, Stack, Avatar, TextStyle, Caption } from '@shopify/polaris';

const Comment = (props) => {
  const { t } = useTranslation();

  return (
    <Card.Section actions={[{ content: t('shared.delete'), onAction: () => props.delete(props.comment.id) }]}>
      <Stack vertical>
        <Stack>
          <Avatar size="medium" name={props.comment.user?.name} initials={props.comment.user?.initials} />
          <Stack.Item>
            <TextStyle variation="strong">{props.comment.user?.name}</TextStyle>
            <Caption><TextStyle variation="subdued">{moment(props.comment.createdAt).format('LLLL')}</TextStyle></Caption>
          </Stack.Item>
        </Stack>
        <div dangerouslySetInnerHTML={{ __html: props.comment.body }} />
      </Stack>
    </Card.Section>
  );
};

export default Comment;
