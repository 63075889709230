import React from 'react';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { Droppable } from './Droppables/Droppable';

const Breadcrumps = (props) => (
  <nav className="flex mb-8 flex-1" aria-label="Breadcrumb">
    {/* eslint-disable-next-line */}
      <ol role="list" className="flex items-center space-x-2">
        <li>
          <div>
            {/* eslint-disable-next-line */}
            <button onClick={props.resetPath} type="button" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </button>
          </div>
        </li>
        {props.pages.map((page) => (
          <li key={page.id}>
            <Droppable key={page.id} id={page.id} item={page}>
              {/* <DraggableItem id={page.id} isContainer label={page.name} handleSingleClick={() => {}} handleDoubleClick={() => {}} isShared={false} childOnly> */}
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <button
                  onClick={() => props.changePath(page)}
                  type="button"
                  className="ml-2 text-base font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </button>
              </div>
              {/* </DraggableItem> */}
            </Droppable>
          </li>
        ))}
      </ol>
  </nav>
);

export default Breadcrumps;
