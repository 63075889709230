import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { DropZone, Button, Form, FormLayout, Modal, TextField, Stack, Caption, Icon, TextStyle } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import Banners from '../../Shared/Banners';

const EmailTemplateAttachmentModalForm = (props) => {
  const [banner, setBanner] = useState([]);
  const [description, setDescription] = useState('');
  const [show, setShow] = useState(true);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const { t } = useTranslation();

  const fileUpload = !file && <DropZone.FileUpload actionHint="or drop a file to upload" />;
  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles) => setFile(() => acceptedFiles[0]), []);
  const uploadedFile = file && (
    <Stack>
      <div>
        {`${file.name} `}
        <Caption>
          {`${file.size} bytes`}
        </Caption>
      </div>
    </Stack>
  );

  useEffect(() => {
    setBanner(props.banner || []);
  }, [props.banner]);

  useEffect(() => {
    setLoading(props.loading || false);
  }, [props.loading]);

  const closeModal = () => {
    setDescription('');
    setShow(false);
    props.reset();
  };

  const formValid = () => {
    const errors = {};
    if (!description) {
      errors.description = t('shared.validations.required');
    }
    if (!file) {
      errors.file = t('shared.validations.required');
    }

    setFormErrors(errors);
    return Object.entries(errors).length === 0;
  };

  const onSubmit = () => {
    if (!formValid()) {
      return;
    }

    const formData = new FormData();
    formData.append('email_template[email_template_attachments_attributes][0][description]', description);
    if (file) {
      formData.append('email_template[email_template_attachments_attributes][0][file]', file);
    }

    props.handleSubmit(formData);
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title={props.title}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <Banners banners={banner} onDismissBanner={props.dismissBanner} />
          <FormLayout>
            <TextField label={t('shared.description')} error={formErrors.description} value={description} onChange={(value) => setDescription(value)} />
            <DropZone allowMultiple={false} onDrop={handleDropZoneDrop}>
              {uploadedFile}
              {fileUpload}
            </DropZone>
            {formErrors.file ? (
              <Stack>
                <Icon source={AlertMinor} color="critical" />
                <TextStyle variation="negative">{formErrors.file}</TextStyle>
              </Stack>
            ) : '' }
            <Button loading={loading} submit>{t('shared.submit')}</Button>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(EmailTemplateAttachmentModalForm);
