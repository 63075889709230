/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppProvider, Frame, Toast } from '@shopify/polaris';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import en from '@shopify/polaris/locales/en.json';
import fr from '@shopify/polaris/locales/fr.json';
import de from '@shopify/polaris/locales/de.json';
import axios from 'axios';
import moment from 'moment-timezone';
import * as mom from 'moment';
import deMoment from 'moment/locale/de';
import frMoment from 'moment/locale/fr';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainNavigation from './MainNavigation/MainNavigation';
import ApplicationTopBar from './ApplicationTopBar/ApplicationTopBar';
import logo from '../../assets/images/planubo-blue.png';
import * as actions from '../../store/actions/index';
import GlobalNotification from './GlobalNotification';
import PerformWorkflow from './PerformWorkflow';
import NotificationBanner from '../../components/Shared/NotificationBanner/NotificationBanner';

const Layout = (props) => {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [brand, setBrand] = useState({});
  const [showNewVersionBanner, setShowNewVersionBanner] = useState(false);

  const { t } = useTranslation();

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleToastActive}
      duration={200000}
      error
      action={{
        content: 'Refresh',
        onAction: () => {
          window.location.reload();
        }
      }}
    />
  ) : null;

  useEffect(() => {
    if (props.newVersionAvailable) {
      toggleToastActive('A new version is available');
    }
  // eslint-disable-next-line
  }, [props.newVersionAvailable]);

  useEffect(() => {
    props.loadBrand();
    if (window.location.host.split('.')[0] !== 'app' && window.location.host.split('.')[0] !== 'localhost:3000') {
      axios.get('/v1/custom_domains/brand')
        .then((response) => {
          setBrand(response.data);
          props.setBrand(response.data);
        })
        .catch(() => {});
    } else {
      props.setBrand({});
    }
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.newVersionAvailable) {
      setShowNewVersionBanner(true);
    }
  }, [props.newVersionAvailable]);

  const customLinkComponent = ({ url, ...rest }) => <Link to={url} {...rest} />;

  const toggleMobileNavigationActive = () => setMobileNavigationActive(!mobileNavigationActive);

  const dismissMobileNavigation = () => setMobileNavigationActive(() => {
    if (mobileNavigationActive) {
      return !mobileNavigationActive;
    }

    return false;
  });

  const language = () => {
    if (props.timezone) {
      moment.tz.setDefault(props.timezone);
    }

    switch (props.lang) {
      case 'en': {
        mom.locale('en');
        return en;
      }
      case 'de': {
        mom.locale('de', deMoment);
        return de;
      }
      case 'fr': {
        mom.locale('fr', frMoment);
        return fr;
      }
      default: {
        mom.locale('en');
        return en;
      }
    }
  };

  const theme = {
    colorScheme: brand.color_scheme || 'light',
    colors: {
      surface: brand.surface || '#111213',
      onSurface: brand.onSurface || '#111213',
      interactive: brand.interactive || '#2e72d2',
      secondary: brand.secondary || '#111213',
      primary: brand.primary || '#3b5998',
      critical: brand.critical || '#d82c0d',
      warning: brand.warning || '#ffc453',
      highlight: brand.highlight || '#5bcdda',
      success: brand.success || '#008060',
      decorative: brand.decorative || '#ffc96b'
    },
    logo: {
      width: 124,
      topBarSource: brand.appLogoUrl || logo,
      url: '/',
      accessibilityLabel: 'Planubo'
    }
  };

  const showSearchAccountAcctive = () => {
    if (props.isNew) return true;
    if (!props.active) return false;
    if (props.accountState === 'pending') return false; // we also don't want to show search if the account is pending
    if (props.accountState === 'denied') return false;
    if (props.accountState === 'declined') return false;
    if (props.accountState === 'new_application') return false;
    if (props.accountState === 'on_hold') return false;
    if (props.accountState === 'new_registration') return false;
    if (props.accountState === 'approved') return false;
    if (props.accountState === 'cancelled') return false;

    return true;
  };

  const accountActive = () => {
    if (props.isNew) return true;
    if (!props.active) return false;
    if (props.accountState === 'trial') return true;
    if (props.accountState === 'pending') return true;
    if (props.accountState === 'on_hold') return true;
    if (props.accountState === 'new_registration') return true;
    if (props.accountState === 'new_application') return true;
    if (props.accountState === 'completed') return true;
    if (props.accountState === 'validated') return true;
    if (props.accountState === 'cancelled') return false;
    if (props.accountState === 'denied') return false;

    return true;
  };

  const showAccountMessage = () => {
    if (!props.active) {
      return (
        <div className="m-4">
          <NotificationBanner label={t('registration.not_active')} color="bg-red-400" />
        </div>
      );
    }

    if (props.isNew || props.accountState === 'active' || props.accountState === 'completed' || props.accountState === 'trial' || props.accountState === 'validated') {
      return '';
    }

    if (props.accountState === 'pending') {
      return (
        <div className="m-4">
          <NotificationBanner label={t('registration.pending')} color="bg-green-400" />
        </div>
      );
    }

    if (props.accountState === 'new_application') {
      return (
        <div className="m-4">
          <NotificationBanner label={t('registration.new_application')} color="bg-green-400" />
        </div>
      );
    }

    if (props.accountState === 'new_registration') {
      return (
        <div className="m-4">
          <NotificationBanner label={t('registration.new_registration')} color="bg-green-400" />
        </div>
      );
    }
    if (props.accountState === 'denied') {
      return (
        <div className="m-4">
          <NotificationBanner label={t('registration.denied')} color="bg-red-400" />
        </div>
      );
    }
    if (props.accountState === 'on_hold') {
      return (
        <div className="m-4">
          <NotificationBanner label={t('registration.on_hold')} color="bg-red-400" />
        </div>
      );
    }
    if (props.accountState === 'cancelled') {
      return (
        <div className="m-4">
          <NotificationBanner label={t('registration.cancelled')} color="bg-red-400" />
        </div>
      );
    }
    if (props.accountState === 'approved') {
      return (
        <div className="m-4">
          <NotificationBanner label={t('registration.approved')} color="bg-green-400" />
        </div>
      );
    }

    return (
      <div className="m-4">
        <NotificationBanner label={t('registration.inactive')} color="bg-red-400" />
      </div>
    );
  };

  const { children, withNavigation } = props;
  let frame = children;

  if (withNavigation) {
    frame = (
      <Frame
        navigation={!props.assignedWorkflows && accountActive() && <MainNavigation toggle={dismissMobileNavigation} />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
        topBar={<ApplicationTopBar toggle={toggleMobileNavigationActive} showSearch={!props.assignedWorkflows && showSearchAccountAcctive()} />}
      >
        {toastMarkup}
        {(props.isClient || props.isStaff) && <GlobalNotification />}
        <PerformWorkflow show={props.assignedWorkflows}>
          {accountActive() ? showAccountMessage() : ''}
          {accountActive() ? children : showAccountMessage()}
          {/* {children} */}
        </PerformWorkflow>
      </Frame>
    );
  }

  const isMobile = window.innerWidth <= 500;

  return (
    <div className={isMobile && withNavigation ? 'MobileFooter-Margin' : null}>
      <AppProvider
        theme={theme}
        i18n={language()}
        linkComponent={customLinkComponent}
      >
        <DndProvider backend={HTML5Backend}>
          {frame}
        </DndProvider>
      </AppProvider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.lang,
  timezone: state.auth.timezone,
  isClient: state.auth.client,
  newVersionAvailable: state.auth.newVersionAvailable,
  isStaff: state.auth.role === 'staff',
  assignedWorkflows: state.auth.assigned_workflows,
  accountState: state.auth.accountState,
  active: state.auth.active,
  isNew: state.auth.isNew
});

const mapDispatchToProps = (dispatch) => ({
  setBrand: (brand) => dispatch(actions.setBrand(brand)),
  loadBrand: () => dispatch(actions.loadBrand()),
  checkAuthState: (url, history) => dispatch(actions.authCheckState(url, history))
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
