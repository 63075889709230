/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { memo, useCallback, useState } from 'react';
import { Handle, Position, useReactFlow, useStoreApi } from 'reactflow';
import { PlayIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

const options = [
  {
    value: 'Staff registration',
    label: 'Staff registration'
  },
  {
    value: 'Client registration',
    label: 'Client registration'
  },
  {
    value: 'staff_status_change',
    label: 'Staff status change'
  },
  {
    value: 'client_status_change',
    label: 'Client status change'
  },
  {
    value: 'event',
    label: 'Event'
  },
  {
    value: 'gift_card',
    label: 'Gift card expiration'
  }
];

function StartNode({ data, id }) {
  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (evt) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            action: evt.target.value
          };
        }

        return node;
      })
    );
  };

  return (
    <>
      <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400">
        {/* <button type="button" className="top-1 right-1 absolute opacity-30 hover:opacity-100">
          <PencilSquareIcon className="h-3 w-3 text-gray-700" aria-hidden="true" />
        </button> */}
        <div className="flex justify-center">
          <div className="rounded-full w-12 h-12 flex justify-center items-center bg-blue-300">
            <PlayIcon className="h-5 w-5 text-blue-700" aria-hidden="true" />
          </div>
        </div>
        <Handle type="source" position={Position.Right} className="h-4 bg-teal-500 align-middle" />
        <div className="text-md text-center">{data.type}</div>
        <div>
          <label htmlFor="text">Type: </label>
          <select className="nodrag" onChange={onChange} value={data.action}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

export default memo(StartNode);
