import React, { useCallback, useState } from 'react';
import { TopBar, ThemeProvider } from '@shopify/polaris';
import { ViewMajor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import UserMenu from '../../../components/Users/UserMenu/UserMenu';
import ExternalLink from '../../../components/Shared/ExternalLink';
import SearchResults from '../../../components/GlobalSearch/SearchResults';
import useDebounce from '../../../hooks/useDebounce';

const ApplicationTopBar = (props) => {
  const { t } = useTranslation('translation');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 400);

  const handleSearchResultsDismiss = useCallback(() => {
    setIsSearchActive(false);
    setSearchValue('');
  }, []);

  const handleSearchChange = useCallback((value) => {
    setSearchValue(value);
    setIsSearchActive(value.length);
  }, []);

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={handleSearchChange}
      value={searchValue}
      placeholder={t('user.search')}
    />
  );

  const getUrl = () => {
    switch (process.env.NODE_ENV) {
      case 'development': return `/booking/${encodeURIComponent(props.userUrlName)}`;
      case 'production': return `https://${props.subdomain || 'app'}.planubo.com/booking/${encodeURIComponent(props.userUrlName)}`;
      default: return `/booking/${encodeURIComponent(props.userUrlName)}`;
    }
  };

  return (
    <TopBar
      searchField={!props.isClient && props.showSearch && searchFieldMarkup}
      searchResults={(
        <ThemeProvider theme={{ colorScheme: 'light' }}>
          <SearchResults
            searchValue={debouncedSearchValue}
            onSelection={handleSearchResultsDismiss}
          />
        </ThemeProvider>
      )}
      showNavigationToggle
      searchResultsVisible={isSearchActive}
      onSearchResultsDismiss={() => handleSearchResultsDismiss()}
      secondaryMenu={props.showSearch && <ExternalLink url={getUrl()} label={t('public_profile.portal')} hideIcon buttonIcon={ViewMajor} />}
      userMenu={<UserMenu />}
      onNavigationToggle={() => props.toggle()}
    />
  );
};

const mapStateToProps = (state) => ({
  isClient: state.auth.client,
  subdomain: state.auth.subdomain,
  userUrlName: state.auth.urlName
});

export default connect(mapStateToProps)(ApplicationTopBar);
