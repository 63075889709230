/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import ReactFlow, { useNodesState, useEdgesState, addEdge, MiniMap, Controls, Background, Panel, useReactFlow, ReactFlowProvider, getOutgoers } from 'reactflow';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

import 'reactflow/dist/base.css';

// Nodes
import FormNode from './Nodes/FormNode';
import StartNode from './Nodes/StartNode';
import EndNode from './Nodes/EndNode';
import LanguageCondition from './Nodes/LanguageCondition';
import StaffStatusChangeCondition from './Nodes/StaffStatusChangeCondition';

// Edges
import LanguageEdge from './Edges/LanguageEdge';
import StaffStatusChangeEdge from './Edges/StaffStatusChangeEdge';

import ModalService from '../Shared/Services/ModalService';
import LanguageModal from './Edges/LanguageModal/LanguageModal';
import StaffStatusChangeModal from './Edges/StaffStatusChangeModal/StaffStatusChangeModal';
import EmailNode from './Nodes/EmailNode';
import ClientStatusChangeEdge from './Edges/ClientStatusChangeEdge';
import ClientStatusChangeCondition from './Nodes/ClientStatusChangeCondition';
import ClientStatusChangeModal from './Edges/ClientStatusChangeModal/ClientStatusChangeModal';
import HasProgramSpaceCondition from './Nodes/HasProgramSpaceCondition';
import ProgramSpaceEdge from './Edges/ProgramSpaceEdge';
import ProgramSpaceChangeModal from './Edges/ProgramSpaceChangeModal/ProgramSpaceChangeModal';
import Timer from './Nodes/Timer';
import TimerEdge from './Edges/TimerEdge';
import TimerModal from './Edges/TimerModal/TimerModal';
// import Sidebar from './Sidebar';

const languageModalRef = React.createRef();
const staffStatusModalRef = React.createRef();
const clientStatusModalRef = React.createRef();
const programSpaceModalRef = React.createRef();
const timerModalRef = React.createRef();

const nodeTypes = {
  form: FormNode,
  start: StartNode,
  end: EndNode,
  language: LanguageCondition,
  staff_status_change: StaffStatusChangeCondition,
  client_status_change: ClientStatusChangeCondition,
  has_program_space: HasProgramSpaceCondition,
  timer: Timer,
  email: EmailNode
};

const edgeTypes = {
  languageEdge: LanguageEdge,
  staffStatusEdge: StaffStatusChangeEdge,
  clientStatusEdge: ClientStatusChangeEdge,
  programSpaceEdge: ProgramSpaceEdge,
  timerEdge: TimerEdge
};

const initNodes = [
  {
    id: '1',
    type: 'start',
    data: { type: 'Start', action: 'Staff registration' },
    position: { x: 0, y: 0 }
    // sourcePosition: 'left'
  },
  {
    id: '2',
    type: 'form',
    data: { type: 'Form' },
    position: { x: 350, y: 6.5 }
    // sourcePosition: 'left'
  },
  {
    id: '3',
    type: 'end',
    data: { type: 'End' },
    position: { x: 750, y: 21 }
    // sourcePosition: 'left'
  }
];

const initEdges = [
  {
    id: 'e1-2',
    source: '1',
    target: '2'
  },
  {
    id: 'e1-3',
    source: '2',
    target: '3'
  }
];

const Flow = () => {
  // eslint-disable-next-line no-unused-vars
  const [workflow, setWorkflow] = useState({});
  const [name, setName] = useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);
  const { setViewport } = useReactFlow();
  const [showSaved, setShowSaved] = useState(false);
  const [programSpaceOptions, setProgramSpaceOptions] = useState([{}]);

  const history = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [rfInstance, setRfInstance] = useState(null);
  const onConnect = useCallback((params) => setEdges((eds) => {
    const sourceNode = nodes.find((node) => node.id === params.source);
    // List of custom edges depending on source
    if (sourceNode.type === 'language') {
      return addEdge({ ...params, type: 'languageEdge', label: 'english' }, eds);
    }

    if (sourceNode.type === 'staff_status_change') {
      return addEdge({ ...params, type: 'staffStatusEdge', label: 'new_registration' }, eds);
    }

    if (sourceNode.type === 'client_status_change') {
      return addEdge({ ...params, type: 'clientStatusEdge', label: 'new_application' }, eds);
    }

    if (sourceNode.type === 'has_program_space') {
      return addEdge({ ...params, type: 'programSpaceEdge', label: 'default' }, eds);
    }

    if (sourceNode.type === 'timer') {
      return addEdge({ ...params, type: 'timerEdge', label: '10' }, eds);
    }

    return addEdge({ ...params }, eds);
  // eslint-disable-next-line
  }), [nodes]);
  // const onConnect = useCallback((params) => setEdges(addEdge({ ...params, type: 'smoothstep' }, edges)), []);

  const fetchProgramSpaces = () => {
    axios.get('/v1/public_profiles/workflow_public_profiles')
      .then((res) => {
        setProgramSpaceOptions(res.data);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    fetchProgramSpaces();
    // eslint-disable-next-line
  }, []);

  const handleEdgeClick = async (id) => {
    const temp = [...edges];

    const selectedEdge = temp.find((edge) => edge.id === id);

    if (selectedEdge?.type === 'languageEdge') {
      const data = await languageModalRef.current.open({
        title: 'Select the language',
        description: 'This is a conditional node. If the user has the language selected in their profile, the flow will only continue the path of the language.',
        cancelButton: 'Cancel',
        submitButton: 'Submit',
        data: selectedEdge.label
      });

      if (['english', 'german', 'french'].includes(data)) {
        selectedEdge.label = data;
        setEdges(temp);
      }
    }

    if (selectedEdge?.type === 'staffStatusEdge') {
      const data = await staffStatusModalRef.current.open({
        title: 'Select a status',
        description: 'This is a conditional node. It will get triggered if the status of the staff changes.',
        cancelButton: 'Cancel',
        submitButton: 'Submit',
        data: selectedEdge.label
      });

      if (['new_registration', 'approved', 'denied', 'cancelled', 'on_hold', 'pending', 'completed'].includes(data)) {
        selectedEdge.label = data;
        setEdges(temp);
      }
    }

    if (selectedEdge?.type === 'clientStatusEdge') {
      const data = await clientStatusModalRef.current.open({
        title: 'Select a status',
        description: 'This is a conditional node. It will get triggered if the status of the client changes.',
        cancelButton: 'Cancel',
        submitButton: 'Submit',
        data: selectedEdge.label
      });

      if (['new_application', 'validated', 'denied', 'pending'].includes(data)) {
        selectedEdge.label = data;
        setEdges(temp);
      }
    }

    if (selectedEdge?.type === 'programSpaceEdge') {
      const data = await programSpaceModalRef.current.open({
        title: 'Select a program space',
        description: 'This is a conditional node. It will get triggered if the staff is assigned to this program space.',
        cancelButton: 'Cancel',
        submitButton: 'Submit',
        data: selectedEdge.label
      });

      if (programSpaceOptions.map((o) => o.value).includes(data)) {
        selectedEdge.label = data;
        setEdges(temp);
      }
    }

    if (selectedEdge?.type === 'timerEdge') {
      const data = await timerModalRef.current.open({
        title: 'Choose a timer',
        // eslint-disable-next-line max-len
        description: 'Choose the time the workflow should wait until it continues (in hours). If this is the timer right after the event, you can also select a negative value to send for example notification/reminder before the event.',
        cancelButton: 'Cancel',
        submitButton: 'Submit',
        data: selectedEdge.label
      });

      if (data) {
        selectedEdge.label = data;
        setEdges(temp);
      }
    }
  };

  const handleNodeClick = async (id) => {
    const temp = [...edges];

    const selectedEdge = temp.find((edge) => edge.id === id);

    if (selectedEdge?.type === 'languageEdge') {
      const data = await languageModalRef.current.open({
        title: 'Select the language',
        description: 'This is a conditional node. If the user has the language selected in their profile, the flow will only continue the path of the language.',
        cancelButton: 'Cancel',
        submitButton: 'Submit',
        data: selectedEdge.label
      });

      if (['english', 'german', 'french'].includes(data)) {
        selectedEdge.label = data;
        setEdges(temp);
      }
    }

    if (selectedEdge?.type === 'staffStatusEdge') {
      const data = await staffStatusModalRef.current.open({
        title: 'Select a status',
        description: 'This is a conditional node. It will get triggered if the status of the staff changes.',
        cancelButton: 'Cancel',
        submitButton: 'Submit',
        data: selectedEdge.label
      });

      if (['new_registration', 'approved', 'denied', 'cancelled', 'on_hold', 'pending', 'completed'].includes(data)) {
        selectedEdge.label = data;
        setEdges(temp);
      }
    }

    if (selectedEdge?.type === 'programSpaceEdge') {
      const data = await programSpaceModalRef.current.open({
        title: 'Select a status',
        description: 'This is a conditional node. It will get triggered if the staff is assigned to this program space.',
        cancelButton: 'Cancel',
        submitButton: 'Submit',
        data: selectedEdge.label
      });

      if (programSpaceOptions.includes(data)) {
        selectedEdge.label = data;
        setEdges(temp);
      }
    }

    if (selectedEdge?.type === 'clientStatusEdge') {
      const data = await clientStatusModalRef.current.open({
        title: 'Select a status',
        description: 'This is a conditional node. It will get triggered if the status of the client changes.',
        cancelButton: 'Cancel',
        submitButton: 'Submit',
        data: selectedEdge.label
      });

      if (['new_application', 'denied', 'pending', 'validated'].includes(data)) {
        selectedEdge.label = data;
        setEdges(temp);
      }
    }
  };

  const getNodeId = () => `randomnode_${+new Date()}`;

  const matchParams = useParams();

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  useEffect(() => {
    if (setViewport.toString() !== '() => {}') {
      axios.get(`/v1/workflows/${matchParams.id}`)
        .then((response) => {
          setWorkflow(response.data);
          setName(response.data.name);

          if (response.data.data) {
            const { x = 0, y = 0, zoom = 1 } = response.data.data.viewport;
            setNodes(response.data.data.nodes || []);
            setEdges(response.data.data.edges || []);
            setViewport({ x, y, zoom });
          }
        })
        .catch(() => {
          setViewport({ x: 200, y: 200, zoom: 1 });
        });
    }
  // eslint-disable-next-line
  }, [setViewport]);

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();

      const params = {
        workflow: {
          name,
          data: flow
        }
      };

      if (workflow.token) {
        axios.patch(`/v1/workflows/${workflow.token}`, params)
          .then(() => {
            setShowSaved(true);
          })
          .catch(() => {})
          .then(() => {
            setTimeout(() => {
              setShowSaved(false);
            }, [1000]);
          });
      } else {
        axios.post('/v1/workflows', params)
          .then(() => {
            setShowSaved(true);
            history('/flows');
          })
          .catch(() => {})
          .then(() => {
            setTimeout(() => {
              setShowSaved(false);
            }, [1000]);
          });
      }
      // localStorage.setItem(flowKey, JSON.stringify(flow));
    }
  // eslint-disable-next-line
  }, [rfInstance, workflow, name]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      // const flow = JSON.parse(localStorage.getItem(flowKey));

      const response = await axios.get(`/v1/workflows/${matchParams.id}`);
      const flow = response.data.data;

      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };

    restoreFlow();
  // eslint-disable-next-line
  }, [setNodes, setViewport]);

  const onAdd = (type, data, x, y) => {
    const newNode = {
      id: getNodeId(),
      type,
      data,
      position: {
        x,
        y
      }
    };
    setNodes((nds) => nds.concat(newNode));
  };

  const onDrop = (event) => {
    event.preventDefault();

    const type = event.dataTransfer.getData('application/reactflow');

    const x = event.clientX;
    const y = event.clientY;

    const canvasPosition = rfInstance.project({ x, y }, rfInstance);

    onAdd(type, {}, canvasPosition.x, canvasPosition.y);
  };

  const { getNodes, getEdges } = useReactFlow();

  const isValidConnection = useCallback(
    (connection) => {
      // we are using getNodes and getEdges helpers here
      // to make sure we create isValidConnection function only once
      const ns = getNodes();
      const es = getEdges();
      const target = ns.find((node) => node.id === connection.target);
      // eslint-disable-next-line
      // const hasCycle = (node, visited = new Set()) => {
      //   if (visited.has(node.id)) return false;

      //   visited.add(node.id);

      //   // eslint-disable-next-line
      //   for (const outgoer of getOutgoers(node, ns, es)) {
      //     if (outgoer.id === connection.source) return true;
      //     if (hasCycle(outgoer, visited)) return true;
      //   }
      // };

      const source = ns.find((n) => n.id === connection.source);
      const existingConnections = es.filter((e) => e.source === connection.source).length;

      const onlyOneConnection = ['start', 'form', 'Email', 'timer'];

      if (onlyOneConnection.includes(source.type) && existingConnections >= 1) {
        return false;
      }

      return true;

      // if (target.id === connection.source) return false;
      // return !hasCycle(target);
    },
    // eslint-disable-next-line
    [getNodes, getEdges]
  );

  return (
    <div
      className={`h-full flex-1 ${showSaved ? 'border-green-200 shadow-inner border-4' : ''}`}
      onDrop={onDrop}
      onDragOver={(event) => {
        event.preventDefault();
        console.log('Dragging over');
      }}
      onDragEnd={() => console.log('Drag ended')}
    >
      <ModalService ref={languageModalRef}>
        <LanguageModal />
      </ModalService>
      <ModalService ref={staffStatusModalRef}>
        <StaffStatusChangeModal />
      </ModalService>
      <ModalService ref={clientStatusModalRef}>
        <ClientStatusChangeModal />
      </ModalService>
      <ModalService ref={programSpaceModalRef}>
        <ProgramSpaceChangeModal />
      </ModalService>
      <ModalService ref={timerModalRef}>
        <TimerModal />
      </ModalService>
      {showSaved && (
        <div className="absolute top-2 right-64 flex align-middle text-center justify-center">
          <CheckCircleIcon className="h-4 text-green-400 flex inline-block align-middle text-center justify-center" />
        </div>
      )}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onNodeClick={(_, { id }) => handleNodeClick(id)}
        onEdgesChange={onEdgesChange}
        onEdgeClick={(_, { id }) => handleEdgeClick(id)}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onInit={setRfInstance}
        isValidConnection={isValidConnection}
        // fitView
        edgeTypes={edgeTypes}
        className="bg-grey-50"
      >
        <Panel position="top-left">
          <div>
            <div className="mt-2 flex rounded-md shadow-sm">
              <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                Name
              </span>
              <input
                type="text"
                name="name"
                id="name"
                onChange={handleNameChange}
                value={name}
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter a name"
              />
            </div>
          </div>
        </Panel>
        {showSaved ? '' : (
          <Panel position="top-right">
            <button type="button" className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={onSave}>Save</button>
            {workflow.token && <button type="button" className="rounded mx-2 bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={onRestore}>Restore</button>}
          </Panel>
        )}
        <MiniMap />
        <Controls />
        <Background color="#aaa" gap={32} />
      </ReactFlow>
    </div>
  );
};

const FormSidebar = () => (
  <div
    draggable
    onDragStart={(event) => {
      event.dataTransfer.setData('application/reactflow', 'form');
      event.dataTransfer.setData('text/plain', 'This is draggable content');
    }}
    className="p-4 border-b-2 bg-white"
  >
    Form
  </div>
);

const EmailSidebar = () => (
  <div
    draggable
    onDragStart={(event) => {
      event.dataTransfer.setData('application/reactflow', 'email');
      event.dataTransfer.setData('text/plain', 'This is draggable content');
    }}
    className="p-4 border-b-2 bg-white"
  >
    Email
  </div>
);

const EndSidebar = () => (
  <div
    draggable
    onDragStart={(event) => {
      event.dataTransfer.setData('application/reactflow', 'end');
      event.dataTransfer.setData('text/plain', 'This is draggable content');
    }}
    className="p-4 border-b-2 bg-white"
  >
    End
  </div>
);

const ConditionSidebar = () => (
  <div
    draggable
    onDragStart={(event) => {
      event.dataTransfer.setData('application/reactflow', 'language');
      event.dataTransfer.setData('text/plain', 'This is draggable content');
    }}
    className="p-4 border-b-2 bg-white"
  >
    Language
  </div>
);

const StaffStatusChangeConditionSidebar = () => (
  <div
    draggable
    onDragStart={(event) => {
      event.dataTransfer.setData('application/reactflow', 'staff_status_change');
      event.dataTransfer.setData('text/plain', 'This is draggable content');
    }}
    className="p-4 border-b-2 bg-white"
  >
    Staff status
  </div>
);

const ClientStatusChangeConditionSidebar = () => (
  <div
    draggable
    onDragStart={(event) => {
      event.dataTransfer.setData('application/reactflow', 'client_status_change');
      event.dataTransfer.setData('text/plain', 'This is draggable content');
    }}
    className="p-4 border-b-2 bg-white"
  >
    Client status
  </div>
);

const TimerConditionSidebar = () => (
  <div
    draggable
    onDragStart={(event) => {
      event.dataTransfer.setData('application/reactflow', 'timer');
      event.dataTransfer.setData('text/plain', 'This is draggable content');
    }}
    className="p-4 border-b-2 bg-white"
  >
    Timer
  </div>
);

const ProgramSpaceConditionSidebar = () => (
  <div
    draggable
    onDragStart={(event) => {
      event.dataTransfer.setData('application/reactflow', 'has_program_space');
      event.dataTransfer.setData('text/plain', 'This is draggable content');
    }}
    className="p-4 border-b-2 bg-white"
  >
    Program space
  </div>
);

export default () => (
  <ReactFlowProvider>
    <DndProvider backend={HTML5Backend}>
      <div className="flex h-full w-full">
        <Flow onEdgeClick={() => { debugger; }} />
        <div className="border-l-2 border-black-300 w-60">
          <div className="p-2 font-medium bg-white border-b-2">
            Nodes
          </div>
          <FormSidebar />
          <EmailSidebar />
          <EndSidebar />
          <div className="p-2 font-medium bg-white border-b-2">
            Conditions
          </div>
          <ConditionSidebar />
          <StaffStatusChangeConditionSidebar />
          <ClientStatusChangeConditionSidebar />
          <ProgramSpaceConditionSidebar />
          <TimerConditionSidebar />
        </div>
      </div>
    </DndProvider>
  </ReactFlowProvider>
);
