import React from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import showdown from 'showdown';

const DisplayForm = (props) => {
  const survey = new Model(props.form_data);

  // Apply the theme if provided
  if (props.theme) {
    survey.applyTheme(JSON.parse(props.theme));
  }

  if (props.reply) {
    if (typeof props.reply === 'string') {
      survey.data = JSON.parse(props.reply);
    } else if (Array.isArray(props.reply)) {
      survey.data = props.reply;
    }
  }

  survey.onTextMarkdown.add((_survey, options) => {
    const converter = new showdown.Converter();
    const html = converter.makeHtml(options.text);
    // eslint-disable-next-line no-param-reassign
    options.html = html;
  });

  survey.mode = 'display';

  // You can also disable the complete button since it's a view-only survey
  survey.showCompleteButton = false;

  return (
    <div className="no-twp">
      <Survey model={survey} />
    </div>
  );
};

export default DisplayForm;
