import axios from 'axios';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Stack, Filters, Card, Loading, Page, ResourceList, TextStyle, ResourceItem, Avatar, SkeletonPage } from '@shopify/polaris';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { HideMinor, ViewMinor } from '@shopify/polaris-icons';
import UserModalForm from './Edit/UserModalForm';
import FlashBanners from '../Shared/Banners';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import roleFilterDefinition from '../FilterEngine/Role/roleFilterDefinition';
import roleFilterActive from '../FilterEngine/Role/roleFilterActive';
import { handleFilterChange, searchFilterToURL, appliedFiltersQuery } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import StatusBadge from '../Shared/StatusBadge';

const Users = () => {
  const [banner, setBanner] = useState([]);
  const [modalBanner, setModalBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [records, setRecords] = useState([]);
  const [showUserModalForm, setShowUserModalForm] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [init, setInit] = useState(false);
  const [lastPage, setLastPage] = useState(null);

  const history = useNavigate();
  const location = useLocation();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const usersRef = useRef();

  const filters = [
    roleFilterDefinition({ history, location }, setTableFilters, tableFilters.role)
  ];

  const appliedFilters = useCallback(() => {
    const af = [];

    if (tableFilters.role.length) af.push(roleFilterActive({ history, location }, setTableFilters, tableFilters.role));

    return af;
  }, [history, location, tableFilters.role]);

  const fetchUsers = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters())
    };

    axios.post('/v1/users/search', params)
      .then((res) => {
        if (mounted) {
          setRecords(res.data.users);
          setLastPage(res.data.lastPage);
          usersRef.current = res.data.users;
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setBanner([{ title: 'Cannot get list of users', status: 'critical', details: error.response.data.error }]);
      });
  // We are very explicit here on when we want to make a new request
  // eslint-disable-next-line
  }, [debouncedSearch]);

  const handleActiveToggle = (token, action) => {
    if (!window.confirm(`Are you sure you wish to ${action} this user?`)) {
      return;
    }

    axios.post(`/v1/users/${token}/${action}`)
      .then(() => {
        const updatedUsers = usersRef.current;
        const userUpdate = updatedUsers.findIndex(((c) => c.token === token));
        const updatedData = { active: action === 'activate' };
        updatedUsers[userUpdate] = { ...updatedUsers[userUpdate], ...updatedData };
        usersRef.current = updatedUsers;
        setRecords(usersRef.current);
        setBanner([{ title: `User ${action}d`, status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: `Cannot ${action} users`, status: 'critical', details: error.response.data.errors }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      });
  };

  const handleNew = (formData) => {
    const userData = {
      user: formData
    };

    axios.post('/v1/users/admin_create', userData)
      .then(() => {
        fetchUsers();
        setShowUserModalForm(false);
      })
      .catch((error) => {
        setModalBanner([{ title: 'Cannot add user', status: 'critical', details: error.response.data.errors }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      });
  };

  const showUser = (token) => {
    history(`/users/${token}`);
  };

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/users', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder="Filter by User Name or Email"
    />
  );

  useEffect(() => {
    setMounted(true);
    fetchUsers();
    return () => {
      setMounted(false);
    };
  }, [fetchUsers]);

  useEffect(() => {
    if (init) {
      setTableFilters((f) => ({ ...f, page: 1 }));
      searchFilterToURL({ history, location }, 'page', null, 'delete');
    }
    setInit(true);
  // eslint-disable-next-line
  }, [tableFilters.queryValue, tableFilters.role, tableFilters.userType]);

  const dismissBanner = () => {
    setBanner([]);
  };

  const cleanup = () => {
    setShowUserModalForm(false);
    setModalBanner([]);
  };

  let userForm = null;
  if (showUserModalForm) {
    userForm = (
      <UserModalForm
        title="Add new user"
        banner={modalBanner}
        newUser
        handleSubmit={handleNew}
        cleanup={cleanup}
      />
    );
  }

  const addNewUser = () => {
    setShowUserModalForm(true);
  };

  const resourceName = {
    singular: 'User',
    plural: 'Users'
  };

  let loadingContainer = null;
  if (loading) {
    loadingContainer = <Loading />;
  }

  const renderItem = (item) => {
    const { token, email, name, initials, active, role } = item;
    const actions = [];
    if (active) {
      actions.push({
        content: 'Inactivate',
        destructive: true,
        icon: HideMinor,
        onAction: () => handleActiveToggle(token, 'inactivate')
      });
    } else {
      actions.push({
        content: 'Activate',
        icon: ViewMinor,
        onAction: () => handleActiveToggle(token, 'activate')
      });
    }

    const media = <Avatar size="medium" name={name} initials={initials} />;

    return (
      <ResourceItem
        id={token}
        media={media}
        showHeader
        accessibilityLabel={`View details for ${item.name}`}
        shortcutActions={actions}
        onClick={() => showUser(token)}
      >
        <Stack>
          <TextStyle variation="strong">{email}</TextStyle>
          <StatusBadge active={active} />
        </Stack>
        <div>
          {`${name} (${role}) `}
        </div>
        <div>
          {`Registered on ${moment(item.createdAt).format('LLLL')}`}
        </div>
      </ResourceItem>
    );
  };

  return (
    <Page
      title="User Management"
      primaryAction={{ content: 'Add new user', onAction: () => addNewUser() }}
      separator
    >
      <FlashBanners banners={banner} onDismissBanner={dismissBanner} />
      {userForm}
      {loadingContainer}
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            <ResourceList
              resourceName={resourceName}
              items={records}
              renderItem={renderItem}
              selectedItems={selectedItems}
              onSelectionChange={setSelectedItems}
            />
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={records}
        lastPage={lastPage}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  admin: state.auth.admin
});

export default connect(mapStateToProps)(Users);
