/* eslint-disable arrow-body-style */
import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Tabs = (props) => {
  return (props.tabs.length ? (
    <div className="m-2">
      <div className="sm:hidden">
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={props.tabs?.[0]}
          onChange={props.onChange}
        >
          {props.tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8 overflow-x-auto" aria-label="Tabs">
            {props.tabs.map((tab) => (
              <button
                type="button"
                key={tab.name}
                onClick={() => props.onChange({ target: { value: tab.name } })}
                className={classNames(
                  tab.name === props.selected
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.name === props.selected ? 'page' : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  ) : '');
};

export default Tabs;
