import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const ref = useRef();
  const location = useLocation();

  useEffect(() => {
    if (ref.current !== location.pathname) window.scrollTo(0, 0);
    ref.current = location.pathname;
  }, [location]);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default ScrollToTop;
