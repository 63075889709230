import { handleFilterChange } from '../FilterEngine';

const clientFilterActive = (t, props, setTableFilters, clients, client, filter = 'client_token_eq') => ({
  key: 'client',
  label: `${t('client.client')}: ${(clients.find((elm) => elm.value === client) || {}).label}`,
  onRemove: handleFilterChange(props, setTableFilters, 'client', 'reset', ''),
  filter,
  value: client
});

export default clientFilterActive;
