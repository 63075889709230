/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Button, Card, EmptyState, Heading, Layout, Stack, TextStyle, Spinner, FormLayout } from '@shopify/polaris';
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import LoginModal from './LoginModal';
import BookingPage from './BookingPage';
import Banners from '../Shared/Banners';
import BookingAvatar from './BookingAvatar';
import ProhibitedPage from './ProhibitedPage';
import PnbBadge from '../Tailwind/PnbBadge';
import ViewableProducts from './ViewableProducts';
import PublicProduct from './PublicProduct';
import Badges from '../Shared/Badges';
import * as actions from '../../store/actions/index';

const PublicProfile = (props) => {
  const [prohibited, setProhibited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [banner, setBanner] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [product, setProduct] = useState({
    template: [],
    booking_tags: [],
    products: []
  });

  const { t } = useTranslation();
  const [booking, setBooking] = useState({});
  const location = useLocation();
  const history = useNavigate();
  const matchParams = useParams();

  let queryParams = new URLSearchParams(location.search);
  const hideNav = queryParams.get('hideNav');

  useEffect(() => {
    props.onTryAutoSignin();
  // eslint-disable-next-line
  }, [props.onTryAutoSignin]);

  const fetchProfile = useCallback(() => {
    const newProfileToken = queryParams.get('profile_token');
    const token = queryParams.get('token');

    setProhibited(false);
    setLoading(true);

    if (newProfileToken) {
      axios.get(`/v1/public_profiles/${matchParams.name}/search_user_profile?userToken=${newProfileToken}`)
        .then((res) => {
          setProduct(res.data);
          props.setIndividual(res.data);
          props.setSelected(res.data);
          props.hideSearch(res.data);
          history(`/booking/${matchParams.name}?tab=booking&profile_token=${res.data.token}`);
        })
        .catch((error) => {
          if (error.response?.data?.error?.message === 'Access denied') {
            setProhibited(true);
          }
        })
        .then(() => {
          setLoading(false);
        });
    } else if (!newProfileToken && !token) {
      props.setSelected('');
      props.setIndividual({});
      props.hideSearch(false);
    }
  // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  const goBackChange = () => {
    props.setSelected('');
    props.setIndividual({});
    props.hideSearch(false);
    queryParams = new URLSearchParams(location.search);
    queryParams.delete('profile_token');
    const productOnly = queryParams.get('productOnly');

    if (productOnly) {
      history(`/booking/${matchParams.name}?tab=booking&productOnly=true`);
    } else {
      history(`/booking/${matchParams.name}?tab=booking`);
    }
  };

  let loginModalForm = null;

  if (showLoginModal) {
    loginModalForm = (
      <LoginModal
        close={() => setShowLoginModal(false)}
      />
    );
  }

  const productpage = (
    !Object.keys(booking).length ? (
      <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1100px' }}>
        <div className="flex justify-center">
          <Badges data={product.booking_tags.filter((tag) => tag.language === (props.lang || 'en'))} textOnly class="text-center" />
        </div>
        <nav className="flex mb-8 pb-1 border-b items-center" aria-label="Breadcrumb">
          {hideNav !== 'true' ? (
            <ol className="flex flex-1 items-center space-x-4">
              <li>
                <button type="button" className="text-gray-400 hover:text-gray-500" onClick={() => goBackChange()}>
                  <HomeIcon className="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                  <span className="sr-only">Home</span>
                </button>
              </li>
              {props.individual?.name && (
                <li>
                  <div className="flex items-center font-medium text-md">
                    <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                    {props.individual?.name}
                  </div>
                </li>
              )}
              {product.title && (
                <li>
                  <div className="flex items-center font-medium text-xl">
                    <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400 mr-4" aria-hidden="true" />
                    {product.title}
                  </div>
                </li>
              )}
            </ol>
          ) : (
            <div className="flex flex-1 font-medium text-xl">
              {product.title}
            </div>
          )}
          {props.user.token ? (
            <BookingAvatar />
          ) : (
            <Button plain onClick={() => setShowLoginModal(true)}>{t('product.login')}</Button>
          )}
        </nav>
        {/* put profile here */}
        {product.template.filter((profile) => profile.language === (props.lang || 'en')).map((profile) => (
          <div dangerouslySetInnerHTML={{ __html: profile.value }} />
        ))}
        {product.description && (
          <div className="mt-4">
            <Card sectioned>
              <div dangerouslySetInnerHTML={{ __html: product.description }} />
            </Card>
          </div>
        )}
        <ViewableProducts products={product.products} setSelected={setSelectedProduct} />
      </div>
    ) : (
      <FormLayout>
        {/* show products here */}
        <BookingPage booking={booking} setBooking={setBooking} />
      </FormLayout>
    )
  );

  return (
    <div className="bg-white">
      {loginModalForm}
      <Banners banners={banner} onDismissBanner={() => setBanner([])} />
      {selectedProduct.token ? (
        <div>
          <PublicProduct publicProfile={props.publicProfile} token={selectedProduct.token} setSelected={setSelectedProduct} hideSearch={props.setSelected} individual={props.individual} setIndividual={props.setIndividual} profileToken={props.individual?.token} type="profile" />
          {/* <Button onClick={() => setSelectedProduct({})}>Back</Button> */}
        </div>
      ) : (
        prohibited ? <ProhibitedPage setSelected={props.setSelected} hideSearch={props.hideSearch} /> : loading ? <Spinner /> : productpage
      )}
    </div>
  );
};

const mapPropsToDispatch = (dispatch) => ({
  onTryAutoSignin: () => dispatch(actions.authCheckState())
});

const mapStateToProps = (state) => ({
  user: state.auth,
  lang: state.auth.lang
});

export default connect(mapStateToProps, mapPropsToDispatch)(PublicProfile);
