import React from 'react';
import { ChoiceList } from '@shopify/polaris';
import { handleFilterChange } from '../FilterEngine';

const roleFilterDefinition = (props, setTableFilters, selected) => ({
  key: 'role',
  label: 'Role',
  filter: (
    <ChoiceList
      title="Roles"
      titleHidden
      choices={[{
        label: 'Admin',
        value: 'admin'
      }, {
        label: 'Instructor',
        value: 'instructor'
      }, {
        label: 'Client',
        value: 'client'
      }]}
      selected={selected || []}
      onChange={handleFilterChange(props, setTableFilters, 'role')}
      allowMultiple
    />
  ),
  shortcut: true
});

export default roleFilterDefinition;
