import { handleFilterChange } from '../FilterEngine';

// eslint-disable-next-line default-param-last
const programSpaceFilterActive = (t, props, setTableFilters, selected, options, filter = 'program_space_eq') => {
  const selectedFilter = options.find((o) => o.value === selected);

  return {
    key: 'program_space',
    label: `Program: ${selectedFilter?.label}`,
    onRemove: handleFilterChange(props, setTableFilters, 'program_space', 'reset', ''),
    filter,
    value: selected
  };
};

export default programSpaceFilterActive;
