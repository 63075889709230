/* eslint-disable no-unused-vars */
import React from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';

const options = [{ label: '', value: '' }, { label: 'English', value: 'english' }, { label: 'German', value: 'german' }, { label: 'French', value: 'french' }];

const LanguageEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  label,
  data
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} type="smoothstep" />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: '#03fc98',
            padding: 5,
            borderRadius: 5,
            fontSize: 12,
            fontWeight: 700
          }}
          className="nodrag nopan"
        >
          {options.find((option) => option.value === label).label}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default LanguageEdge;
