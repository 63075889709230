import React from 'react';
import { Icon } from '@shopify/polaris';
import { CircleTickMajor, CircleCancelMajor } from '@shopify/polaris-icons';

const StatusBadge = (props) => {
  const color = props.active ? 'success' : 'critical';
  const icon = props.active ? CircleTickMajor : CircleCancelMajor;
  return <Icon backdrop source={icon} color={color} />;
};

export default StatusBadge;
