/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import useDebounce from '../../hooks/useDebounce';
import PermissionDropDown from './PermissionDropdown';
import { useTranslation } from 'react-i18next';


const Permissions = (props) => {
  const { t } = useTranslation();

  const permssionOptions = [
    { title: t('media_library.permissions.view'), description: t('media_library.permissions.view_desc'), current: true },
    { title: t('media_library.permissions.write'), description: t('media_library.permissions.write_desc'), current: false }
  ];

  const [selectedPermission, setSelectedPermission] = useState(permssionOptions[0]);
  const [inputValue, setValue] = useState('');
  const [selected, setSelected] = useState({});
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(false);
  const [notification, setNotification] = useState('');

  // const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(inputValue, 400);

  const handleChange = (value) => {
    if (value) {
      setLoading(true);
    }

    if (!value && !selected) {
      props.setShowShare(false);
    }

    setValue(value);
  };

  const handleNotify = (event) => {
    setNotify(event.target.checked);
  }

  const handleNotification = (value) => {
    setNotification(value.target.value);
  }

  const handleShare = () => {
    const params = {
      user_id: selected?.value,
      permission: selectedPermission?.title || t('media_library.permissions.view'),
      notify,
      notification
    }

    props.createPermissions(params)
    setSelected({});
    setValue('');
  };

  const fetchDefault = useCallback(() => {
    const params = {
      per_page: 30,
      search: inputValue,
      q: '',
      s: ''
    };

    axios.post(`/v1/media_libraries/${props.mediaLibraryToken}/media_library_files/${props.token}/open_permissions`, params)
      .then((res) => {
        setDefaultOptions(res.data.permissions);
      })
      .catch(() => {
      })
      .then(() => setLoading(false));
  // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    fetchDefault();
  }, [fetchDefault]);

  // create paginated table
  return (
    <div className="react-select">
      <div className="flex mb-2">
        <div className="relative flex flex-1 items-start m-2">
          <div className="flex items-center h-5">
            <input
              id="notify"
              aria-describedby="notify"
              name="notify"
              onChange={handleNotify}
              value={notify}
              type="checkbox"
              className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"
            />
          </div>
          <div className="ml-2 text-sm">
            <label htmlFor="notify" className="font-medium text-gray-700">
              {t('media_library.permissions.notify')}
            </label>
          </div>
        </div>
        <PermissionDropDown selected={selectedPermission} setSelected={setSelectedPermission} permssionOptions={permssionOptions} />
      </div>
      <Select
        // cacheOptions
        inputValue={inputValue}
        isClearable
        onInputChange={handleChange}
        value={selected}
        onChange={setSelected}
        // noOptionsMessage="Loading"
        isLoading={loading}
        defaultOptions={defaultOptions}
        options={defaultOptions}
        getOptionValue={(user) => user.value}
        getOptionLabel={(user) => user.label}
      />
      {notify && (
        <textarea
          rows={4}
          name="description"
          value={notification}
          className="p-4 mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border border-gray-300 rounded-md"
          onChange={handleNotification}
        />
      )}
      <button
        type="button"
        onClick={() => handleShare()}
        className="bg-white mt-2 py-1 px-2 w-full border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {t('media_library.share')}
      </button>
    </div>
  );
};

export default Permissions;
