import { handleFilterChange } from '../FilterEngine';

const languageFilterActive = (t, props, setTableFilters, languages, filter = 'languages_label_in') => {
  const label = languages.map((tag) => tag.label)?.sort().join(', ');

  return {
    key: 'languages',
    label: `${t('user.language')}: ${label}`,
    onRemove: handleFilterChange(props, setTableFilters, 'languages', 'reset', []),
    filter,
    value: languages.map((tag) => tag.label)
  };
};

export default languageFilterActive;
