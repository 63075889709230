import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page } from '@shopify/polaris';
import Calendar from './Calendar';

const Client = (props) => {
  const { t } = useTranslation();
  const history = useNavigate();

  return (
    <div className="Client">
      <Page
        title={props.calendarName}
        fullWidth
        separator
        breadcrumbs={[{ content: t('shared.back'), onAction: () => history(-1) }]}
      >
        <Calendar />
      </Page>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin,
  isInstructor: state.auth.instructor,
  calendarName: state.calendar.name
});

export default connect(mapStateToProps)(Client);
