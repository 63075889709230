import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormLayout, Modal, TextField, Form, Badge, Stack, TextStyle, Icon, Button, Tooltip } from '@shopify/polaris';
import { AlertMinor, EditMinor, InfoMinor } from '@shopify/polaris-icons';
import Datetime from 'react-datetime';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import Search from '../Shared/Search';
import SwitchToggle from '../Shared/SwitchToggle';
import Banners from '../Shared/Banners';

const EventEdit = (props) => {
  const [event, setEvent] = useState({ ...props.event });
  const [showLocation, setShowLocation] = useState(props.event.meta?.location);
  const [participations, setParticipations] = useState([]);
  const [showAddParticipations, setShowAddParticipations] = useState(false);
  const [onlineEvent, setOnlineEvent] = useState(props.event.online);
  const [clients, setClients] = useState([]);
  const [showTitle, setShowTitle] = useState(props.event.meta?.show_title);
  const [showParticipationLimit, setShowParticipationLimit] = useState(false);
  const [banner, setBanner] = useState([]);

  const { t } = useTranslation();

  const history = useNavigate();

  useEffect(() => {
    setShowParticipationLimit(Boolean(event.participation_limit));
  }, [event.participation_limit]);

  const handleSubmit = useCallback(() => {
    if (event.participation_limit && event.signedInParticipations?.length > event.participation_limit) {
      setBanner([{ title: t('calendar.increase_participation_limit'), status: 'critical' }]);
      return;
    }

    event.meta = {
      location: showLocation,
      show_title: showTitle
    };
    event.start_time = event.start;
    event.end_time = event.end;
    props.handleSubmit(event);
    props.close();
  }, [event, showLocation, showTitle, props, t]);

  const handleDelete = useCallback(() => {
    props.close();
    props.handleDelete(event);
  }, [props, event]);

  const handleChange = (key) => (value) => {
    setEvent({ ...event, [key]: value });
  };

  const handleTimeChange = (key) => (value) => {
    setEvent({ ...event, [key]: new Date(value) });
  };

  const participationBadges = () => {
    const badges = (participations || []).map((p) => (
      <Badge key={p.id}>{p.label}</Badge>
    ));

    return <Stack>{badges}</Stack>;
  };

  const fetchClients = useCallback(() => {
    const params = {
      per_page: 1000,
      page: 1,
      search: '',
      q: ''
    };

    axios.post('/v1/clients/event_search', params)
      .then((res) => {
        setClients(res.data);
      })
      .catch(() => {});
    // eslint-disable-next-line
  }, []);

  const fetchDetails = () => {
    const params = {
      date: event.start_time
    };

    axios.post(`/v1/events/${event.id}/details`, params)
      .then((response) => {
        setEvent(response.data.event);
        setParticipations(response.data.participations);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchDetails();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showAddParticipations) {
      fetchClients();
    }
  }, [showAddParticipations, fetchClients]);

  const participationAdd = clients.length ? (
    <Search
      showTags
      allowMultiple
      selectedData={event.signedInParticipations}
      handleChange={handleChange('signedInParticipations')}
      deselectedOptions={clients}
      placeholder={t('client.clients')}
    />
  ) : null;

  const renderInput = (_, openCalendar) => (
    <Button plain type="button" onClick={openCalendar}>{t('shared.change')}</Button>
  );

  const toggleParticipationLimit = () => {
    if (showParticipationLimit) {
      handleChange('participation_limit')('');
    }

    setShowParticipationLimit(!showParticipationLimit);
  };

  const toggleShowLocation = () => {
    if (showLocation) {
      handleChange('location')('');
    }

    setShowLocation(!showLocation);
  };

  const handleOnlineChange = (value) => {
    setOnlineEvent(value);
    setEvent({ ...event, online: value });
  };

  const eventOptions = () => (
    <FormLayout>
      <div className="mb-8">
        <Stack>
          <TextStyle>{t('shared.participants')}</TextStyle>
          {!showAddParticipations ? (
            <Button plain onClick={() => setShowAddParticipations(true)}>
              <Icon color="success" source={EditMinor} />
            </Button>
          ) : null}
        </Stack>
        {showAddParticipations ? participationAdd : participationBadges()}
      </div>
      <TextStyle>{t('shared.start')}</TextStyle>
      <Stack>
        <TextStyle>{moment(event.start).format('LLLL')}</TextStyle>
        <Datetime
          renderInput={renderInput}
          timeConstraints={{ minutes: { step: 15 } }}
          value={new Date(event.start)}
          onChange={handleTimeChange('start')}
        />
      </Stack>
      <TextStyle>{t('shared.end')}</TextStyle>
      <Stack>
        <TextStyle>{moment(event.end).format('LLLL')}</TextStyle>
        <Datetime
          renderInput={renderInput}
          timeConstraints={{ minutes: { step: 15 } }}
          value={new Date(event.end)}
          onChange={handleTimeChange('end')}
        />
      </Stack>
      <br />
      {props.hasInvoiceAccess && (
      <Stack vertical spacing="tight">
        <Stack alignment="center">
          <Stack.Item fill>
            <Stack wrap={false} spacing="tight" alignment="center">
              <TextStyle>{t('shared.price')}</TextStyle>
              <Tooltip content={t('events.price_info')}>
                <Icon color="critical" source={AlertMinor} />
              </Tooltip>
            </Stack>
          </Stack.Item>
        </Stack>
        <TextField
          value={event.price}
          onChange={handleChange('price')}
          helpText={t('invoices.each_participant_gets_billed')}
        />
      </Stack>
      )}
      <TextField
        multiline={1}
        label={t('shared.description')}
        value={event.description}
        onChange={handleChange('description')}
      />
      <Stack alignment="center">
        <Stack.Item fill>
          <TextStyle>{t('shared.title')}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <SwitchToggle
            handleChange={() => {
              handleChange('title')('');
              setShowTitle(!showTitle);
            }}
            checked={showTitle}
          />
        </Stack.Item>
      </Stack>
      {showTitle ? (
        <TextField
          value={event.title}
          onChange={handleChange('title')}
        />
      ) : null}
      <Stack alignment="center">
        <Stack.Item fill>
          <TextStyle>{t('shared.participation_limit')}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <SwitchToggle
            handleChange={() => toggleParticipationLimit()}
            checked={showParticipationLimit}
          />
        </Stack.Item>
      </Stack>
      {showParticipationLimit && (
      <TextField
        type="number"
        min={0}
        value={(event.participation_limit || '').toString()}
        onChange={handleChange('participation_limit')}
      />
      )}
      <Stack alignment="center">
        <Stack.Item fill>
          <TextStyle>{t('address.location')}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <SwitchToggle
            handleChange={() => toggleShowLocation()}
            checked={showLocation}
          />
        </Stack.Item>
      </Stack>
      {showLocation && (
      <>
        <TextField
          type="string"
          value={event.location || ''}
          onChange={handleChange('location')}
        />
        <br />
      </>
      )}
      <Stack alignment="center" spacing="tight">
        <Stack.Item fill>
          <Stack wrap={false} spacing="tight" alignment="center">
            <TextStyle>Online</TextStyle>
            {!props.canDoOnlineEvent ? (
              <Tooltip
                content={(
                  <FormLayout>
                    {t('settings.google_calendar_info')}
                  </FormLayout>
                  )}
              >
                <Icon color="highlight" source={InfoMinor} />
              </Tooltip>
            ) : ''}
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <SwitchToggle
            disabled={!props.canDoOnlineEvent}
            handleChange={handleOnlineChange}
            checked={onlineEvent}
          />
        </Stack.Item>
      </Stack>
    </FormLayout>
  );

  const secondaryActions = () => {
    const actions = [{
      destructive: true,
      content: t('shared.delete'),
      onAction: handleDelete
    }];

    if (!props.hideSettings) {
      actions.unshift({
        content: t('settings.settings'),
        onAction: () => history(`/events/${event.id}?calendar=true`)
      });
    }

    return actions;
  };

  return (
    <Modal
      open={props.active}
      onClose={() => props.close()}
      title={t('shared.event_edit')}
      primaryAction={{
        content: t('shared.save'),
        onAction: handleSubmit
      }}
      secondaryActions={secondaryActions()}
    >
      <Modal.Section>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <Banners banners={banner} onDismissBanner={() => setBanner([])} />
            {eventOptions()}
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  canDoOnlineEvent: state.auth.canDoOnlineEvent,
  hasInvoiceAccess: state.auth.hasInvoiceAccess
});

export default connect(mapStateToProps)(EventEdit);
