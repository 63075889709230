import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormLayout, Modal, TextField, Stack, TextStyle } from '@shopify/polaris';
import AddressForm from '../Address/Address';
import SwitchToggle from './SwitchToggle';

const SavedAddressModalForm = (props) => {
  const [savedAddressData, setSavedAddressData] = useState(props.savedAddress);
  const [address, setAddress] = useState(savedAddressData.address || {});
  const [show, setShow] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const addressFormErrorMessages = useRef();

  const { t } = useTranslation();

  const closeModal = () => {
    setShow(false);
    props.cleanup();
  };

  const handleChange = (field) => (value) => setSavedAddressData({ ...savedAddressData, [field]: value });
  const handleAddressChange = (field) => (value) => {
    setAddress({ ...address, [field]: value });
  };

  const handlePreferredChange = (value) => {
    setSavedAddressData({ ...savedAddressData, preferred: value });
  };

  const validateForm = () => {
    let result = true;
    setFormErrors({});
    if (!savedAddressData.label) {
      setFormErrors({ ...formErrors, label: 'Label is required' });
      result = false;
    }

    return result;
  };

  const onSubmit = () => {
    let formValid = true;

    if (!validateForm()) {
      formValid = false;
    }

    if (formValid) {
      props.handleSubmit({
        saved_addresses_attributes: [{
          id: savedAddressData.id,
          label: savedAddressData.label,
          preferred: savedAddressData.preferred,
          address_attributes: {
            id: address.id,
            attention_name: address.attentionName,
            address: address.address,
            address_line_two: address.addressLineTwo,
            city: address.city,
            state: address.state,
            zip_code: address.zipCode
          }
        }]
      });
    }
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title={props.title}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <Stack alignment="center">
              <Stack.Item fill>
                <TextStyle>{t('address.preferred')}</TextStyle>
              </Stack.Item>
              <Stack.Item>
                <SwitchToggle
                  handleChange={handlePreferredChange}
                  checked={savedAddressData.preferred}
                />
              </Stack.Item>
            </Stack>
            <TextField label={t('shared.label')} value={savedAddressData.label} onChange={handleChange('label')} error={formErrors.label} />
          </FormLayout>
          <br />
          <AddressForm
            handleChange={handleAddressChange}
            showAttentionName
            address={address}
            ref={addressFormErrorMessages}
          />
          <br />
          <FormLayout>
            <Button primary submit>{t('shared.save')}</Button>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

export default SavedAddressModalForm;
