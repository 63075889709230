import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Datetime from 'react-datetime';
import { TextField, ButtonGroup, Button, TextStyle, Stack, FormLayout } from '@shopify/polaris';
import { RRule, RRuleSet, rrulestr } from 'rrule';

const RRuleCreator = (props) => {
  const { t } = useTranslation();

  const handleChange = (field) => (value) => {
    props.handleRruleDataChange({ ...props.rruleData, [field]: value });
  };

  const showCountChange = () => {
    props.handleRruleDataChange({ ...props.rruleData, showCount: !props.rruleData.showCount });
  };

  const checkIfPressed = (day) => {
    const index = props.rruleData.byweekday.indexOf(day);
    if (index > -1) {
      return true;
    }

    return false;
  };

  const byweekdays = (day) => {
    const index = props.rruleData.byweekday.indexOf(day);
    const temp = [...props.rruleData.byweekday];
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(day);
    }

    props.handleRruleDataChange({ ...props.rruleData, byweekday: temp });
  };

  const renderInput = (_, openCalendar) => (
    <Button plain type="button" onClick={openCalendar}>{t('shared.change')}</Button>
  );

  return (
    <>
      <FormLayout>
        <TextStyle>{t('shared.start')}</TextStyle>
        <Stack>
          <TextStyle>{moment(props.rruleData.startTime).format('LLLL')}</TextStyle>
          <Datetime
            renderInput={renderInput}
            timeConstraints={{ minutes: { step: 15 } }}
            value={new Date(props.rruleData.startTime)}
            onChange={handleChange('startTime')}
          />
        </Stack>
      </FormLayout>
      <br />
      <ButtonGroup segmented fullWidth>
        <Button pressed={checkIfPressed('mo')} onClick={() => byweekdays('mo')}>{t('calendar.mo')}</Button>
        <Button pressed={checkIfPressed('tu')} onClick={() => byweekdays('tu')}>{t('calendar.tu')}</Button>
        <Button pressed={checkIfPressed('we')} onClick={() => byweekdays('we')}>{t('calendar.we')}</Button>
        <Button pressed={checkIfPressed('th')} onClick={() => byweekdays('th')}>{t('calendar.th')}</Button>
        <Button pressed={checkIfPressed('fr')} onClick={() => byweekdays('fr')}>{t('calendar.fr')}</Button>
        <Button pressed={checkIfPressed('sa')} onClick={() => byweekdays('sa')}>{t('calendar.sa')}</Button>
        <Button pressed={checkIfPressed('su')} onClick={() => byweekdays('su')}>{t('calendar.su')}</Button>
      </ButtonGroup>
      <br />
      <TextField
        min="1"
        label={props.rruleData.interval === '1' ? t('events.every_week') : `${t('events.every')} ${props.rruleData.interval} ${t('events.weeks')}`}
        type="number"
        value={props.rruleData.interval}
        onChange={handleChange('interval')}
      />
      <br />
      <ButtonGroup segmented fullWidth>
        <Button pressed={props.rruleData.showCount} onClick={showCountChange}>{t('shared.number_of_events')}</Button>
        <Button pressed={!props.rruleData.showCount} onClick={showCountChange}>{t('shared.end')}</Button>
      </ButtonGroup>
      <br />
      {!props.rruleData.showCount ? (
        <TextField
          type="date"
          label={t('shared.end')}
          value={props.rruleData.endTime}
          onChange={handleChange('endTime')}
        />
      ) : null}
      {props.rruleData.showCount ? (
        <TextField
          label={t('shared.number_of_events')}
          min="1"
          type="number"
          value={props.rruleData.count}
          onChange={handleChange('count')}
        />
      ) : null}
      <br />
      <TextField
        label={t('shared.duration_in_hours')}
        value={props.rruleData.duration}
        onChange={handleChange('duration')}
      />
    </>
  );
};

export const RRuleCreatorProduct = (props) => {
  const { t } = useTranslation();

  const handleChange = (field) => (value) => {
    props.handleRruleDataChange({ ...props.rruleData, [field]: value });
  };

  const checkIfPressed = (day) => {
    const index = props.rruleData.byweekday.indexOf(day);
    if (index > -1) {
      return true;
    }

    return false;
  };

  const byweekdays = (day) => {
    const index = props.rruleData.byweekday.indexOf(day);
    const temp = [...props.rruleData.byweekday];
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(day);
    }

    props.handleRruleDataChange({ ...props.rruleData, byweekday: temp });
  };

  const renderInput = (_, openCalendar) => (
    <Button plain type="button" onClick={openCalendar}>{t('shared.change')}</Button>
  );

  return (
    <>
      <FormLayout>
        <TextStyle>{t('shared.start')}</TextStyle>
        <Stack>
          <TextStyle>{moment(props.rruleData.startTime).format('LLLL')}</TextStyle>
          <Datetime
            renderInput={renderInput}
            timeConstraints={{ minutes: { step: 15 } }}
            value={new Date(props.rruleData.startTime)}
            onChange={handleChange('startTime')}
          />
        </Stack>
        <TextField
          label={t('shared.duration_in_hours')}
          value={props.rruleData.duration}
          onChange={handleChange('duration')}
        />
        <TextField
          type="date"
          label={t('shared.end')}
          value={props.rruleData.endTime}
          onChange={handleChange('endTime')}
        />
      </FormLayout>
      <br />
      <ButtonGroup segmented fullWidth>
        <Button pressed={checkIfPressed('mo')} onClick={() => byweekdays('mo')}>{t('calendar.mo')}</Button>
        <Button pressed={checkIfPressed('tu')} onClick={() => byweekdays('tu')}>{t('calendar.tu')}</Button>
        <Button pressed={checkIfPressed('we')} onClick={() => byweekdays('we')}>{t('calendar.we')}</Button>
        <Button pressed={checkIfPressed('th')} onClick={() => byweekdays('th')}>{t('calendar.th')}</Button>
        <Button pressed={checkIfPressed('fr')} onClick={() => byweekdays('fr')}>{t('calendar.fr')}</Button>
        <Button pressed={checkIfPressed('sa')} onClick={() => byweekdays('sa')}>{t('calendar.sa')}</Button>
        <Button pressed={checkIfPressed('su')} onClick={() => byweekdays('su')}>{t('calendar.su')}</Button>
      </ButtonGroup>
      <br />
      <TextField
        min="1"
        label={props.rruleData.interval === '1' ? t('events.every_week') : `${t('events.every')} ${props.rruleData.interval} ${t('events.weeks')}`}
        type="number"
        value={props.rruleData.interval}
        onChange={handleChange('interval')}
      />
      <br />
    </>
  );
};

const convertByweekdayStringToRrule = (day) => {
  switch (day) {
    case 'mo': return RRule.MO;
    case 'tu': return RRule.TU;
    case 'we': return RRule.WE;
    case 'th': return RRule.TH;
    case 'fr': return RRule.FR;
    case 'sa': return RRule.SA;
    case 'su': return RRule.SU;
    default: return undefined;
  }
};

const getByweekdays = (bwds) => {
  const rruleByweekdays = [];

  bwds.forEach((d) => rruleByweekdays.push(convertByweekdayStringToRrule(d)));

  return rruleByweekdays;
};

const setUTCTime = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return new Date(Date.UTC(year, month, day, hours, minutes));
};

export const rruleToString = (data, exdate = false) => {
  const rruleSet = new RRuleSet();
  let old = '';

  if (data.dig('meta', 'rrule')) {
    old = rrulestr(data.dig('meta', 'rrule'));
  }
  let rule = {};

  if (data.meta) {
    rule = {
      freq: RRule.WEEKLY,
      interval: data.meta.interval,
      count: data.meta.count,
      dtstart: setUTCTime(data.meta.startTime),
      until: data.meta.endTime,
      byweekday: getByweekdays(data.meta.byweekday)
    };
  } else {
    rule = {
      freq: RRule.WEEKLY,
      interval: data.interval,
      count: data.count,
      dtstart: setUTCTime(data.startTime),
      until: data.endTime,
      byweekday: getByweekdays(data.byweekday)
    };
  }

  Object.keys(rule).forEach((key) => {
    if (rule[key] === undefined || rule[key] === '') {
      delete rule[key];
    }
  });

  if (old.exdate && old.exdates().length) {
    old.exdates().forEach((date) => {
      rruleSet.exdate(applyHoursToAnotherDate(data.start_time, date, true));
    });
  }

  if (exdate) {
    rruleSet.exdate(setUTCTime(data.start_time));
  }

  rruleSet.rrule(new RRule(rule));

  return rruleSet.toString();
};

export const rruleToText = (event) => {
  if (!event.meta) return;

  const rule = {
    freq: RRule.WEEKLY,
    interval: event.meta.interval,
    count: event.meta.count,
    dtstart: setUTCTime(event.start),
    until: event.meta.endTime,
    byweekday: getByweekdays(event.meta.byweekday)
  };

  Object.keys(rule).forEach((key) => {
    if (rule[key] === undefined || rule[key] === '') {
      delete rule[key];
    }
  });

  const test = new RRule(rule);

  if (test.options.until) {
    test.options.until = new Date(test.options.until);
  }
  const rruleText = test.toText();

  // const temp = [];

  // rruleText.replace(/[^\w\s]/gi, '').split(' ').forEach((t) => {
  //   temp.push(germanStrings[t] || t);
  // });

  // return temp.join(' ').replace(/  +/g, ' ');

  // eslint-disable-next-line consistent-return
  return rruleText;
};

export const addExdate = (data, oldEvent) => {
  const rruleSet = new RRuleSet();
  const old = rrulestr(data.extendedProps.meta.rrule);

  const rule = {
    freq: RRule.WEEKLY,
    interval: data.extendedProps.meta.interval,
    count: parseInt(data.extendedProps.meta.count, 10),
    dtstart: setUTCTime(data.extendedProps.meta.startTime),
    until: data.extendedProps.meta.until,
    byweekday: getByweekdays(data.extendedProps.meta.byweekday)
  };

  Object.keys(rule).forEach((key) => {
    if (rule[key] === undefined || rule[key] === '') {
      delete rule[key];
    }
  });

  if (old.exdate && old.exdates().length) {
    old.exdates().forEach((date) => {
      rruleSet.exdate(new Date(date));
    });
  }

  rruleSet.exdate(setUTCTime(oldEvent.start));
  rruleSet.rrule(new RRule(rule));

  return rruleSet.toString();
};

export const applyHoursToAnotherDate = (hourDate, dayDate, utc = false) => {
  const hDate = new Date(hourDate);
  const hourHours = hDate.getHours();
  const hourMinutes = hDate.getMinutes();

  const dDate = new Date(dayDate);
  const dyear = dDate.getFullYear();
  const dmonth = dDate.getMonth();
  const dDay = dDate.getDate();

  if (utc) {
    return new Date(Date.UTC(dyear, dmonth, dDay, hourHours, hourMinutes));
  }

  return new Date(dyear, dmonth, dDay, hourHours, hourMinutes);
};

export const convertMinutesToHHMM = (mins) => {
  const h = Math.floor(mins / 60);
  let m = mins % 60;
  m = m < 10 ? `0${m}` : m;
  return `${h}:${m}`;
};

export default RRuleCreator;
