import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Profile from '../components/Settings/Profile';
import Invoices from '../components/Invoices/Invoices';
import NewInvoice from '../components/Invoices/NewInvoice';
import Payments from '../components/Payments/Payments';
import CreateInvoice from '../components/Invoices/CreateInvoice';
import AutoCreateInvoice from '../components/Invoices/AutoCreateInvoice';
import Help from '../components/Help/Help';
import InvoiceSettings from '../components/Settings/InvoiceSettings';
import Documentation from './Documentation';
import Clients from '../components/Clients/Clients';
import Client from '../components/Clients/Client';
import SessionHistoryDetails from '../components/Clients/SessionHistory/SessionHistoryDetails';
import ClientEvents from '../components/Clients/ClientEvents';
// import ClientConversation from '../components/Clients/ClientConversation';
// import ClientIndividualConversation from '../components/Clients/ClientIndividualConversation';

const AccountantRoutes = (props) => {
  const canAccessInvoices = () => {
    if (props.hasInvoiceAccess) return true;

    return false;
  };

  const canAccessPayments = () => {
    if (props.hasInvoiceAccess) return true;

    return false;
  };

  return (
    <Routes>
      <Route exact path="/help" element={<Help />} />
      {canAccessInvoices() ? <Route exact path="/invoices/auto" element={<AutoCreateInvoice />} /> : null}
      {canAccessInvoices() ? <Route exact path="/invoices/create" element={<CreateInvoice />} /> : null}
      {canAccessInvoices() ? <Route exact path="/invoices" element={<Invoices />} /> : null}
      {canAccessInvoices() ? <Route exact path="/invoices/:id" element={<NewInvoice />} /> : null}
      {canAccessInvoices() ? <Route exact path="/invoice_settings" element={<InvoiceSettings />} /> : null}
      {canAccessPayments() ? <Route exact path="/payments" element={<Payments />} /> : null}
      <Route exact path="/clients" element={<Clients />} />
      <Route exact path="/clients/:id" element={<Client />} />
      <Route exact path="/clients/:id/session_histories" element={<SessionHistoryDetails />} />
      <Route exact path="/clients/:id/events" element={<ClientEvents />} />
      {/* <Route exact path="/clients/:id/feedback" element={<ClientConversation />} /> */}
      {/* <Route exact path="/clients/:id/feedback/:feedbackId" element={<ClientIndividualConversation />} /> */}
      <Route exact path="/profile_settings" element={<Profile />} />
      <Route exact path="/documentation" element={<Documentation />} />
      <Route path="*" element={<Navigate to="/invoices" />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  hasInvoiceAccess: state.auth.hasInvoiceAccess
});

export default connect(mapStateToProps)(AccountantRoutes);
