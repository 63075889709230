import React, { useEffect, useState } from 'react';
import { Button, TextField, Form, SkeletonPage, Layout, Card, SkeletonBodyText, TextContainer, SkeletonDisplayText } from '@shopify/polaris';
import { Animate, AnimateGroup, useAnimate } from 'react-simple-animate';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import lock from './lock.png';
import styles from './Unlock.module.scss';
import * as actions from '../../store/actions/index';
import Banners from '../Shared/Banners';

const Unlock = (props) => {
  // const [errorMessage, setErrorMessage] = useState(0);
  const [playobject, setPlayobject] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [banner, setBanner] = useState([]);

  const { t } = useTranslation('translation');

  const { play, style, isPlaying } = useAnimate({
    duration: '1',
    start: { transform: 'rotate(0)' },
    end: { transform: 'rotate(360deg)' }
  });

  const handleLogin = () => {
    setBanner([]);
    props.onAuth(email, password);
  };

  useEffect(() => {
    if (!props.accessDenied && props.isAuthenticated) {
      if (props.isAuthenticated) {
        setDisabled(true);
        play(!isPlaying);
        setTimeout(() => {
          if (props.closeOnSuccess) {
            props.closeOnSuccess();
          }
          setPlayobject(!playobject);
        }, 1000);
        setTimeout(() => {
          setDisabled(false);
        }, 2000);
      }
    }

    if (props.error) {
      setBanner([{ title: props.error?.response?.data?.error || props.error, status: 'critical' }]);
      setDisabled(true);
      // setErrorMessage(1);
      setTimeout(() => {
        // setErrorMessage(0);
        setDisabled(false);
      }, 500);
    }
  // eslint-disable-next-line
  }, [props.isAuthenticated, props.accessDenied, props.error, props.isLoading]);

  return (
    props.accessDenied && props.isAuthenticated ? (<p className="text-base font-semibold text-indigo-600 pb-8">Access Denied</p>) : (
      <div className={styles.panel}>
        <Banners banners={banner} onDismissBanner={() => setBanner([])} />
        <div style={{ position: 'absolute', width: '100%' }}>
          <SkeletonPage breadcrumbs>
            <Layout>
              <Layout.Section>
                <Card sectioned>
                  <SkeletonBodyText />
                </Card>
                <Card sectioned>
                  <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                  </TextContainer>
                </Card>
              </Layout.Section>
            </Layout>
          </SkeletonPage>
        </div>
        <AnimateGroup play={playobject}>
          <Animate
            duration="2"
            sequenceIndex={0}
            start={{
              transform: 'translateY(0px)'
            }}
            end={{ transform: 'translateY(-400px)' }}
          >
            <div className={styles.contentTop}>
              {props.logo && (
                <div className="flex justify-center mt-6">
                  <img src={props.logo} alt="planubo" />
                </div>
              )}
            </div>
          </Animate>
          <Animate
            duration="2"
            sequenceIndex={0}
            start={{
              transform: 'translateY(0px)'
            }}
            end={{ transform: 'translateY(400px)' }}
          >
            <div className={styles.content}>
              <div className="ml-6" style={{ position: 'relative', zIndex: 100 }}>
                <div className={styles.lock} style={style}>
                  <img src={lock} alt="lock" style={{ height: '40px', paddingTop: '8px', paddingBottom: '3px', paddingLeft: '18px', paddingRight: '19px' }} />
                </div>
              </div>
              <div className={styles.login}>
                <Form onSubmit={() => handleLogin()}>
                  <div className={styles.loginDetail}>
                    <TextField
                      value={email}
                      onChange={setEmail}
                      placeholder={t('user.email')}
                      type="email"
                      disabled={disabled}
                    />
                  </div>
                  <div className={styles.loginDetail}>
                    <TextField
                      value={password}
                      onChange={setPassword}
                      placeholder={t('user.password')}
                      type="password"
                      disabled={disabled}
                    />
                  </div>
                  <div className={styles.loginDetail}>
                    <Button disabled={disabled} submit fullWidth primary onClick={() => handleLogin()}>{t('user.sign_in')}</Button>
                    <Button plain url="/forgot-password">{t('user.forgot_password')}</Button>
                  </div>
                </Form>
              </div>
            </div>
          </Animate>
        </AnimateGroup>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  error: state.auth.error,
  isAuthenticated: state.auth.token !== null,
  loading: state.auth.loading
});

const mapDispatchToProps = (dispatch) => ({
  onAuth: (email, password) => dispatch(actions.auth(email, password, null, true, true, null, null))
});

export default connect(mapStateToProps, mapDispatchToProps)(Unlock);
