import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Card, Heading, Icon, Stack, Toast, Tooltip } from '@shopify/polaris';
import { SecureMajor } from '@shopify/polaris-icons';
import Comment from './Comment';
import CommentForm from './CommentForm';
import Banners from '../Shared/Banners';

const Comments = (props) => {
  const [comments, setComments] = useState([]);
  const [banner, setBanner] = useState([]);

  const { t } = useTranslation();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const fetchComments = useCallback(() => {
    if (props.commentableId) {
      axios.get(`/v1/${props.commentablePath}/${props.commentableId}/comments`)
        .then((res) => {
          setComments(res.data);
        })
        .catch((error) => {
          setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.error }]);
        });
    }
  // eslint-disable-next-line
  }, [props.commentableId, props.commentablePath]);

  useEffect(() => fetchComments(), [fetchComments]);

  const dismissBanner = () => setBanner([]);

  const deleteComment = (id) => {
    axios.delete(`/v1/${props.commentablePath}/${props.commentableId}/comments/${id}`)
      .then((res) => {
        setComments(res.data);
        toggleToastActive(t('shared.deleted'));
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.error }]);
      });
  };

  const existingComments = () => {
    const items = [];
    comments.forEach((comment) => items.push(<Comment key={comment.id} comment={comment} delete={deleteComment} />));
    return items;
  };

  return (
    <Card title={
      (
        <Stack wrap={false} spacing="tight" alignment="center">
          <Heading>{t('comment.comments')}</Heading>
          <Tooltip content={props.tooltip}>
            <Icon color="highlight" source={SecureMajor} />
          </Tooltip>
        </Stack>
      )
    }
    >
      {toastMarkup}
      {banner.length ? <Banners banners={banner} onDismissBanner={dismissBanner} /> : null}
      {existingComments()}
      <CommentForm commentableId={props.commentableId} commentablePath={props.commentablePath} handleSubmit={fetchComments} />
    </Card>
  );
};

export default Comments;
