import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicBooking from '../../components/Products/PublicBooking';
import PublicProduct from '../../components/Products/PublicProduct';
import ReadOnly from '../../components/PublicProfile/ReadOnly';
// import ViewableProduct from '../../components/Products/ViewableProduct';

const PremiumPlusClientRoutes = () => (
  <Routes>
    <Route exact path="/booking/:name" element={<ReadOnly />} />
    <Route exact path="/embeddedbooking/:token" element={<PublicProduct />} />
    <Route exact path="/manage_booking/:token" element={<PublicBooking />} />
    <Route path="/" element={<ReadOnly />} />
  </Routes>
);
export default PremiumPlusClientRoutes;
