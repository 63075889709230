import React, { useCallback, useEffect, useState } from 'react';
import { Card, FormLayout, Page, Layout, TextField, Toast, ContextualSaveBar, Banner, Tabs, DropZone, Stack } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { DeleteMajor, HideMinor, ViewMinor } from '@shopify/polaris-icons';
import { getTabId, getTabIndex, onTabSelect } from '../FilterEngine/Tabs/tabs';
import { stateConverter } from '../FilterEngine/filterParams';
import ProductBookings from '../ProductBookings/ProductBookings';
import EmailTemplate from '../Settings/EmailTemplates/EmailTemplate';
import StaffAccounts from './StaffAccounts';
import Tags from '../Tags/Tags';
import GenericFormBuilderV2 from '../Shared/FormBuilderV2/GenericFormBuilder';
import TagModifier from '../Tags/TagModifier';
import usePrevious from '../../hooks/usePrevious';
import Banners from '../Shared/Banners';
import { ElementStore } from '../Shared/FormBuilder';
import SwitchToggle from '../Shared/SwitchToggle';

const LessonPackage = (props) => {
  const [initTags, setInitTags] = useState(false);
  const [banner, setBanner] = useState([]);
  const [lessonPackage, setLessonPackage] = useState({});
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [survey, setSurvey] = useState({});
  const [currency, setCurrency] = useState('');
  const [bookingFormData, setBookingFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [owner, setOwner] = useState({});

  const { t } = useTranslation();
  const history = useNavigate();
  const matchParams = useParams();
  const location = useLocation();

  const [selectedTags, setSelectedTags] = useState([]);
  const [initialTags, setInitialTags] = useState([]);

  const [productImage, setProductImage] = useState(null);
  const [customFields, setCustomFields] = useState([]);

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const [tableFilters, setTableFilters] = useState(stateConverter(location));

  const fetchCurrency = useCallback(() => {
    axios.get('/v1/settings/invoice_settings')
      .then((response) => {
        setCurrency(response.data.invoiceSettings.currency);
      })
      .catch(() => {});
  }, []);

  const previousTags = usePrevious(selectedTags);

  const updateTags = useCallback(() => {
    setInitTags(false);

    const params = {
      product: {
        tags_attributes: TagModifier(selectedTags, initialTags)
      }
    };

    axios.patch(`/v1/products/${matchParams.token}`, params)
      .then((response) => {
        setSelectedTags(response.data.selectedTags);
        setInitialTags(response.data.selectedTags);
        setInitTags(true);
      })
      .catch(() => {
      });
  }, [matchParams.token, selectedTags, initialTags]);

  useEffect(() => {
    if (initTags && JSON.stringify(previousTags) !== JSON.stringify(selectedTags)) {
      updateTags();
    }
  // eslint-disable-next-line
  }, [selectedTags]);

  const fetchProductData = useCallback(() => {
    if (matchParams.token) {
      setLoading(true);
      axios.get(`/v1/products/${matchParams.token}`)
        .then((res) => {
          if (res.data.product_image) setProductImage(new File([''], res.data.product_image));
          if (res.data.schedule) res.data.schedule = JSON.parse(res.data.schedule);
          setLessonPackage(res.data);
          setOwner(res.data.owner);
          setSelectedTags(res.data.selectedTags);
          setInitialTags(res.data.selectedTags);
          setInitTags(true);
          const data = JSON.parse(res.data.form_data);

          setBookingFormData(data);
          ElementStore.state.data = data;
        })
        .catch(() => {})
        .then(() => {
          setLoading(false);
        });
    }
  // eslint-disable-next-line
  }, [matchParams.token]);

  const dismissBanner = () => {
    setBanner([]);
  };

  useEffect(() => {
    fetchProductData();
    fetchCurrency();
  }, [fetchCurrency, fetchProductData]);

  const handleChange = (field) => (value) => {
    const temp = { ...lessonPackage, [field]: value };

    setLessonPackage(temp);

    if (Object.values(temp).some((v) => v)) {
      setShowSaveBar(true);
    } else {
      setShowSaveBar(false);
    }
  };

  const formValid = () => {
    const errors = {};

    if (!lessonPackage.title) {
      errors.name = t('shared.validations.required');
    }
    if (!lessonPackage.price) {
      errors.price = t('shared.validations.required');
    }
    if (!lessonPackage.sessions) {
      errors.sessions = t('shared.validations.required');
    }
    // if (!lessonPackage.description) {
    //   errors.description = t('shared.validations.required');
    // }

    setFormErrors(errors);
    return Object.entries(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!formValid()) return;

    setLoading(true);

    const formData = new FormData();

    formData.append('product[title]', lessonPackage.title || '');
    formData.append('product[description]', lessonPackage.description || '');
    formData.append('product[big_description]', lessonPackage.big_description || '');
    formData.append('product[price]', lessonPackage.price || '');
    formData.append('product[sessions]', lessonPackage.sessions || '');
    formData.append('product[requires_payment]', lessonPackage.requires_payment || false);
    formData.append('product[requires_login]', lessonPackage.requires_login || false);
    formData.append('product[type]', 'lessonPackage');
    formData.append('product[requires_to_be_client]', lessonPackage.requires_to_be_client || false);
    // if (props.hasInvoiceAccess) formData.append('product[price]', lessonPackage.price || 0);
    formData.append('product[product_settings_attributes][id]', lessonPackage.product_settings?.id || '');
    formData.append('product[product_settings_attributes][start_date]', lessonPackage.product_settings?.start_date || '');
    formData.append('product[product_settings_attributes][end_date]', lessonPackage.product_settings?.end_date || '');
    formData.append('product[product_settings_attributes][buffer]', lessonPackage.product_settings?.buffer || '');
    formData.append('product[product_settings_attributes][cutoff_days]', lessonPackage.product_settings?.cutoff_days || '');
    formData.append('product[product_settings_attributes][cutoff_hours', lessonPackage.product_settings?.cutoff_hours || '');
    formData.append('product[product_settings_attributes][cutoff_minutes]', lessonPackage.product_settings?.cutoff_minutes || '');
    formData.append('product[product_settings_attributes][book_days_in_the_future]', lessonPackage.product_settings?.book_days_in_the_future || '');

    if (productImage?.size > 0) {
      formData.append('product[product_image]', productImage);
    }

    if (!productImage) {
      formData.append('remove_product_image', true);
    }

    formData.append('product[form_data]', JSON.stringify(bookingFormData) || JSON.stringify(ElementStore.state.data));

    if (customFields.length) {
      customFields.forEach((customField, i) => {
        if (customField.id) formData.append(`product[custom_fields_attributes][${i}][id]`, customField.id);
        formData.append(`product[custom_fields_attributes][${i}][label]`, customField.label);
        formData.append(`product[custom_fields_attributes][${i}][value]`, customField.value);
        formData.append(`product[custom_fields_attributes][${i}][order_number]`, customField.order_number);

        // eslint-disable-next-line
        if (customField._destroy) {
          // eslint-disable-next-line
          formData.append(`product[custom_fields_attributes][${i}][_destroy]`, customField._destroy);
        }
      });
    }

    if (!matchParams.token) {
      axios.post('/v1/products', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          toggleToastActive(t('shared.saved'));
          setTimeout(() => {
            history(`/event_package/${response.data.token}`);
          });
        })
        .catch(() => {
          toggleToastActive(t('shared.unable_to_save'));
        })
        .then(() => {
          setShowSaveBar(false);
          setLoading(false);
        });
    } else {
      axios.patch(`/v1/products/${lessonPackage.token}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          setLessonPackage(response.data);
          setCustomFields(response.data.custom_fields);
          toggleToastActive(t('shared.saved'));
        })
        .catch(() => {
          toggleToastActive(t('shared.unable_to_save'));
        })
        .then(() => {
          setShowSaveBar(false);
          setLoading(false);
        });
    }
  };

  const tabs = [
    {
      id: 'Product',
      content: t('products.plural'),
      accessibilityLabel: t('products.plural'),
      panelID: 'Product'
    },
    {
      id: 'Staff',
      content: t('staff.staff'),
      accessibilityLabel: 'Staff',
      panelID: 'Staff'
    },
    {
      id: 'EmailTemplate',
      content: t('email_template.title'),
      accessibilityLabel: t('email_template.title'),
      panelID: 'EmailTemplate'
    },
    {
      id: 'Bookings',
      content: t('client.bookings'),
      accessibilityLabel: t('client.bookings'),
      panelID: 'Bookings'
    },
    {
      id: 'CustomBookingFields',
      content: t('survey.survey'),
      accessibilityLabel: 'CustomBookingFields',
      panelID: 'CustomBookingFields'
    }
  ];

  if (props.isStaff) {
    tabs.splice(tabs.findIndex((i) => i.id === 'EmailTemplate'), 1);
  }

  if (!matchParams.token) {
    tabs.splice(tabs.findIndex((i) => i.id === 'EmailTemplate'), 1);
    tabs.splice(tabs.findIndex((i) => i.id === 'Bookings'), 1);
    tabs.splice(tabs.findIndex((i) => i.id === 'BookingFields'), 1);
  }

  const resetProductImage = () => {
    setShowSaveBar(true);
    setProductImage(null);
  };

  const handleProductImageDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
      setTimeout(() => {
        setBanner([]);
      }, 3000);
    } else {
      setLessonPackage({ ...lessonPackage, product_image: window.URL.createObjectURL(acceptedFiles[0]) });
      setProductImage(acceptedFiles[0]);
      setShowSaveBar(true);
    }
  }, [t, lessonPackage]);

  const uploadedProductImageFile = productImage && (
    <Stack alignment="center">
      <img src={lessonPackage.product_image} alt="product" width="50%" />
    </Stack>
  );

  const handleActiveToggle = (id, action) => {
    axios.post(`/v1/products/${id}/${action}`)
      .then(() => {
        setLessonPackage({ ...lessonPackage, status: lessonPackage.active ? 'inactive' : 'active', active: !lessonPackage.active });
        toggleToastActive(t('shared.saved'));
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      });
  };

  const handleDelete = (token) => {
    if (!window.confirm(`${t('shared.are_you_sure')} ${t('products.plural')}`)) return;

    axios.delete(`/v1/products/${token}`)
      .then(() => {
        history('/products');
        toggleToastActive(t('shared.success'));
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  const pageActions = () => {
    if (!lessonPackage || loading || matchParams.token === 'new') {
      return {};
    }

    const toggleContent = lessonPackage.active ? 'Inactivate' : 'Activate';
    const content = lessonPackage.active ? t('client.inactivate') : t('client.activate');

    const actions = [{
      icon: lessonPackage.active ? HideMinor : ViewMinor,
      content,
      onAction: () => handleActiveToggle(lessonPackage.token, toggleContent.toLowerCase())
    }];

    actions.push({
      icon: DeleteMajor,
      content: t('shared.delete'),
      onAction: () => handleDelete(lessonPackage.token)
    });

    return actions;
  };

  useEffect(() => {
    ElementStore.state.data = bookingFormData;
    // eslint-disable-next-line
  }, [tableFilters.tab]);

  const uploadFiles = (formData, options) => {
    axios.patch(`/v1/products/${matchParams.token}/files`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((result) => {
        options.callback(
          'success',
          // A link to the uploaded file
          // eslint-disable-next-line prefer-template
          result.data.file_paths.find((option) => decodeURIComponent(option).includes(options.files[options.files.length - 1].name))
        );
      })
      .catch(() => {
        options.callback('error');
      });
  };

  const onSubmit = (data) => {
    if (!data.json.title) {
      data.callback(data.saveNo, false);

      return;
    }

    const params = {
      product: {
        form_data: JSON.stringify(data.json),
        theme: JSON.stringify(data.theme)
      }
    };

    axios.patch(`/v1/products/${matchParams.token}`, params)
      .then((response) => {
        if (response.status === 200) {
          setSurvey({
            title: data.json.title,
            data: response.data.form_data,
            theme: response.data.theme
          });
          data.callback(data.saveNo, true);
        } else {
          data.callback(data.saveNo, false);
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((_err) => {
        toggleToastActive(t('shared.something_went_wrong'));
        data.callback(data.saveNo, false);
      });
  };

  return (
    <Page
      fullWidth
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history('/products') }]}
      secondaryActions={pageActions()}
      title={lessonPackage.title || 'Neues Unterrichtspaket'}
    >
      {toastMarkup}
      {showSaveBar && (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: handleSubmit,
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      <Tabs
        tabs={tabs}
        selected={getTabIndex(tabs, tableFilters.tab)}
        onSelect={(tabIndex) => onTabSelect({ history, location }, tabs, tabIndex, setTableFilters)}
      >
        <br />
        {getTabId(tabs, tableFilters.tab) === 'Product' && (
          <Layout>
            <Layout.Section oneHalf>
              <Card sectioned>
                <Card.Section title={t('product.image')} actions={[{ content: t('shared.change'), onAction: () => resetProductImage() }]}>
                  {!productImage ? (
                    <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleProductImageDropZoneDrop}>
                      <DropZone.FileUpload />
                    </DropZone>
                  ) : uploadedProductImageFile}
                </Card.Section>
                <Card.Section>
                  <TextField
                    label={t('shared.title')}
                    requiredIndicator
                    value={lessonPackage.title}
                    onChange={handleChange('title')}
                  />
                </Card.Section>
                {/* <Card.Section title={t('shared.description')}>
                  <TextField
                    label={t('payments.bank_statement')}
                    value={lessonPackage.description}
                    error={formErrors.description}
                    requiredIndicator
                    onChange={handleTinymceChange('description')}
                  />
                </Card.Section> */}
              </Card>
            </Layout.Section>
            <Layout.Section oneHalf>
              {matchParams.token !== 'new' && (
                <Card
                  sectioned
                  title="Tags"
                >
                  <Tags
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    tagUrl={`/v1/products/${matchParams.token}/tags`}
                  />
                </Card>
              )}
              <Card
                sectioned
              >
                <Card.Section>
                  <FormLayout>
                    {props.hasInvoiceAccess && (
                      <>
                        <Stack alignment="center">
                          <Stack.Item fill>
                            <TextField
                              requiredIndicator
                              label={t('shared.price')}
                              type="currency"
                              value={lessonPackage.price}
                              prefix={currency}
                              error={formErrors.price}
                              onChange={handleChange('price')}
                              helpText={t('product.pricing_toggle')}
                            />
                          </Stack.Item>
                          <Stack.Item>
                            <SwitchToggle
                              handleChange={handleChange('requires_payment')}
                              checked={lessonPackage.requires_payment}
                            />
                          </Stack.Item>
                        </Stack>
                        {!lessonPackage.requires_payment && (
                          <p className="text-red-600 mt-2">{t('event_package.if_payment_not_required')}</p>
                        )}
                      </>
                    )}
                    <TextField
                      requiredIndicator
                      label={t('membership.sessions')}
                      type="number"
                      error={formErrors.sessions}
                      value={lessonPackage.sessions}
                      min={1}
                      onChange={handleChange('sessions')}
                    />
                  </FormLayout>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        )}
        {getTabId(tabs, tableFilters.tab) === 'Staff' && (
          matchParams.token ? <StaffAccounts owner={owner.email} isStaff={props.isStaff} /> : (
            <Banner status="info">
              <p>{t('product.created_before_staff_can_be_added')}</p>
            </Banner>
          )
        )}
        {getTabId(tabs, tableFilters.tab) === 'CustomBookingFields' && matchParams.token !== 'new_event' && (
          <div className="h-[calc(100vh-14rem)]">
            <GenericFormBuilderV2
              onSubmit={onSubmit}
              survey={survey}
              uploadFiles={uploadFiles}
            />
          </div>
        )}
        {getTabId(tabs, tableFilters.tab) === 'EmailTemplate' && (
          <EmailTemplate path="booking_mailer/client_confirmation" hideNav subtype={lessonPackage.token} isIndividualProduct />
        )}
        {getTabId(tabs, tableFilters.tab) === 'Bookings' && (
          lessonPackage.id && <ProductBookings product_id={lessonPackage.id} />
        )}
      </Tabs>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  canDoOnlineEvent: state.auth.canDoOnlineEvent,
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  isStaff: state.auth.role === 'staff',
  email: state.auth.email
});

export default connect(mapStateToProps)(LessonPackage);
