import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import GenericFormBuilder from '../Shared/FormBuilderV2/GenericFormBuilder';
import '../Shared/FormBuilder/scss/application.scss';

const Survey2 = (props) => {
  const [survey, setSurvey] = useState({});
  const [loading, setLoading] = useState(true);

  const matchParams = useParams();
  const history = useNavigate();

  const fetchSurvey = useCallback(() => {
    setLoading(true);
    if (matchParams.id !== 'new') {
      axios.get(`/v1/surveys/${matchParams.id}`)
        .then((response) => {
          setSurvey(response.data);
        })
        .catch(() => {
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [matchParams.id]);

  useEffect(() => {
    fetchSurvey();
  }, [fetchSurvey]);

  const onSubmit = (data) => {
    if (!data.json.title) {
      data.callback(data.saveNo, false);

      return;
    }

    let title = '';

    if (typeof data.json.title === 'string') {
      title = data.json.title;
    } else if (typeof data.json.title === 'object') {
      title = data.json.title[props.lang];
    }

    const params = {
      survey: {
        title,
        data: JSON.stringify(data.json),
        theme: JSON.stringify(data.theme)
      }
    };

    if (matchParams.id === 'new') {
      axios.post('/v1/surveys', params)
        .then((response) => {
          if (response.status === 200) {
            setSurvey({
              title: data.json.title,
              data: params.survey.data,
              theme: params.survey.theme
            });

            data.callback(data.saveNo, true);
          } else {
            data.callback(data.saveNo, false);
          }
          history(`/surveys/${response.data.id}`);
        })
        .catch(() => {
          data.callback(data.saveNo, false);
        });
    } else {
      axios.patch(`/v1/surveys/${matchParams.id}`, params)
        .then((response) => {
          if (response.status === 200) {
            setSurvey({
              title: data.json.title,
              data: params.survey.data,
              theme: params.survey.theme
            });
            data.callback(data.saveNo, true);
          } else {
            data.callback(data.saveNo, false);
          }
        })
        .catch(() => {
        });
    }
  };

  const uploadFiles = (formData, options) => {
    axios.patch(`/v1/surveys/${matchParams.id}/files`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((result) => {
        options.callback(
          'success',
          // A link to the uploaded file
          // eslint-disable-next-line prefer-template
          result.data.file_paths.find((option) => decodeURIComponent(option).includes(options.files[options.files.length - 1].name))
        );
      })
      .catch(() => {
        options.callback('error');
      });
  };

  const deleteFiles = (options) => {
    axios.delete(`/v1/surveys/${matchParams.id}/delete_file`, { fileUrl: options.fileValue.content })
      .then(() => {
        options.callback('success');
      })
      .catch(() => {
        options.callback('error');
      });
  };

  return (
    !loading && (
      <GenericFormBuilder onSubmit={onSubmit} survey={survey} uploadFiles={uploadFiles} deleteFiles={deleteFiles} readOnly={survey.readOnly} />
    )
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.lang
});

export default connect(mapStateToProps)(Survey2);
