import React from 'react';
import { ChoiceList } from '@shopify/polaris';
import { handleFilterChange } from '../FilterEngine';

const statusFilterDefinition = (props, setTableFilters, options, selected) => ({
  key: 'status',
  label: 'Status',
  filter: (
    <ChoiceList
      title="Status"
      titleHidden
      choices={options}
      selected={selected}
      onChange={handleFilterChange(props, setTableFilters, 'status')}
      allowMultiple
    />
  ),
  shortcut: true
});

export default statusFilterDefinition;
