/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { Card } from '@shopify/polaris';
import { DocumentTextIcon, FolderIcon } from '@heroicons/react/20/solid';
import DraggableItem from './DraggableItem';
import { Droppable } from './Droppable';
import { Draggable } from './Draggable';
import EmptyState from '../../EmptyState';

const DroppableStory = ({
  folders,
  files,
  collisionDetection,
  handleDoubleClick,
  newFolder,
  isLoading,
  handleSingleClick,
  modifiers,
  moveFile,
  header
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [parent, setParent] = useState(null);
  const [currentDraggingFile, setCurrentDraggingFile] = useState({});
  const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { delay: 250 } }), useSensor(TouchSensor, { activationConstraint: { delay: 250 } }));

  const { t } = useTranslation();

  const template = [0, 1];

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      modifiers={parent === null ? undefined : modifiers}
      onDragStart={(data) => {
        const file = files.find((f) => f.id === data.active?.id) || folders.find((f) => f.id === data.active?.id);
        setCurrentDraggingFile(file);
        setIsDragging(true);
      }}
      onDragEnd={(data) => {
        moveFile(data);
        setParent(data.over ? data.over.id : null);
        setCurrentDraggingFile({});
        setIsDragging(false);
      }}
      onDragCancel={() => setIsDragging(false)}
    >
      {header}
      <Card.Section title={<div className="select-none text-sm uppercase font-semibold">{t('media_library.folders')}</div>} actions={[{ content: t('media_library.new_folder'), onAction: () => newFolder() }]}>
        <div className="flex flex-wrap">
          {!folders.length && !isLoading ? (
            <EmptyState />
          ) : ''}
          {!folders.length && isLoading ? (
            template.map((temp) => (
              <div key={temp} className="m-2 shadow border hover:border-indigo-500/100 rounded-lg px-4 min-h-[40px] min-w-[200px] max-w-[200px]">
                <div className="animate-pulse flex space-x-4">
                  <FolderIcon className="pt-2 h-10 w-10 fill-zinc-600 hover:fill-zinc-500" />
                  <div className="flex-1 pt-2">
                    <div className="h-6 bg-slate-100 rounded" />
                  </div>
                </div>
              </div>
            ))
          ) : ''}
          {folders.map((folder) => (
            <Droppable key={folder.id} id={folder.id} dragging={isDragging} handleSingleClick={handleSingleClick} handleDoubleClick={handleDoubleClick} item={folder}>
              <DraggableItem id={folder.id} isContainer label={folder.name} handleSingleClick={() => {}} handleDoubleClick={() => {}} isShared={folder.isShared} />
            </Droppable>
          ))}
        </div>
      </Card.Section>
      <Card.Section title={<div className="select-none text-sm uppercase font-semibold">{t('media_library.files')}</div>}>
        <div className="flex flex-wrap">
          {!folders.length && isLoading ? (
            template.map((temp) => (
              <div key={temp} className="m-4 border hover:border-indigo-500/100 rounded-lg min-w-[140px] max-w-[140px] min-h-[140px] max-h-[140px]">
                <div className="animate-pulse">
                  <div className="bg-white py-7 pl-12">
                    <DocumentTextIcon className="h-10 w-10 fill-sky-500 hover:fill-cyan-500" />
                  </div>
                  <div className="px-4 pt-3 border-t">
                    <div className="h-5 bg-slate-100 rounded" />
                  </div>
                </div>
              </div>
            ))
          ) : ''}
          {files.map((file) => (
            <DraggableItem id={file.id} label={file.name} name={file.name} handleSingleClick={handleSingleClick} handleDoubleClick={handleDoubleClick} item={file} isShared={file.isShared} />
          ))}
        </div>
      </Card.Section>
      <DragOverlay>
        {isDragging ? (
          currentDraggingFile.content_type === 'file' ? (
            <Draggable dragging dragOverlay name={currentDraggingFile.name} label={currentDraggingFile.name} isShared={currentDraggingFile.isShared} />
          ) : (
            <Draggable dragging isContainer dragOverlay name={currentDraggingFile.name} label={currentDraggingFile.name} isShared={currentDraggingFile.isShared} />
          )
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default DroppableStory;
