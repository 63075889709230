const Download = (response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', response.headers.filename);
  document.body.appendChild(link);
  link.click();
};

export default Download;
