import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '@shopify/polaris';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid';
import SessionHistorySummary from './SessionHistorySummary';
import PnbBadge from '../../Tailwind/PnbBadge';
import Tabs from '../../Shared/Tabs';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SessionHistoryDetails = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [historyData, setHistoryData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedTab, setSelectedTab] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [selectedHistory, setSelectedHistory] = useState([]);
  const [remainingSessions, setRemainingSessions] = useState(0);
  const history = useNavigate();
  const matchParams = useParams();

  const fetchUserData = useCallback(() => {
    axios.get(`/v1/clients/${matchParams.id}/session_histories`)
      .then((res) => {
        // eslint-disable-next-line
        setHistoryData(res.data.session_histories);
        setSelectedHistory(res.data.session_histories[Object.keys(res.data.session_histories)[0]]);
        setSelectedTab(Object.keys(res.data.session_histories)[0]);
      })
      .catch(() => {});
  }, [matchParams.id]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    setRemainingSessions(selectedHistory.reduce((accumulator, object) => accumulator + object.amount, 0));
  }, [selectedTab, selectedHistory]);

  const getIcon = (type) => {
    switch (type) {
      case 'add': return <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      case 'remove': return <MinusIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      default: return CheckIcon;
    }
  };

  const getIconBackground = (type) => {
    switch (type) {
      case 'add': return 'bg-green-500';
      case 'remove': return 'bg-red-500';
      default: return 'bg-green-500';
    }
  };

  const getDescriptiveText = (sessionHistory) => {
    if (!sessionHistory) return '';

    switch (sessionHistory.action) {
      case 'add': return `${t('session_histories.added')} (${sessionHistory.amount})`;
      case 'remove': return `${t('session_histories.removal')} (${String(sessionHistory.amount).split('-')[1]}) (${sessionHistory.event?.name})`;
      default: return '';
    }
  };

  const tabs = () => Object.keys(historyData).map((hd) => ({ name: hd }));

  const onTabChange = (a) => {
    setSelectedHistory(historyData[a.target.value]);
    setSelectedTab(a.target.value);
  };

  return (
    <Page
      title={t('client.remaining_sessions')}
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history(-1) }]}
      separator
    >
      <div className="flow-root bg-white rounded-lg border-2">
        <ul className="mb-2">
          <Tabs onChange={onTabChange} tabs={tabs()} selected={selectedTab} />
          <SessionHistorySummary remaining_sessions={remainingSessions} hideButton />
          {selectedHistory.map((sessionHistory, sessionHistoryIdx) => (
            <li key={sessionHistory.id} className="px-8">
              <div className="relative pb-8">
                {sessionHistoryIdx !== selectedHistory.length - 1 ? (
                  <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        getIconBackground(sessionHistory.action),
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                      )}
                    >
                      {getIcon(sessionHistory.action)}
                    </span>
                  </div>
                  <button type="button" className="text-left min-w-0 flex-1 pt-1.5 flex justify-between space-x-4" onClick={() => history(`/events/${sessionHistory.event.id}`)}>
                    <div>
                      <p className="text-sm text-gray-500">
                        {getDescriptiveText(sessionHistory)}
                      </p>
                      {sessionHistory.tags.map((tag) => <PnbBadge color="bg-blue-200 text-blue-800" title={tag} />)}
                    </div>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      <time dateTime={sessionHistory.created_at}>{moment(sessionHistory.created_at).format('LLLL')}</time>
                    </div>
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Page>
  );
};

export default SessionHistoryDetails;
