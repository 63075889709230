import axios from 'axios';
import * as actions from '../store/actions/index';

export default {
  // eslint-disable-next-line no-unused-vars
  setupInterceptor: (store) => {
    axios.interceptors.response.use((response) => (response), (error) => {
      if (error.response.status === 401 && error.response.data?.error !== 'Invalid Email or password.') {
        store.dispatch(actions.logout());
        store.dispatch(actions.authSessionExpired(error.response.data.error));
      }
      return Promise.reject(error);
    });
  }
};
