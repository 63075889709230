import React, { useState } from 'react';
import { Button, Form, FormLayout, Modal, TextField } from '@shopify/polaris';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const EditClient = (props) => {
  const [clientData, setClientData] = useState(props.client);
  const [address, setAddress] = useState(props.client.address || {});
  const [disabledEdit] = useState(Boolean(props.client.email));
  const [showNewClient, setShowNewClient] = useState(true);

  const { t } = useTranslation();

  const closeModal = () => {
    setClientData({ ...clientData, firstName: '', lastName: '', email: '' });
    setShowNewClient(false);
    props.close();
  };

  const handleChange = (field) => (value) => setClientData({ ...clientData, [field]: value });

  const handleAddressChange = (field) => (value) => setAddress({ ...address, [field]: value });

  const onSubmit = () => {
    props.handleSubmit(clientData, address);
    props.close();
  };

  return (
    <Modal
      open={showNewClient}
      onClose={closeModal}
      title={props.title}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <TextField disabled={disabledEdit} label={t('user.first_name')} value={clientData.firstName} onChange={handleChange('firstName')} />
            <TextField disabled={disabledEdit} label={t('user.last_name')} value={clientData.lastName} onChange={handleChange('lastName')} />
            <TextField label={t('user.email')} type="email" value={clientData.email} onChange={handleChange('email')} />
            <TextField label={t('user.dob')} type="date" value={clientData.dob} onChange={handleChange('dob')} />
            <TextField disabled={disabledEdit} label={t('address.phone_number')} value={clientData.phoneNumber} onChange={handleChange('phoneNumber')} />
            <TextField label={t('address.address')} value={address.address} onChange={handleAddressChange('address')} />
            <TextField label={t('address.address_line_two')} value={address.address_line_two} onChange={handleAddressChange('address_line_two')} />
            <TextField label={t('address.city')} value={address.city} onChange={handleAddressChange('city')} />
            <TextField label={t('address.state')} value={address.state} onChange={handleAddressChange('state')} />
            <TextField label={t('address.zip_code')} value={address.zip_code} onChange={handleAddressChange('zip_code')} />

            <Button primary submit>{t('shared.save')}</Button>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isInstructor: state.auth.instructor
});

export default connect(mapStateToProps)(EditClient);
