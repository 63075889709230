import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { Filters, Card, SkeletonPage, Page, DataTable, Badge, Tabs, Button } from '@shopify/polaris';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange, searchFilterToURL, appliedFiltersQuery } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import { defaultSortDirection, handleSort, initialSortColumnIndex } from '../FilterEngine/Sort/sort';
import { contentLabel, getTabIndex, onTabSelect } from '../FilterEngine/Tabs/tabs';

const Subscriptions = (props) => {
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [init, setInit] = useState(false);
  const [lastPage, setLastPage] = useState(null);
  // const [subscriptionCount, setSubscriptionCount] = useState(0);
  const [statusCounts, setStatusCounts] = useState({});

  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const filters = [];

  const appliedFilters = useCallback(() => {
    const af = [];

    return af;
  }, []);

  const getStatusColor = (state) => {
    switch (state) {
      case 'active': return 'success';
      case 'waiting_for_stripe_confirmation': return 'info';
      case 'inactive': return 'warning';
      case 'pending': return 'attention';
      case 'failure': return 'critical';
      default: return 'new';
    }
  };

  const retrySub = (token) => {
    axios.post(`/v1/product_bookings/${token}/retry`)
      .then(() => {
      })
      .catch(() => {
      });
  };

  const modifier = useCallback(
    (tableData) => tableData.map((row) => {
      const modifiedRow = [];

      modifiedRow.push(
        <button type="button" className="ml-3 text-left" onClick={() => history(`/clients/${row.client.token}`)}>
          <p className="text-sm font-medium text-gray-900">{row.client.name}</p>
          <p className="text-sm text-gray-500">{row.client.email}</p>
        </button>
      );
      modifiedRow.push(row.title);
      modifiedRow.push(row.sub?.start_date ? moment(row.sub?.start_date).format('LL') : '');
      modifiedRow.push(row.sub?.next_billing_cycle ? moment(row.sub?.next_billing_cycle).format('LL') : '');
      modifiedRow.push(row.sub?.price);
      // modifiedRow.push(row.sub?.pricing_desc);
      modifiedRow.push(row.sub?.cancel_at ? moment(row.sub?.cancel_at).format('LL') : '');
      modifiedRow.push(<Badge status={getStatusColor(row.state)} size="small">{t(`membership.states.${row.state}`)}</Badge>);

      if (props.isAdmin && row.state === 'failure') {
        modifiedRow.push(<Button onClick={() => retrySub(row.token)}>Retry</Button>);
      } else if (props.isAdmin) {
        modifiedRow.push('');
      }

      return modifiedRow;
    }),
    // eslint-disable-next-line
    []
  );

  const fetchSubscriptions = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      status: tableFilters.tab === 'All' ? '' : tableFilters.tab,
      subs_only: true,
      q: appliedFiltersQuery(appliedFilters())
    };

    axios.post('/v1/product_bookings/search', params)
      .then((res) => {
        if (mounted) {
          setRecords(modifier(res.data.product_bookings));
          setLastPage(res.data.lastPage);
          setStatusCounts(res.data.statusCount);
          // setSubscriptionCount(res.data.productBookingCount);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  // We are very explicit here on when we want to make a new request
  // eslint-disable-next-line
  }, [debouncedSearch]);

  // const showProductBooking = (token) => {
  //   history(`/product_booking/${token}`);
  // };

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/subscriptions', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={t('client.search')}
    />
  );

  useEffect(() => {
    setMounted(true);
    fetchSubscriptions();
    return () => {
      setMounted(false);
    };
  }, [fetchSubscriptions]);

  useEffect(() => {
    if (init) {
      setTableFilters((f) => ({ ...f, page: 1 }));
      searchFilterToURL({ history, location }, 'page', null, 'delete');
    }
    setInit(true);
  // eslint-disable-next-line
  }, [tableFilters.queryValue, tableFilters.role, tableFilters.userType]);

  const tabs = [
    {
      id: 'all-request-items',
      content: `${t('tabs.all')} (${contentLabel('All', statusCounts)})`,
      accessibilityLabel: 'All',
      panelID: 'all-items'
    },
    {
      id: 'Active',
      content: `${t('shared.active')} (${contentLabel('active', statusCounts)})`,
      accessibilityLabel: 'active',
      panelID: 'active'
    },
    {
      id: 'Manual',
      content: `${t('client.waiting_for_approval')} (${contentLabel('manual', statusCounts)})`,
      accessibilityLabel: 'manual',
      panelID: 'manual'
    },
    {
      id: 'Waiting for Stripe confirmation',
      content: `${t('membership.states.waiting_for_stripe_confirmation')} (${contentLabel('waiting_for_stripe_confirmation', statusCounts)})`,
      accessibilityLabel: 'waiting_for_stripe_confirmation',
      panelID: 'waiting_for_stripe_confirmation'
    },
    {
      id: 'Failure',
      content: `${t('tabs.failure')} (${contentLabel('failure', statusCounts)})`,
      accessibilityLabel: 'failure',
      panelID: 'failure'
    },
    {
      id: 'Inactive',
      content: `${t('client.inactive')} (${contentLabel('inactive', statusCounts)})`,
      accessibilityLabel: 'inactive',
      panelID: 'inactive'
    },
    {
      id: 'Pending',
      content: `${t('payments.pending')} (${contentLabel('pending', statusCounts)})`,
      accessibilityLabel: 'pending',
      panelID: 'pending'
    }
  ];

  const indexSortMapping = {
    status: true,
    created_at: false
  };

  const headings = [
    t('user.name'),
    t('navigation.plans'),
    t('product.start_time'),
    t('payments.next_payment'),
    t('shared.price'),
    // 'Interval',
    t('membership.cancel_at'),
    t('user.status')
  ];

  if (props.isAdmin) {
    headings.push('');
  }

  const content = (
    <DataTable
      columnContentTypes={[
        'text',
        'text',
        'text',
        'text',
        'text',
        'text'
      ]}
      verticalAlign="middle"
      headings={headings}
      rows={records}
      sortable={Object.values(indexSortMapping)}
      defaultSortDirection={defaultSortDirection(tableFilters.sorts)}
      initialSortColumnIndex={initialSortColumnIndex(indexSortMapping, tableFilters.sorts)}
      onSort={handleSort({ location, history }, setTableFilters, indexSortMapping)}
      // footerContent={`${events.length} ${t('shared.of')} ${totalEvents}`}
    />
  );

  return (
    <Page
      title={t('memberships.memberships')}
      fullWidth
      separator
    >
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            <div className="mb-4">
              <Tabs
                tabs={tabs}
                selected={getTabIndex(tabs, tableFilters.tab)}
                onSelect={(tabIndex) => onTabSelect({ history, location }, tabs, tabIndex, setTableFilters)}
              />
            </div>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            {content}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={records}
        lastPage={lastPage}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin
});

export default connect(mapStateToProps)(Subscriptions);
