import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import New from '../../../../../components/Shared/New';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Menu = (props) => {
  const history = useNavigate();

  return (
    <div className="flex flex-col w-60 select-none flex-grow border-r border-gray-200 bg-white overflow-y-auto z-100">
      <div className="mt-5 w-full flex-grow flex flex-col">
        <nav className="flex-1 px-4 bg-white" aria-label="Sidebar">
          {props.navigation.map((item) => (!item.children ? (
            item.condition && (
            <div key={item.label} className={item.boarderTop ? 'border-t border-y-gray-200' : ''}>
              <div
                onClick={() => history(item.url)}
                onKeyPress={() => {}}
                role="button"
                tabIndex="0"
                className={classNames(
                  item.selected
                    ? 'bg-gray-200 pl-1 border-l-4 border-blue-300'
                    : 'bg-white pl-2 hover:bg-gray-50 hover:text-gray-900',
                  'group w-full flex items-center py-2 text-sm font-medium'
                )}
              >
                <item.icon
                  className={classNames(
                    item.selected ? 'text-gray-700' : 'text-gray-700 group-hover:text-gray-900',
                    'mr-4 flex-shrink-0 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                {item.label}
                {item.new && <New />}
                {item.beta && <New title="Beta" />}
              </div>
            </div>
            )
          ) : (
            item.condition && (
            <Disclosure as="div" key={item.label} defaultOpen={item.selected}>
              {({ open }) => (
                <div key={item.label} className={item.boarderTop ? 'border-t border-y-gray-200' : ''}>
                  <div className={classNames(item.selected ? 'border-l-4 border-x-blue-300' : 'pl-1')}>
                    <Disclosure.Button
                      className={classNames(
                        item.selected
                          ? 'text-gray-900 pl-1'
                          : 'bg-white text-gray-700 pl-1 hover:bg-gray-50 hover:text-gray-900',
                        'group w-full flex items-center pr-1 py-2 text-left text-sm font-medium rounded-md'
                      )}
                    >
                      {item.icon && (
                      <item.icon
                        className="mr-4 flex-shrink-0 h-5 w-5 text-gray-700 group-hover:text-gray-900"
                        aria-hidden="true"
                      />
                      )}
                      <span className="flex-1">{item.label}</span>
                      {item.new && (
                        <div className="bg-indigo-200 border-2 w-3 h-3 rounded-full" />
                      )}
                      <svg
                        className={classNames(
                          open ? 'text-gray-600 rotate-90' : 'text-gray-500',
                          'ml-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-900 transition-colors ease-in-out duration-150'
                        )}
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                      </svg>
                    </Disclosure.Button>
                    <Disclosure.Panel>
                      {item.children.map((subItem) => (
                        subItem.condition && (
                          <div
                            key={subItem.label}
                            onClick={() => {
                              if (subItem.onClick) {
                                subItem.onClick();
                              } else {
                                history(subItem.url);
                              }
                            }}
                            onKeyPress={() => {}}
                            role="button"
                            tabIndex="0"
                            className={classNames(
                              subItem.selected
                                ? 'bg-gray-200 text-gray-900'
                                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                              'group w-full flex items-center pl-12 py-2 text-sm font-medium'
                            )}
                          >
                            {subItem.label}
                            {subItem.new && <New />}
                            {subItem.beta && <New title="Beta" />}
                          </div>
                        )
                      ))}
                    </Disclosure.Panel>
                  </div>
                </div>
              )}
            </Disclosure>
            )
          )))}
        </nav>
      </div>
    </div>
  );
};

export default Menu;
