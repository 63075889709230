import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { Filters, Card, ResourceList, ResourceItem, SkeletonPage } from '@shopify/polaris';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Download from '../Shared/Download';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange, searchFilterToURL, appliedFiltersQuery } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';

const ProductBookings = (props) => {
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [records, setRecords] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [init, setInit] = useState(false);
  const [lastPage, setLastPage] = useState(null);

  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();
  const matchParams = useParams();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const filters = [];

  const appliedFilters = useCallback(() => {
    const af = [];

    return af;
  }, []);

  const fetchUsers = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters())
    };

    if (props.event_id) {
      params.event_id = props.event_id;
    } else if (props.product_id) {
      params.product_id = props.product_id;
    }

    axios.post('/v1/product_bookings/search', params)
      .then((res) => {
        if (mounted) {
          setRecords(res.data.product_bookings);
          setLastPage(res.data.lastPage);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  // We are very explicit here on when we want to make a new request
  // eslint-disable-next-line
  }, [debouncedSearch]);

  const showProductBooking = (token) => {
    history(`/product_booking/${token}`);
  };

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/users', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={t('filters.by_product_title_or_name')}
    />
  );

  useEffect(() => {
    setMounted(true);
    fetchUsers();
    return () => {
      setMounted(false);
    };
  }, [fetchUsers]);

  useEffect(() => {
    if (init) {
      setTableFilters((f) => ({ ...f, page: 1 }));
      searchFilterToURL({ history, location }, 'page', null, 'delete');
    }
    setInit(true);
  // eslint-disable-next-line
  }, [tableFilters.queryValue, tableFilters.role, tableFilters.userType]);

  const resourceName = {
    singular: 'product booking',
    plural: 'product bookings'
  };

  const renderItem = (item) => {
    const { token, client, user } = item;

    return (
      <ResourceItem
        id={token}
        showHeader
        accessibilityLabel={`View details for ${item.name}`}
        onClick={() => showProductBooking(token)}
      >
        <Card sectioned title={client?.name}>
          <div>
            {`${t('instructor.instructor')}: ${user?.name}`}
          </div>
          <div>{moment(item.created_at).format('LLLL')}</div>
          {/* <div>
            {t(`membership.states.${item.state}`)}
          </div> */}
        </Card>
      </ResourceItem>
    );
  };

  const exportProductBookings = () => {
    axios.get(`/v1/products/${matchParams.token}/export`, { responseType: 'blob' })
      .then((response) => {
        Download(response);
      })
      .catch(() => {});
  };

  return (
    <>
      {!loading ? (
        <Card sectioned actions={[{ content: t('booking.export'), onAction: () => exportProductBookings() }]}>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            <ResourceList
              resourceName={resourceName}
              items={records}
              renderItem={renderItem}
              selectedItems={selectedItems}
              onSelectionChange={setSelectedItems}
            />
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={records}
        lastPage={lastPage}
      />
    </>
  );
};

export default ProductBookings;
