import React, { useState } from 'react';
import { Button, Form, FormLayout, Modal, TextField } from '@shopify/polaris';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NewProgra = (props) => {
  const [program, setProgram] = useState('');
  const [name, setName] = useState('');
  const [showNewStaff, setShowNewStaff] = useState(true);
  const [formErrors, setFormErrors] = useState({});

  const { t } = useTranslation();

  const closeModal = () => {
    setProgram('');
    setShowNewStaff(false);
    props.close();
  };

  const onSubmit = () => {
    setFormErrors({});

    if (!formValid()) return;

    const params = {
      public_profile: {
        program_space: program,
        name
      }
    };

    props.handleSubmit(params);
    props.close();
  };

  const formValid = () => {
    const e = {};
    if (!program) {
      e.program = t('shared.validations.required');
    }
    if (!name) {
      e.name = t('shared.validations.required');
    }

    setFormErrors(e);
    return Object.entries(e).length === 0;
  };

  return (
    <Modal
      open={showNewStaff}
      onClose={closeModal}
      title={props.title}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <TextField error={formErrors.program} label={t('program_spaces.internal_name')} value={program} onChange={setProgram} />
            <TextField error={formErrors.name} label={t('program_spaces.public_name')} value={name} onChange={setName} />
            <Button primary submit>{t('shared.add')}</Button>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isInstructor: state.auth.instructor
});

export default connect(mapStateToProps)(NewProgra);
