import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Button, Form, FormLayout, TextField, TextStyle, Checkbox } from '@shopify/polaris';
import classes from './Auth.module.scss';
import logo from '../../assets/images/planubo-blue.png';
import appSumo from '../../assets/images/app-sumo.png';
import * as actions from '../../store/actions/index';
import Banners from '../../components/Shared/Banners';

const AppSumo = (props) => {
  const [email, setEmail] = useState('');
  // const [address, setAddress] = useState('');
  // const [zipCode, setZipCode] = useState('');
  // const [city, setCity] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [coupon, setCoupon] = useState('');
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const { t } = useTranslation('translation');
  const history = useNavigate();

  const handleSubmit = (event) => {
    setFormErrors({});

    event.preventDefault();

    if (!formValid()) return;

    let lang = 'en';

    try {
      lang = localStorage.getItem('lang');
      if (!lang) {
        lang = 'en';
      }
    } catch (e) {
      lang = 'en';
    }

    const authData = {
      coupon,
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        password_confirmation: password,
        coupon,
        marketing,
        accepted_terms_of_use: acceptedPrivacyPolicy,
        lang
        // saved_addresses_attributes: [{
        //   label: 'Home',
        //   preferred: true,
        //   address_attributes: {
        //     address,
        //     city,
        //     zip_code: zipCode
        //   }
        // }]
      }
    };

    axios.post('/v1/users/app_sumo', authData)
      .then((response) => {
        const data = {
          token: response.data.token,
          accountState: response.data.accountState,
          id: response.data.id,
          initials: response.data.initials,
          name: response.data.name,
          email: response.data.email,
          instructor: response.data.instructor,
          client: response.data.client,
          role: response.data.role,
          lang: response.data.lang
        };

        try {
          localStorage.setItem('wasLoggedIn', 'true');
        } catch (e) {
          console.log(e);
        }

        props.signupSuccess(data);
        history('/');
      })
      .catch((err) => {
        setErrors([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
      });
  };

  const handleFirstNameChange = useCallback((value) => setFirstName(value), []);
  const handleLastNameChange = useCallback((value) => setLastName(value), []);
  const handleEmailChange = useCallback((value) => setEmail(value), []);
  const handlePasswordChange = useCallback((value) => setPassword(value), []);

  const formValid = () => {
    const e = {};
    if (!firstName) {
      e.firstName = t('user.validations.first_name');
    }
    if (!lastName) {
      e.lastName = t('user.validations.last_name');
    }
    if (!password) {
      e.password = t('user.validations.password');
    }
    if (!email) {
      e.email = t('user.validations.email');
    }
    if (!acceptedPrivacyPolicy) {
      e.acceptedPrivacyPolicy = t('user.validations.terms_of_use');
    }
    // if (!address) {
    //   e.address = t('address.validations.address');
    // }
    // if (!city) {
    //   e.city = t('address.validations.city');
    // }
    // if (!zipCode) {
    //   e.zipCode = t('address.validations.zip_code');
    // }
    if (!coupon) {
      e.coupon = 'Coupon is required.';
    }

    setFormErrors(e);
    return Object.entries(e).length === 0;
  };

  const firstNameField = (
    <TextField
      value={firstName}
      error={formErrors.firstName}
      onChange={handleFirstNameChange}
      placeholder={t('user.first_name')}
      type="text"
    />
  );

  const lastNameField = (
    <TextField
      value={lastName}
      error={formErrors.lastName}
      onChange={handleLastNameChange}
      placeholder={t('user.last_name')}
      type="text"
    />
  );

  const emailField = (
    <TextField
      value={email}
      error={formErrors.email}
      onChange={handleEmailChange}
      placeholder={t('user.email')}
      type="email"
    />
  );

  const passwordfield = (
    <TextField
      value={password}
      error={formErrors.password}
      onChange={handlePasswordChange}
      placeholder={t('user.password')}
      type="password"
    />
  );

  const couponField = (
    <TextField
      value={coupon}
      error={formErrors.coupon}
      onChange={setCoupon}
      placeholder="Coupon"
      type="text"
    />
  );

  return (
    <div className={classes.AppSumo}>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <img src={logo} alt="planubo" />
          <div style={{ borderBottom: 'solid' }} />
          <img src={appSumo} alt="appsumo" />
          {errors.length ? <Banners banners={errors} onDismissBanner={() => setErrors([])} /> : null}
          {firstNameField}
          {lastNameField}
          {emailField}
          {passwordfield}
          {/* <TextField
            type="text"
            placeholder={t('address.address')}
            value={address}
            onChange={setAddress}
            error={formErrors.address}
          />
          <TextField
            type="text"
            placeholder={t('address.city')}
            value={city}
            onChange={setCity}
            error={formErrors.city}
          />
          <TextField
            type="text"
            placeholder={t('address.zip_code')}
            value={zipCode}
            onChange={setZipCode}
            error={formErrors.zipCode}
          /> */}
          {couponField}
          <div className="text-left">
            <Checkbox
              checked={acceptedPrivacyPolicy}
              onChange={setAcceptedPrivacyPolicy}
              error={formErrors.acceptedPrivacyPolicy}
              label={t('user.privacy_policy_agreement')}
            />
            <Checkbox
              checked={marketing}
              onChange={setMarketing}
              label={t('user.newsletter')}
            />
          </div>
          <Button fullWidth primary submit>Sign Up</Button>
          <TextStyle variation="subdued">{`© ${new Date().getFullYear()} Planubo. All Rights Reserved.`}</TextStyle>
          <a href="https://planubo.com/privacy-policy/">{t('user.privacy_policy')}</a>
          <a href="https://planubo.com/terms-of-service/">{t('user.terms_of_use')}</a>
        </FormLayout>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
  isAuthenticated: state.auth.token !== null
});

const mapDispatchToProps = (dispatch) => ({
  signupSuccess: (data) => dispatch(actions.authSuccess(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSumo);
