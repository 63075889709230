import React from 'react';
import { ChoiceList } from '@shopify/polaris';
import { handleFilterChange } from '../FilterEngine';

const unpaidInvoiceFilterDefinition = (t, props, setTableFilters, selected) => ({
  key: 'unpaid',
  label: t('user.status'),
  filter: (
    <ChoiceList
      title={t('invoices.open')}
      titleHidden
      choices={[{
        label: t('invoices.open'),
        value: 'open'
      }, {
        label: t('payments.paid'),
        value: 'paid'
      }, {
        label: t('invoices.refunded'),
        value: 'refunded'
      }, {
        label: t('invoices.cancellation'),
        value: 'cancellation'
      }]}
      selected={selected}
      onChange={handleFilterChange(props, setTableFilters, 'unpaid')}
      allowMultiple
    />
  ),
  shortcut: true
});

export default unpaidInvoiceFilterDefinition;
