import React, { useState } from 'react';
import { Button, Form, Modal } from '@shopify/polaris';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as actions from '../../store/actions/index';
import Schedule from '../Schedule/Schedule';

const BookNowModal = (props) => {
  const [showBookingModal, setShowBookingModal] = useState(true);
  const [availability, setAvailability] = useState({});

  const { t } = useTranslation();

  const closeModal = () => {
    setShowBookingModal(false);
    props.close();
  };

  const onSubmit = () => {
    props.handleSubmit(availability);
    props.close();
  };

  return (
    <Modal
      open={showBookingModal}
      onClose={closeModal}
      title={props.title}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <Schedule value={props.data} handleChange={setAvailability} />
          <Button fullWidth primary submit>{t('shared.confirm')}</Button>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth,
  name: state.auth.name,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  email: state.auth.email,
  isInstructor: state.auth.instructor
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(BookNowModal);
