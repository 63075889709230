import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Filters, Card, Loading, Page, SkeletonPage, DataTable, Stack, Toast, Button, Badge } from '@shopify/polaris';
import Banners from '../Shared/Banners';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { appliedFiltersQuery, handleFilterChange } from '../FilterEngine/FilterEngine';
import { defaultSortDirection, initialSortColumnIndex, handleSort } from '../FilterEngine/Sort/sort';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import PaymentOptions from '../Shared/PaymentOptions';
import createdAtAfterFilterActive from '../FilterEngine/CreatedAtAfter/createdAtAfterFilterActive';
import createdAtBeforeFilterActive from '../FilterEngine/CreatedAtBefore/createdAtBeforeFilterActive';
import createdAtAfterFilterDefinition from '../FilterEngine/CreatedAtAfter/createdAtAfterFilterDefinition';
import createdAtBeforeFilterDefinition from '../FilterEngine/CreatedAtBefore/createdAtBeforeFilterDefinition';
import PnbStats from '../Tailwind/PnbStats';

const Payments = (props) => {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [totalPayments, setTotalInvoices] = useState(0);
  const [lastPage, setLastPage] = useState(null);
  const [stats, setStats] = useState([]);
  const [mounted, setMounted] = useState(true);

  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const translateStatus = useCallback((status) => {
    switch (status) {
      case 'paid': return t('payments.paid');
      case 'processing': return t('payments.processing');
      case 'failed': return t('payments.failed');
      default: return '';
    }
  }, [t]);

  const downloadInvoice = (id) => {
    axios.get(`/v1/invoices/${id}/download`)
      .then((response) => {
        window.open(response.data.url);
      })
      .catch(() => {});
  };

  const modifier = useCallback((tableData) => tableData.map((row) => {
    const modifiedRow = [];

    modifiedRow.push(<Button plain onClick={() => downloadInvoice(row.invoice.token)}>{row.invoice.number}</Button>);
    modifiedRow.push(<Badge status={props.userId === row.coachToken ? 'info' : 'warning'}>{row.coach}</Badge>);
    modifiedRow.push(<Button plain onClick={() => history(`/clients/${row.client.token}`)}>{row.client.name}</Button>);
    modifiedRow.push(moment(row.processedAt).format('ll'));
    modifiedRow.push(translateStatus(row.status));
    modifiedRow.push(PaymentOptions(t).find((option) => option.value === row.paymentMethod)?.label);
    modifiedRow.push(row.amount);
    modifiedRow.push(row.fee);
    modifiedRow.push(row.total);

    return modifiedRow;
  // eslint-disable-next-line
  }), [translateStatus, props.userId, t]);

  const appliedFilters = useCallback(() => {
    const af = [];

    if (!tableFilters.createdAtAfter.default) {
      af.push(createdAtAfterFilterActive(t, { history, location }, setTableFilters, tableFilters.createdAtAfter, 'processed_at_gteq'));
    }
    if (!tableFilters.createdAtBefore.default) {
      af.push(createdAtBeforeFilterActive(t, { history, location }, setTableFilters, tableFilters.createdAtBefore, 'processed_at_lteq'));
    }

    return af;
  }, [tableFilters.createdAtAfter, tableFilters.createdAtBefore, t, history, location]);

  const fetchPayments = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters()),
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/payments/search', params)
      .then((response) => {
        if (mounted) {
          const tableData = modifier(response.data.payments);
          setPayments(tableData);
          setLastPage(response.data.lastPage);
          setStats(response.data.stats);
          setTotalInvoices(response.data.paymentsCount);
          setLoading(false);
        }
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const filters = [
    createdAtAfterFilterDefinition(t, { history, location }, setTableFilters, tableFilters.createdAtAfter, tableFilters.createdAtAfterMonthYear),
    createdAtBeforeFilterDefinition(t, { history, location }, setTableFilters, tableFilters.createdAtBefore, tableFilters.createdAtBeforeMonthYear)
  ];

  const placeHolderText = t('user.search');

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/payments', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={placeHolderText}
    />
  );

  useEffect(() => {
    setMounted(true);
    fetchPayments();
    return () => {
      setMounted(false);
    };
  }, [fetchPayments]);

  const dismissBanner = () => {
    setBanner([]);
  };

  const indexSortMapping = {
    invoice_id: true,
    invoice_client_user_name: true,
    processed_at: true
  };

  const headings = [
    t('payments.reference'),
    t('staff.staff'),
    t('client.client'),
    t('payments.payment_date'),
    t('user.status'),
    t('invoices.payment_method'),
    t('payments.amount'),
    t('payments.fee'),
    t('shared.total')
  ];

  const content = (
    <DataTable
      verticalAlign="middle"
      columnContentTypes={[
        'text',
        'text',
        'text',
        'text',
        'text',
        'text',
        'text'
      ]}
      headings={headings}
      rows={payments}
      sortable={Object.values(indexSortMapping)}
      defaultSortDirection={defaultSortDirection(tableFilters.sorts)}
      initialSortColumnIndex={initialSortColumnIndex(indexSortMapping, tableFilters.sorts)}
      onSort={handleSort({ history, location }, setTableFilters, indexSortMapping)}
      footerContent={`${payments.length} ${t('shared.of')} ${totalPayments}`}
    />
  );

  let loadingContainer = null;
  if (loading) {
    loadingContainer = <Loading />;
  }

  // eslint-disable-next-line
  const pnbStatsFilterChange = (action) => {
    // if (!action) {
    //   handleFilterChange({ history, location }, setTableFilters, 'unpaid', 'reset', '')('', '');
    // } else {
    //   handleFilterChange({ history, location }, setTableFilters, 'unpaid')([action]);
    // }
  };

  return (
    <Page
      title={t('payments.payments')}
      fullWidth
      separator
    >
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      <PnbStats stats={stats} onClick={pnbStatsFilterChange} activeFilter={tableFilters.unpaid?.[0]} decimal="," decimals={2} />
      {toastMarkup}
      {loadingContainer}
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            <Stack>
              <Stack.Item fill>
                {filterControl}
              </Stack.Item>
            </Stack>
          </Card.Subsection>
          <Card.Subsection>
            {content}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={payments}
        lastPage={lastPage}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin,
  isInstructor: state.auth.instructor,
  userId: state.auth.token
});

export default connect(mapStateToProps)(Payments);
