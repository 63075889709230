import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  id: null,
  name: null
};

const setCalendar = (state, action) => updateObject(state, {
  id: action.calendar.id,
  name: action.calendar.name
});

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CALENDAR: return setCalendar(state, action);
    default:
      return state;
  }
};

export default reducer;
