/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { DatePicker, Modal, Button } from '@shopify/polaris';

const DatePickerModal = (props) => {
  const [date, setDate] = useState({ month: props.currentSelect.getMonth(), year: props.currentSelect.getFullYear() });

  const handleChange = (data) => {
    const api = props.calendarRef.current.getApi();
    api.gotoDate(data.start);
    props.setCurrentSelect(data.start);
    props.close();
  };

  const handleMonthChange = useCallback((m, y) => {
    setDate({ month: m, year: y });
  }, []);

  return (
    <Modal
      open={props.active}
      onClose={() => props.close()}
      title="Select a date"
    >
      <Modal.Section>
        <DatePicker
          month={date.month}
          year={date.year}
          onChange={handleChange}
          onMonthChange={handleMonthChange}
          selected={props.currentSelect}
          allowRange={false}
        />
        <Button plain onClick={() => handleChange({ start: new Date() })}>Today</Button>
      </Modal.Section>
    </Modal>
  );
};

export default DatePickerModal;
