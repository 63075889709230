import React from 'react';
import {
  useDraggable
} from '@dnd-kit/core';
import { Draggable } from './Draggable';

const DraggableItem = ({ id, name, label, childOnly, handle, isShared, children, isContainer, handleSingleClick, item }) => {
  const { isDragging, setNodeRef, listeners } = useDraggable({
    id
  });

  return (
    <Draggable
      childOnly={childOnly}
      label={label}
      name={name}
      item={item}
      isShared={isShared}
      isContainer={isContainer}
      dragging={isDragging}
      ref={setNodeRef}
      handle={handle}
      handleSingleClick={handleSingleClick}
      listeners={listeners}
      style={isContainer && {
        opacity: isDragging ? 0 : undefined
      }}
    >
      {children}
    </Draggable>
  );
};

export default DraggableItem;
