import { EnvelopeIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Handle, Position, useReactFlow, useStoreApi } from 'reactflow';

// eslint-disable-next-line no-unused-vars
function EmailNode({ data, value, id }) {
  const [records, setRecords] = useState([]);

  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (evt) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === id) {
          // eslint-disable-next-line no-param-reassign
          node.data = {
            ...node.data,
            type: 'Email',
            selects: {
              ...node.data.selects,
              email: evt.target.value
            }
          };
        }

        return node;
      })
    );
  };

  const fetchData = useCallback(() => {
    const params = {
      per_page: 100,
      page: 1,
      search: '',
      status: 'All',
      q: ''
    };

    axios.post('/v1/email_templates/search', params)
      .then((res) => {
        const temp = [{ label: '', value: '' }, ...res.data.email_templates];
        setRecords(temp);
      })
      .catch(() => {});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400">
      <div className="flex justify-center mb-2">
        <div className="flex items-center">
          <div className="rounded-full w-12 h-12 flex justify-center items-center bg-yellow-300">
            <EnvelopeIcon className="h-5 w-5 text-yellow-700" aria-hidden="true" />
          </div>
        </div>
      </div>

      <Handle type="target" position={Position.Left} className="h-4 !bg-teal-500" />
      <Handle type="source" position={Position.Right} className="h-4 !bg-teal-500" />

      <div>
        <label htmlFor="text">Email: </label>
        <select className="nodrag" onChange={onChange} value={data?.selects?.email}>
          {records.map((option) => (
            <option key={option.token} value={option.token}>
              {option.subject}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default memo(EmailNode);
