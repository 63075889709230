import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BanknotesIcon, CogIcon, CreditCardIcon, InformationCircleIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { connect } from 'react-redux';
import Menu from './Menu/Menu';

const AccountantNavigation = (props) => {
  const { t } = useTranslation('translation');
  const location = useLocation();

  const activeRoute = (route) => location.pathname.split('/')[1] === route;

  const settingsItems = [
    {
      url: '/profile_settings',
      label: t('navigation.profile'),
      selected: activeRoute('profile_settings'),
      condition: true
    }
  ];

  const navigation = [
    {
      label: t('navigation.clients'),
      icon: UserCircleIcon,
      selected: activeRoute('clients'),
      url: '/clients',
      condition: true
    },
    {
      url: '/invoices',
      label: t('invoices.invoices'),
      icon: BanknotesIcon,
      selected: activeRoute('invoices'),
      condition: true,
      boarderTop: true
      // badge: props.unpaidInvoices ? props.unpaidInvoices.toString() : null
    },
    {
      url: '/payments',
      label: t('payments.payments'),
      icon: CreditCardIcon,
      selected: activeRoute('payments'),
      onClick: props.toggle,
      condition: true
    },
    {
      label: t('settings.settings'),
      selected: settingsItems.some((h) => h.url.split('/')[1] === location.pathname.split('/')[1]),
      children: settingsItems,
      icon: CogIcon,
      condition: true,
      boarderTop: true
    },
    {
      label: 'Help Center',
      selected: activeRoute('help'),
      condition: true,
      icon: InformationCircleIcon,
      boarderTop: true,
      children: [
        {
          url: '/help',
          label: t('help.help'),
          selected: activeRoute('help'),
          condition: true
        }
      ]
    }
  ];

  return (
    <Menu toggle={props.toggle} navigation={navigation} />
  );
};

const mapStateToProps = (state) => ({
  unread: state.auth.unread,
  unpaidInvoices: state.auth.unpaidInvoices
});

export default connect(mapStateToProps)(AccountantNavigation);
