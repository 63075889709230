import React, { useState } from 'react';
import { Form, FormLayout, Modal, TextField } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

const CancelReasonModalForm = (props) => {
  const [cancelReason, setCancelReason] = useState('');
  const [show, setShow] = useState(true);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const closeModal = () => {
    setShow(false);
    props.close();
  };

  const onSubmit = () => {
    setError(false);

    if (!cancelReason) {
      setError(true);
      return;
    }

    props.handleSubmit({
      cancel_reason: cancelReason
    });
    props.close();
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title={t('invoices.cancel_reason')}
      primaryAction={{
        content: t('shared.confirm'),
        onAction: onSubmit
      }}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <TextField
              placeholder={t('invoices.cancel_reason')}
              value={cancelReason}
              onChange={setCancelReason}
              error={error}
            />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

export default CancelReasonModalForm;
