import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PublicBooking from '../components/Products/PublicBooking';
import ConfirmProfile from '../containers/Auth/ConfirmProfile';
import Documentation from './Documentation';
import ZoomDocumentation from './ZoomDocumentation';

const NewUserRoutes = () => (
  <Routes>
    <Route exact path="/zoom_documentation" element={<ZoomDocumentation />} />
    <Route exact path="/documentation" element={<Documentation />} />
    <Route path="/manage_booking/:token" exact element={<PublicBooking />} />
    <Route exact path="/" element={<ConfirmProfile />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default NewUserRoutes;
