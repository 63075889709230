import React from 'react';
import { DatePicker } from '@shopify/polaris';
import { handleFilterChange } from '../FilterEngine';

const createdAtBeforeFilterDefinition = (t, props, setTableFilters, selected, selectedMonthYear) => ({
  key: 'created-at-before',
  label: t('filters.before'),
  filter: (
    <DatePicker
      month={selectedMonthYear.month}
      year={selectedMonthYear.year}
      onChange={handleFilterChange(props, setTableFilters, 'createdAtBefore', 'date')}
      onMonthChange={handleFilterChange(props, setTableFilters, 'createdAtBeforeMonthYear', 'monthYear')}
      selected={selected}
    />
  ),
  shortcut: true
});

export default createdAtBeforeFilterDefinition;
