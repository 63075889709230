import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Card, Layout, SkeletonPage, DataTable, Filters, Icon, Button, DescriptionList, TextStyle, Tooltip } from '@shopify/polaris';
import { CircleTickMajor, CircleCancelMajor, QuestionMarkMajor, CashDollarMajor, CurrencyConvertMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Banners from '../Shared/Banners';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { defaultSortDirection, initialSortColumnIndex, handleSort } from '../FilterEngine/Sort/sort';
import { handleFilterChange, appliedFiltersQuery } from '../FilterEngine/FilterEngine';
import createdAtAfterFilterDefinition from '../FilterEngine/CreatedAtAfter/createdAtAfterFilterDefinition';
import createdAtAfterFilterActive from '../FilterEngine/CreatedAtAfter/createdAtAfterFilterActive';
import createdAtBeforeFilterDefinition from '../FilterEngine/CreatedAtBefore/createdAtBeforeFilterDefinition';
import createdAtBeforeFilterActive from '../FilterEngine/CreatedAtBefore/createdAtBeforeFilterActive';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import MarkAsPaidModalForm from '../Invoices/MarkAsPaidModalForm';
import PaymentOptions from '../Shared/PaymentOptions';

const ClientEvents = (props) => {
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState([]);
  const [events, setEvents] = useState([]);
  const [noShows, setNoShows] = useState('');
  const [paidParticipations, setPaidParticipations] = useState('');
  const [openParticipations, setOpenParticipations] = useState('');
  const [participationsWithoutInvoice, setParticipationsWithoutInvoice] = useState('');
  const [lastPage, setLastPage] = useState(null);
  const [mounted, setMounted] = useState(true);
  const [totalEvents, setTotalEvents] = useState(0);
  const [markAsPaidModalOptions, setMarkAsPaidModalOptions] = useState({});

  const currentParticipations = useRef();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();
  const matchParams = useParams();

  const [tableFilters, setTableFilters] = useState(stateConverter(location, [
    { key: 'sorts', value: 'event_start_time desc ' },
    { key: 'createdAtAfter', value: { start: new Date(moment().clone().startOf('month')), end: new Date(moment().clone().startOf('month')) } },
    { key: 'createdAtBefore', value: { start: new Date(moment().clone().endOf('month')), end: new Date(moment().clone().endOf('month')) } }
  ]));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const markAsPaid = (data) => {
    const params = {
      participation_id: data?.participationId,
      payment_method: data.payment_method,
      unpaid: data.unpaid === true,
      per_page: 25,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters()),
      s: { sorts: tableFilters.sorts }
    };

    // New hook
    axios.post(`/v1/clients/${matchParams.id}/paid_toggle`, params)
      .then((res) => {
        const td = modifier(res.data.participations);
        currentParticipations.current = td;
        setEvents(td);
        setLastPage(res.data.lastPage);
        setNoShows(res.data.noShows);
        setOpenParticipations(res.data.openParticipations);
        setPaidParticipations(res.data.paidParticipations);
        setParticipationsWithoutInvoice(res.data.participationsWithoutInvoice);
        setTotalEvents(res.data.eventCount);
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
      });
  };

  let markAsPaidModalForm = null;

  if (Object.keys(markAsPaidModalOptions).length) {
    markAsPaidModalForm = (
      <MarkAsPaidModalForm
        paymentMethod={markAsPaidModalOptions.paymentMethod}
        handleSubmit={markAsPaid}
        participationId={markAsPaidModalOptions.id}
        paid={markAsPaidModalOptions.paid}
        close={() => setMarkAsPaidModalOptions({})}
      />
    );
  }

  const modifier = useCallback((tableData) => {
    const noShowIcon = (val) => {
      switch (val) {
        case 'pending': return (
          <Tooltip content={t('payments.pending')}>
            <Icon backdrop source={QuestionMarkMajor} color="highlight" />
          </Tooltip>
        );
        case 'no_show_no_pay': return (
          <Tooltip content={t('client.no_show_no_po')}>
            <Icon backdrop source={CircleCancelMajor} color="critical" />
          </Tooltip>
        );
        case 'show': return (
          <Tooltip content={t('client.show')}>
            <Icon backdrop source={CircleTickMajor} color="success" />
          </Tooltip>
        );
        case 'no_show': return (
          <Tooltip content={t('client.no_show_and_pay')}>
            <Icon backdrop source={CurrencyConvertMinor} color="critical" />
          </Tooltip>
        );
        default: return <Icon backdrop source={CircleCancelMajor} color="critical" />;
      }
    };

    const downloadInvoice = (id) => {
      axios.get(`/v1/invoices/${id}/download`)
        .then((response) => {
          window.open(response.data.url);
        })
        .catch(() => {});
    };

    return tableData.map((row) => {
      const modifiedRow = [];

      modifiedRow.push(<Button plain url={`/events/${row.eventId}`}>{moment(row.eventStartTime).format('llll')}</Button>);
      modifiedRow.push(<div className="whitespace-pre-wrap">{row.eventTitle}</div>);
      modifiedRow.push(noShowIcon(row.state));
      if (props.hasInvoiceAccess) {
        if (row.invoiceAttached) {
          modifiedRow.push(<Button plain onClick={() => downloadInvoice(row.invoiceToken)}>{row.invoiceNumber}</Button>);
        } else {
          modifiedRow.push(row.invoiceNumber);
        }
        modifiedRow.push(
          !row.paid ? (
            <Button
              destructive
              plain
              icon={CashDollarMajor}
              onClick={() => setMarkAsPaidModalOptions({ id: row.id, paid: row.paid, paymentMethod: row.paymentMethod })}
            >
              {t('invoices.mark_as_paid')}
            </Button>
          ) : (
            <TextStyle variation="subdued">{PaymentOptions(t).find((option) => option.value === row.paymentMethod)?.label}</TextStyle>
          )
        );
      }

      return modifiedRow;
    });
  // eslint-disable-next-line
  }, []);

  const fetchEvents = useCallback(() => {
    const params = {
      per_page: 25,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters()),
      s: { sorts: tableFilters.sorts }
    };

    // new hook
    axios.post(`/v1/clients/${matchParams.id}/events`, params)
      .then((res) => {
        if (mounted) {
          const tableData = modifier(res.data.participations);
          currentParticipations.current = tableData;
          setEvents(tableData);
          setLastPage(res.data.lastPage);
          setNoShows(res.data.noShows);
          setOpenParticipations(res.data.openParticipations);
          setPaidParticipations(res.data.paidParticipations);
          setParticipationsWithoutInvoice(res.data.participationsWithoutInvoice);
          setTotalEvents(res.data.eventCount);
          setLoading(false);
        }
      })
      .catch((err) => {
        setBanner([{ title: t('client.cannot_get_list'), status: 'critical', details: err.response.data.errors }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const createMultiInvoice = () => {
    const params = {
      per_page: 25,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters()),
      s: { sorts: tableFilters.sorts }
    };

    // new hook
    axios.post(`/v1/clients/${matchParams.id}/events_create_invoice`, params)
      .then((res) => {
        history(`/invoices/${res.data.invoice_token}`);
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
        setLoading(false);
      });
  };

  useEffect(() => {
    setMounted(true);
    fetchEvents();
    return () => {
      setMounted(false);
    };
  }, [fetchEvents]);

  const filters = [
    createdAtAfterFilterDefinition(t, { history, location }, setTableFilters, tableFilters.createdAtAfter, tableFilters.createdAtAfterMonthYear),
    createdAtBeforeFilterDefinition(t, { history, location }, setTableFilters, tableFilters.createdAtBefore, tableFilters.createdAtBeforeMonthYear)
  ];

  const appliedFilters = useCallback(() => {
    const af = [];

    if (!tableFilters.createdAtAfter.default) {
      af.push(createdAtAfterFilterActive(t, { history, location }, setTableFilters, tableFilters.createdAtAfter));
    }
    if (!tableFilters.createdAtBefore.default) {
      af.push(createdAtBeforeFilterActive(t, { history, location }, setTableFilters, tableFilters.createdAtBefore));
    }

    return af;
  }, [tableFilters.createdAtAfter, tableFilters.createdAtBefore, t, history, location]);

  const placeHolderText = '';

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={appliedFilters()}
      queryPlaceholder={placeHolderText}
    />
  );

  const indexSortMapping = {
    event_start_time: true,
    no_show: false,
    paid: false
  };

  let headings = [
    t('shared.time'),
    t('calendar.events'),
    t('shared.attendance'),
    t('invoices.invoice'),
    t('shared.paid')
  ];

  if (!props.hasInvoiceAccess) {
    headings = [
      t('shared.time'),
      t('calendar.events'),
      t('shared.attendance')
    ];
  }

  const content = (
    <DataTable
      columnContentTypes={[
        'text',
        'text',
        'text',
        'text',
        'text'
      ]}
      verticalAlign="middle"
      headings={headings}
      rows={events}
      sortable={Object.values(indexSortMapping)}
      defaultSortDirection={defaultSortDirection(tableFilters.sorts)}
      initialSortColumnIndex={initialSortColumnIndex(indexSortMapping, tableFilters.sorts)}
      onSort={handleSort({ history, location }, setTableFilters, indexSortMapping)}
      footerContent={`${events.length} ${t('shared.of')} ${totalEvents}`}
    />
  );

  const descriptionList = () => {
    const items = [{
      term: t('events.total'),
      description: totalEvents
    }, {
      term: t('events.no_shows'),
      description: noShows
    }, {
      term: t('events.paid'),
      description: paidParticipations
    }, {
      term: t('events.unpaid'),
      description: openParticipations
    }, {
      term: t('events.participations_without_event'),
      description: participationsWithoutInvoice
    }];

    return items;
  };

  return (
    <>
      {markAsPaidModalForm}
      {loading ? <SkeletonPage /> : (
        <>
          <Banners banners={banner} onDismissBanner={() => setBanner([])} />
          <Layout>
            <Layout.Section>
              {!loading ? (
                <Card sectioned>
                  <Card.Subsection>
                    {filterControl}
                  </Card.Subsection>
                  <Card.Subsection>
                    {content}
                  </Card.Subsection>
                </Card>
              ) : <SkeletonPage />}
              <TablePagination
                pageFilter={tableFilters.page}
                setTableFilters={setTableFilters}
                records={events}
                length={25}
                lastPage={lastPage}
              />
            </Layout.Section>
            {props.hasInvoiceAccess && (
              <Layout.Section secondary>
                <Card
                  sectioned
                >
                  <DescriptionList items={descriptionList()} />
                  {props.canCreateInvoices && <Button primary fullWidth onClick={() => createMultiInvoice()}>{t('invoices.create_invoice_for_all_unpaid_events')}</Button>}
                </Card>
              </Layout.Section>
            )}
          </Layout>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  canCreateInvoices: !['', 'Free'].includes(state.auth.plan),
  hasInvoiceAccess: state.auth.hasInvoiceAccess
});

export default connect(mapStateToProps)(ClientEvents);
