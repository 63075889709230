import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  ContextualSaveBar, Page, Layout, Card, Stack, TextStyle, Select, FormLayout,
  Button, Form, Toast
} from '@shopify/polaris';
import { ColorPicker, useColor, toColor } from 'react-color-palette';
import fileDownload from 'js-file-download';
import SwitchToggle from '../Shared/SwitchToggle';
import { meridiemHours, hours } from '../Shared/Constants/Hours';
import Schedule from '../Schedule/Schedule';

const CalendarSettings = (props) => {
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [weekend, setWeekend] = useState(true);
  const [startOfWeek, setStartOfWeek] = useState('0');
  const [calendarTime, setCalendarTime] = useState({});
  const [color, setColor] = useColor('hex', '#3b5998');
  const [schedule, setSchedule] = useState([]);
  const [timeZone, setTimeZone] = useState('');
  const { t } = useTranslation();
  const location = useLocation();
  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const handleWeekendToggleChange = (data) => {
    setShowSaveBar(true);
    setWeekend(data);
  };

  const handleTimeZoneChange = (data) => {
    setShowSaveBar(true);
    setTimeZone(data);
  };

  const handleSelectStartOfWeekChange = (data) => {
    setShowSaveBar(true);
    setStartOfWeek(data);
  };

  const handleScheduleChange = (data) => {
    if (!init) return;

    setShowSaveBar(true);
    setSchedule(data);
  };

  const handleCalenderTimeChange = (field) => (data) => {
    setShowSaveBar(true);
    setCalendarTime({ ...calendarTime, [field]: data });
  };

  const startOfWeekOptions = [
    { label: t('week.sunday'), value: '0' },
    { label: t('week.monday'), value: '1' },
    { label: t('week.tuesday'), value: '2' },
    { label: t('week.wednesday'), value: '3' },
    { label: t('week.thursday'), value: '4' },
    { label: t('week.friday'), value: '5' },
    { label: t('week.saturday'), value: '6' }
  ];

  const updateSettings = () => {
    setLoading(true);

    const formData = new FormData();

    formData.append('calendar[min_time]', calendarTime.start);
    formData.append('calendar[max_time]', calendarTime.end);
    formData.append('calendar[weekends]', weekend);
    formData.append('calendar[first_day]', startOfWeek);
    formData.append('calendar[schedule]', JSON.stringify(schedule));
    if (timeZone) formData.append('calendar[time_zone]', timeZone);
    formData.append('color', color.hex);

    axios.patch('/v1/settings', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        toggleToastActive(t('shared.saved'));
        setStartOfWeek(response.data.calendarSettings.firstDay);
        setWeekend(response.data.calendarSettings.weekends);
        setCalendarTime({
          start: response.data.calendarSettings.minTime,
          end: response.data.calendarSettings.maxTime
        });
        setSchedule(JSON.parse(response.data.calendarSettings.schedule));
        setLoading(false);
        setShowSaveBar(false);
        setColor(toColor('hex', response.data.color));
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
        setLoading(false);
      });
  };

  const fetchSettings = useCallback(() => {
    axios.get('/v1/settings')
      .then((response) => {
        setInit(true);
        setStartOfWeek(response.data.calendarSettings.firstDay);
        setWeekend(response.data.calendarSettings.weekends);
        setTimeZone(response.data.calendarSettings.timeZone);
        setSchedule(JSON.parse(response.data.calendarSettings.schedule));
        setCalendarTime({
          start: response.data.calendarSettings.minTime,
          end: response.data.calendarSettings.maxTime
        });
        setColor(toColor('hex', response.data.color));
      })
      .catch(() => {
        setLoading(false);
      });
  }, [setColor]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const error = query.get('error');

    if (error) {
      toggleToastActive(t('shared.something_went_wrong'));
    }
    // eslint-disable-next-line
  }, []);

  const exportCalendar = () => {
    axios.get('/v1/settings/export_calendar')
      // eslint-disable-next-line no-shadow
      .then((res) => {
        const data = new Blob([res.data.toString()], { type: 'text/calendar' });
        fileDownload(data, res.headers.filename);
      })
      .catch(() => { });
  };

  const timeZoneOptions = () => {
    const tzs = moment.tz.names().map((tz) => ({ value: tz, label: tz }));
    tzs.unshift({ value: '', label: '' });
    return tzs;
  };

  const handleColorChange = (val) => {
    setShowSaveBar(true);
    setColor(val);
  };

  const settingsForm = (
    <Form onSubmit={updateSettings}>
      <FormLayout>
        <Layout>
          <Layout.AnnotatedSection
            title={t('settings.calendar_details')}
            description={t('settings.customize_calendar')}
          >
            <Card sectioned>
              <Card.Section>
                <Stack alignment="center">
                  <Stack.Item fill>
                    <TextStyle>{t('settings.show_weekends')}</TextStyle>
                  </Stack.Item>
                  <Stack.Item>
                    <SwitchToggle
                      handleChange={handleWeekendToggleChange}
                      checked={weekend}
                    />
                  </Stack.Item>
                </Stack>
              </Card.Section>
              <Card.Section>
                <Select
                  label={t('settings.first_day')}
                  options={startOfWeekOptions}
                  onChange={handleSelectStartOfWeekChange}
                  value={startOfWeek}
                />
              </Card.Section>
              <Card.Section>
                <FormLayout>
                  <Select
                    label={t('shared.start')}
                    options={props.lang === 'en' ? meridiemHours : hours}
                    onChange={handleCalenderTimeChange('start')}
                    value={calendarTime.start}
                  />
                  <Select
                    label={t('shared.end')}
                    options={props.lang === 'en' ? meridiemHours : hours}
                    onChange={handleCalenderTimeChange('end')}
                    value={calendarTime.end}
                  />
                </FormLayout>
              </Card.Section>
              {!props.isStaff ? (
                <Card.Section>
                  <Select
                    label={t('shared.time_zone')}
                    options={timeZoneOptions()}
                    onChange={handleTimeZoneChange}
                    value={timeZone}
                  />
                </Card.Section>
              ) : ''}
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('product.availability')}
          >
            <Card>
              <Schedule value={schedule} handleChange={handleScheduleChange} />
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('custom_domain.colors')}
            description={t('custom_domain.colors_description')}
          >
            <Card sectioned>
              <Card.Section title={t('custom_domain.calendar_event_color')} style={{ backgroundColor: 'black' }}>
                <Stack>
                  <ColorPicker width={228} height={114} color={color} onChange={(e) => handleColorChange(e)} hideHSV hideRGB hideHSB />
                  <div style={{ width: '80px', height: '114px', backgroundColor: color.hex, borderRadius: '9px' }} />
                </Stack>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={`${t('navigation.calendar')} ${t('invoices.export')}`}
          >
            <Card sectioned>
              <Card.Section>
                <Button plain onClick={() => exportCalendar()}>{t('invoices.export')}</Button>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </FormLayout>
    </Form>
  );

  return (
    <Page
      title={t('navigation.calendar_settings')}
      separator
    >
      {showSaveBar && (
        <ContextualSaveBar
          message={t('shared.unsaved_changes')}
          saveAction={{
            onAction: () => updateSettings(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      {toastMarkup}
      {init && settingsForm}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.lang,
  isStaff: state.auth.role === 'staff',
  isFreeMember: state.auth.freeMember,
  isBasicMember: state.auth.basicMember,
  isPremiumMember: state.auth.premiumMember,
  isPremiumPlusMember: state.auth.premiumPlusMember
});

export default connect(mapStateToProps)(CalendarSettings);
