/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Banner } from '@shopify/polaris';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Checkout from '../Checkout/Checkout';
import defaultImage from '../../assets/images/default_product.jpg';
import * as actions from '../../store/actions/index';

const BookNowModal = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    props.onTryAutoSignin();
  // eslint-disable-next-line
  }, [props.onTryAutoSignin]);

  const onSubmit = (data) => {
    const client = {
      first_name: data.firstName || props.firstName,
      last_name: data.lastName || props.lastName,
      email: data.email || props.user.email,
      code: data.code,
      form_data_reply: data.form_data_reply,
      paymentMethod: data.paymentMethod,
      eventPackage: data.eventPackage
    };

    if (data.pbCustomFields.length) {
      client.product_booking_custom_fields = data.pbCustomFields;
    }

    props.handleSubmit(client);
    props.close();
  };

  const products = () => [
    {
      id: props.product.token,
      title: props.product.title,
      timeslot: props.timeSlot,
      duration: props.duration,
      price: props.price,
      product_raw_price: props.product.price,
      imageSrc: props.product.product_image || defaultImage,
      imagealt: 'product',
      instructorName: props.selectedSlot.user_name,
      bookingFields: props.product.form_data || '[]',
      product_settings: props.product.product_settings,
      self: props.product
    }
  ];

  const payNotification = (props.product.requires_payment ? (
    <>
      <Banner status="info">
        {t('booking.confirm_payment')}
      </Banner>
      <br />
    </>
  ) : null);

  return (
    <Checkout
      isLoading={props.isLoading}
      onSubmit={onSubmit}
      isLoggedIn={props.name}
      products={products()}
      timeslot={props.timeSlot}
      duration={props.duration}
      customFields={props.customFields}
      requiresPayment={props.product.requires_payment}
      price={props.price}
      close={props.close}
      payNotification={payNotification}
      user={props.user}
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.auth,
  name: state.auth.name,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  email: state.auth.email,
  isInstructor: state.auth.instructor
});

const mapDispatchToProps = (dispatch) => ({
  onTryAutoSignin: () => dispatch(actions.authCheckState())
});

export default connect(mapStateToProps, mapDispatchToProps)(BookNowModal);
