import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLayout, Modal, TextField, Form, Card } from '@shopify/polaris';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import Banners from '../Shared/Banners';

const EventEdit = (props) => {
  const [event, setEvent] = useState({ ...props.event });
  const [banner, setBanner] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const { t } = useTranslation();

  const formValid = useCallback(() => {
    const errors = {};

    if (!event.title) {
      errors.title = t('shared.validations.required');
    }

    setFormErrors(errors);

    return Object.entries(errors).length === 0;
  }, [event.title, t]);

  const handleSubmit = () => {
    if (!formValid()) return;

    event.start_time = event.start;
    event.end_time = event.end;
    props.handleSubmit(event);
    props.close();
  };

  const handleDelete = () => {
    props.close();
    props.handleDelete(event);
  };

  const fetchDetails = () => {
    const params = {
      date: event.start_time
    };

    axios.get(`/v1/holiday_events/${event.token}`, params)
      .then((response) => {
        setEvent(response.data.event);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchDetails();
  // eslint-disable-next-line
  }, []);

  const handleChange = (key) => (value) => {
    setEvent({ ...event, [key]: value });
  };

  const eventOptions = () => (
    <FormLayout>
      <TextField
        value={event.title}
        label="Description"
        requiredIndicator
        error={formErrors.title}
        onChange={handleChange('title')}
      />
      <Card>
        {event.staffName && (
          <Card.Section title="Name">
            {event.staffName}
          </Card.Section>
        )}
        <Card.Section title={t('shared.start')}>
          {moment(event.start).format('LL')}
        </Card.Section>
        <Card.Section title={t('shared.end')}>
          {moment(event.end).format('LL')}
        </Card.Section>
      </Card>
    </FormLayout>
  );

  const secondaryActions = () => {
    const actions = [{
      destructive: true,
      content: t('shared.delete'),
      onAction: handleDelete
    }];

    return actions;
  };

  return (
    <Modal
      open={props.active}
      onClose={() => props.close()}
      title={t('shared.event_edit')}
      primaryAction={{
        content: t('shared.save'),
        onAction: handleSubmit
      }}
      secondaryActions={secondaryActions()}
    >
      <Modal.Section>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <Banners banners={banner} onDismissBanner={() => setBanner([])} />
            {eventOptions()}
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.isStaff
});

export default connect(mapStateToProps)(EventEdit);
