import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Page, Layout, SkeletonPage, Stack, Thumbnail, Button, DropZone, Tabs } from '@shopify/polaris';
import { HideMinor, ViewMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import { connect } from 'react-redux';
import Banners from '../Shared/Banners';
import Comments from '../Comments/Comments';
import { stateConverter } from '../FilterEngine/filterParams';
import StackList from '../Shared/StackList/StackList';
import { getTabIndex, onTabSelect } from '../FilterEngine/Tabs/tabs';

const Accountant = (props) => {
  const [avatar, setAvatar] = useState('');

  const [accountant, setAccountant] = useState({ address: {} });
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();
  const matchParams = useParams();

  const tabs = [
    {
      id: 'details',
      content: t('client.overview'),
      accessibilityLabel: t('client.overview'),
      panelID: 'details'
    },
    {
      id: 'notes',
      content: t('comment.comments'),
      accessibilityLabel: t('comment.comments'),
      panelID: 'notes'
    }
  ];

  if (!props.canAccessInvoices || !props.hasInvoiceAccess) {
    tabs.splice(tabs.findIndex((tab) => tab.panelID === 'invoices'), 1);
  }

  const [tableFilters, setTableFilters] = useState(stateConverter(location));

  const fetchAccountant = useCallback(() => {
    axios.get(`/v1/accountants/${matchParams.token}`)
      .then((response) => {
        if (response.data.avatar) setAvatar(response.data.avatar);
        setAccountant(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setBanner([{ title: t('client.cannot_get_list'), status: 'critical', details: err.response.data.error }]);
        setLoading(false);
      });
  }, [matchParams.token, t]);

  const handleActiveToggle = (id, action) => {
    if (!window.confirm(action === 'inactivate' ? t('staff.deactivate') : t('staff.activate'))) {
      return;
    }

    axios.post(`/v1/accountants/${id}/${action}`)
      .then(() => {
        setAccountant({ ...accountant, accountState: accountant.active ? 'inactive' : 'active', active: !accountant.active });
        setBanner([{ title: `User ${action}d`, status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: `Cannot ${action} user`, status: 'critical', details: error.response.data.errors }]);
      });
  };

  const pageActions = () => {
    if (!accountant || loading) {
      return {};
    }

    const toggleContent = accountant.active ? 'Inactivate' : 'Activate';
    const content = accountant.active ? t('client.inactivate') : t('client.activate');

    const actions = {
      icon: accountant.active ? HideMinor : ViewMinor,
      content,
      destructive: true,
      onAction: () => handleActiveToggle(accountant.token, toggleContent.toLowerCase())
    };

    return actions;
  };

  useEffect(() => {
    fetchAccountant();
  }, [fetchAccountant]);

  const dismissBanner = () => {
    setBanner([]);
  };

  const descriptionList = () => {
    const items = [{
      term: t('user.name'),
      description: accountant.name
    }, {
      term: t('user.email'),
      description: accountant.email
    }, {
      term: t('address.phone_number'),
      description: accountant.phoneNumber
    }, {
      term: t('address.addresse'),
      description: accountant.address && Object.keys(accountant.address).length ? (
        <>
          <div>{accountant.address.attentionName}</div>
          <div>{accountant.address.address}</div>
          <div>{accountant.address.addressLineTwo}</div>
          <div>{`${accountant.address.city}, ${accountant.address.state || ''} ${accountant.address.zip_code || ''}`}</div>
        </>
      ) : null
    }];

    return items;
  };

  const updateAvatar = useCallback((a) => {
    const formData = new FormData();

    if (a?.size > 0) {
      formData.append('avatar', a);
    }

    if (!a) {
      formData.append('remove_logo', true);
    }

    axios.patch(`/v1/accountants/${matchParams.token}/avatar`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        setAvatar(response.data.avatar);
        setBanner([{ title: t('user.update_successful'), status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  }, [matchParams.token, t]);

  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
    } else {
      setAvatar(acceptedFiles[0]);
      updateAvatar(acceptedFiles[0]);
    }
  }, [updateAvatar, t]);
  const uploadedFile = avatar && (
    <Stack alignment="center">
      {avatar?.length ? <Thumbnail size="large" name="avatar" source={avatar} /> : ''}
      <Button
        plain
        onClick={() => {
          setAvatar('');
        }}
      >
        {t('shared.change')}
      </Button>
    </Stack>
  );

  const staffAvatar = !avatar?.length ? (
    <>
      <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleDropZoneDrop}>
        <DropZone.FileUpload />
      </DropZone>
      <br />
    </>
  ) : uploadedFile;

  const detailsTab = (
    loading ? <SkeletonPage /> : (
      <>
        <Banners banners={banner} onDismissBanner={dismissBanner} />
        <Layout>
          <Layout.Section oneHalf>
            <Card sectioned title={t('client.personal_info')}>
              <StackList items={descriptionList()} />
            </Card>
          </Layout.Section>
          <Layout.Section oneHalf>
            <Card
              sectioned
              actions={avatar?.length ? [{
                content: t('shared.change'),
                onAction: () => setAvatar('')
              }, {
                content: t('shared.delete'),
                onAction: () => updateAvatar('')
              }] : ''}
            >
              {avatar ? (
                <img
                  alt=""
                  width="100%"
                  style={{
                    objectFit: 'scale-down',
                    objectPosition: 'center',
                    maxHeight: '400px'
                  }}
                  src={avatar}
                />
              ) : staffAvatar}
            </Card>
          </Layout.Section>
        </Layout>
      </>
    )
  );

  return (
    <Page
      title={`${t('accountant.singular')} ${accountant.name ? '-' : ''} ${accountant.name || ''}`}
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history('/accountants') }]}
      fullWidth
      separator
      primaryAction={pageActions()}
    >
      <Tabs
        tabs={tabs}
        selected={getTabIndex(tabs, tableFilters.tab)}
        onSelect={(tabIndex) => {
          onTabSelect({ history, location }, tabs, tabIndex, setTableFilters);
        }}
      >
        <br />
        {getTabIndex(tabs, tableFilters.tab) === 0 && (
          detailsTab
        )}
        {getTabIndex(tabs, tableFilters.tab) === 1 && (
          <Comments
            commentableId={accountant.token}
            commentablePath="accountants"
            tooltip={t('accountants.secure')}
          />
        )}
      </Tabs>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  canAccessInvoices: !['', 'Free'].includes(state.auth.plan),
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  unread: state.auth.unread
});

export default connect(mapStateToProps)(Accountant);
