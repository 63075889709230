import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, TextStyle, FormLayout } from '@shopify/polaris';
import CountUp from 'react-countup';

const HomeCard = (props) => {
  const history = useNavigate();

  const handleAction = () => {
    if (props.action) {
      props.handleChange();
    } else {
      history(props.url);
    }
  };
  return (
    <div
      onClick={() => handleAction()}
      onKeyPress={() => handleAction()}
      role="link"
      style={{ cursor: 'pointer' }}
      tabIndex={0}
    >
      <Card>
        <Card.Section>
          <br />
          <FormLayout>
            <div style={{ fontSize: '48px', textAlign: 'center' }}>{props.text ? props.text : <CountUp duration={0.5} end={props.number} decimal={props.decimal} decimals={props.decimals} />}</div>
            <div style={{ textAlign: 'center' }}><TextStyle variation="subdued">{props.subTitle}</TextStyle></div>
          </FormLayout>
          <br />
        </Card.Section>
      </Card>
    </div>
  );
};

export default HomeCard;
