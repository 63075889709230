const loadPaddle = (callback) => {
  const existingScript = document.getElementById('paddle');
  if (!existingScript) {
    const script = document.createElement('script');
    script.crossOrigin = true;
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.id = 'paddle';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export default loadPaddle;
