import { handleFilterChange } from '../FilterEngine';

// eslint-disable-next-line default-param-last
const productTypeFilterActive = (t, props, setTableFilters, type = [], filter = 'type_eq') => ({
  key: 'product_type',
  label: `Type: ${t(`memberships.${type.split('::')[1]}`)}`,
  onRemove: handleFilterChange(props, setTableFilters, 'product_type', 'reset', ''),
  filter,
  value: type
});

export default productTypeFilterActive;
