import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Badge, Stack, TopBar } from '@shopify/polaris';
import { ArrowLeftMinor, EditMajor } from '@shopify/polaris-icons';
import * as actions from '../../../store/actions/index';

const UserMenu = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { t } = useTranslation();
  const history = useNavigate();

  const toggleUserMenu = () => setMenuOpen(!menuOpen);

  const logout = () => {
    if (!window.confirm(t('shared.are_you_sure_to_logout'))) {
      return;
    }

    props.onLogout();
    history('/');
  };

  const menuActions = () => {
    const items = [{
      content: t('user.edit_profile'),
      icon: EditMajor,
      onAction: () => history('/profile_settings')
    },
    {
      content: t('user.sign_out'),
      destructive: true,
      icon: ArrowLeftMinor,
      onAction: logout
    }];

    if (props.isInstructor) {
      items.unshift({
        content: (
          <Stack>
            <Stack.Item fill />
            <Badge status="success">{props.plan}</Badge>
          </Stack>
        ),
        onAction: () => history('/plans')
      });
    }

    return items;
  };

  return (
    <TopBar.UserMenu
      actions={[{
        items: menuActions()
      }]}
      avatar={props.user.avatar}
      name={props.user.name}
      detail={props.user.email}
      initials={props.user.initials}
      open={menuOpen}
      onToggle={() => toggleUserMenu()}
    />
  );
};

const mapStateToProps = (state) => ({
  isInstructor: state.auth.instructor,
  user: state.auth,
  plan: state.auth.plan
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
