import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  PencilIcon,
  PlusSmallIcon as PlusSmallIconSolid
} from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Avatar } from '@shopify/polaris';
import Permissions from './Permissions';
import SidePanelSkeleton from './SidePanelSkeleton';

const SidePanel = (props) => {
  const { file } = props;
  const [showShare, setShowShare] = useState(false);

  const [editName, setEditName] = useState(false);
  const [editDescription, setEditDescription] = useState(false);

  const { t } = useTranslation();

  const handleChange = (event) => {
    props.setFile({ ...props.file, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    setEditDescription(false);
    setEditName(false);
    props.updateFile();
  };

  const mapDescriptionText = () => file?.description?.split('\n')?.map((str) => <p className="text-sm flex text-gray-500 italic">{str}</p>);

  const deleteMe = () => {
    if (!window.confirm(`${t('shared.are_you_sure')} ${file?.name}`)) {
      return;
    }

    props.deleteItem(file?.id);
  };

  const onClose = () => {
    props.close();
    setShowShare(false);
  };

  const editAccess = () => (
    (props.isInstructor || (props.isStaff && props.permissions.write)) || (props.hasMediaLibraryAccess) || file?.isOwner || props.isAdmin
  );

  const canShareAccess = () => (
    (props.isInstructor || (props.isStaff && props.permissions.write)) || (props.hasMediaLibraryAccess) || props.isAdmin
  );

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 sm:w-full md:w-1/2 xl:w-1/4 w-full flex pt-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-200 sm:duration-200"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-200 sm:duration-200"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-200"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-4 flex sm:-ml-6 sm:pr-4">
                    <button
                      type="button"
                      className="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => onClose()}
                    >
                      <span className="sr-only">{t('media_library.close_panel')}</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="relative flex-1 px-4 sm:px-6">
                    {props.isLoading ? (
                      <SidePanelSkeleton />
                    ) : (
                      <div className="pb-16 space-y-4">
                        <div>
                          {/* <div className="sticky top-0 block w-full aspect-w-7 aspect-h-5 rounded-lg"> */}
                          {file?.previewUrl && <img src={file?.previewUrl || file?.fileUrl} alt="" className="object-cover h-60 w-full" />}
                          {/* </div> */}
                          <div className="flex items-center flex">
                            <div className="flex flex-1 align-center">
                              {!editName ? (
                                <div className="flex">
                                  <h2 className="text-base font-medium text-gray-900">
                                    <span className="sr-only">Details for </span>
                                    {file?.name}
                                  </h2>
                                </div>
                              ) : (
                                <div className="w-full">
                                  <div className="mt-1 w-full">
                                    <input
                                      type="text"
                                      name="name"
                                      onChange={handleChange}
                                      className="shadow text-base focus:ring-indigo-500 focus:border-indigo-500 block w-full border py-2 px-4 border-gray-300 rounded-md"
                                      defaultValue={file?.name}
                                    />
                                    <button
                                      type="button"
                                      onClick={() => handleSubmit()}
                                      className="bg-white mt-2 py-2 px-4 w-full border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      {t('shared.save')}
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            {!editName && editAccess() && (
                              <button
                                type="button"
                                onClick={() => setEditName(true)}
                                className="bg-white rounded-full h-6 w-6 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              >
                                <PencilIcon className="h-4 w-4" aria-hidden="true" />
                              </button>
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="flex item-center justify-between">
                            <h5 className="font-medium text-gray-900">{t('shared.description')}</h5>
                            {!editDescription && editAccess() && (
                              <button
                                type="button"
                                onClick={() => setEditDescription(true)}
                                className="bg-white rounded-full h-6 w-6 flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              >
                                <PencilIcon className="h-4 w-4" aria-hidden="true" />
                                <span className="sr-only">{t('shared.add_description')}</span>
                              </button>
                            )}
                          </div>
                          <div className="mb-4">
                            {!editDescription && (file?.description ? mapDescriptionText() : <p className="text-sm text-gray-500 italic">{t('shared.add_a_description')}</p>)}
                            {editDescription ? (
                              <div>
                                <textarea
                                  rows={4}
                                  name="description"
                                  className="p-4 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border border-gray-300 rounded-md"
                                  onChange={handleChange}
                                  defaultValue={file?.description || ''}
                                />
                                <button
                                  type="button"
                                  onClick={() => handleSubmit()}
                                  className="bg-white py-2 px-4 w-full border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                  {t('shared.save')}
                                </button>
                              </div>
                            ) : ''}
                          </div>
                        </div>
                        {canShareAccess() && (
                          <div>
                            <h5 className="font-medium text-gray-900">{t('meda_library.shared_with')}</h5>
                            <ul className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                              <div className="overflow-y-auto max-h-56">
                                {file?.sharedWith?.map((person) => (
                                  <li key={person.user.id} className="py-1 flex justify-between items-center">
                                    <div className="flex items-center">
                                      <Avatar size="medium" name={person.user.name} initials={person.user.initials} source={person.user.avatar} />
                                      <p className="ml-4 mr-2 text-sm font-medium text-gray-900">{person.user.name}</p>
                                      {person.permissions.read && (
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                                          {t('media_library.permissions.view')}
                                        </span>
                                      )}
                                      {person.permissions.write && (
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                                          {t('media_library.permissions.write')}
                                        </span>
                                      )}
                                    </div>
                                    <button
                                      type="button"
                                      onClick={() => props.revokePermissions(person.user.id)}
                                      className="ml-6 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      {t('shared.remove')}
                                      <span className="sr-only">{person.user.name}</span>
                                    </button>
                                  </li>
                                ))}
                              </div>
                              <li className="py-1 flex justify-between items-center">
                                {showShare ? (
                                  <div className="w-full">
                                    <Permissions mediaLibraryToken={props.mediaLibraryToken} token={file?.id} setShowShare={setShowShare} createPermissions={props.createPermissions} />
                                  </div>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => setShowShare(true)}
                                    className="group -ml-1 bg-white p-1 rounded-md flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                  >
                                    <span className="w-8 h-8 rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center text-gray-400">
                                      <PlusSmallIconSolid className="h-5 w-" aria-hidden="true" />
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500">
                                      {t('media_library.share')}
                                    </span>
                                  </button>
                                )}
                              </li>
                            </ul>
                          </div>
                        )}
                        <div>
                          <h5 className="font-medium text-gray-900">{t('shared.details')}</h5>
                          <dl className="mt-1 border-b border-gray-200 divide-y divide-gray-200">
                            <div className="py-2 flex justify-between text-xs font-medium">
                              <dt className="text-xs text-gray-500">{`${file?.content_type === 'folder' ? 'Created by' : 'Uploaded by'}`}</dt>
                              <dd className="text-xs text-gray-900">{file?.information?.name}</dd>
                            </div>
                          </dl>
                          <dl className="border-b border-gray-200 divide-y divide-gray-200">
                            <div className="py-2 flex justify-between text-xs font-medium">
                              <dt className="text-xs text-gray-500">{t('shared.created_at')}</dt>
                              <dd className="text-xs text-gray-900">{moment(file?.information?.created_at).format('ll')}</dd>
                            </div>
                          </dl>
                          <dl className="border-b border-gray-200 divide-y divide-gray-200">
                            <div className="py-2 flex justify-between text-xs font-medium">
                              <dt className="text-xs text-gray-500">{t('shared.size')}</dt>
                              <dd className="text-xs text-gray-900">{file?.information?.size}</dd>
                            </div>
                          </dl>
                        </div>
                        <div className="flex">
                          {file?.content_type === 'file' && (
                            <button
                              type="button"
                              className="flex-1 bg-indigo-600 py-1 px-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              onClick={() => window.open(file?.fileUrl)}
                            >
                              {t('shared.download')}
                            </button>
                          )}
                          {editAccess() && (
                            <button
                              type="button"
                              onClick={() => deleteMe()}
                              className="flex-1 text-white bg-red-600 py-1 px-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                            >
                              {t('shared.delete')}
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => ({
  isClient: state.auth.client,
  isInstructor: state.auth.instructor,
  isStaff: state.auth.role === 'staff',
  hasMediaLibraryAccess: state.auth.mediaLibraryAccess,
  isAdmin: state.auth.admin
});

export default connect(mapStateToProps)(SidePanel);
