/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ColorPicker, useColor, toColor } from 'react-color-palette';
import { useTranslation } from 'react-i18next';
import {
  ContextualSaveBar, Page, Layout, Card, FormLayout, Form, Toast, TextField, TextStyle, Stack, Button, Thumbnail, DropZone, Banner
} from '@shopify/polaris';
import StatusBadge from '../Shared/StatusBadge';
import 'react-color-palette/lib/css/styles.css';
import Banners from '../Shared/Banners';

const CustomDomain = () => {
  const [primaryColor, setPrimaryColor] = useColor('hex', '#3b5998');
  const [secondaryColor, setSecondaryColor] = useColor('hex', '#111213');
  const [appLogo, setAppLogo] = useState(null);
  const [loginLogo, setLoginLogo] = useState(null);
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wasBlank, setWasBlank] = useState(true);
  const [banner, setBanner] = useState([]);
  const [showInfo, setShowInfo] = useState(true);
  const [customDomains, setCustomDomains] = useState({});
  const { t } = useTranslation();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const handleCustomDomainsChange = (field) => (data) => {
    setShowSaveBar(true);
    setCustomDomains({ ...customDomains, [field]: data });
  };

  const handlePrimaryColorChange = (e) => {
    setPrimaryColor(e);
    setShowSaveBar(true);
    setCustomDomains({ ...customDomains, primary: e.hex });
  };

  const handleSecondaryColorChange = (e) => {
    setSecondaryColor(e);
    setShowSaveBar(true);
    setCustomDomains({ ...customDomains, secondary: e.hex });
  };

  const updateSettings = () => {
    setLoading(true);

    const formData = new FormData();

    if (customDomains.subdomain) formData.append('custom_domain[subdomain]', customDomains.subdomain);
    if (customDomains.primary) formData.append('custom_domain[primary]', customDomains.primary);
    if (customDomains.secondary) formData.append('custom_domain[secondary]', customDomains.secondary);

    if (appLogo?.size > 0) {
      formData.append('custom_domain[app_logo]', appLogo);
    }

    if (!appLogo) {
      formData.append('remove_app_logo', true);
    }

    if (loginLogo?.size > 0) {
      formData.append('custom_domain[login_logo]', loginLogo);
    }

    if (!loginLogo) {
      formData.append('remove_login_logo', true);
    }

    if (wasBlank) {
      axios.post('/v1/custom_domains', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          window.location.reload();
          toggleToastActive(t('shared.saved'));
          setCustomDomains(response.data);
          setLoading(false);
          setShowSaveBar(false);
        })
        .catch((error) => {
          toggleToastActive(t('shared.something_went_wrong'));
          setBanner([{ status: 'critical', details: error.response.data.errors }]);
          setLoading(false);
        });
    } else {
      axios.patch(`/v1/custom_domains/${customDomains.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          window.location.reload();
          toggleToastActive(t('shared.saved'));
          setCustomDomains(response.data);
          setLoading(false);
          setShowSaveBar(false);
        })
        .catch((error) => {
          toggleToastActive(t('shared.something_went_wrong'));
          setBanner([{ status: 'critical', details: error.response.data.errors }]);
          setLoading(false);
        });
    }

    setShowSaveBar(false);
  };

  const deleteSettings = () => {
    if (!window.confirm(`${t('shared.are_you_sure')} Custom Domain`)) return;

    axios.delete(`/v1/custom_domains/${customDomains.id}`)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {});
  };

  const fetchSettings = useCallback(() => {
    axios.get('/v1/custom_domains')
      .then((response) => {
        setCustomDomains(response.data);
        setPrimaryColor(toColor('hex', response.data.primary));
        setSecondaryColor(toColor('hex', response.data.secondary));
        if (response.data.appLogoUrl) setAppLogo(new File([''], response.data.appLogoUrl));
        if (response.data.loginLogoUrl) setLoginLogo(new File([''], response.data.loginLogoUrl));
        if (response.data.id) {
          setWasBlank(false);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const handleAppLogoDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
    } else {
      setShowSaveBar(true);
      setCustomDomains({ ...customDomains, appLogoUrl: window.URL.createObjectURL(acceptedFiles[0]) });
      setAppLogo(acceptedFiles[0]);
    }
  }, [t, customDomains]);

  const handleLoginLogoDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
    } else {
      setShowSaveBar(true);
      setCustomDomains({ ...customDomains, loginLogoUrl: window.URL.createObjectURL(acceptedFiles[0]) });
      setLoginLogo(acceptedFiles[0]);
    }
  }, [t, customDomains]);

  const resetAppLogo = () => {
    setShowSaveBar(true);
    setAppLogo(null);
  };
  const resetLoginLogo = () => {
    setShowSaveBar(true);
    setLoginLogo(null);
  };

  const uploadedAppLogoFile = appLogo && (
    <Stack alignment="center">
      <Thumbnail size="large" name="invoice_logo" source={customDomains.appLogoUrl} />
      <Button plain onClick={() => resetAppLogo()}>{t('shared.change')}</Button>
    </Stack>
  );
  const uploadedLoginLogoFile = loginLogo && (
    <Stack alignment="center">
      <Thumbnail size="large" name="invoice_logo" source={customDomains.loginLogoUrl} />
      <Button plain onClick={() => resetLoginLogo()}>{t('shared.change')}</Button>
    </Stack>
  );

  const settingsForm = (
    <Form onSubmit={updateSettings}>
      <FormLayout>
        <Layout>
          <Layout.AnnotatedSection
            title={t('custom_domain.main')}
            description={t('custom_domain.desc')}
          >
            <Card sectioned>
              <Card.Section>
                <TextField
                  value={customDomains.subdomain}
                  onChange={handleCustomDomainsChange('subdomain')}
                  connectedRight={<TextStyle>.planubo.com</TextStyle>}
                />
              </Card.Section>
              <Card.Section>
                <Stack alignment="center" spacing="tight">
                  <StatusBadge active={customDomains.verified} />
                  <TextStyle>{t('shared.verified')}</TextStyle>
                  <br />
                  {!customDomains.verified ? (
                    <TextStyle variation="negative">{customDomains.message}</TextStyle>
                  ) : ''}
                </Stack>
                {customDomains.subdomain && !customDomains.verified && !showSaveBar && (
                  <div style={{ marginTop: '1rem' }}>
                    <Banner status="warning">
                      <p>{t('custom_domain.note')}</p>
                    </Banner>
                  </div>
                )}
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('custom_domain.app_logo')}
            description={t('custom_domain.app_logo_desc')}
          >
            {!appLogo ? (
              <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleAppLogoDropZoneDrop}>
                <DropZone.FileUpload />
              </DropZone>
            ) : uploadedAppLogoFile}
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('custom_domain.login_logo')}
            description={t('custom_domain.login_logo_desc')}
          >
            {!loginLogo ? (
              <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleLoginLogoDropZoneDrop}>
                <DropZone.FileUpload />
              </DropZone>
            ) : uploadedLoginLogoFile}
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('custom_domain.colors')}
            description={t('custom_domain.color_desc')}
          >
            <Card sectioned>
              <Card.Section title={t('custom_domain.primary')} style={{ backgroundColor: 'black' }}>
                <Stack>
                  <ColorPicker width={228} height={114} color={primaryColor} onChange={(e) => handlePrimaryColorChange(e)} hideHSV hideRGB hideHSB />
                  <div style={{ width: '80px', height: '114px', backgroundColor: primaryColor.hex, borderRadius: '9px' }} />
                </Stack>
              </Card.Section>
              <Card.Section title={t('custom_domain.secondary')}>
                <Stack>
                  <ColorPicker width={228} height={114} color={secondaryColor} onChange={(e) => handleSecondaryColorChange(e)} hideHSV hideRGB hideHSB />
                  <div style={{ width: '80px', height: '114px', backgroundColor: secondaryColor.hex, borderRadius: '9px' }} />
                </Stack>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </FormLayout>
    </Form>
  );

  return (
    <Page
      title={t('custom_domain.custom_settings')}
      separator
    >
      {showSaveBar && (
        <ContextualSaveBar
          message={t('shared.unsaved_changes')}
          saveAction={{
            onAction: () => updateSettings(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      {showInfo && (
        <Banners
          onDismissBanner={() => setShowInfo(false)}
          banners={
            [
              {
                title: t('custom_domain.information'),
                status: 'info',
                details:
                  [
                    t('custom_domain.info_1'),
                    t('custom_domain.info_2')
                  ]
              }
            ]
          }
        />
      )}
      <Banners banners={banner} onDismissBanner={() => setBanner([])} />
      {toastMarkup}
      {settingsForm}
      <Stack>
        <Stack.Item fill />
        {customDomains.subdomain && !wasBlank ? (
          <div style={{ marginTop: '2rem' }}>
            <Button destructive onClick={() => deleteSettings()}>{t('shared.delete')}</Button>
          </div>
        ) : null}
      </Stack>
    </Page>
  );
};

export default CustomDomain;
