import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlusMinor } from '@shopify/polaris-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Filters, Card, Page, Toast, SkeletonPage, Button, IndexTable, Avatar, useIndexResourceState, Banner } from '@shopify/polaris';
import Banners from '../Shared/Banners';
import NewAccountModal from './NewAccountantModal';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import StatusBadge from '../Shared/StatusBadge';

const Accountants = () => {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [staffaccounts, setStaffAccounts] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [showNewStaffAccountModal, setShowNewStaffAccountModal] = useState(false);
  const [mounted, setMounted] = useState(true);

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const fetchAccountants = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: '',
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/accountants/search', params)
      .then((res) => {
        if (mounted) {
          setStaffAccounts(res.data.accountants);
          setLastPage(res.data.lastPage);
          setLoading(false);
        }
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const filters = [];

  const placeHolderText = t('staff_accounts.filter');

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={[]}
      queryPlaceholder={placeHolderText}
    />
  );

  const handleNew = (client) => {
    axios.post('/v1/accountants', client)
      .then(() => {
        setLoading(false);
        setBanner([{ title: 'Accountant added', status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
        fetchAccountants();
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
      });
  };

  useEffect(() => {
    setMounted(true);
    fetchAccountants();
    return () => {
      setMounted(false);
    };
  }, [fetchAccountants]);

  const dismissBanner = () => {
    setBanner([]);
  };

  let newAccountForm = null;

  if (showNewStaffAccountModal) {
    newAccountForm = (
      <NewAccountModal
        title={t('accountant.new')}
        handleSubmit={handleNew}
        close={() => setShowNewStaffAccountModal(false)}
      />
    );
  }

  const headings = [
    { title: '' },
    { title: t('user.name') },
    { title: t('user.email') },
    { title: t('user.active') }
  ];

  const resourceName = {
    singular: t('accountant.singular'),
    plural: t('accountant.plural')
  };

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(staffaccounts);

  const rowMarkup = staffaccounts.map(({ id, name, avatar, email, status }, index) => (
    <IndexTable.Row
      id={id}
      key={id}
      selected={selectedResources?.includes(id)}
      position={index}
    >
      <IndexTable.Cell>
        <Avatar size="small" name="account_logo" source={avatar} />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Button plain url={`/accountants/${id}`}>{name}</Button>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {email}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <StatusBadge active={status} />
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  // eslint-disable-next-line no-unused-vars
  const content = (
    <IndexTable
      resourceName={resourceName}
      headings={headings}
      rows={staffaccounts}
      itemCount={staffaccounts.length}
      selectedItemsCount={
        allResourcesSelected ? 'All' : selectedResources.length
      }
      onSelectionChange={handleSelectionChange}
    >
      {rowMarkup}
    </IndexTable>
  );

  return (
    <Page
      title={t('accountant.plural')}
      primaryAction={{ icon: PlusMinor, content: t('accountant.new'), onAction: () => setShowNewStaffAccountModal(true) }}
      separator
      fullWidth
    >
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      {newAccountForm}
      <div className="mb-4">
        <Banner status="info">
          <p>{t('accountant.desc')}</p>
        </Banner>
      </div>
      {toastMarkup}
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            {content}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={staffaccounts}
        lastPage={lastPage}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin,
  isInstructor: state.auth.instructor
});

export default connect(mapStateToProps)(Accountants);
