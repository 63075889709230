import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Templates from './Templates';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EmailTemplates = () => {
  const { t } = useTranslation();

  const history = useNavigate();

  return (
    <div>
      <h2 className="text-sm font-medium text-gray-900 flex">
        <div className="flex flex-1">{t('email_template.modify')}</div>
      </h2>
      <p className="mt-1 text-xs text-gray-500">{t('email_template.get_started_desc')}</p>
      <ul className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">
        {Templates(t).map((item, itemIdx) => (
          <div role="link" tabIndex={0} onKeyPress={() => {}} key={itemIdx} className="cursor-pointer hover:bg-gray-100" onClick={() => history(`/email_settings/email_template/${encodeURIComponent(item.path)}`)}>
            <div className="relative group py-2 flex items-start space-x-2">
              <div className="flex-shrink-0">
                <span
                  className={classNames(item.iconColor, 'ml-2 mr-4 inline-flex items-center justify-center h-10 w-10 rounded-lg')}
                >
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="min-w-0 flex-1">
                <div className="text-sm font-medium text-gray-900">
                  <a href={item.href}>
                    <span className="absolute inset-0" aria-hidden="true" />
                    {item.name}
                  </a>
                </div>
                <p className="text-xs text-gray-500">{item.description}</p>
              </div>
              <div className="flex-shrink-0 self-center">
                <ChevronRightIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              </div>
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default EmailTemplates;
