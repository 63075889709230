/* eslint-disable */

/**
  * <FormValidator />
  */

import React from 'react';
import xss from 'xss';
import { withTranslation } from 'react-i18next';

const myxss = new xss.FilterXSS({
  whiteList: {
    u: [],
    br: [],
    b: [],
    i: [],
    ol: ['style'],
    ul: ['style'],
    li: [],
    p: ['style'],
    sub: [],
    sup: [],
    div: ['style'],
    em: [],
    strong: [],
    span: ['style'],
  },
});

class FormValidator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  componentDidMount() {
    this.subscription = this.props.emitter.addListener('formValidation', (errors) => {
      this.setState({ errors });
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  dismissModal(e) {
    e.preventDefault();
    this.setState({ errors: [] });
  }

  render() {
    const errors = this.state.errors.map((error, index) => <li key={`error_${index}`} dangerouslySetInnerHTML={{ __html: myxss.process(error) }} />);

    return (
      <div>
        { this.state.errors.length > 0 &&
          <div className="flex relative px-3 pt-4 pb-4 mb-4 border rounded bg-red-200 border-red-300 text-red-800 validation-error">
            <div className="flex-1 clearfix">
              <i className="fas fa-exclamation-triangle float-left pt-1 mr-2"></i>
              <ul className="float-left">
                {errors}
              </ul>
            </div>
            <div className="clearfix">
              <a className="float-right inline-block align-middle text-center select-none border font-normal whitespace-no-wrap rounded py-1 px-3 leading-normal no-underline btn-default py-1 px-2 leading-tight text-xs  bg-red-600 text-white hover:bg-red-700" onClick={this.dismissModal.bind(this)}>{this.props.t('shared.ok')}</a>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default withTranslation()(FormValidator);
