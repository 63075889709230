/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Toast } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';

const FormNotificationModal = (props) => {
  const [surveys, setSurveys] = useState([]);

  const history = useNavigate();
  const { t } = useTranslation();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const fetchSubs = useCallback(() => {
    axios.get('/v1/surveys/open_surveys')
      .then((res) => {
        setSurveys(res.data.surveys);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    fetchSubs();
  }, [fetchSubs]);

  const buttonRef = useRef(null);

  const openSurvey = (token) => {
    history(`/survey_reply/${token}`);
    props.onCancel();
  };

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" initialFocus={buttonRef} onClose={() => {}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          {toastMarkup}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <ExclamationCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {t('shared.an_action_is_necessary')}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {t('survey.fill_it_out')}
                    </p>
                  </div>
                  <div>
                    {surveys.map((survey, i) => (
                      <div key={i} className="my-2">
                        <button type="button" onClick={() => openSurvey(survey.token)} className="w-full text-center text-sm border rounded-lg border-gray-300 hover:bg-green-200 p-2">
                          <p className="font-medium text-gray-900p">
                            {survey.title}
                          </p>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-red-300 text-base font-medium text-gray-700 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                  onClick={() => props.onCancel()}
                >
                  {t('shared.cancel')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FormNotificationModal;
