/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Button, Form, FormLayout, TextField, TextStyle, Checkbox, Spinner } from '@shopify/polaris';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import classes from './Auth.module.scss';
import logo from '../../assets/images/planubo-blue.png';
import * as actions from '../../store/actions/index';
import Banners from '../../components/Shared/Banners';
import languages from '../../components/Shared/Constants/Languages';

const Signup = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [marketing, setMarketing] = useState(false);
  const [lastName, setLastName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [language, setLanguage] = useState({ value: 'en', label: 'EN' });
  const [signupFields, setSignupFields] = useState({});

  const { t } = useTranslation('translation');
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const signupType = queryParams.get('signupType');
  const signupPortal = queryParams.get('portal');
  const signupRole = queryParams.get('signupRole');
  const next = queryParams.get('next');

  const fetchSignupFields = useCallback(() => {
    if (next === 'my_portal') {
      localStorage.setItem('next', '/my_portal')
    }

    axios.get(`/v1/public_profiles/${signupPortal}/signup_fields`)
      .then((response) => {
        if (signupPortal) {
          setSignupFields(response.data.signup_fields);
        } else if (response.data.instructor_signup_fields.length) {
          setSignupFields(response.data.instructor_signup_fields);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    fetchSignupFields();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formValid()) return;

    let pbCustomFields = [];

    if (signupFields.length) {
      const temp = [...signupFields];
      temp.forEach((cf) => {
        cf.id = undefined;
        cf.custom_fieldable_id = undefined;
        cf.custom_fieldable_type = undefined;
        if (cf.value) {
          // eslint-disable-next-line
          cf.value = cf.value.toString();
        }
      });

      pbCustomFields = signupFields;
    }

    localStorage.setItem('signupRole', signupRole);

    const authData = {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        password_confirmation: confirmPassword,
        businesstype: businessType,
        marketing,
        accepted_terms_of_use: acceptedPrivacyPolicy,
        lang: language?.value || 'en',
        signup_fields_attributes: pbCustomFields
      },
      signupType,
      portal: signupPortal
    };

    axios.post('/v1/users', authData)
      .then((response) => {
        const data = {
          accountState: response.data.accountState,
          token: response.data.token,
          initials: response.data.initials,
          name: response.data.name,
          email: response.data.email,
          instructor: response.data.instructor,
          client: response.data.client,
          isNew: response.data.isNew,
          admin: response.data.admin,
          role: response.data.role,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          urlName: response.data.urlName,
          forms: response.data.forms,
          assigned_workflows: response.data.assigned_workflows,
          avatar: response.data.avatar,
          subdomain: response.data.subdomain,
          timezone: response.data.timezone,
          getting_started: response.data.getting_started,
          lang: response.data.lang,
          active: response.data.active,
          hasStripe: response.data.hasStripe,
          unread: response.data.unread,
          canDoOnlineEvent: response.data.canDoOnlineEvent,
          unpaidInvoices: response.data.unpaidInvoices,
          plan: response.data.plan,
          hide_invoice_option: response.data.hide_invoice_option,
          planType: response.data.planType,
          hasPaymentMethod: response.data.hasPaymentMethod,
          freeMember: response.data.freeMember,
          subscriptions: response.data.subscriptions,
          basicMember: response.data.basicMember,
          premiumMember: response.data.premiumMember,
          premiumPlusMember: response.data.premiumPlusMember,
          hasInvoiceAccess: response.data.hasInvoiceAccess,
          bookingPageAccess: response.data.bookingPageAccess,
          mediaLibraryAccess: response.data.mediaLibraryAccess,
          deployVersion: response.data.deployVersion
        };

        try {
          localStorage.setItem('wasLoggedIn', 'true');
        } catch (e) {
          console.log(e);
        }

        props.signupSuccess(data);
      })
      .catch((err) => {
        setErrors([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
      });
  };

  const handleFirstNameChange = useCallback((value) => setFirstName(value), []);
  const handleLastNameChange = useCallback((value) => setLastName(value), []);
  const handleConfirmPasswordChange = useCallback((value) => setConfirmPassword(value), []);
  const handleBusinessTypeChange = useCallback((value) => setBusinessType(value), []);
  const handleEmailChange = useCallback((value) => setEmail(value), []);
  const handlePasswordChange = useCallback((value) => setPassword(value), []);

  const formValid = () => {
    const e = {};
    if (!firstName) {
      e.firstName = t('user.validations.first_name');
    }
    if (!lastName) {
      e.lastName = t('user.validations.last_name');
    }
    if (!password) {
      e.password = t('user.validations.password');
    }
    if (!confirmPassword) {
      e.confirmPassword = t('user.validations.password_confirmation');
    }
    if (!email) {
      e.email = t('user.validations.email');
    }
    if (!acceptedPrivacyPolicy && !signupFields.length) {
      e.acceptedPrivacyPolicy = t('user.validations.terms_of_use');
    }
    // if (!businessType) {
    //   e.businessType = t('shared.validations.required');
    // }

    if (signupFields.length) {
      signupFields.forEach((cf) => {
        if (cf.required && !cf.value && cf.language === language.value) {
          e[cf.label] = t('shared.validations.required');
        }
      });
    }

    setFormErrors(e);
    return Object.entries(e).length === 0;
  };

  const onLanguageChange = (lang) => {
    props.setLang(lang.value);
    setLanguage(lang);
  };

  const firstNameField = (
    <TextField
      value={firstName}
      error={formErrors.firstName}
      onChange={handleFirstNameChange}
      placeholder={t('user.first_name')}
      type="text"
    />
  );

  const lastNameField = (
    <TextField
      value={lastName}
      error={formErrors.lastName}
      onChange={handleLastNameChange}
      placeholder={t('user.last_name')}
      type="text"
    />
  );

  const emailField = (
    <TextField
      value={email}
      error={formErrors.email}
      onChange={handleEmailChange}
      placeholder={t('user.email')}
      type="email"
    />
  );

  const passwordfield = (
    <TextField
      value={password}
      error={formErrors.password}
      onChange={handlePasswordChange}
      placeholder={t('user.password')}
      type="password"
    />
  );

  const confirmPasswordField = (
    <TextField
      value={confirmPassword}
      error={formErrors.confirmPassword}
      onChange={handleConfirmPasswordChange}
      placeholder={t('user.confirm_password')}
      type="password"
    />
  );

  const businessTypeField = (
    <TextField
      value={businessType}
      onChange={handleBusinessTypeChange}
      placeholder={t('user.organization_name')}
      type="text"
      error={formErrors.businessType}
    />
  );

  const handleCustomFieldChange = (idx) => (value) => {
    const temp = [...signupFields];
    temp[idx].value = value;
    temp[idx].touched = true;
    setSignupFields(temp);
  };

  const displayCustomFields = () => signupFields.map((customField, idx) => {
    if (customField.language !== language.value) return '';

    switch (customField.category) {
      case 'text': return (
        <TextField
          key={idx}
          requiredIndicator={customField.required}
          label={customField.label}
          value={customField.value}
          error={formErrors[customField.label]}
          onChange={handleCustomFieldChange(idx)}
          type="text"
        />
      );
      case 'checkbox': return (
        <Checkbox
          requiredIndicator={customField.required}
          checked={customField.value}
          label={(<div dangerouslySetInnerHTML={{ __html: customField.label }} />)}
          onChange={handleCustomFieldChange(idx)}
          error={formErrors[customField.label]}
        />
      );
      default: return (
        <TextField
          key={idx}
          requiredIndicator={customField.required}
          placeholder={customField.label}
          value={customField.value || ''}
          error={formErrors[customField.label]}
          onChange={handleCustomFieldChange(idx)}
          type="text"
        />
      );
    }
  });

  return (
    <div className={classes.Auth}>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          {!props.loadingLogo ? <img src={props.brand?.loginLogoUrl || logo} alt="planubo" /> : (
            <Spinner />
          )}
          {errors.length ? <Banners banners={errors} onDismissBanner={() => setErrors([])} /> : null}
          {firstNameField}
          {lastNameField}
          {emailField}
          {passwordfield}
          {confirmPasswordField}
          {businessTypeField}
          {signupFields.length ? (
            displayCustomFields()
          ) : (
            <div className="text-left">
              <Checkbox
                checked={acceptedPrivacyPolicy}
                onChange={setAcceptedPrivacyPolicy}
                error={formErrors.acceptedPrivacyPolicy}
                label={t('user.privacy_policy_agreement')}
              />
              <Checkbox
                checked={marketing}
                onChange={setMarketing}
                label={t('user.newsletter')}
              />
            </div>
          )}
          <div className="react-select">
            <Select
              value={language}
              onChange={(lang) => onLanguageChange(lang)}
              options={languages}
            />
          </div>
          <Button fullWidth primary submit>{t('user.register')}</Button>
          <Button fullWidth secondary url="/">{t('user.sign_in')}</Button>
          <Button plain url="/forgot-password">{t('user.forgot_password')}</Button>
          <TextStyle variation="subdued">{`© ${new Date().getFullYear()} Planubo. All Rights Reserved.`}</TextStyle>
          {!signupFields.length && (
            <div>
              <a href="https://planubo.com/privacy-policy/">{t('user.privacy_policy')}</a>
              <a href="https://planubo.com/terms-of-service/">{t('user.terms_of_use')}</a>
            </div>
          )}
        </FormLayout>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  brand: state.auth.brand,
  loading: state.auth.loading,
  loadingLogo: state.auth.loadingLogo,
  error: state.auth.error,
  isAuthenticated: state.auth.token !== null
});

const mapDispatchToProps = (dispatch) => ({
  setLang: (lang) => dispatch(actions.setLang(lang)),
  signupSuccess: (data) => dispatch(actions.authSuccess(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
