/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Page, Layout, Card, Stack, TextStyle, FormLayout,
  Button, Form, Toast, Badge, Tooltip, Icon, Select
} from '@shopify/polaris';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { InfoMinor } from '@shopify/polaris-icons';
import ExternalLink from '../Shared/ExternalLink';
import { authCheckState } from '../../store/actions';
import Google from './Google';

const Integrations = (props) => {
  const [init, setInit] = useState(false);
  const [googleAuth, setGoogleAuth] = useState(false);
  const [hasZoom, setHasZoom] = useState(false);
  const [zoomSettings, setZoomSettings] = useState({});
  const [videoPreference, setVideoPreference] = useState('');
  const [redirectUri, setRedirectUri] = useState('');
  const [loadingStripeConnection, setLoadingStripeConnection] = useState(false);
  const [stripeStatus, setStripeStatus] = useState(null);
  const [stripeLinkLoading, setStripeLinkLoading] = useState(false);
  // const [googleSyncWay, setGoogleSyncWay] = useState('oneWay');
  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const canAccessPayments = () => {
    if (props.isBasicMember || props.isPremiumMember || props.isPremiumPlusMember) return true;

    return false;
  };

  const videoPreferenceOptions = [
    { value: '', label: '' },
    { value: 'Google', label: 'Google' }
  ];

  if (hasZoom) {
    videoPreferenceOptions.push({ value: 'Zoom', label: 'Zoom' });
  }

  const fetchSettings = useCallback(() => {
    axios.get('/v1/settings')
      .then((response) => {
        setInit(true);
        setVideoPreference(response.data.videoPreference);
        setZoomSettings(response.data.zoomSettings);
        setStripeStatus(response.data.stripeStatus);
        setGoogleAuth(response.data.googleAuthenticated);
        setHasZoom(response.data.hasZoom);
        setRedirectUri(response.data.redirectUri);
      })
      .catch(() => {});
  }, []);

  const verifyStripe = () => {
    axios.post('/v1/stripe_payments/verify_connect')
      .then(() => {
        setStripeStatus('verified');
      })
      .catch((error) => {
        if (error.response.data.stripe === 'pending') {
          setStripeStatus('pending');
        }
      });
  };

  const forwardStripe = () => {
    setStripeLinkLoading(true);

    axios.get('/v1/settings/stripe_account')
      .then((res) => {
        window.location.replace(res.data.url);
      })
      .catch(() => {})
      .then(() => setStripeLinkLoading(false));
  };

  const viewDashboard = () => {
    const isDev = process.env.NODE_ENV === 'development';

    const url = isDev ? 'https://dashboard.stripe.com/test/' : 'https://dashboard.stripe.com/';

    window.location.replace(url);
  };

  const verifyZoom = (zoomCode) => {
    axios.get(`/v1/zoom?code=${zoomCode}`)
      .then(() => {
        history('/integrations');
        setHasZoom(true);
        toggleToastActive('Successfully connected zoom');
        props.checkAuthState();
      })
      .catch((error) => {
        console.log(error);
        setHasZoom(false);
        toggleToastActive('Could not set up zoom connection, please try again or contact the planubo team');
      });
  };

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const error = query.get('error');
    const verify = query.get('verify');
    const zoomCode = query.get('code');

    if (zoomCode) verifyZoom(zoomCode);

    if (verify) verifyStripe();

    if (error) {
      toggleToastActive(t('shared.something_went_wrong'));
      setStripeStatus(null);
    }
    // eslint-disable-next-line
  }, []);

  const handleConnectStripe = () => {
    setLoadingStripeConnection(true);
    axios.post('/v1/stripe_payments/connect')
      .then((response) => {
        window.location.assign(response.data.stripe.url);
      })
      .catch(() => {
      });
  };

  const handleDisconnectStripe = () => {
    axios.post('/v1/stripe_payments/disconnect')
      .then(() => {
        setStripeStatus(null);
      })
      .catch(() => {
      });
  };

  const handleVideoPreferenceChange = (value) => {
    setVideoPreference(value);

    axios.post('/v1/users/video_preference', { video_preference: value })
      .then(() => {
        toggleToastActive(t('shared.success'));
      })
      .catch(() => {
        toggleToastActive('Could not update preference');
      });
  };

  const handleDisconnectZoom = () => {
    axios.delete('/v1/zoom')
      .then(() => {
        setHasZoom(false);
      })
      .catch(() => {
        toggleToastActive('Could not disconnect zoom');
      });
  };

  const handleDisconnectGoogle = () => {
    axios.post('/v1/settings/disconnect_google')
      .then(() => {
        setGoogleAuth(false);
      })
      .catch(() => { });
  };

  const googleSuccess = (res) => {
    axios.post('/v1/settings/google_auth', { code: res.code })
      .then(() => {
        setGoogleAuth(true);
      })
      .catch(() => {
      });
  };

  // eslint-disable-next-line no-unused-vars
  const googleFailure = (res) => {
  };

  const settingsForm = (
    <Form>
      <FormLayout>
        <Layout>
          <Layout.AnnotatedSection
            title={t('settings.google_calendar')}
            description={(
              <Stack spacing="tight">
                <TextStyle>{t('settings.google_calendar_info')}</TextStyle>
              </Stack>
            )}
          >
            <Card sectioned>
              {!googleAuth ? (
                <Card.Section>
                  <Stack alignment="center">
                    <GoogleOAuthProvider clientId="147901521422-un4me1j1rs8paufkbtch2vtfsgkcqfuj.apps.googleusercontent.com">
                      <Google googleSuccess={googleSuccess} />
                      {/* <GoogleLogin
                        flow="auth-code"
                        buttonText="Connect"
                        onSuccess={googleSuccess}
                        onError={(error) => {
                          console.log(error);
                        }} */}
                      {/* // onFailure={googleFailure}
                      // accessType="offline"
                      // responseType="code"
                      // approvalPrompt="force"
                      // redirectUri={`profile email ${decodeURIComponent(redirectUri)}`}
                      // prompt="consent"
                      // cookiePolicy="single_host_origin"
                      // isSignedIn={false} */}
                      {/* /> */}
                    </GoogleOAuthProvider>
                  </Stack>
                </Card.Section>
              ) : (
                <>
                  <Card.Section>
                    <Stack alignment="center">
                      <Button destructive plain onClick={() => handleDisconnectGoogle()}>{t('payments.disconnect')}</Button>
                    </Stack>
                  </Card.Section>
                  <Card.Section>
                    <Button plain onClick={() => history('/integrations/google')}>{t('product.advanced_settings')}</Button>
                    {/* <div className="mb-2 flex">
                      <div className="font-medium mr-2">Type of sync</div>
                      <Tooltip content="'One Way' sync only syncs events from Planubo to Google. Whereas 'Two Way' sync also fetches events created in Google and creates them in your Planubo calendar">
                        <Icon color="highlight" source={InfoMinor} />
                      </Tooltip>
                    </div>
                    <ButtonGroup segmented>
                      <Button pressed={googleSyncWay === 'oneWay'} onClick={() => setGoogleSyncWay('oneWay')}>One Way</Button>
                      <Button pressed={googleSyncWay === 'twoWay'} onClick={() => setGoogleSyncWay('twoWay')}>Two Way</Button>
                    </ButtonGroup>
                    <div className="mt-4 font-medium">Select the Google Calendar to sync</div> */}
                  </Card.Section>
                </>
              )}
              {googleAuth && (
                <Card.Section>
                  <Stack>
                    <TextStyle>
                      {t('settings.google_calendar_export')}
                      (
                      <ExternalLink label="Google" url="https://support.google.com/calendar/answer/37118?co=GENIE.Platform%3DDesktop" />
                      )
                    </TextStyle>
                  </Stack>
                </Card.Section>
              )}
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={(
              <div className="flex">
                <div className="mr-4">
                  Zoom
                </div>
              </div>
            )}
            description={t('settings.zoom_description')}
          >
            <Card sectioned>
              <Card.Section>
                <Stack alignment="center">
                  {!hasZoom ? (
                    <a href={`https://zoom.us/oauth/authorize?client_id=${zoomSettings.clientId}&state=${props.token}&response_type=code&redirect_uri=${zoomSettings.redirectUri}`} target="_blank" rel="noopener noreferrer"><img src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png" className="h-8" alt="Add to ZOOM" /></a>
                  ) : (
                    <Button destructive plain onClick={() => handleDisconnectZoom()}>{t('payments.disconnect')}</Button>
                  )}
                </Stack>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          {hasZoom || googleAuth ? (
            <Layout.AnnotatedSection
              title={t('zoom.preference')}
              description={t('zoom.description')}
            >
              <Card sectioned>
                <Card.Section>
                  <Select
                    value={videoPreference}
                    helpText={t('settings.select_preference')}
                    onChange={handleVideoPreferenceChange}
                    options={videoPreferenceOptions}
                  />
                </Card.Section>
              </Card>
            </Layout.AnnotatedSection>
          ) : null}
          {props.hasInvoiceAccess && !props.isStaff && (
            <Layout.AnnotatedSection
              title={(
                <Stack alignment="center">
                  <Stack.Item>Stripe</Stack.Item>
                  <Tooltip content={t('help.stripe_platform_fee')}>
                    <Icon color="highlight" source={InfoMinor} />
                  </Tooltip>
                </Stack>
              )}
              description={t('settings.stripe')}
            >
              {canAccessPayments() ? (
                <Card sectioned>
                  <Card.Section>
                    {stripeStatus === 'verified' && (
                      <>
                        <Stack>
                          <Stack.Item fill>
                            <Badge status="success">{t('payments.connected')}</Badge>
                          </Stack.Item>
                          <Button destructive plain onClick={() => handleDisconnectStripe()}>{t('payments.disconnect')}</Button>
                        </Stack>
                        <br />
                        <Stack>
                          <Button plain loading={stripeLinkLoading} onClick={() => forwardStripe()}>{t('payments.modify_stripe_connection')}</Button>
                        </Stack>
                        <Stack>
                          <Button plain onClick={() => viewDashboard()}>{t('payments.view_dashboard')}</Button>
                        </Stack>
                      </>
                    )}
                    {stripeStatus === 'pending' && (
                      <Stack>
                        <Stack.Item fill>
                          <Badge status="success">{t('payments.pending')}</Badge>
                        </Stack.Item>
                        <TextStyle>{t('payments.additional_docs')}</TextStyle>
                        <Button loading={loadingStripeConnection} onClick={() => handleConnectStripe()}>{t('payments.retry')}</Button>
                      </Stack>
                    )}
                    {(!stripeStatus || stripeStatus === 'verifying') && (
                      <Button loading={loadingStripeConnection} onClick={() => handleConnectStripe()}>{t('payments.connect_stripe')}</Button>
                    )}
                  </Card.Section>
                </Card>
              ) : (
                <Card sectioned>
                  <Card.Section>
                    <Stack alignment="center">
                      <Stack.Item fill>{t('plan.upgrade_plan_to_access_payments')}</Stack.Item>
                      <Button onClick={() => history('/plans')}>{t('shared.upgrade')}</Button>
                    </Stack>
                  </Card.Section>
                </Card>
              )}
            </Layout.AnnotatedSection>
          )}
        </Layout>
      </FormLayout>
    </Form>
  );

  return (
    <Page
      title={t('navigation.integrations')}
      separator
    >
      {toastMarkup}
      {init && settingsForm}
    </Page>
  );
};

const mapPropsToDispatch = (dispatch) => ({ checkAuthState: () => dispatch(authCheckState()) });

const mapStateToProps = (state) => ({
  token: state.auth.token,
  lang: state.auth.lang,
  isStaff: state.auth.role === 'staff',
  isFreeMember: state.auth.freeMember,
  isBasicMember: state.auth.basicMember,
  isPremiumMember: state.auth.premiumMember,
  isPremiumPlusMember: state.auth.premiumPlusMember,
  hasInvoiceAccess: state.auth.hasInvoiceAccess
});

export default connect(mapStateToProps, mapPropsToDispatch)(Integrations);
