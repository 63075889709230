import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import loadCrisp from '../../services/loadCrisp';

const lang = 'en';

try {
  localStorage.getItem('lang');
} catch (e) {
  console.log(e);
}

const initialState = {
  accountState: '',
  token: null,
  initials: null,
  name: null,
  deployVersion: '',
  newVersionAvailable: false,
  email: null,
  error: null,
  loading: true,
  instructor: false,
  hasPaymentMethod: false,
  client: false,
  canDoOnlineEvent: false,
  admin: false,
  role: null,
  avatar: '',
  assigned_workflows: 0,
  timezone: '',
  firstName: '',
  subdomain: '',
  unlockscreen: false,
  lastName: '',
  urlName: '',
  forms: 0,
  active: false,
  loadingLogo: true,
  isNew: false,
  lang,
  unread: null,
  unpaidInvoices: '',
  plan: '',
  planType: '',
  subscriptions: [],
  getting_started: false,
  freeMember: false,
  basicMember: false,
  hasStripe: false,
  bookingPageAccess: false,
  mediaLibraryAccess: false,
  premiumMember: false,
  hasInvoiceAccess: false,
  hide_invoice_option: false,
  premiumPlusMember: false,
  currency: 'USD'
};

const authStart = (state) => updateObject(state, { error: null, loading: true });

const authSucces = (state, action) => {
  try {
    localStorage.setItem('lang', action.authData.lang);
  } catch (e) {
    console.log(e);
  }

  if (!action.authData.unlockscreen && (action.authData.role === 'instructor' || action.authData.role === 'staff')) {
    loadCrisp(() => {}, action.authData);
  }

  let newVersionAvailable = false;

  if (state.deployVersion && state.deployVersion !== action.authData.deployVersion) {
    newVersionAvailable = true;
  }

  return updateObject(state, {
    accountState: action.authData.accountState,
    token: action.authData.token,
    deployVersion: action.authData.deployVersion,
    initials: action.authData.initials,
    email: action.authData.email,
    firstName: action.authData.firstName,
    lastName: action.authData.lastName,
    name: action.authData.name,
    hasPaymentMethod: action.authData.hasPaymentMethod,
    instructor: action.authData.instructor,
    client: action.authData.client,
    admin: action.authData.admin,
    hide_invoice_option: action.authData.hide_invoice_option,
    forms: action.authData.forms,
    role: action.authData.role,
    getting_started: action.authData.getting_started,
    canDoOnlineEvent: action.authData.canDoOnlineEvent,
    lang: action.authData.lang,
    urlName: action.authData.urlName,
    error: null,
    avatar: action.authData.avatar,
    loading: false,
    newVersionAvailable,
    hasStripe: action.authData.hasStripe,
    subdomain: action.authData.subdomain,
    unread: action.authData.unread,
    unpaidInvoices: action.authData.unpaidInvoices,
    plan: action.authData.plan,
    timezone: action.authData.timezone,
    bookingPageAccess: action.authData.bookingPageAccess,
    mediaLibraryAccess: action.authData.mediaLibraryAccess,
    planType: action.authData.planType,
    freeMember: action.authData.freeMember,
    subscriptions: action.authData.subscriptions,
    basicMember: action.authData.basicMember,
    premiumMember: action.authData.premiumMember,
    assigned_workflows: action.authData.assigned_workflows,
    premiumPlusMember: action.authData.premiumPlusMember,
    hasInvoiceAccess: action.authData.hasInvoiceAccess,
    active: action.authData.active,
    isNew: action.authData.isNew,
    unlockscreen: action.authData.unlockscreen || false,
    currency: action.authData.currency
  });
};

const authLogout = (state) => updateObject(state, {
  accountState: '',
  token: null,
  initials: null,
  name: null,
  email: null,
  error: null,
  loading: false,
  deployVersion: '',
  instructor: false,
  client: false,
  admin: false,
  avatar: null,
  role: null,
  canDoOnlineEvent: false,
  unread: null,
  subdomain: '',
  unpaidInvoices: null,
  unlockscreen: false,
  forms: 0,
  assigned_workflows: 0,
  plan: '',
  active: false,
  firstName: '',
  isNew: false,
  hasPaymentMethod: false,
  lastName: '',
  hide_invoice_option: false,
  urlName: '',
  timezone: '',
  planType: '',
  subscriptions: [],
  hasInvoiceAccess: false,
  hasStripe: false,
  freeMember: false,
  basicMember: false,
  premiumMember: false,
  bookingPageAccess: false,
  mediaLibraryAccess: false,
  premiumPlusMember: false,
  getting_started: false,
  currency: 'USD'
});

const authCheckDone = (state) => updateObject(state, { loading: false });

const loadBrand = (state) => updateObject(state, { loading: true });

const setLang = (state, action) => updateObject(state, { lang: action.lang });

const setBrand = (state, action) => updateObject(state, { brand: action.brand, loadingLogo: false });

const setUnlockScreen = (state, action) => updateObject(state, { unlockscreen: action.unlockscreen });

const updateUrlName = (state, action) => updateObject(state, { urlName: action.urlName });

const authSessionExpired = (state) => updateObject(state, { error: 'Session Expired.' });

const changeUnread = (state, action) => updateObject(state, { unread: action.unread });

const authFail = (state, action) => updateObject(state, { error: action.error, loading: false });

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSucces(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.AUTH_CHECK_DONE: return authCheckDone(state, action);
    case actionTypes.AUTH_SESSION_EXPIRED: return authSessionExpired(state, action);
    case actionTypes.CHANGE_UNREAD: return changeUnread(state, action);
    case actionTypes.SET_BRAND: return setBrand(state, action);
    case actionTypes.SET_UNLOCKSCREEN: return setUnlockScreen(state, action);
    case actionTypes.LOAD_BRAND: return loadBrand(state);
    case actionTypes.UPDATE_URL_NAME: return updateUrlName(state, action);
    case actionTypes.SET_LANG: return setLang(state, action);
    default:
      return state;
  }
};

export default reducer;
