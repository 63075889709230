/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';

const ProgramSpaceEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  label,
  data
}) => {
  const [options, setOptions] = useState([{}]);
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  const fetchProgramSpaces = () => {
    axios.get('/v1/public_profiles/workflow_public_profiles')
      .then((res) => {
        setOptions(res.data);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    fetchProgramSpaces();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} type="smoothstep" />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: '#3d9df5',
            padding: 5,
            borderRadius: 5,
            fontSize: 12,
            fontWeight: 700
          }}
          className="nodrag nopan"
        >
          {options.length ? (
            options.find((option) => option?.value === label)?.label
          ) : ''}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default ProgramSpaceEdge;
