export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHECK_DONE = 'AUTH_CHECK_DONE';
export const AUTH_SESSION_EXPIRED = 'AUTH_SESSION_EXPIRED';
export const LOAD_BRAND = 'LOAD_BRAND';
export const SET_BRAND = 'SET_BRAND';
export const SET_LANG = 'SET_LANG';

export const CHANGE_UNREAD = 'CHANGE_UNREAD';

export const SET_CALENDAR = 'SET_CALENDAR';

export const UPDATE_URL_NAME = 'UPDATE_URL_NAME';

export const SET_UNLOCKSCREEN = 'SET_UNLOCKSCREEN';
