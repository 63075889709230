import React, { useState } from 'react';
import { Banner, Modal } from '@shopify/polaris';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const EmbeddedProductsModal = (props) => {
  const [show, setShow] = useState(true);

  const { t } = useTranslation();

  const closeModal = () => {
    setShow(false);
    props.cleanup();
  };

  const textToCopy = `
    <iframe src="https://${props.subdomain || 'app'}.planubo.com/booking/${props.urlName}?productOnly=true" title="description" width="100%" height="100%"></iframe>
  `;

  const productToCopy = `
    <iframe src="https://${props.subdomain || 'app'}.planubo.com/embeddedbooking/${props.token}?hideNav=true" title="description" width="100%" height="750px"></iframe>
`;

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(props.token ? productToCopy : textToCopy);
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title={props.title}
      primaryAction={{
        content: t('shared.copy'),
        onAction: copyToClipBoard
      }}
    >
      <Modal.Section>
        <Banner status="info">
          <p>{t('embed.css')}</p>
        </Banner>
        <div className="mt-2 bg-gray-100 p-4 border">
          <code className="break-words">{props.token ? productToCopy : textToCopy}</code>
        </div>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  subdomain: state.auth.subdomain,
  urlName: state.auth.urlName
});

export default connect(mapStateToProps)(EmbeddedProductsModal);
