import React, { useState } from 'react';
import { Banner, Button, Form, FormLayout, Modal, TextField } from '@shopify/polaris';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NewClientModal = (props) => {
  const [clientData, setClientData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [showNewClient, setShowNewClient] = useState(true);
  const [formErrors, setFormErrors] = useState({});

  const { t } = useTranslation();

  const closeModal = () => {
    setClientData({ ...clientData, firstName: '', lastName: '', email: '' });
    setShowNewClient(false);
    props.close();
  };

  const handleChange = (field) => (value) => setClientData({ ...clientData, [field]: value });

  const onSubmit = () => {
    setFormErrors({});

    if (!formValid()) return;

    const client = {
      first_name: clientData.firstName,
      last_name: clientData.lastName
    };

    if (clientData.email) {
      client.email = clientData.email;
    }

    props.handleSubmit({ client });
    props.close();
  };

  const formValid = () => {
    const e = {};
    if (!clientData.firstName) {
      e.firstName = t('user.validations.first_name');
    }
    if (!clientData.lastName) {
      e.lastName = t('user.validations.last_name');
    }

    setFormErrors(e);
    return Object.entries(e).length === 0;
  };

  return (
    <Modal
      open={showNewClient}
      onClose={closeModal}
      title={props.title}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <Banner title={t('client.client_accounts')} status="info">
              <p>{t('client.client_accounts_desc')}</p>
            </Banner>
            <TextField error={formErrors.firstName} label={t('user.first_name')} value={clientData.firstName} onChange={handleChange('firstName')} />
            <TextField error={formErrors.lastName} label={t('user.last_name')} value={clientData.lastName} onChange={handleChange('lastName')} />
            <TextField error={formErrors.email} label={t('user.email')} type="email" value={clientData.email} onChange={handleChange('email')} />
            <Button primary submit>{t('shared.add')}</Button>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isInstructor: state.auth.instructor
});

export default connect(mapStateToProps)(NewClientModal);
