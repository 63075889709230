import { searchFilterToURL } from '../FilterEngine';

export const polarisSortMapping = {
  ascending: 'asc',
  descending: 'desc',
  asc: 'ascending',
  desc: 'descending'
};

export const handleSort = (props, setTableFilters, indexSortMapping) => (index, direction) => {
  const sort = `${indexMapper(indexSortMapping)(index)} ${polarisSortMapping[direction]}`;
  searchFilterToURL(props, 'sorts', sort);
  setTableFilters((f) => ({ ...f, sorts: sort }));
};

export const initialSortColumnIndex = (indexSortMapping, sorts) => Object.keys(indexSortMapping).indexOf(sorts.split(' ')[0]);

export const defaultSortDirection = (sorts) => polarisSortMapping[sorts.split(' ')[1]];

export const indexMapper = (indexSortMapping) => (index) => Object.keys(indexSortMapping)[index];
