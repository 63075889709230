import React, { useEffect } from 'react';
import { ReactFormBuilder, ElementStore } from './FormBuilder';

const GenericFormBuilder = (props) => {
  useEffect(() => () => {
    ElementStore.state.data = [];
  }, []);

  return (
    <ReactFormBuilder
      data={props.data}
      setData={props.setData}
      show_description={false}
    />
  );
};

export default GenericFormBuilder;
