import React from 'react';

import { Banner, List } from '@shopify/polaris';

const Banners = (props) => {
  const showItems = (details) => {
    let list = [];

    if (details && typeof details === 'object') {
      list = details.map((detail, idx) => <List.Item key={idx}>{detail}</List.Item>);
    } else if (details && typeof details === 'string') {
      return <List.Item>{details}</List.Item>;
    }

    return <List type="bullet">{list}</List>;
  };

  return (
    props.banners.map((banner, idx) => (
      <div key={`banner_${idx + 1}`}>
        <Banner
          title={banner.title}
          status={banner.status}
          onDismiss={() => { props.onDismissBanner(idx); }}
        >
          {showItems(banner.details)}
          {banner.exception ? showItems(banner.exception.split(': ')[1]?.slice(0, -1)) : null}
        </Banner>
        <br />
      </div>
    ))
  );
};

export default Banners;
