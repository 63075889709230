import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, FormLayout, TextStyle, Spinner, Layout, Stack, Heading } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import Banners from '../../components/Shared/Banners';
import classes from './Auth.module.scss';
import { authCheckState } from '../../store/actions/index';
import loadPaddle from '../../services/loadPaddle';
import HomeCard from '../../components/Home/HomeCard';
import logo from '../../assets/images/planubo-blue.png';
import SubscriptionOptions from '../../components/Shared/Constants/SubscriptionOptions';
import Percentage from '../../components/Shared/Percentage';

const ChoosePlan = (props) => {
  const [banner, setBanner] = useState([]);
  const [waitForWebhook, setWaitForWebhook] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [planSelected, setPlanSelected] = useState(false);

  const [currency, setCurrency] = useState('');
  const [total, setTotal] = useState('');
  const [tax, setTax] = useState('');
  const [subtotal, setSubtotal] = useState('');
  const [intervalType, setIntervalType] = useState('');
  const [name, setName] = useState('');

  const [showSelection, setShowSelection] = useState(true);
  const [showMonthlyPlans, setShowMonthlyPlans] = useState(false);
  const [showYearlyPlans, setShowYearlyPlans] = useState(false);

  const { t } = useTranslation();
  const history = useNavigate();

  const fetchPlans = useCallback(() => {
    axios.get('/v1/new_users/list_plans')
      .then((response) => {
        setAvailablePlans(response.data.plans);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  }, [t]);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  useEffect(() => {
    loadPaddle(() => {});
  });

  const monthlyPlans = () => (
    <Layout>
      {availablePlans.monthly.map((plan) => (
        <Layout.Section key={plan.id}>
          <HomeCard action handleChange={() => openCheckout(plan.id)} number={plan.name} />
        </Layout.Section>
      ))}
    </Layout>
  );

  const yearlyPlans = () => (
    <Layout>
      {availablePlans.yearly.map((plan) => (
        <Layout.Section key={plan.id}>
          <HomeCard action handleChange={() => openCheckout(plan.id)} number={plan.name} />
        </Layout.Section>
      ))}
    </Layout>
  );

  const paddleSuccess = useCallback(() => {
    setWaitForWebhook(true);

    // Instead of webhook, actively reach out to check
    setBanner([{ title: t('plan.successful_selection'), status: 'success' }]);
    setTimeout(() => {
      props.checkAuthState('/plans', history);
    }, 10000);
    setLoading(true);
  // eslint-disable-next-line
  }, []);

  const openCheckout = (id) => {
    setPlanSelected(id);
  };

  const updatePrices = (data) => {
    const planCurrency = data.eventData.checkout.prices?.customer?.currency;
    const planTotal = data.eventData.checkout.prices?.customer?.total;
    const planTax = data.eventData.checkout.prices?.customer?.total_tax;
    const planSubtotal = planTotal - planTax;

    const planIntervalType = data.eventData.checkout.recurring_prices?.interval.type;

    const planName = data.eventData.product?.name;

    setCurrency(planCurrency);
    setTotal(parseFloat(planTotal, 10).toFixed(2));
    setTax(parseFloat(planTax, 10).toFixed(2));
    setSubtotal(parseFloat(planSubtotal, 10).toFixed(2));
    setIntervalType(planIntervalType);
    setName(planName);
  };

  useEffect(() => {
    if (planSelected) {
      const Paddle = window.Paddle;
      Paddle.Setup({
        vendor: 123186,
        eventCallback: (eventData) => updatePrices(eventData)
      });
      Paddle.Checkout.open({
        method: 'inline',
        product: planSelected,
        email: props.email,
        passthrough: {
          token: props.token
        },
        frameInitialHeight: 520,
        frameStyle: 'height: 700px; width: 100%; max-width: 495px; background-color: white; border: none; border-radius: 12px',
        frameTarget: 'checkout-container',
        successCallback: () => paddleSuccess()
      });
    }
  // eslint-disable-next-line
  }, [planSelected]);

  const handleMonthlyPlansChange = () => {
    setShowMonthlyPlans(true);
    setShowSelection(false);
  };

  const handleYearlyPlansChange = () => {
    setShowYearlyPlans(true);
    setShowSelection(false);
  };

  const back = () => {
    setShowMonthlyPlans(false);
    setShowYearlyPlans(false);
    setShowSelection(true);
  };

  const handleFreePlan = () => {
    if (!window.confirm(t('plan.are_you_sure_free_plan'))) return;

    axios.post('/v1/new_users/free')
      .then(() => {
        setBanner([{ title: t('plan.successful_selection'), status: 'success' }]);
        setLoading(true);
        setTimeout(() => {
          if (process.env.NODE_ENV === 'development') {
            window.location.assign(`http://${props.subdomain}.localhost:3000`);
          } else {
            window.location.assign(`https://${props.subdomain}.planubo.com`);
          }
        }, 4000);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  };

  const reset = () => {
    setPlanSelected(false);
    setTax('');
    setName('');
    setTotal('');
    setSubtotal('');
    setIntervalType('');
  };

  return (
    <>
      {!planSelected && (
        <div className={classes.Auth}>
          {banner.length ? <Banners banners={banner} onDismissBanner={() => setBanner([])} /> : null}
          {showMonthlyPlans && !loading && (
            <FormLayout>
              {availablePlans.monthly?.length ? monthlyPlans() : <Spinner />}
              <Button primary onClick={() => back()}>{t('shared.back')}</Button>
              <TextStyle variation="subdued">{`© ${new Date().getFullYear()} Planubo. All Rights Reserved.`}</TextStyle>
            </FormLayout>
          )}
          {showYearlyPlans && !loading && (
            <FormLayout>
              {availablePlans.yearly?.length ? yearlyPlans() : <Spinner />}
              <Button primary onClick={() => back()}>{t('shared.back')}</Button>
              <TextStyle variation="subdued">{`© ${new Date().getFullYear()} Planubo. All Rights Reserved.`}</TextStyle>
            </FormLayout>
          )}
          {showSelection && !loading && (
            <FormLayout>
              <Layout>
                <Layout.Section>
                  <HomeCard action handleChange={() => handleFreePlan()} subTitle={t('plan.forever_free')} number={t('plan.free')} />
                </Layout.Section>
                <Layout.Section>
                  <HomeCard action handleChange={() => handleMonthlyPlansChange()} subTitle={t('plan.more_flexibility')} number={t('plan.monthly_membership')} />
                </Layout.Section>
                <Layout.Section>
                  <HomeCard action handleChange={() => handleYearlyPlansChange()} subTitle={t('plan.value')} number={t('plan.yearly_membership')} />
                </Layout.Section>
              </Layout>
              <TextStyle variation="subdued">{`© ${new Date().getFullYear()} Planubo. All Rights Reserved.`}</TextStyle>
            </FormLayout>
          )}
          {loading && <Spinner />}
        </div>
      )}
      {planSelected && (
        <div className="flex-container">
          <div className="flex-item-left">
            <FormLayout>
              <img src={logo} alt="planubo" style={{ maxWidth: '200px' }} />
              <Heading element="h1">{t('plan.unlock_experience')}</Heading>
              {SubscriptionOptions(name, t)}
              <br />
              <Stack>
                <Stack.Item fill>
                  <Heading element="h1">{t('plan.your_subscription')}</Heading>
                  <Button plain onClick={() => reset()}>{t('shared.change')}</Button>
                </Stack.Item>
                <Stack.Item>
                  <TextStyle>{`${currency} / ${intervalType}`}</TextStyle>
                </Stack.Item>
              </Stack>
              <Stack>
                <Stack.Item fill>
                  <TextStyle>{name}</TextStyle>
                </Stack.Item>
                <Stack.Item>
                  <TextStyle>{`${currency} ${subtotal}`}</TextStyle>
                </Stack.Item>
              </Stack>
              <Stack>
                <Stack.Item fill>
                  <TextStyle>
                    {`${t('invoices.tax')} ${Percentage(subtotal, tax)}`}
                  </TextStyle>
                </Stack.Item>
                <Stack.Item>
                  <TextStyle>{`${currency} ${tax}`}</TextStyle>
                </Stack.Item>
              </Stack>
              <Stack>
                <Stack.Item fill>
                  <TextStyle variation="strong">{t('shared.total')}</TextStyle>
                </Stack.Item>
                <Stack.Item>
                  <TextStyle variation="strong">{`${currency} ${total}`}</TextStyle>
                </Stack.Item>
              </Stack>
            </FormLayout>
          </div>
          <div className="flex-item-right">
            <div style={{ maxWidth: '400px' }}>
              {banner.length ? <Banners banners={banner} onDismissBanner={() => setBanner([])} /> : null}
              {waitForWebhook ? <Spinner /> : null}
            </div>
            <div className="checkout-container" />
          </div>
        </div>
      )}
    </>
  );
};

const mapPropsToDispatch = (dispatch) => ({ checkAuthState: (url, history) => dispatch(authCheckState(url, history)) });
const mapStateToProps = (state) => ({
  email: state.auth.email,
  lang: state.auth.lang,
  company: state.auth.company,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  token: state.auth.token,
  sudomain: state.auth.subdomain
});

export default connect(mapStateToProps, mapPropsToDispatch)(ChoosePlan);
