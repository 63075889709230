/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { SurveyCreatorComponent, SurveyCreator, editorLocalization } from 'survey-creator-react';
import showdown from 'showdown'; // Import Showdown
import { Model, setLicenseKey } from 'survey-core';
import { connect } from 'react-redux';

// import 'survey-core/i18n/french';
// import 'survey-core/i18n/german';
import 'survey-core/survey.i18n';
import 'survey-creator-core/survey-creator-core.i18n';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import './customSurveyStyles.css';

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: false,
  showThemeTab: true,
  showTranslationTab: true,
  allowAddRemovePages: true, // Ensure this is true if you want to add/remove pages
  allowDragDrop: true,
  allowHtml: true
};

const GenericFormBuilder = (props) => {
  setLicenseKey(
    'NmY0MzZiMjctNTk1YS00OGQ2LWFlMDQtYjVjN2YxMDk4ZTNkOzE9MjAyNS0xMC0wMywyPTIwMjUtMTAtMDMsND0yMDI1LTEwLTAz'
  );

  creatorOptions.readOnly = props.readOnly;
  const creator = new SurveyCreator(creatorOptions);

  const text = creator.JSON;

  creator.locale = props.lang;
  creator.survey.locale = props.lang;
  // creator.onTextMarkdown.add((survey, options) => {
  // const converter = new showdown.Converter();
  //   const html = converter.makeHtml(options.text);
  //   // eslint-disable-next-line no-param-reassign
  //   options.html = html;
  // });

  creator.onSurveyInstanceCreated.add((sender, options) => {
    options.survey.onTextMarkdown.add((_survey, o) => {
      const converter = new showdown.Converter();
      const html = converter.makeHtml(o.text);
      // eslint-disable-next-line no-param-reassign
      o.html = html;
    });
  });

  if (Object.keys(text)[0] === 'logoPosition' && props.survey.data) {
    creator.JSON = JSON.parse(props.survey.data);
    if (props.survey.theme) {
      creator.theme = JSON.parse(props.survey.theme);
    }
  }

  creator.saveSurveyFunc = (saveNo, callback) => {
    props.onSubmit({
      callback,
      saveNo,
      json: creator.JSON
    });
  };

  creator.onUploadFile.add((_, options) => {
    const formData = new FormData();
    options.files.forEach((file) => {
      formData.append(`file_${file.name}`, file);
    });

    props.uploadFiles(formData, options);
  });

  creator.saveThemeFunc = (saveNo, callback) => {
    props.onSubmit({
      callback,
      saveNo,
      json: creator.JSON,
      theme: creator.theme
    });
  };

  return (
    <div className="h-full no-twp">
      <SurveyCreatorComponent creator={creator} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.lang
});

export default connect(mapStateToProps)(GenericFormBuilder);
