/* eslint-disable no-shadow */
import React from 'react';
import { Button, Stack } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LineItem from './LineItem';
import styles from './LineItems.module.scss';

const LineItems = (props) => {
  const { t } = useTranslation();

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    // helper function to reorder result (src: react-beautiful-dnd docs)
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    // perform reorder
    const lineItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    // call parent handler with new state representation
    reorderHandler(lineItems);
  };

  const { items, addHandler, reorderHandler, ...functions } = props;

  return (
    <form>
      <div>
        <div className={`${styles.gridTable}`}>

          <div className={`${styles.row} ${styles.header}`}>
            <div>#</div>
            <div>{t('invoices.item')}</div>
            <div>{t('shared.description')}</div>
            <div>{t('invoices.qty')}</div>
            <div>{t('shared.price')}</div>
            <div>{t('shared.total')}</div>
            <div />
          </div>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={snapshot.isDraggingOver ? styles.listDraggingOver : ''}
                >
                  {items.map((item, i) => (
                    <Draggable key={item.id} draggableId={item.id} index={i}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={provided.draggableProps.style}
                          className={snapshot.isDragging ? styles.listItemDragging : ''}
                        >
                          <LineItem
                            style={{ color: 'red' }}
                            key={i + item.id}
                            index={i}
                            name={item.name}
                            description={item.description}
                            quantity={item.quantity}
                            price={item.price}
                            {...functions}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <br />
        <Stack>
          <Stack.Item fill />
          <Stack.Item>
            <Button type="button" onClick={addHandler}>{t('invoices.add_item')}</Button>
          </Stack.Item>
        </Stack>
      </div>
    </form>
  );
};

export default LineItems;
