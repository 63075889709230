import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Card, Form, FormLayout, Button, Toast } from '@shopify/polaris';
import Banners from '../Shared/Banners';
import TinymceEditor from '../Tinymce/TinymceEditor';

const CommentForm = (props) => {
  const [comment, setComment] = useState('');
  const [banner, setBanner] = useState([]);

  const { t } = useTranslation();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const handleSubmit = () => {
    if (comment) {
      axios.post(`/v1/${props.commentablePath}/${props.commentableId}/comments`, { comment: { body: comment } })
        .then(() => {
          toggleToastActive(t('shared.saved'));
          setComment('');
          props.handleSubmit();
        })
        .catch((error) => {
          setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.error }]);
        });
    }
  };

  const handleChange = useCallback((newValue) => setComment(newValue), []);

  return (
    <Card.Section>
      {toastMarkup}
      {banner.length ? <Banners banners={banner} onDismissBanner={() => setBanner([])} /> : null}
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <TinymceEditor
            value={comment}
            handleChange={handleChange}
          />
          <Button submit>{t('shared.add')}</Button>
        </FormLayout>
      </Form>
    </Card.Section>
  );
};

export default CommentForm;
