import React, { forwardRef, useImperativeHandle, useState } from 'react';
// import ConfirmationModalLayout from './ConfirmationModalLayout';

const ModalService = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [promise, setPromise] = useState({});
  const [options, setOptions] = useState({});

  useImperativeHandle(ref, () => ({
    async open(altProps) {
      setOptions(altProps);

      return new Promise((resolve, reject) => {
        setPromise({
          resolve,
          reject
        });
        setShow(true);
      });
    }
  }));

  const close = () => {
    setShow(false);
  };

  return React.cloneElement(props.children, {
    ...options,
    show,
    onOk: (data) => {
      close();
      promise.resolve(data || true);
    },
    onCancel: () => {
      close();
      promise.resolve(false);
    }
  });
});

export default ModalService;
