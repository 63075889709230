/* eslint-disable */
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Page, Layout, Card, FormLayout,
  Button, Form, Toast, ButtonGroup, ContextualSaveBar, Spinner
} from '@shopify/polaris';
import Select from 'react-select';

const Google = () => {
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [googleSyncWay, setGoogleSyncWay] = useState('oneWay');
  const [calendar, setCalendar] = useState('');
  const [token, setToken] = useState('');
  const [calendars, setCalendars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({});
  const { t } = useTranslation();
  const history = useNavigate();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const fetchSettings = useCallback(() => {
    axios.get('/v1/settings/google_calendars')
      .then((response) => {
        const c = response.data.calendars.find((ca) => ca.id === response.data.googleCalendarId);
        setCalendar(c);
        setGoogleSyncWay(response.data.syncWay);
        setSettings(response.data);
        setToken(response.data.token);
        setCalendars(response.data.calendars);
      })
      .catch(() => {})
      .then(() => setLoading(false));
  }, []);

  const handleUpdate = () => {
    const params = {
      google_connection: {
        sync_way: googleSyncWay,
        google_calendar_id: calendar.id
      }
    };

    axios.patch(`/v1/google_connection/${token}`, params)
      .then(() => {
        toggleToastActive(t('shared.saved'));
        setShowSaveBar(false);
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  // eslint-disable-next-line no-unused-vars
  const handleChange = (v) => {
    setCalendar(v);
    setShowSaveBar(true);
  };

  const syncWayUpdate = (option) => {
    setGoogleSyncWay(option);
    setShowSaveBar(true);
  };

  const settingsForm = (
    <Form>
      <FormLayout>
        <Layout>
          <Layout.AnnotatedSection
            title={t('integrations.type_of_sync')}
            description={t('integrations.google_sync_desc')}
          >
            <Card>
              <Card.Section>
                <ButtonGroup segmented>
                  <Button pressed={googleSyncWay === 'oneWay'} onClick={() => syncWayUpdate('oneWay')}>One Way</Button>
                  <Button pressed={googleSyncWay === 'twoWay'} onClick={() => syncWayUpdate('twoWay')}>Two Way</Button>
                </ButtonGroup>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('integrations.select_google_c')}
          >
            <Card>
              <Card.Section>
                <Select
                  value={calendar}
                  onChange={handleChange}
                  options={calendars}
                  isLoading={loading}
                  getOptionValue={(c) => c.id}
                  getOptionLabel={(c) => c.label}
                />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title={t('integrations.last_full_sync_at')}
          >
            <Card>
              <Card.Section>
                {settings.currentlyProcessing ? (
                  <div className="flex items-center">
                    <div className="mr-2">{t('integrations.currently_processing')}</div>
                    <Spinner size="small" />
                  </div>
                ) : (
                  settings.lastFullSyncAt ? moment(settings.lastFullSyncAt).format('LLLL') : t('shared.never')
                )}
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </FormLayout>
    </Form>
  );

  return (
    <Page
      breadcrumbs={[{
        onAction: () => history(-1)
      }]}
      title={t('integrations.google_calendar')}
      separator
    >
      {showSaveBar && (
        <ContextualSaveBar
          message={t('shared.unsaved_changes')}
          saveAction={{
            onAction: () => handleUpdate(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      {toastMarkup}
      {loading ? <Spinner /> : settingsForm}
    </Page>
  );
};

export default Google;
