import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLayout, Modal, TextField, Form, Card } from '@shopify/polaris';
import moment from 'moment';
import { connect } from 'react-redux';
import Banners from '../Shared/Banners';

const NewEvent = (props) => {
  const [event, setEvent] = useState({ ...props.event });
  const [banner, setBanner] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const { t } = useTranslation();

  const formValid = useCallback(() => {
    const errors = {};

    if (!event.title) {
      errors.title = t('shared.validations.required');
    }

    setFormErrors(errors);

    return Object.entries(errors).length === 0;
  }, [event.title, t]);

  const handleSubmit = useCallback(() => {
    if (!formValid()) return;

    props.handleSubmit(event);

    props.close();
  }, [event, props, formValid]);

  const handleChange = (key) => (value) => {
    setEvent({ ...event, [key]: value });
  };

  const eventOptions = () => (
    <FormLayout>
      <TextField
        label={t('shared.description')}
        value={event.title}
        requiredIndicator
        error={formErrors.title}
        onChange={handleChange('title')}
      />
      <Card>
        <Card.Section title={t('shared.start')}>
          {moment(event.start).format('LL')}
        </Card.Section>
        <Card.Section title={t('shared.end')}>
          {moment(event.end).format('LL')}
        </Card.Section>
      </Card>
    </FormLayout>
  );

  return (
    <Modal
      open={props.active}
      onClose={() => props.close()}
      title="Time off"
      primaryAction={{
        content: t('shared.save'),
        onAction: handleSubmit
      }}
    >
      <Modal.Section>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <Banners banners={banner} onDismissBanner={() => setBanner([])} />
            {eventOptions()}
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  canDoOnlineEvent: state.auth.canDoOnlineEvent,
  hasInvoiceAccess: state.auth.hasInvoiceAccess
});

export default connect(mapStateToProps)(NewEvent);
