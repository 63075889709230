/* eslint-disable */

import Store from 'beedle';

const store = new Store({
  actions: {
    setData(context, data) {
      context.commit('setData', data);
    },

    load(context, { data }) {
      this.setData(context, data);
    },

    create(context, element) {
      const { data } = context.state;
      data.push(element);
      this.setData(context, data);
    },

    delete(context, element) {
      const { data } = context.state;
      data.splice(data.indexOf(element), 1);
      this.setData(context, data);
    },

    post(context) {
      const { data } = context.state;
      this.setData(context, data, true);
    },

    updateOrder(context, elements) {
      const newData = elements.filter(x => x && !x.parentId);
      elements.filter(x => x && x.parentId).forEach(x => newData.push(x));
      this.setData(context, newData);
    },
  },

  mutations: {
    setData(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.data = payload;
      return state;
    },
  },

  initialState: {
    data: [],
  },
});

export default store;
