import React from 'react';
import { Button } from '@shopify/polaris';
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';

const Google = (props) => {
  const { t } = useTranslation();

  const login = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/calendar',
    onSuccess: (codeResponse) => props.googleSuccess(codeResponse),
    flow: 'auth-code'
  });

  return (
    <Button onClick={() => login()}>{t('integrations.sign_in_with_google')}</Button>
  );
};

export default Google;
