import React, { useCallback, useEffect, useState } from 'react';
import { Banner, Button, Form, FormLayout, Modal } from '@shopify/polaris';
import Select from 'react-select';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';
import Banners from '../Shared/Banners';

const EditChildren = (props) => {
  const [inputValue, setValue] = useState('');
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [selected, setSelected] = useState({});
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const debouncedSearch = useDebounce(inputValue, 400);

  const { t } = useTranslation();

  const matchParams = useParams();

  // const [clients, setClients] = useState([]);

  const handleChange = (value) => {
    if (value) {
      setLoading(true);
    }

    setValue(value);
  };

  const fetchDefault = useCallback(() => {
    const params = {
      per_page: 30,
      search: inputValue,
      q: '',
      s: ''
    };

    axios.post('/v1/clients/search', params)
      .then((res) => {
        setDefaultOptions(res.data.clients);
      })
      .catch(() => {
      })
      .then(() => setLoading(false));
  // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    fetchDefault();
  }, [fetchDefault]);

  const onSubmit = () => {
    const params = {
      child_ids: [selected.token]
    };

    axios.post(`/v1/clients/${matchParams.id}/assign_children`, params)
      .then(() => {
        props.close();
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  };

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      title={t('client.add_children')}
    >
      <div className="p-4">
        <Banners banners={banner} onDismissBanner={() => setBanner([])} />
      </div>
      <Modal.Section>
        <div>
          <div className="pb-4">
            <Banner status="info">
              {t('client.connect_child_info')}
            </Banner>
          </div>
          <div className="mb-20 react-select">
            <Form onSubmit={onSubmit}>
              <FormLayout>
                <Select
                  inputValue={inputValue}
                  isClearable
                  onInputChange={handleChange}
                  value={selected}
                  onChange={setSelected}
                  isLoading={loading}
                  defaultOptions={defaultOptions}
                  options={defaultOptions}
                  getOptionValue={(user) => user.token}
                  getOptionLabel={(user) => user.name}
                />
              </FormLayout>
            </Form>
          </div>
        </div>
      </Modal.Section>
      <Modal.Section>
        <Button primary onClick={onSubmit}>{t('shared.save')}</Button>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isInstructor: state.auth.instructor
});

export default connect(mapStateToProps)(EditChildren);
