import React from 'react';
import { ResponsivePie } from '@nivo/pie';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const CenteredMetric = (fontSize, centerValue) => ({ dataWithArc, centerX, centerY }) => {
  let total = 0;
  if (centerValue) {
    total = centerValue;
  } else {
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });
  }

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize,
        fontWeight: 600
      }}
    >
      {total}
    </text>
  );
};

const Pie = ({ data, fontSize = '60px', centerValue, showCenterValue, colorFromDataset, callback = () => {} }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 2, bottom: 2, left: 2, right: 2 }}
    innerRadius={0.7}
    padAngle={3}
    fit
    cornerRadius={1}
    arcLabel={React.Fragment}
    legends={React.Fragment}
    activeOuterRadiusOffset={1}
    borderWidth={1}
    colors={colorFromDataset && { datum: 'data.color' }}
    layers={showCenterValue && ['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric(fontSize, centerValue)]}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          0.2
        ]
      ]
    }}
    onClick={(d) => callback(d)}
    arcLinkLabelsSkipAngle={5000}
    // arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsSkipAngle={5000}
    arcLabelsTextColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          2
        ]
      ]
    }}
  />
);

export default Pie;
