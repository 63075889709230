/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BookOpenIcon, CalculatorIcon, CalendarIcon, CogIcon, HomeIcon, InformationCircleIcon, BuildingLibraryIcon, UserCircleIcon, UserGroupIcon, UsersIcon } from '@heroicons/react/20/solid';
import { BanknotesIcon, PencilSquareIcon, ClipboardDocumentListIcon, ClipboardDocumentCheckIcon, EnvelopeIcon, GiftIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import Menu from './Menu/Menu';

const InstructorNavigation = (props) => {
  const { t } = useTranslation('translation');
  const location = useLocation();

  const activeRoute = (route, exact = false) => {
    const path = location.pathname.split('/')[1];

    if (exact) return path === route;

    return route.includes(path);
  };

  const canAccessInvoices = () => {
    if (props.hasInvoiceAccess) return true;

    return false;
  };

  const openDocumentation = () => {
    window.open('https://www.notion.so/Planubo-Help-Doc-63b7b34ee37c402383211473b58be8fb');
  };

  const settingsItems = [
    {
      url: '/profile_settings',
      label: t('navigation.profile'),
      selected: activeRoute('profile_settings'),
      condition: true
    },
    {
      url: '/calendar_settings',
      label: t('navigation.calendar_settings'),
      selected: activeRoute('calendar_settings'),
      condition: true
    },
    {
      url: '/integrations',
      label: t('navigation.integrations'),
      selected: activeRoute('integrations'),
      condition: true
    }
  ];

  const navigation = [
    {
      label: t('settings.settings'),
      selected: settingsItems.some((h) => h.url.split('/')[1] === location.pathname.split('/')[1]),
      children: settingsItems,
      icon: CogIcon,
      condition: true
    },
    {
      label: t('help.help_center'),
      selected: ['help'].some((h) => h === location.pathname.split('/')[1]),
      condition: true,
      icon: InformationCircleIcon,
      children: [
        {
          url: '/help',
          label: t('help.help'),
          selected: activeRoute('help'),
          condition: true
        },
        {
          label: t('help.documentation'),
          onClick: () => openDocumentation(),
          condition: true
        }
      ]
    }
  ];

  return (
    <Menu toggle={props.toggle} navigation={navigation} />
  );
};

const mapStateToProps = (state) => ({
  isFreeMember: state.auth.freeMember,
  isBasicMember: state.auth.basicMember,
  isPremiumMember: state.auth.premiumMember,
  isPremiumPlusMember: state.auth.premiumPlusMember,
  isStaff: state.auth.role === 'staff',
  bookingPageAccess: state.auth.bookingPageAccess,
  unread: state.auth.unread,
  userUrlName: state.auth.urlName,
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  hasStripe: state.auth.hasStripe
});

export default connect(mapStateToProps)(InstructorNavigation);
