import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import 'jsdig';
import App from './App';
import * as serviceWorker from './serviceWorker';
import authReducer from './store/reducers/auth';
import calendarReducer from './store/reducers/calendar';
import AxiosIntercepter from './services/AxiosInterceptor';
import './i18n';
import ScrollToTop from './containers/Layout/ScrollToTop/ScrollToTop';

const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);

const rootReducer = combineReducers({
  auth: authReducer,
  calendar: calendarReducer
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

AxiosIntercepter.setupInterceptor(store);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
