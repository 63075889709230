import React, { useState } from 'react';
import { Modal } from '@shopify/polaris';
import Unlock from './Unlock';

const LoginModal = (props) => {
  const [showBookingModal, setShowBookingModal] = useState(true);

  const closeModal = () => {
    setShowBookingModal(false);
    props.close();
  };

  return (
    <Modal
      open={showBookingModal}
      onClose={closeModal}
      title="Login"
    >
      <Modal.Section>
        <Unlock closeOnSuccess={closeModal} />
      </Modal.Section>
    </Modal>
  );
};

export default LoginModal;
