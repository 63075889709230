import { handleFilterChange } from '../FilterEngine';

// eslint-disable-next-line default-param-last
const productFilterActive = (t, props, setTableFilters, products = [], product, filter = 'product_title_eq') => ({
  key: 'product',
  label: `${t('products.plural')}: ${(products.find((elm) => elm.value === product) || {}).label}`,
  onRemove: handleFilterChange(props, setTableFilters, 'product', 'reset', ''),
  filter,
  value: product
});

export default productFilterActive;
