import React, { useCallback, useEffect, useState } from 'react';
import { Card, FormLayout, Page, TextField, Toast, Spinner } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import GenericFormBuilder from '../Shared/GenericFormBuilder';
import { ElementStore } from '../Shared/FormBuilder';
import PreviewFormBuilderModal from '../Shared/FormBuilder/PreviewFormBuilderModal';
import ModalService from '../Shared/Services/ModalService';
import '../Shared/FormBuilder/scss/application.scss';

const previewModalRef = React.createRef();

const Survey = () => {
  const [survey, setSurvey] = useState({});
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});

  const { t } = useTranslation();
  const matchParams = useParams();
  const history = useNavigate();

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);
  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const handleChange = (field) => (value) => {
    setSurvey({ ...survey, [field]: value });
  };

  const formValid = () => {
    const e = {};
    if (!survey.title) {
      e.title = t('shared.validations.required');
    }

    setFormErrors(e);
    return Object.entries(e).length === 0;
  };

  const fetchSurvey = useCallback(() => {
    if (matchParams.id !== 'new') {
      axios.get(`/v1/surveys/${matchParams.id}`)
        .then((response) => {
          const data = JSON.parse(response.data.data);
          setSurvey({ ...response.data, data });
          ElementStore.state.data = data;
        })
        .catch(() => {
        })
        .then(() => {
          setLoading(false);
        });
    }

    setLoading(false);
  }, [matchParams.id]);

  useEffect(() => {
    fetchSurvey();
  }, [fetchSurvey]);

  const onSubmit = () => {
    if (!formValid()) return;

    setLoading(true);

    const params = {
      survey: {
        title: survey.title,
        data: JSON.stringify(ElementStore.state.data)
      }
    };

    if (matchParams.id === 'new') {
      axios.post('/v1/surveys', params)
        .then(() => {
          history('/surveys');
          setSurvey({});
        })
        .catch(() => {
          toggleToastActive(t('shared.something_went_wrong'));
          setLoading(false);
        });
    } else {
      axios.patch(`/v1/surveys/${survey.token}`, params)
        .then(() => {
          history('/surveys');
          setSurvey({});
        })
        .catch(() => {
          toggleToastActive(t('shared.something_went_wrong'));
        })
        .then(() => setLoading(false));
    }
  };

  const openModal = async () => {
    await previewModalRef.current.open({
      cancelButton: 'Cancel',
      submitButton: 'Ok',
      data: ElementStore.state.data,
      submit: () => {}
    });
  };

  return (
    <Page
      title={t('survey.single')}
      fullWidth
      separator
      secondaryActions={[{ content: t('invoices.preview'), onAction: openModal }]}
      primaryAction={{ content: t('shared.save'), onAction: onSubmit }}
    >
      {toastMarkup}
      <Card sectioned>
        {loading ? <Spinner /> : (
          <FormLayout>
            <TextField
              type="text"
              error={formErrors.title}
              label={t('shared.title')}
              placeholder={t('shared.title')}
              value={survey.title}
              onChange={handleChange('title')}
            />
            <GenericFormBuilder data={survey.data} />
          </FormLayout>
        )}
      </Card>
      <ModalService ref={previewModalRef}>
        <PreviewFormBuilderModal />
      </ModalService>
    </Page>
  );
};

export default Survey;
