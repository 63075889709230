import { handleFilterChange } from '../FilterEngine';

const tagFilterActive = (t, props, setTableFilters, tags, filter = 'booking_tags_label_in') => {
  const label = tags.map((tag) => tag.label)?.sort().join(', ');

  return {
    key: 'tags',
    label: `Tags: ${label}`,
    onRemove: handleFilterChange(props, setTableFilters, 'tags', 'reset', []),
    filter,
    value: tags.map((tag) => tag.label)
  };
};

export default tagFilterActive;
