/* eslint-disable */

/**
  * <ReactFormBuilder />
*/

import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Preview from './preview';
import Toolbar from './toolbar';
import FormGenerator from './form';
import store from './stores/store';
import Registry from './stores/registry';

class ReactFormBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editElement: null,
    };
    this.editModeOn = this.editModeOn.bind(this);
  }

  editModeOn(data, e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.editMode) {
      this.setState({ editMode: !this.state.editMode, editElement: null });
      if (this.props.setData) {
        this.props.setData(data);
      }
    } else {
      this.setState({ editMode: !this.state.editMode, editElement: data });
      if (this.props.setData) {
        this.props.setData(data);
      }
    }
  }

  manualEditModeOff() {
    if (this.state.editMode) {
      this.setState({
        editMode: false,
        editElement: null,
      });
    }
  }

  render() {
    const toolbarProps = {
      showDescription: this.props.show_description,
    };

    if (this.props.toolbarItems) { toolbarProps.items = this.props.toolbarItems; }
    return (
      <div>
        <div className="react-form-builder clearfix">
          <div className="flex">
            <Preview
              files={this.props.files}
              manualEditModeOff={this.manualEditModeOff.bind(this)}
              showCorrectColumn={this.props.showCorrectColumn}
              parent={this}
              data={this.props.data}
              setData={this.props.setData}
              editModeOn={this.editModeOn}
              editMode={this.state.editMode}
              variables={this.props.variables}
              registry={Registry}
              editElement={this.state.editElement}
              renderEditForm={this.props.renderEditForm}
            />
            <Toolbar {...toolbarProps} customItems={this.props.customToolbarItems} />
          </div>
        </div>
      </div>
    );
  }
}

function ReactFormGenerator(props) {
  return (
    <FormGenerator {...props} />
  );
}

// const ReactFormGenerator = forwardRef((props, ref) => {
//   const gridRef = useRef(null);

//   useImperativeHandle(gridRef, () => {
//     return {
//       submit() {
//         debugger;
//         gridRef.current.handleSubmit()
//       },
//     }
//   }, [])

//   return (
//     <FormGenerator ref={gridRef} {...props} />
//   );
// });

const FormBuilders = {};
FormBuilders.ReactFormBuilder = ReactFormBuilder;
FormBuilders.ReactFormGenerator = ReactFormGenerator;
FormBuilders.ElementStore = store;
FormBuilders.Registry = Registry;

export default FormBuilders;

export {
  ReactFormBuilder, ReactFormGenerator, store as ElementStore, Registry,
};
