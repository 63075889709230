/* eslint-disable */

import React from 'react';
import myxss from './myxss';
import { useTranslation } from 'react-i18next';

const ComponentLabel = (props) => {
  const hasRequiredLabel = (props.data.hasOwnProperty('required') && props.data.required === true && !props.read_only);
  const labelText = myxss.process(props.data.label);
  const { t } = useTranslation();

  return (
    <label className={props.className || ''}>
      <span dangerouslySetInnerHTML={{ __html: labelText }}/>
      {hasRequiredLabel && <span className="ml-2 mb-1 label-required inline-block p-1 text-center text-xs align-baseline leading-none rounded bg-red-600 text-white hover:bg-red-700">{t('shared.validations.required')}</span>}
    </label>
  );
};

export default ComponentLabel;
