import React from 'react';
import { Icon } from '@shopify/polaris';
import { CircleTickMajor, ClockMajor } from '@shopify/polaris-icons';

const InvoiceStatusBadge = (props) => {
  const color = props.active ? 'success' : 'highlight';
  const icon = props.active ? CircleTickMajor : ClockMajor;
  return <Icon backdrop source={icon} color={color} />;
};

export default InvoiceStatusBadge;
