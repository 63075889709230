import { handleFilterChange } from '../FilterEngine';

const staffFilterActive = (t, props, setTableFilters, clients, staff, filter = 'calendar_user_token_eq') => ({
  key: 'staff',
  label: `${t('staff.staff')}: ${(clients.find((elm) => elm.value === staff) || {}).label}`,
  onRemove: handleFilterChange(props, setTableFilters, 'staff', 'reset', ''),
  filter,
  value: staff
});

export default staffFilterActive;
