export const hours = [
  { label: '00:00', value: '00:00:00' },
  { label: '00:30', value: '00:30:00' },
  { label: '01:00', value: '01:00:00' },
  { label: '01:30', value: '01:30:00' },
  { label: '02:00', value: '02:00:00' },
  { label: '02:30', value: '02:30:00' },
  { label: '03:00', value: '03:00:00' },
  { label: '03:30', value: '03:30:00' },
  { label: '04:00', value: '04:00:00' },
  { label: '04:30', value: '04:30:00' },
  { label: '05:00', value: '05:00:00' },
  { label: '05:30', value: '05:30:00' },
  { label: '06:00', value: '06:00:00' },
  { label: '06:30', value: '06:30:00' },
  { label: '07:00', value: '07:00:00' },
  { label: '07:30', value: '07:30:00' },
  { label: '08:00', value: '08:00:00' },
  { label: '08:30', value: '08:30:00' },
  { label: '09:00', value: '09:00:00' },
  { label: '09:30', value: '09:30:00' },
  { label: '10:00', value: '10:00:00' },
  { label: '10:30', value: '10:30:00' },
  { label: '11:00', value: '11:00:00' },
  { label: '11:30', value: '11:30:00' },
  { label: '12:00', value: '12:00:00' },
  { label: '12:30', value: '12:30:00' },
  { label: '13:00', value: '13:00:00' },
  { label: '13:30', value: '13:30:00' },
  { label: '14:00', value: '14:00:00' },
  { label: '14:30', value: '14:30:00' },
  { label: '15:00', value: '15:00:00' },
  { label: '15:30', value: '15:30:00' },
  { label: '16:00', value: '16:00:00' },
  { label: '16:30', value: '16:30:00' },
  { label: '17:00', value: '17:00:00' },
  { label: '17:30', value: '17:30:00' },
  { label: '18:00', value: '18:00:00' },
  { label: '18:30', value: '18:30:00' },
  { label: '19:00', value: '19:00:00' },
  { label: '19:30', value: '19:30:00' },
  { label: '20:00', value: '20:00:00' },
  { label: '20:30', value: '20:30:00' },
  { label: '21:00', value: '21:00:00' },
  { label: '21:30', value: '21:30:00' },
  { label: '22:00', value: '22:00:00' },
  { label: '22:30', value: '22:30:00' },
  { label: '23:00', value: '23:00:00' },
  { label: '23:30', value: '23:30:00' },
  { label: '24:00', value: '24:00:00' }
];

export const meridiemHours = [
  { label: 'Midnight - BOD', value: '00:00:00' },
  { label: '0:30 AM', value: '00:30:00' },
  { label: '1:00 AM', value: '01:00:00' },
  { label: '1:30 AM', value: '01:30:00' },
  { label: '2:00 AM', value: '02:00:00' },
  { label: '2:30 AM', value: '02:30:00' },
  { label: '3:00 AM', value: '03:00:00' },
  { label: '3:30 AM', value: '03:30:00' },
  { label: '4:00 AM', value: '04:00:00' },
  { label: '4:30 AM', value: '04:30:00' },
  { label: '5:00 AM', value: '05:00:00' },
  { label: '5:30 AM', value: '05:30:00' },
  { label: '6:00 AM', value: '06:00:00' },
  { label: '6:30 AM', value: '06:30:00' },
  { label: '7:00 AM', value: '07:00:00' },
  { label: '7:30 AM', value: '07:30:00' },
  { label: '8:00 AM', value: '08:00:00' },
  { label: '8:30 AM', value: '08:30:00' },
  { label: '9:00 AM', value: '09:00:00' },
  { label: '9:30 AM', value: '09:30:00' },
  { label: '10:00 AM', value: '10:00:00' },
  { label: '10:30 AM', value: '10:30:00' },
  { label: '11:00 AM', value: '11:00:00' },
  { label: '11:30 AM', value: '11:30:00' },
  { label: '12:00 PM', value: '12:00:00' },
  { label: '12:30 PM', value: '12:30:00' },
  { label: '1:00 PM', value: '13:00:00' },
  { label: '1:30 PM', value: '13:30:00' },
  { label: '2:00 PM', value: '14:00:00' },
  { label: '2:30 PM', value: '14:30:00' },
  { label: '3:00 PM', value: '15:00:00' },
  { label: '3:30 PM', value: '15:30:00' },
  { label: '4:00 PM', value: '16:00:00' },
  { label: '4:30 PM', value: '16:30:00' },
  { label: '5:00 PM', value: '17:00:00' },
  { label: '5:30 PM', value: '17:30:00' },
  { label: '6:00 PM', value: '18:00:00' },
  { label: '6:30 PM', value: '18:30:00' },
  { label: '7:00 PM', value: '19:00:00' },
  { label: '7:30 PM', value: '19:30:00' },
  { label: '8:00 PM', value: '20:00:00' },
  { label: '8:30 PM', value: '20:30:00' },
  { label: '9:00 PM', value: '21:00:00' },
  { label: '9:30 PM', value: '21:30:00' },
  { label: '10:00 PM', value: '22:00:00' },
  { label: '10:30 PM', value: '22:30:00' },
  { label: '11:00 PM', value: '23:00:00' },
  { label: '11:30 PM', value: '23:30:00' },
  { label: 'Midnight - EOD', value: '24:00:00' }
];

export const days = (t) => [
  { value: '0', label: t('week.monday') },
  { value: '1', label: t('week.tuesday') },
  { value: '2', label: t('week.wednesday') },
  { value: '3', label: t('week.thursday') },
  { value: '4', label: t('week.friday') },
  { value: '5', label: t('week.saturday') },
  { value: '6', label: t('week.sunday') }
];

export const minutes = [
  '', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '90', '120', '180', '240'
];
