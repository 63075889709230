import { handleFilterChange } from '../FilterEngine';

const roleFilterActive = (props, setTableFilters, role) => ({
  key: 'role',
  label: `Roles: ${role.join(', ')}`,
  onRemove: handleFilterChange(props, setTableFilters, 'role', 'reset', []),
  filter: 'role_in',
  value: role
});

export default roleFilterActive;
