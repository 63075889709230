import React from 'react';
import { Stack, TextStyle } from '@shopify/polaris';
import TickBadge from '../TickBadge';

const GetText = (plan, t) => {
  switch (plan) {
    case 'Basic': {
      return [
        [`50 ${t('client.clients')}`, true],
        [`10 GB ${t('settings.storage')}`, true],
        [t('user.feedback'), true],
        [t('invoices.create_p'), true],
        [t('settings.payment_integration'), true],
        [t('settings.personal_branding'), false]
      ];
    }
    case 'Premium': {
      return [
        [`100 ${t('client.clients')}`, true],
        [`25 GB ${t('settings.storage')}`, true],
        [t('user.feedback'), true],
        [t('invoices.create_p'), true],
        [t('settings.payment_integration'), true],
        [t('settings.personal_branding'), false]
      ];
    }
    case 'Premium Plus': {
      return [
        [t('settings.unlimited_clients'), true],
        [`200 GB ${t('settings.storage')}`, true],
        [t('user.feedback'), true],
        [t('invoices.create_p'), true],
        [t('settings.payment_integration'), true],
        [t('settings.personal_branding'), true]
      ];
    }
    default: return [];
  }
};

const SubscriptionOptions = (plan, t) => GetText(plan, t).map((p, i) => (
  <Stack key={i}>
    <TickBadge active={p[1]} />
    <TextStyle>{p[0]}</TextStyle>
  </Stack>
));

export default SubscriptionOptions;
