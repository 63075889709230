import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, ChoiceList, Form, FormLayout, Modal, TextField, Select, ButtonGroup } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import languages from '../../Shared/Constants/Languages';
import Banners from '../../Shared/Banners';

const UserModalForm = (props) => {
  const [userData, setUserData] = useState({
    email: null,
    firstName: null,
    lastName: null,
    role: '',
    password: '',
    passwordConfirmation: '',
    lang: '',
    plan_id: ''
  });

  const { t } = useTranslation('translation');
  const history = useNavigate();

  const [show, setShow] = useState(true);
  const [mounted] = useState(true);

  const fetchUserData = useCallback(() => {
    if (!props.newUser) {
      axios.get('/v1/users/info')
        .then((response) => {
          if (mounted) {
            const { role, email, firstName, lastName, lang } = response.data;
            setUserData((user) => ({ ...user, role, email, firstName, lastName, lang }));
          }
        })
        .catch(() => {
          console.log('Can\'t load User');
        });
    }
  }, [mounted, props.newUser]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  let roles = null;
  let plans = null;

  const handleChange = (field) => (value) => setUserData({ ...userData, [field]: value });

  const handleRoleChange = () => (value) => setUserData({ ...userData, role: value[0] });

  const handlePlanChange = () => (value) => setUserData({ ...userData, plan_id: value[0] });

  if (props.isAdmin) {
    roles = (
      <FormLayout.Group condensed>
        <ChoiceList
          title="Role"
          choices={[
            { label: t('user.admin'), value: 'admin' },
            { label: t('user.instructor'), value: 'instructor' },
            { label: t('client.client'), value: 'client' }
          ]}
          selected={userData.role}
          onChange={handleRoleChange()}
        />
      </FormLayout.Group>
    );

    plans = (
      <FormLayout.Group condensed>
        <ChoiceList
          title="Plan id"
          choices={[
            { label: 'Basic - year', value: '1' },
            { label: 'Basic - month', value: '2' },
            { label: 'Premium - month', value: '3' },
            { label: 'Premium - year', value: '4' },
            { label: 'Free', value: '5' },
            { label: 'Premium Plus - month', value: '6' },
            { label: 'Premium Plus - year <- for demo', value: '7' }
          ]}
          selected={userData.plan_id}
          onChange={handlePlanChange()}
        />
      </FormLayout.Group>
    );
  }

  const closeModal = () => {
    setUserData({
      role: '',
      email: null,
      firstName: null,
      lastName: null,
      lang: '',
      password: '',
      passwordConfirmation: ''
    });
    setShow(false);
    props.cleanup();
  };

  const onSubmit = () => {
    let user = {
      email: userData.email,
      first_name: userData.firstName,
      last_name: userData.lastName,
      password: userData.password || undefined,
      password_confirmation: userData.passwordConfirmation || undefined,
      lang: userData.lang
    };

    if (props.isAdmin) {
      user = { ...user, role: userData.role, plan_id: userData.plan_id };
    }

    props.handleSubmit(user);
  };

  const editAddresses = () => {
    history('/profile_settings');
    closeModal();
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title={props.title}
    >
      <Modal.Section>
        <Banners banners={props.banner} onDismissBanner={() => props.dismissBanner()} />
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <TextField label={t('user.first_name')} value={userData.firstName} onChange={handleChange('firstName')} />
            <TextField label={t('user.last_name')} value={userData.lastName} onChange={handleChange('lastName')} />
            <TextField label="Email" value={userData.email} onChange={handleChange('email')} />
            <TextField
              label={t('user.password')}
              type="password"
              value={userData.password}
              onChange={handleChange('password')}
              autoComplete="new-password"
            />
            <TextField
              label={t('user.confirm_password')}
              type="password"
              value={userData.passwordConfirmation}
              onChange={handleChange('passwordConfirmation')}
              autoComplete="new-password"
            />
            <Select
              label={t('user.language')}
              value={userData.lang}
              onChange={handleChange('lang')}
              options={languages}
            />
            {roles}
            {plans}
            <ButtonGroup>
              <Button onClick={() => editAddresses()}>{t('address.manage')}</Button>
              <Button primary submit>{t('shared.save')}</Button>
            </ButtonGroup>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin
});

export default connect(mapStateToProps)(UserModalForm);
