/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable quotes */
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from '@shopify/polaris';
import React from 'react';
import CountUp from 'react-countup';

const PnbStats = (props) => (
  <div className="mb-4">
    <dl className={`mt-5 gap-4 flex flex-wrap`}>
      {props.stats.map((item) => (
        <button type="button" onClick={() => props.onClick(item.filter)} key={item.name} className={`flex-1 px-4 py-5 ${item.filter === props.activeFilter ? item.customColor || 'bg-green-200' : 'bg-white'} shadow rounded-lg overflow-hidden sm:p-3 min-w-[8rem]`}>
          <div className="flex">
            <dt className="text-sm flex-1 text-left font-normal text-gray-900">
              {item.name}
            </dt>
            {item.tooltip && (
              <Tooltip content={item.tooltip}>
                <div>
                  <InformationCircleIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                </div>
              </Tooltip>
            )}
          </div>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className={`flex items-baseline text-2xl font-semibold ${item.color || 'text-indigo-600'}`}>
              <CountUp duration={0.5} end={item.stat} decimal={props.decimal} decimals={props.decimals} />
            </div>
          </dd>
        </button>
      ))}
    </dl>
  </div>
);

export default PnbStats;
