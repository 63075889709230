import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Pagination } from '@shopify/polaris';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftMinor, ArrowRightMinor } from '@shopify/polaris-icons';
import classes from './TablePagination.module.scss';
import { searchFilterToURL } from '../FilterEngine';

const TablePagination = ({ pageFilter, lastPage, setTableFilters, records, length = 30, resetOnRefresh = false }) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (resetOnRefresh) {
      searchFilterToURL({ history, location }, 'page', null, 'delete');
    }
  // eslint-disable-next-line
  }, []);

  const setPageFilter = (page) => {
    searchFilterToURL({ history, location }, 'page', page);
    setTableFilters((f) => ({ ...f, page }));
  };

  const setPreviousPage = (page) => {
    if (pageFilter !== 1) {
      setPageFilter(page);
    }
  };

  const setNextPage = (page) => {
    if (records.length === length) {
      setPageFilter(page);
    }
  };

  return (
    <div className={classes.Navigation}>
      <Stack>
        <Button icon={ArrowLeftMinor} onClick={() => setPreviousPage(1)} />
        <Pagination
          label={`${t('shared.page')}: ${pageFilter}/${lastPage || 1}`}
          onPrevious={() => {
            setPreviousPage(pageFilter - 1);
          }}
          hasPrevious
          hasNext
          onNext={() => {
            setNextPage(pageFilter + 1);
          }}
        />
        <Button icon={ArrowRightMinor} onClick={() => setNextPage(lastPage)} />
      </Stack>
    </div>
  );
};

export default TablePagination;
