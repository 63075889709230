/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Select, TextField, Form, FormLayout, TextStyle, Banner, Card, ButtonGroup, Stack, Checkbox, Tooltip, Icon } from '@shopify/polaris';
import axios from 'axios';
import cc from 'currency-codes';
import { useNavigate } from 'react-router-dom';
import { InfoMinor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import Banners from '../../components/Shared/Banners';
import { authCheckState } from '../../store/actions/index';
import Stepper from '../../components/Stepper';
import * as actions from '../../store/actions/index';
import SwitchToggle from '../../components/Shared/SwitchToggle';
import TimezoneSelect from '../../components/Shared/TimezoneSelect/TimezoneSelect';
import languages from '../../components/Shared/Constants/Languages';

const ConfirmProfile = (props) => {
  const [step, setStep] = useState(!props.hide_invoice_option ? 0 : 1);
  const [address, setAddress] = useState({});
  const [banner, setBanner] = useState([]);
  const [currency, setCurrency] = useState('');
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [setupInvoices, setSetupInvoices] = useState(true);
  const [cityErrorMessage, setCityErrorMessage] = useState(null);
  const [addressErrorMessage, setAddressErrorMessage] = useState(null);
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState(null);
  const [taxRateErrorMessage, setTaxRateErrorMessage] = useState(null);
  const [currencyError, setCurrencyError] = useState(null);
  const [taxRate, setTaxRate] = useState('');
  const [taxIncluded, setTaxIncluded] = useState(false);
  const [language, setLanguage] = useState(props.lang);

  const { t } = useTranslation();
  const history = useNavigate();

  const handleAddressChange = (field) => (value) => {
    setAddress({ ...address, [field]: value });
  };

  const stepperData = {
    currentStep: step,
    outerCircleBorderColor: '#03c2fc',
    innerCircleBorderColor: '#02b8f0',
    counterTextColor: '#ccc',
    labelTextColor: '#ccc',
    completedIndicatorColor: '#02b8f0',
    completedTextColor: '#fff',
    connectorColor: '#02b8f0',
    completedLabelColor: '#02b8f0',
    activeIndicator: '#006b8c',
    steps: []
  };

  if (!props.hide_invoice_option) {
    stepperData.steps = [
      {
        title: t('invoices.invoice')
      },
      {
        title: t('shared.time_zone')
      },
      {
        title: t('shared.plan')
      }
    ];
  } else {
    stepperData.steps = [
      {
        title: t('user.language')
      },
      {
        title: t('shared.time_zone')
      },
      {
        title: t('shared.continue')
      }
    ];
  }

  const resetErrorMessages = () => {
    setAddressErrorMessage(null);
    setCityErrorMessage(null);
    setZipCodeErrorMessage(null);
    setCurrencyError(null);
    setTaxRateErrorMessage(null);
  };

  const onLanguageChange = (lang) => {
    props.setLang(lang);
    setLanguage(lang);
  };

  useEffect(() => {
    if (props.isInstructor) {
      handleSubmit('free');
    }
  // eslint-disable-next-line
  }, [props.isInstructor]);

  useEffect(() => {
    if (window.location.host.split('.')[0] !== 'app') {
      handleSubmit('free');
    }
  // eslint-disable-next-line
  }, []);

  const validateForm = () => {
    let errors = false;
    resetErrorMessages();
    if (step === 0 && setupInvoices && !props.hide_invoice_option) {
      if (!address.address) {
        setAddressErrorMessage(t('address.validations.address'));
        errors = true;
      }
      if (!address.city) {
        setCityErrorMessage(t('address.validations.city'));
        errors = true;
      }
      if (!address.zipCode) {
        setZipCodeErrorMessage(t('address.validations.zip_code'));
        errors = true;
      }
      if (!taxRate) {
        setTaxRateErrorMessage(t('shared.validations.required'));
        errors = true;
      }
      if (!currency) {
        setCurrencyError(t('shared.validations.required'));
        errors = true;
      }
    }
    return errors;
  };

  const handleSubmit = (submitType) => {
    if (validateForm()) return;

    const params = {
      user: {
        lang: language,
        has_invoice_access: setupInvoices
      },
      time_zone: typeof timezone === 'string' ? timezone : timezone.value,
      submit_type: submitType,
      signupRole: localStorage.getItem('signupRole')
    };

    if (!props.hide_invoice_option) {
      params.user.saved_addresses_attributes = [{
        label: 'Home',
        preferred: true,
        address_attributes: {
          attention_name: address.attentionName,
          address: address.address,
          address_line_two: address.addressLineTwo,
          city: address.city,
          state: address.state,
          zip_code: address.zipCode
        }
      }];

      params.user.invoice_settings_attributes = {
        tax_rate: taxRate,
        tax_included: taxIncluded,
        currency
      };
    }

    axios.patch(`/v1/new_users/${props.token}`, params)
      .then(() => {
        props.checkAuthState('', history);
      })
      .catch((error) => {
        setBanner([{ title: t('user.unable_to_update'), status: 'critical', details: error.response.data.errors }]);
      });
  };

  const currencies = () => {
    const temp = cc.codes();
    temp.unshift('');
    return temp;
  };

  const handleNext = () => {
    if (validateForm()) return;

    setStep(step + 1);
  };

  return (
    window.location.host.split('.')[0] !== 'app' ? (
      <div style={{ maxWidth: '600px', margin: 'auto', marginTop: '10rem' }}>
        <Card sectioned>
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <Banner title={t('shared.welcome')} status="info">
                <p>{t('onboarding.follow')}</p>
              </Banner>
              {banner.length ? <Banners banners={banner} onDismissBanner={() => setBanner([])} /> : null}
              <Stepper stepperData={stepperData} />
              <br />
              {step === 0 && (
                <FormLayout>
                  {!props.hide_invoice_option && (
                    <Stack wrap={false}>
                      <Stack.Item fill>
                        <TextStyle>{t('onboarding.do_you_want_invoices')}</TextStyle>
                      </Stack.Item>
                      <SwitchToggle
                        handleChange={setSetupInvoices}
                        checked={setupInvoices}
                      />
                    </Stack>
                  )}
                  {setupInvoices && (
                    <>
                      {!props.hide_invoice_option && (
                        <Card.Section title={t('address.address')}>
                          <FormLayout>
                            <Banner title={t('onboarding.why_do_we_need')} status="info">
                              <p>{t('onboarding.invoice')}</p>
                            </Banner>
                            <TextField
                              type="text"
                              placeholder={t('address.address')}
                              value={address.address}
                              onChange={handleAddressChange('address')}
                              error={addressErrorMessage}
                            />
                            <TextField
                              type="text"
                              placeholder={t('address.additional_address_info')}
                              value={address.addressLineTwo}
                              onChange={handleAddressChange('addressLineTwo')}
                              maxLength={25}
                            />
                            <TextField
                              type="text"
                              placeholder={t('address.city')}
                              value={address.city}
                              onChange={handleAddressChange('city')}
                              error={cityErrorMessage}
                            />
                            <TextField
                              type="text"
                              placeholder={t('address.zip_code')}
                              value={address.zipCode}
                              onChange={handleAddressChange('zipCode')}
                              error={zipCodeErrorMessage}
                            />
                          </FormLayout>
                        </Card.Section>
                      )}
                      {props.hide_invoice_option && (
                        <Select
                          label={t('user.language')}
                          value={language}
                          onChange={onLanguageChange}
                          options={languages}
                        />
                      )}
                      {!props.hide_invoice_option && (
                        <Card.Section title="Currency">
                          <FormLayout>
                            <Select
                              options={currencies()}
                              value={currency}
                              onChange={setCurrency}
                              error={currencyError}
                            />
                          </FormLayout>
                        </Card.Section>
                      )}
                      {!props.hide_invoice_option && (
                        <Card.Section title={`${t('invoices.tax')} (%)`}>
                          <Stack>
                            <Stack.Item fill>
                              <TextField
                                value={taxRate}
                                onChange={setTaxRate}
                                type="number"
                                error={taxRateErrorMessage}
                              />
                            </Stack.Item>
                            <Stack.Item>
                              <Checkbox
                                checked={taxIncluded}
                                onChange={setTaxIncluded}
                                label={(
                                  <Stack wrap={false}>
                                    <TextStyle>{t('invoices.incl_tax')}</TextStyle>
                                    <Tooltip content={t('invoices.incl_tax_help')}>
                                      <Icon color="highlight" source={InfoMinor} />
                                    </Tooltip>
                                  </Stack>
                                )}
                              />
                            </Stack.Item>
                          </Stack>
                        </Card.Section>
                      )}
                    </>
                  )}
                </FormLayout>
              )}
              {step === 1 && (
                <Card.Section title={t('shared.time_zone')}>
                  <FormLayout>
                    <TimezoneSelect
                      value={timezone}
                      onChange={setTimezone}
                    />
                  </FormLayout>
                </Card.Section>
              )}
              {step === 2 && (
                props.hide_invoice_option ? (
                  <ButtonGroup fullWidth>
                    <Button primary onClick={() => handleSubmit('free')}>Continue</Button>
                  </ButtonGroup>
                ) : (
                  <ButtonGroup fullWidth>
                    <Button onClick={() => handleSubmit('trial')}>{t('plan.14_day_free_trial')}</Button>
                    <Button primary onClick={() => handleSubmit('plan')}>{t('plan.choose_a_plan')}</Button>
                  </ButtonGroup>
                )
              )}
              <ButtonGroup fullWidth>
                {step !== 0 && <Button onClick={() => setStep(step - 1)}>{t('shared.previous')}</Button>}
                {step !== 2 && <Button primary onClick={() => handleNext()}>{t('shared.next')}</Button>}
              </ButtonGroup>
            </FormLayout>
          </Form>
        </Card>
        <div style={{ textAlign: 'center' }}><TextStyle variation="subdued">{`© ${new Date().getFullYear()} Planubo. All Rights Reserved.`}</TextStyle></div>
      </div>
    ) : ''
  );
};

const mapPropsToDispatch = (dispatch) => ({
  checkAuthState: (url, history) => dispatch(authCheckState(url, history)),
  setLang: (lang) => dispatch(actions.setLang(lang))
});

const mapStateToProps = (state) => ({
  company: state.auth.company,
  lang: state.auth.lang,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  token: state.auth.token,
  isInstructor: state.auth.instructor,
  hide_invoice_option: state.auth.hide_invoice_option,
  sudomain: state.auth.subdomain
});

export default connect(mapStateToProps, mapPropsToDispatch)(ConfirmProfile);
