import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

const AccessDenied = (props) => {
  const tryAgain = () => {
    window.location.reload();
  };

  useEffect(() => {
    props.onTryAutoSignin();
  // eslint-disable-next-line
  }, [props.onTryAutoSignin]);

  return (
    <main className="grid h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600 pb-8">{props.loading ? 'Login in...' : 'Access denied'}</p>
        <button type="button" className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={() => tryAgain()}>Try again</button>
      </div>
    </main>
  );
};

const mapPropsToDispatch = (dispatch) => ({
  onTryAutoSignin: () => dispatch(actions.authCheckState())
});

export default connect(null, mapPropsToDispatch)(AccessDenied);
