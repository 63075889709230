import { Button, Heading, Scrollable, Sheet } from '@shopify/polaris';
import { MobileCancelMajor } from '@shopify/polaris-icons';
import React from 'react';

const PolarisSheet = (props) => (
  <Sheet open={props.active} onClose={props.handleToggle}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <div
        style={{
          alignItems: 'center',
          borderBottom: '1px solid #DFE3E8',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1.6rem',
          width: '100%'
        }}
      >
        <Heading>{props.title}</Heading>
        <Button
          accessibilityLabel="Cancel"
          icon={MobileCancelMajor}
          onClick={props.handleToggle}
          plain
        />
      </div>
      <Scrollable style={{ padding: '1.6rem', height: '100%' }}>
        {props.children}
      </Scrollable>
      <div
        style={{
          alignItems: 'center',
          borderTop: '1px solid #DFE3E8',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1.6rem',
          width: '100%'
        }}
      >
        {props.secondaryAction ? (
          <Button onClick={props.secondaryActionToggle}>{props.secondaryActionTitle}</Button>
        ) : ''}
        {props.showPrimaryAction ? (
          <Button primary loading={props.primaryActionLoading} onClick={props.primaryActionToggle}>{props.primaryActionTitle}</Button>
        ) : ''}
      </div>
    </div>
  </Sheet>
);

export default PolarisSheet;
