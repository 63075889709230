import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormLayout, TextField, TextStyle, Banner, Spinner } from '@shopify/polaris';
import axios from 'axios';
import { connect } from 'react-redux';
import classes from './Auth.module.scss';
import logo from '../../assets/images/planubo-blue.png';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const { t } = useTranslation('translation');

  const handleSubmit = (event) => {
    const data = { email };

    event.preventDefault();
    axios.post('/v1/passwords/send_reset_instructions', data)
      .then(() => {
        setMessage(
          <Banner
            title={t('user.password_reset_sent_shortly')}
            status="success"
          />
        );
        setEmail('');
      })
      .catch(() => {
        setMessage(<Banner title={t('user.unable_to_send_passwort_reset')} status="critical" />);
        setEmail('');
      });
  };

  const handleEmailChange = useCallback((value) => setEmail(value), []);

  const emailField = (
    <TextField
      value={email}
      onChange={handleEmailChange}
      placeholder={t('user.email')}
      type="email"
    />
  );

  return (
    <div className={classes.Auth}>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <div className="flex justify-center">
            {!props.loadingLogo ? <img src={props.brand?.loginLogoUrl || logo} alt="planubo" /> : (
              <Spinner />
            )}
          </div>
          {message}
          {emailField}
          <Button primary submit>{t('user.password_reset')}</Button>
          <Button plain url="/">{t('user.sign_in')}</Button>
          <TextStyle variation="subdued">© 2024 Planubo. All Rights Reserved.</TextStyle>
        </FormLayout>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  brand: state.auth.brand,
  loading: state.auth.loading,
  loadingLogo: state.auth.loadingLogo
});

export default connect(mapStateToProps)(ForgotPassword);
