import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  ContextualSaveBar, Page, Layout, Card, Stack, TextStyle, Select, FormLayout,
  Button, Form, Toast, TextField, DropZone, Thumbnail, Checkbox, Tooltip, Icon
} from '@shopify/polaris';
import cc from 'currency-codes';
import { InfoMinor } from '@shopify/polaris-icons';
import Banners from '../Shared/Banners';
import TinymceEditor from '../Tinymce/TinymceEditor';

const InvoiceSettings = (props) => {
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceSettings, setInvoiceSettings] = useState({
    footer: '',
    generalInfo: '',
    taxRate: '',
    taxIncluded: true,
    currency: 'EUR'
  });
  const [banner, setBanner] = useState([]);
  const [invoiceLogo, setInvoiceLogo] = useState(null);
  const [label, setLabel] = useState('');
  const { t } = useTranslation();
  const history = useNavigate();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const canAccessInvoices = () => {
    if (props.isBasicMember || props.isPremiumMember || props.isPremiumPlusMember) {
      if (!props.hasInvoiceAccess) return false;

      return true;
    }

    return false;
  };

  const handleInvoiceSettingsChange = (field) => (value) => {
    setShowSaveBar(true);
    setInvoiceSettings({ ...invoiceSettings, [field]: value });
  };

  const updateSettings = () => {
    setLoading(true);

    const formData = new FormData();

    if (invoiceSettings.footer || invoiceSettings.footer === '') formData.append('invoice_settings[footer]', invoiceSettings.footer);
    if (invoiceSettings.generalInfo || invoiceSettings.generalInfo === '') formData.append('invoice_settings[general_info]', invoiceSettings.generalInfo);
    if (invoiceSettings.taxRate) formData.append('invoice_settings[tax_rate]', invoiceSettings.taxRate);
    formData.append('invoice_settings[tax_included]', invoiceSettings.taxIncluded);
    if (invoiceSettings.currency) formData.append('invoice_settings[currency]', invoiceSettings.currency);

    if (invoiceLogo?.size > 0) {
      formData.append('invoice_settings[logo]', invoiceLogo);
    }

    if (!invoiceLogo) {
      formData.append('remove_logo', true);
    }

    axios.patch('/v1/settings', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        toggleToastActive(t('shared.saved'));
        setInvoiceSettings(response.data.invoiceSettings);
        if (response.data.invoiceSettings.logo) setInvoiceLogo(new File([''], response.data.invoiceSettings.logo));
        setLoading(false);
        setShowSaveBar(false);
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
        setLoading(false);
      });
  };

  const fetchSettings = useCallback(() => {
    axios.get('/v1/settings')
      .then((response) => {
        setInit(true);
        setInvoiceSettings(response.data.invoiceSettings);
        if (response.data.invoiceSettings.logo) setInvoiceLogo(new File([''], response.data.invoiceSettings.logo));
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
    } else {
      if (label === '') {
        setLabel(acceptedFiles[0].name);
      }
      setShowSaveBar(true);
      setInvoiceLogo(acceptedFiles[0]);
    }
  }, [label, t]);
  const resetLogo = () => {
    setShowSaveBar(true);
    setInvoiceLogo(null);
  };
  const uploadedFile = invoiceLogo && (
    <Stack alignment="center">
      {invoiceSettings.logo === invoiceLogo.name ? <Thumbnail size="large" name="invoice_logo" source={invoiceSettings.logoURL} /> : ''}
      {invoiceLogo.name ? <div>{`${t('settings.selected_file')}: ${invoiceLogo.name}`}</div> : ''}
      <Button plain onClick={() => resetLogo()}>{t('shared.change')}</Button>
    </Stack>
  );

  const settingsForm = (
    <Form onSubmit={updateSettings}>
      <FormLayout>
        <Layout>
          <Layout.AnnotatedSection
            title={t('invoices.invoices')}
            description={t('invoices.manage')}
          >
            {canAccessInvoices() ? (
              <Card sectioned>
                <Banners banners={banner} onDismissBanner={() => setBanner([])} />
                <Card.Section title={t('invoices.logo')}>
                  {!invoiceLogo ? (
                    <DropZone label={t('invoices.logo')} type="image" accept="image/*" allowMultiple={false} onDrop={handleDropZoneDrop}>
                      <DropZone.FileUpload />
                    </DropZone>
                  ) : uploadedFile}
                </Card.Section>
                <Card.Section title={t('payments.currency')}>
                  <Select
                    options={cc.codes()}
                    value={invoiceSettings.currency}
                    onChange={handleInvoiceSettingsChange('currency')}
                  />
                </Card.Section>
                <Card.Section title={`${t('invoices.tax')} (%)`}>
                  <Stack>
                    <Stack.Item fill>
                      <TextField
                        value={invoiceSettings.taxRate}
                        onChange={handleInvoiceSettingsChange('taxRate')}
                        type="number"
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Checkbox
                        checked={invoiceSettings.taxIncluded}
                        onChange={handleInvoiceSettingsChange('taxIncluded')}
                        label={(
                          <Stack wrap={false}>
                            <TextStyle>{t('invoices.incl_tax')}</TextStyle>
                            <Tooltip content={t('invoices.incl_tax_help')}>
                              <Icon color="highlight" source={InfoMinor} />
                            </Tooltip>
                          </Stack>
                        )}
                      />
                    </Stack.Item>
                  </Stack>
                </Card.Section>
                <Card.Section title={t('invoices.general_info')}>
                  <TextStyle variation="subdued">{t('invoices.general_info_description')}</TextStyle>
                  <TextField
                    value={invoiceSettings.generalInfo}
                    onChange={handleInvoiceSettingsChange('generalInfo')}
                    multiline={2}
                  />
                </Card.Section>
                <Card.Section title={t('invoices.footer')}>
                  <TextStyle variation="subdued">{t('invoices.footer_description')}</TextStyle>
                  <TinymceEditor
                    value={invoiceSettings.footer}
                    handleChange={handleInvoiceSettingsChange('footer')}
                  />
                </Card.Section>
              </Card>
            ) : (
              <Card sectioned>
                <Card.Section>
                  <Stack alignment="center">
                    <Stack.Item fill>{t('plan.upgrade_plan_to_access_invoices')}</Stack.Item>
                    <Button onClick={() => history('/plans')}>Upgrade</Button>
                  </Stack>
                </Card.Section>
              </Card>
            )}
          </Layout.AnnotatedSection>
        </Layout>
      </FormLayout>
    </Form>
  );

  return (
    <Page
      title={t('navigation.invoice_settings')}
      separator
    >
      {showSaveBar && (
        <ContextualSaveBar
          message={t('shared.unsaved_changes')}
          saveAction={{
            onAction: () => updateSettings(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      {toastMarkup}
      {init && settingsForm}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  lang: state.auth.lang,
  isFreeMember: state.auth.freeMember,
  isBasicMember: state.auth.basicMember,
  isPremiumMember: state.auth.premiumMember,
  isPremiumPlusMember: state.auth.premiumPlusMember,
  hasInvoiceAccess: state.auth.hasInvoiceAccess
});

export default connect(mapStateToProps)(InvoiceSettings);
