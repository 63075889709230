/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import FillOutForm from '../../components/Surveys/FillOutForm';
import { authCheckState } from '../../store/actions';

const PerformWorkflow = (props) => {
  const [workflows, setWorkflows] = useState([]);
  const [workflow, setWorkflow] = useState({});
  const [formId, setFormId] = useState('');
  const [hasForm, setHasForm] = useState(true);

  const perform = () => {
    switch (workflow.current_type) {
      case 'Form': {
        setFormId(workflow.current_node.data.selects.form);

        break;
      }
      default: {
        // formCallback(true);
        // setHasForm(false);

        break;
      }
    }
  };

  const fetchWorkflows = useCallback(() => {
    axios.post('/v1/workflow_instances/search')
      .then((response) => {
        setWorkflows(response.data.workflows);
        setWorkflow(response.data.workflows[0]);
      })
      .catch(() => {});
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  useEffect(() => {
    if (workflows.length) {
      perform();
    }
  // eslint-disable-next-line
  }, [workflows]);

  const formCallback = (done) => {
    if (done) {
      // call workflow endpoint and go to next node
      axios.post(`/v1/workflow_instances/${workflow.token}/progress`)
        .then(() => {
          fetchWorkflows();
          props.checkAuthState();
          perform();
        });
    }
  };

  const formRenderer = (
    formId && <FillOutForm token={formId} callback={formCallback} />
  );

  return (
    props.show && workflows.length && hasForm ? (
      formRenderer
    ) : props.children
  );
};

const mapPropsToDispatch = (dispatch) => ({ checkAuthState: () => dispatch(authCheckState()) });

export default connect(null, mapPropsToDispatch)(PerformWorkflow);
