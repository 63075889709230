import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinymceEditor = (props) => {
  const editorRef = useRef(null);

  return (
    <Editor
      disabled={props.disabled}
      apiKey="4fchh7rmekdi9qxzupnb30jf0lf9gpivynzhqwpse0vscfx4"
      onInit={(evt, editor) => { editorRef.current = editor; }}
      initialValue={props.initialValue}
      value={props.value}
      onEditorChange={props.handleChange}
      init={{
        height: props.height || 500,
        toolbar_mode: 'wrap',
        menubar: 'insert',
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen media',
          'insertdatetime media table paste code image'
        ],
        toolbar: 'undo redo | formatselect | fontselect | fontsizeselect |'
        + 'bold italic forecolor backcolor | alignleft aligncenter '
        + 'alignright alignjustify | bullist numlist outdent indent image | '
        + 'removeformat | table | code'
      }}
    />
  );
};

export default TinymceEditor;
