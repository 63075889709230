import React, { useEffect } from 'react';

const ZoomDocumentation = () => {
  useEffect(() => {
    window.location.href = 'https://www.notion.so/Integrations-9072c2d461a64fb89b9018e37c152ead%234a28d0a6ddf94719b5c86b116079bdf4';
  }, []);

  return (
    <div className="mt-8 ml-8">
      <h2>Redirect to Planubo Zoom Docs in progress...</h2>
    </div>
  );
};

export default ZoomDocumentation;
