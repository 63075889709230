/* eslint-disable no-unused-vars */
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BookOpenIcon, CalculatorIcon, CalendarIcon, CogIcon, HomeIcon, InformationCircleIcon, BuildingLibraryIcon, UserCircleIcon, UserGroupIcon, UsersIcon } from '@heroicons/react/20/solid';
import { BanknotesIcon, ClipboardDocumentListIcon, ClipboardDocumentCheckIcon, EnvelopeIcon, GiftIcon, CircleStackIcon, SunIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import Menu from './Menu/Menu';

const InstructorNavigation = (props) => {
  const { t } = useTranslation('translation');
  const location = useLocation();

  const activeRoute = (route, exact = false) => {
    const path = location.pathname.split('/')[1];

    if (exact) return path === route;

    return route.includes(path);
  };

  const canAccessInvoices = () => {
    if (props.hasInvoiceAccess) return true;

    return false;
  };

  const openDocumentation = () => {
    window.open(t('shared.help_doc'));
  };

  const settingsItems = [
    {
      url: '/profile_settings',
      label: t('navigation.profile'),
      selected: activeRoute('profile_settings'),
      condition: true
    },
    {
      url: '/client_settings',
      label: t('navigation.client_settings'),
      selected: activeRoute('client_settings'),
      condition: true
    },
    {
      url: '/calendar_settings',
      label: t('navigation.calendar_settings'),
      selected: activeRoute('calendar_settings'),
      condition: true
    },
    {
      url: '/invoice_settings',
      label: t('navigation.invoice_settings'),
      selected: activeRoute('invoice_settings'),
      condition: !props.isFreeMember && props.hasInvoiceAccess
    },
    {
      url: '/custom_settings',
      label: t('custom_domain.custom_settings'),
      selected: activeRoute('custom_settings'),
      condition: props.isPremiumPlusMember && !props.isStaff
    },
    {
      url: '/email_settings',
      label: t('email_settings.main'),
      selected: activeRoute('email_settings'),
      condition: !props.isFreeMember && !props.isStaff
    },
    {
      url: '/integrations',
      label: t('navigation.integrations'),
      selected: activeRoute('integrations'),
      condition: true
    },
    {
      url: '/plans',
      label: t('navigation.plans'),
      selected: activeRoute('plans'),
      condition: !props.isStaff
    }
  ];

  const publicProfileItems = [];

  if (!props.isStaff || (props.isStaff && props.bookingPageAccess)) {
    publicProfileItems.push({
      url: '/public_profile_edit',
      label: t('public_profile.edit_booking_portal'),
      selected: activeRoute('public_profile_edit'),
      condition: true
    });
  }

  publicProfileItems.push({
    url: '/products',
    label: t('products.singular'),
    selected: activeRoute('products'),
    condition: true
  });

  if (!props.isStaff || (props.isStaff && props.bookingPageAccess)) {
    publicProfileItems.push({
      url: '/program_spaces',
      label: t('public_profile.programs'),
      selected: activeRoute('program_spaces'),
      condition: true
    });
  }

  const navigation = [
    {
      label: 'Home',
      icon: HomeIcon,
      selected: activeRoute('home'),
      url: '/home',
      condition: true
    },
    {
      label: t('navigation.calendar'),
      icon: CalendarIcon,
      selected: activeRoute('calendar'),
      url: '/calendar',
      condition: true,
      boarderTop: true
    },
    {
      label: t('public_profile.portal'),
      icon: BookOpenIcon,
      selected: publicProfileItems.some((h) => h.url.split('/')[1] === location.pathname.split('/')[1]),
      children: publicProfileItems,
      condition: true
    },
    {
      label: t('calendar.events'),
      icon: UserGroupIcon,
      selected: activeRoute('events'),
      url: '/events',
      condition: true
    },
    {
      label: t('media_library.title'),
      icon: BuildingLibraryIcon,
      selected: activeRoute('media_library'),
      url: '/media_library',
      condition: true
    },
    // {
    //   label: 'Messages',
    //   icon: EnvelopeIcon,
    //   selected: activeRoute('messages'),
    //   url: '/messages',
    //   condition: true,
    //   new: true
    // },
    {
      label: t('survey.survey'),
      icon: ClipboardDocumentListIcon,
      selected: activeRoute('surveys', true),
      url: '/surveys',
      condition: true
    },
    {
      label: t('survey.filled_out_forms'),
      icon: ClipboardDocumentCheckIcon,
      selected: activeRoute('answered_surveys', true),
      url: '/answered_surveys',
      condition: props.isStaff
    },
    {
      label: t('invoices.invoices'),
      icon: CalculatorIcon,
      selected: activeRoute('invoices'),
      condition: !props.isFreeMember && props.hasInvoiceAccess,
      boarderTop: true,
      url: '/invoices'
    },
    {
      label: t('gift_cards.name'),
      icon: GiftIcon,
      selected: activeRoute('gift_cards'),
      condition: !props.isFreeMember && props.hasInvoiceAccess && !props.isStaff,
      url: '/gift_cards'
    },
    {
      label: t('eventpackages.event_packages'),
      icon: CircleStackIcon,
      selected: activeRoute('event_packages'),
      condition: !props.isFreeMember && props.hasInvoiceAccess && !props.isStaff,
      url: '/event_packages'
    },
    // {
    //   label: t('memberships.memberships'),
    //   icon: PencilSquareIcon,
    //   selected: activeRoute('subscriptions'),
    //   url: '/subscriptions',
    //   condition: true
    // },
    {
      label: t('navigation.clients'),
      icon: UserCircleIcon,
      selected: activeRoute('clients'),
      url: '/clients',
      condition: true,
      boarderTop: true
    },
    {
      label: t('staff-accounts'),
      icon: UsersIcon,
      selected: activeRoute('staff_accounts'),
      url: '/staff_accounts',
      condition: !props.isStaff
    },
    {
      label: t('accountant.singular'),
      icon: BanknotesIcon,
      selected: activeRoute('accountants'),
      url: '/accountants',
      condition: canAccessInvoices()
    },
    {
      label: t('calendar.time_off'),
      selected: activeRoute('time_off'),
      boarderTop: true,
      icon: SunIcon,
      url: '/time_off',
      condition: true
    },
    {
      label: t('settings.settings'),
      selected: settingsItems.some((h) => h.url.split('/')[1] === location.pathname.split('/')[1]),
      children: settingsItems,
      icon: CogIcon,
      condition: true
    },
    {
      label: t('help.help_center'),
      selected: ['help'].some((h) => h === location.pathname.split('/')[1]),
      condition: true,
      icon: InformationCircleIcon,
      children: [
        {
          url: '/help',
          label: t('help.help'),
          selected: activeRoute('help'),
          condition: true
        },
        {
          label: t('help.documentation'),
          onClick: () => openDocumentation(),
          condition: true
        }
      ]
    }
  ];

  return (
    <Menu toggle={props.toggle} navigation={navigation} />
  );
};

const mapStateToProps = (state) => ({
  isFreeMember: state.auth.freeMember,
  isBasicMember: state.auth.basicMember,
  isPremiumMember: state.auth.premiumMember,
  isPremiumPlusMember: state.auth.premiumPlusMember,
  isStaff: state.auth.role === 'staff',
  bookingPageAccess: state.auth.bookingPageAccess,
  unread: state.auth.unread,
  userUrlName: state.auth.urlName,
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  hasStripe: state.auth.hasStripe
});

export default connect(mapStateToProps)(InstructorNavigation);
