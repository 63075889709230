/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Modal } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import Banners from '../Shared/Banners';
import StackedCards from '../Tailwind/StackedCards';

const AddMembershipModal = (props) => {
  const { t } = useTranslation('translation');
  const matchParams = useParams();

  const [memberships, setMemberships] = useState([]);
  const [banner, setBanner] = useState([]);
  const [selection, setSelection] = useState(null);

  const fetchMembershipsData = useCallback(() => {
    axios.get(`/v1/clients/${matchParams.id}/available_memberships`)
      .then((response) => {
        setMemberships(response.data.memberships);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response?.data?.error }]);
      });
  }, [matchParams.id, t]);

  useEffect(() => {
    fetchMembershipsData();
  }, [fetchMembershipsData]);

  const onSubmit = () => {
    const params = {
      membership: selection
    };

    props.handleSubmit(params);
  };

  return (
    <Modal
      open={props.show}
      onClose={props.close}
      title={props.title}
    >
      <Modal.Section>
        <Banners banners={props.banner || banner} onDismissBanner={() => props.dismissBanner()} />
        <StackedCards data={memberships} setSelection={setSelection} selection={selection} />
      </Modal.Section>
      <Modal.Section>
        <Button fullWidth primary onClick={onSubmit}>{t('client.add_to_client')}</Button>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin
});

export default connect(mapStateToProps)(AddMembershipModal);
