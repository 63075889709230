import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Clients from '../components/Clients/Clients';
import Calendar from '../components/Calendar/Calendar';
import Client from '../components/Clients/Client';
import ClientCalendar from '../components/Calendar/Client/Client';
import Event from '../components/Events/Event';
import ParticipationManagement from '../components/Participations/ParticipationManagement';
import Profile from '../components/Settings/Profile';
import Invoices from '../components/Invoices/Invoices';
import NewInvoice from '../components/Invoices/NewInvoice';
import Home from '../components/Home/Home';
import ClientEvents from '../components/Clients/ClientEvents';
// import ClientConversation from '../components/Clients/ClientConversation';
// import ClientIndividualConversation from '../components/Clients/ClientIndividualConversation';
import Users from '../components/Users/Users';
import User from '../components/Users/User';
import Payments from '../components/Payments/Payments';
import CreateInvoice from '../components/Invoices/CreateInvoice';
import AutoCreateInvoice from '../components/Invoices/AutoCreateInvoice';
import Help from '../components/Help/Help';
import PlanSettings from '../components/Settings/PlanSettings';
import ClientSettings from '../components/Settings/ClientSettings';
import EmailSettings from '../components/Settings/EmailSettings';
import CustomDomain from '../components/Settings/CustomDomain';
import CalendarSettings from '../components/Settings/CalendarSettings';
import Integrations from '../components/Settings/Integrations';
import InvoiceSettings from '../components/Settings/InvoiceSettings';
import PublicProfileEdit from '../components/PublicProfile/PublicProfileEdit';
import ReadOnly from '../components/PublicProfile/ReadOnly';
import Product from '../components/Products/Product';
import PublicProduct from '../components/Products/PublicProduct';
import Events from '../components/Events/Events';
import ProductBooking from '../components/ProductBookings/ProductBooking';
import StaffAccounts from '../components/StaffAccounts/StaffAccounts';
import StaffAccount from '../components/StaffAccounts/StaffAccount';
import Products from '../components/Products/Products';
import Documentation from './Documentation';
import ZoomDocumentation from './ZoomDocumentation';
import MediaLibrary from '../components/MediaLibrary/MediaLibrary';
import Google from '../components/Settings/Integrations/Google';
import NewProduct from '../components/Products/NewProduct';
import Membership from '../components/Products/Membership';
import Memberships from '../components/Products/Memberships';
import Accountants from '../components/Accountants/Accountants';
import Subscriptions from '../components/Subscriptions/Subscriptions';
import PublicBooking from '../components/Products/PublicBooking';
import Surveys from '../components/Surveys/Surveys';
import Survey from '../components/Surveys/Survey';
import Replies from '../components/Surveys/Replies';
import FillOutForm from '../components/Surveys/FillOutForm';
import ForwardBookingPortal from '../components/Shared/ForwardBookingPortal';

const AdminRoutes = () => (
  <Routes>
    <Route exact path="/home" element={<Home />} />
    <Route exact path="/calendar" element={<Calendar />} />
    <Route exact path="/clients" element={<Clients />} />
    <Route exact path="/clients/:id" element={<Client />} />
    <Route exact path="/clients/:id/events" element={<ClientEvents />} />
    <Route exact path="/custom_settings" element={<CustomDomain />} />
    <Route exact path="/instructors/:id" element={<ClientCalendar />} />
    {/* <Route exact path="/clients/:id/feedback" element={<ClientConversation />} /> */}
    {/* <Route exact path="/clients/:id/feedback/:feedbackId" element={<ClientIndividualConversation />} /> */}
    <Route exact path="/email_settings" element={<EmailSettings />} />
    <Route exact path="/events" element={<Events />} />
    <Route exact path="/events/:id" element={<Event />} />
    <Route exact path="/events/:id/participation_management" element={<ParticipationManagement />} />
    <Route exact path="/help" element={<Help />} />
    <Route exact path="/client_settings" element={<ClientSettings />} />
    <Route exact path="/invoices" element={<Invoices />} />
    <Route exact path="/invoices/auto" element={<AutoCreateInvoice />} />
    <Route exact path="/invoices/create" element={<CreateInvoice />} />
    <Route exact path="/invoices/:id" element={<NewInvoice />} />
    <Route exact path="/users" element={<Users />} />
    <Route exact path="/users/:id" element={<User />} />
    <Route exact path="/integrations" element={<Integrations />} />
    <Route exact path="/accountants" element={<Accountants />} />
    <Route exact path="/integrations/google" element={<Google />} />
    <Route exact path="/v1/zoom" element={<Integrations />} />
    <Route exact path="/staff_accounts" element={<StaffAccounts />} />
    <Route exact path="/staffs/:token" element={<StaffAccount />} />
    <Route exact path="/invoice_settings" element={<InvoiceSettings />} />
    <Route exact path="/calendar_settings" element={<CalendarSettings />} />
    <Route exact path="/payments" element={<Payments />} />
    <Route exact path="/surveys" element={<Surveys />} />
    <Route exact path="/surveys/:id" element={<Survey />} />
    <Route exact path="/surveys" element={<Surveys />} />
    <Route exact path="/surveys/:id/replies" element={<Replies />} />
    <Route exact path="/survey_reply/:token" element={<FillOutForm />} />
    <Route exact path="/profile_settings" element={<Profile />} />
    <Route exact path="/public_profile_edit" element={<PublicProfileEdit />} />
    <Route exact path="/plans" element={<PlanSettings />} />
    <Route exact path="/products" element={<Products />} />
    <Route exact path="/memberships" element={<Memberships />} />
    <Route exact path="/memberships/new" element={<Membership />} />
    <Route exact path="/memberships/:token" element={<Membership />} />
    <Route exact path="/products/new" element={<NewProduct />} />
    <Route exact path="/products/:token" element={<Product />} />
    <Route exact path="/product_booking/:token" element={<ProductBooking />} />
    <Route exact path="/embeddedbooking/:token" element={<PublicProduct />} />
    <Route exact path="/subscriptions" element={<Subscriptions />} />
    <Route exact path="/booking/:name" element={<ReadOnly />} />
    <Route exact path="/manage_booking/:token" element={<PublicBooking />} />
    <Route exact path="/documentation" element={<Documentation />} />
    <Route exact path="/zoom_documentation" element={<ZoomDocumentation />} />
    <Route exact path="/media_library" element={<MediaLibrary />} />
    <Route exact path="/my_portal" element={<ForwardBookingPortal />} />
    <Route path="*" element={<Navigate to="/home" />} />
  </Routes>
);

export default AdminRoutes;
