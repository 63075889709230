/* eslint-disable */
import React, { useRef } from 'react';
import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';

// import { droppable } from './droppable-svg';
import styles from './Droppable.module.scss';

export const Droppable = ({ children, id, dragging, handleSingleClick, handleDoubleClick, item }) => {
  const numClicks = useRef(0);
  const singleClickTimer = useRef();

  const handleClick = () => {
    numClicks.current++;

    if (numClicks.current === 1) {
      singleClickTimer.current = setTimeout(() => {
        numClicks.current = 0;
        handleSingleClick(item);
      }, 400);
    } else if (numClicks.current === 2) {
      clearTimeout(singleClickTimer.current);
      numClicks.current = 0;
      handleDoubleClick(item);
    }
  };

  const { isOver, setNodeRef } = useDroppable({
    id
  });

  return (
    <div
      role="button"
      onKeyDown={() => {}}
      tabIndex={0}
      onClick={handleClick}
      ref={setNodeRef}
      className={classNames(
        isOver && styles.over,
        dragging && styles.dragging,
        children && styles.dropped
      )}
      aria-label="Droppable region"
    >
      {children}
      {/* {droppable} */}
    </div>
  );
};
