import React from 'react';
import { Select } from '@shopify/polaris';
import { handleFilterChange } from '../FilterEngine';

const clientFilterDefinition = (t, props, setTableFilters, options, selected) => ({
  key: 'client',
  label: t('client.client'),
  filter: (
    <Select
      label={t('client.client')}
      labelHidden
      options={options}
      value={selected}
      onChange={handleFilterChange(props, setTableFilters, 'client')}
    />
  ),
  shortcut: false
});

export default clientFilterDefinition;
