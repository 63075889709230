import React from 'react';
import { FormLayout, TextField } from '@shopify/polaris';

const InvoiceAddress = (props) => (
  <FormLayout>
    <TextField
      type="text"
      value={props.address}
      onChange={props.handleChange('address')}
      multiline={3}
    />
  </FormLayout>
);

export default InvoiceAddress;
