/* eslint-disable */
import { Tooltip } from '@shopify/polaris';
import React, { useEffect, useRef, useState } from 'react';
import PnbBadge from '../Tailwind/PnbBadge';

const Badges = (props) => {
  let color = '';

  if (props.textOnly) {
    const badges = props.data.map((object) => (
      object.label
    )).join(' | ');

    return <div className={`whitespace-normal ${props.class}`}>{badges}</div>;
  }

  switch (props.status) {
    case 'info': {
      color = 'bg-blue-400 text-white';
      break;
    }
    default: color = '';
  }

  const badges = props.data.map((object) => (
    <div className="p-0.5"><PnbBadge color={color || props.color} key={object.id || object.label} title={object.label} /></div>
  ));

  return <div className="flex flex-wrap justify-between">{badges}</div>;
};

export default Badges;
