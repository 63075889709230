import React, { useCallback, useState } from 'react';
import { Banner, Caption, DropZone, List, Stack, Thumbnail } from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import formatBytes from '../Shared/formatBytes';

const Upload = (props) => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [rejetedFilesMap, setRejectedFilesMap] = useState([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, rejectedFiles) => {
      props.setFiles((f) => [...f, ...acceptedFiles]);
      setRejectedFilesMap(rejectedFiles);
    },
    // eslint-disable-next-line
    [props.setFiles]
  );

  const validator = (file) => {
    setError(false);

    const fileSizeMB = (file.size / 1024 / 1024).toFixed(2);

    if (fileSizeMB > 1024) {
      setError(true);
      return false;
    }

    return true;
  };

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

  const fileUpload = !props.files.length && props.showPreview && (
    <DropZone.FileUpload actionHint={t('media_library.file_upload.max_size')} />
  );

  const uploadedFiles = props.files.length > 0 && props.showPreview && (
    <Stack vertical>
      {props.files.map((file, index) => (
        <Stack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}
            {' '}
            <Caption>
              {formatBytes(file.size)}
            </Caption>
          </div>
        </Stack>
      ))}
    </Stack>
  );

  const errorMessage = error && (
    <Banner
      title={t('media_library.file_upload.title')}
      status="critical"
    >
      <List type="bullet">
        {rejetedFilesMap.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" - ${t('media_library.file_upload.max_size')}`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <Stack vertical>
      {errorMessage}
      <DropZone
        onDrop={handleDropZoneDrop}
        variableHeight
        outline={props.outline}
        onClick={props.onClick}
        customValidator={validator}
      >
        {props.children}
        {uploadedFiles}
        {fileUpload}
      </DropZone>
    </Stack>
  );
};

export default Upload;
