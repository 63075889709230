import React from 'react';
import { Icon } from '@shopify/polaris';
import { DeleteMajor } from '@shopify/polaris-icons';
import styles from './LineItem.module.scss';

const LineItem = (props) => {
  const { index, name, description, quantity, price } = props;

  return (
    <div className={styles.lineItem}>
      <div>{index + 1}</div>
      <div><input name="name" type="text" value={name} onChange={props.changeHandler(index)} /></div>
      <div><input name="description" type="text" value={description} onChange={props.changeHandler(index)} /></div>
      <div><input name="quantity" type="number" step="1" value={quantity} onChange={props.changeHandler(index)} onFocus={props.focusHandler} /></div>
      <div className={styles.currency}>
        <input name="price" type="number" step="0.01" min="0.00" max="9999999.99" value={price} onChange={props.changeHandler(index)} onFocus={props.focusHandler} />
      </div>
      <div className={styles.currency}>{props.currencyFormatter(quantity * price)}</div>
      <div>
        <button
          type="button"
          className={styles.deleteItem}
          onClick={props.deleteHandler(index)}
        >
          <Icon source={DeleteMajor} />
        </button>
      </div>
    </div>
  );
};

export default LineItem;
