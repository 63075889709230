import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { SearchMinor } from '@shopify/polaris-icons';
import { Banner, Button, Card, DatePicker, FormLayout, Icon, OptionList, Page, Spinner, Stack, TextStyle, Toast } from '@shopify/polaris';
// eslint-disable-next-line
import { Document, Page as PdfPage, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// import Viewer from 'react-viewer';

import Search from '../Shared/Search';
import SwitchToggle from '../Shared/SwitchToggle';
import PolarisSheet from '../Shared/PolarisSheet';
import Banners from '../Shared/Banners';

const today = new Date();

const AutoCreateInvoice = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tempPreviewLoading, setTempPreviewLoading] = useState(true);
  const [handleCreateLoading, setHandleCreateLoading] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [emailInvoices, setEmailInvoices] = useState(false);
  const [date, setDate] = useState({ month: today.getMonth(), year: today.getFullYear() });
  const [selectedDates, setSelectedDates] = useState(null);
  const [sheetActive, setSheetActive] = useState(false);
  const [tempPreviewInvoices, setTempPreviewInvoices] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [invoiceLoadingBanner, setInvoiceLoadingBanner] = useState([]);

  const { t } = useTranslation();
  const history = useNavigate();

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url
  ).toString();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  // eslint-disable-next-line
  const toggleSheetActive = useCallback(() => setSheetActive(!sheetActive));

  const handleMonthChange = useCallback((m, y) => {
    setDate({ month: m, year: y });
  }, []);

  const fetchClients = useCallback(() => {
    const params = {
      per_page: 1000,
      page: 1,
      search: '',
      q: ''
    };

    axios.post('/v1/clients/invoice_recipient', params)
      .then((res) => {
        setClients(res.data);
      })
      .catch(() => {})
      .then(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const handleCreate = () => {
    setHandleCreateLoading(true);

    const params = {
      send_email_notifications: emailInvoices
    };

    axios.post('/v1/invoices/auto_create_done', params)
      .then(() => {
        toggleToastActive(t('invoices.created'));
        setTimeout(() => {
          history('/invoices');
        }, 2000);
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      })
      .then(() => {
        setHandleCreateLoading(false);
      });
  };

  const fetchTempInvoices = useCallback(() => {
    axios.get('/v1/invoices/temp')
      .then((response) => {
        if (response.data.invoices.some((el) => el.fileUrl === null)) {
          setInvoiceLoadingBanner([{ title: t('invoices.preload'), status: 'critical' }]);
          setTimeout(() => {
            fetchTempInvoices();
            setInvoiceLoadingBanner([]);
          }, [3000]);
        }
        const temp = response.data.invoices.map((invoice) => ({ src: invoice.fileUrl, alt: invoice.clientName }));
        setInvoices(temp);
        setShowPreview(true);
        toggleSheetActive();
      })
      .catch(() => {
      });
  }, [t, toggleSheetActive]);

  const handleSubmit = () => {
    setSubmitLoading(true);

    const params = {
      id: selectedClients
    };

    if (selectedDates && Object.keys(selectedDates).length) {
      params.q = {
        start_time_gteq: moment(selectedDates.start).format('YYYY-MM-DD'),
        end_time_lteq: moment(selectedDates.end).format('YYYY-MM-DD')
      };
      params.s = {
        event_start_time_gteq: moment(selectedDates.start).format('YYYY-MM-DD'),
        event_end_time_lteq: moment(selectedDates.end).format('YYYY-MM-DD')
      };
    }

    axios.post('/v1/invoices/auto_create_start', params)
      .then(() => {
        toggleSheetActive();
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      })
      .then(() => {
        setSubmitLoading(false);
      });
  };

  const fetchTempPreview = useCallback(() => {
    setTempPreviewLoading(true);

    axios.get('/v1/invoices/temp_preview')
      .then((response) => {
        setTempPreviewInvoices({
          count: response.data.totalInvoices,
          createdAt: moment(new Date(response.data.createdAt)).format('LL')
        });
      })
      .catch(() => {
      })
      .then(() => {
        setTempPreviewLoading(false);
      });
  }, []);

  const clearTempInvoices = () => {
    if (!window.confirm(`${t('shared.are_you_sure')} ${t('invoices.temporary')}`)) {
      return;
    }

    axios.post('/v1/invoices/discard_temp')
      .then(() => {
        setTempPreviewInvoices({});
        toggleToastActive(t('shared.success'));
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  useEffect(() => {
    if (sheetActive) {
      fetchTempPreview();
    }
  }, [sheetActive, fetchTempPreview]);

  const setMultiClients = (data) => {
    setSelectedClients(data);
    setShowSave(true);
  };

  const selectAllClients = () => {
    const temp = [];
    setShowSave(true);

    clients.forEach((client) => {
      temp.push(client.value);
    });

    setSelectedClients(temp);
  };

  const clearClients = () => {
    setSelectedClients([]);
    setSelectedDates(null);
    setShowSave(false);
  };

  return (
    <Page
      separator
      primaryAction={showSave && {
        content: t('invoices.preview'),
        loading: submitLoading,
        onAction: () => handleSubmit()
      }}
      secondaryActions={[{
        content: t('invoices.temporary'),
        onAction: () => toggleSheetActive()
      }]}
      title={t('invoices.invoice')}
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history(-1) }]}
    >
      {invoiceLoadingBanner.length ? <Banners banners={invoiceLoadingBanner} onDismissBanner={() => setInvoiceLoadingBanner([])} /> : null}
      {toastMarkup}
      {!loading && (
        <FormLayout>
          <Search
            showTags
            allowMultiple
            selectedData={selectedClients}
            handleChange={(d) => setMultiClients(d)}
            deselectedOptions={clients}
            placeholder={t('client.clients')}
            prefix={<Icon source={SearchMinor} color="subdued" />}
          />
          <Stack>
            <Button onClick={() => selectAllClients()}>{t('client.all')}</Button>
            <Button onClick={() => clearClients()}>{t('client.reset')}</Button>
          </Stack>
          <Card sectioned>
            <DatePicker
              month={date.month}
              year={date.year}
              onChange={setSelectedDates}
              onMonthChange={handleMonthChange}
              selected={selectedDates}
              multiMonth
              allowRange
            />
          </Card>
          <Stack alignment="center">
            <Stack.Item>
              <SwitchToggle
                handleChange={setEmailInvoices}
                checked={emailInvoices}
              />
            </Stack.Item>
            <Stack.Item>
              <TextStyle>{t('invoices.email')}</TextStyle>
            </Stack.Item>
          </Stack>
        </FormLayout>
      )}
      <PolarisSheet
        handleToggle={toggleSheetActive}
        active={sheetActive}
        title={t('invoices.temporary')}
        showPrimaryAction={Object.keys(tempPreviewInvoices).length}
        primaryActionTitle={t('invoices.create')}
        primaryActionToggle={() => handleCreate()}
        primaryActionLoading={handleCreateLoading}
        secondaryAction={Object.keys(tempPreviewInvoices).length}
        secondaryActionToggle={() => clearTempInvoices()}
        secondaryActionTitle={t('shared.delete')}
      >
        {tempPreviewLoading ? (
          <Spinner accessibilityLabel="Spinner" size="large" color="teal" />
        ) : (
          <OptionList
            title=""
            onChange={() => fetchTempInvoices()}
            options={Object.keys(tempPreviewInvoices).length && tempPreviewInvoices.count && [{
              value: 'Load',
              label: `${tempPreviewInvoices.count} ${t('invoices.invoices')} - ${tempPreviewInvoices.createdAt}`
            }]}
            selected="Load"
          />
        )}
        {(!tempPreviewLoading && Object.keys(tempPreviewInvoices).length && !tempPreviewInvoices.count) ? (
          <Banner title={t('invoices.no_invoice_available')} status="info">
            <p>{t('invoices.no_invoice_available_description')}</p>
          </Banner>
        ) : ''}
      </PolarisSheet>
      {/* <Viewer
        visible={showPreview}
        onClose={() => setShowPreview(false)}
        images={invoices}
      /> */}
      {showPreview && (
        <div>
          <p className="text-md my-3">Preview</p>
          {invoices.map((invoice) => (
            <Document file={invoice.src} onLoadSuccess={() => {}}><PdfPage pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false} /></Document>
          ))}
        </div>
      )}
    </Page>
  );
};

export default AutoCreateInvoice;
