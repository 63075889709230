import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Page, Layout, SkeletonPage, Stack, Thumbnail, Button, DropZone, Icon, TextStyle, Tooltip, Heading, Tabs, TextField } from '@shopify/polaris';
import { HideMinor, ViewMinor, EditMinor, InfoMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import Banners from '../Shared/Banners';
import Comments from '../Comments/Comments';
// import SignedInLessons from '../Shared/SignedInLessons';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import 'react-vertical-timeline-component/style.min.css';
import EditClient from './EditClient';
import StackList from '../Shared/StackList/StackList';
import CustomFields from '../CustomFields/CustomFields';
import Calendar from '../Graphs/Calendar';
import ClientEvents from './ClientEvents';
import { getTabId, getTabIndex, onTabSelect } from '../FilterEngine/Tabs/tabs';
import Invoices from '../Invoices/Invoices';
import Tags from '../Tags/Tags';
import TagModifier from '../Tags/TagModifier';
import usePrevious from '../../hooks/usePrevious';
import Memberships from './Memberships';
import AddMembershipModal from './AddMembershipModal';
// import StatusBadge from '../Shared/StatusBadge';
import ModalService from '../Shared/Services/ModalService';
import ManualMembershipModal from './ManualMembershipModal';
// import SessionHistorySummary from './SessionHistory/SessionHistorySummary';
import EditChildren from './EditChildren';

const manualMembershipModalRef = React.createRef();

const Client = (props) => {
  const [avatar, setAvatar] = useState('');
  const [showClientEdit, setShowClientEdit] = useState(false);
  const [showUserAttributes, setShowUserAttributes] = useState(false);
  const [client, setClient] = useState({ address: {} });
  const [banner, setBanner] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [addMembershipBanner, setMembershipBanner] = useState([]);
  const [showAddMembershipModal, setShowAddMembershipModal] = useState(false);
  const [showEditChildrenModal, setShowEditChildrenModal] = useState(false);
  const [init, setInit] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [signupFields, setSignupFields] = useState([]);

  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();
  const matchParams = useParams();

  const tabs = [
    {
      id: 'details',
      content: t('client.overview'),
      accessibilityLabel: t('client.overview'),
      panelID: 'details'
    },
    {
      id: 'events',
      content: t('calendar.events'),
      accessibilityLabel: t('calendar.events'),
      panelID: 'events'
    },
    {
      id: 'invoices',
      content: t('invoices.invoices'),
      accessibilityLabel: t('invoices.invoices'),
      panelID: 'invoices'
    },
    {
      id: 'notes',
      content: t('comment.comments'),
      accessibilityLabel: t('comment.comments'),
      panelID: 'notes'
    }
  ];

  if (!props.canAccessInvoices || !props.hasInvoiceAccess) {
    tabs.splice(tabs.findIndex((tab) => tab.panelID === 'invoices'), 1);
  }

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const fetchInvoices = useCallback(() => {
    const params = {
      per_page: 10,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: '',
      s: { sorts: tableFilters.sorts }
    };

    axios.post(`/v1/clients/${matchParams.id}/search_invoices`, params)
      .then((response) => {
        setInvoices([...invoices, ...response.data.invoices]);
        setLoading(false);
      })
      .catch((err) => {
        setBanner([{ title: t('invoices.cannot_get_invoices'), status: 'critical', details: err.response.data.errors }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const fetchClient = useCallback(() => {
    setLoading(true);

    axios.get(`/v1/clients/${matchParams.id}`)
      .then((response) => {
        if (response.data.avatar) setAvatar(response.data.avatar);
        setClient(response.data);
        setSubscriptions(response.data.subscriptions);
        setSelectedTags(response.data.selectedTags);
        setInitialTags(response.data.selectedTags);
        setSignupFields(response.data.signup_fields);
        setInit(true);
      })
      .catch((err) => {
        setBanner([{ title: t('client.cannot_get_list'), status: 'critical', details: err.response.data.error }]);
      })
      .then(() => {
        setLoading(false);
      });
  }, [matchParams.id, t]);

  const handleActiveToggle = (id, action) => {
    if (!window.confirm(action === 'inactivate' ? t('client.inactivate_toggle') : t('client.activate_toggle'))) {
      return;
    }

    axios.post(`/v1/clients/${id}/${action}`)
      .then(() => {
        setClient({ ...client, accountState: client.active ? 'inactive' : 'active', active: !client.active });
        setBanner([{ title: `User ${action}d`, status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: `Cannot ${action} user`, status: 'critical', details: error.response.data.errors }]);
      });
  };

  const fetchCustomFields = useCallback(() => {
    axios.get(`/v1/clients/${matchParams.id}/custom_fields`)
      .then((res) => {
        setCustomFields(res.data);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.error }]);
      });
  }, [matchParams.id, t]);

  const pageActions = () => {
    if (!client || loading) {
      return {};
    }

    const toggleContent = client.active ? 'Inactivate' : 'Activate';
    const content = client.active ? t('client.inactivate') : t('client.activate');

    const actions = {
      icon: client.active ? HideMinor : ViewMinor,
      content,
      destructive: true,
      onAction: () => handleActiveToggle(client.id, toggleContent.toLowerCase())
    };

    return actions;
  };

  const unassignChild = (id, parent = false) => {
    const params = {};

    if (parent) {
      params.parent_id = id;
    } else {
      params.child_id = id;
    }

    axios.post(`/v1/clients/${client.id}/unassign_child_parent`, params)
      .then(() => {
        fetchClient();
      })
      .catch(() => {
      });
  };

  const secondaryActions = () => {
    const actions = [];

    if (client.parent?.id) return actions;

    actions.push({
      content: t('client.add_children'),
      onAction: () => setShowEditChildrenModal(true)
    });

    return actions;
  };

  const updateTags = useCallback(() => {
    setInit(false);

    const params = {
      client: {
        tags_attributes: TagModifier(selectedTags, initialTags)
      }
    };

    axios.patch(`/v1/clients/${matchParams.id}/update_tags`, params)
      .then((response) => {
        setSelectedTags(response.data.selectedTags);
        setInitialTags(response.data.selectedTags);
        setInit(true);
      })
      .catch(() => {
      });
  }, [matchParams.id, selectedTags, initialTags]);

  const previousTags = usePrevious(selectedTags);

  useEffect(() => {
    if (init && JSON.stringify(previousTags) !== JSON.stringify(selectedTags)) {
      updateTags();
    }
  // eslint-disable-next-line
  }, [selectedTags]);

  useEffect(() => {
    fetchClient();
  }, [fetchClient]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  useEffect(() => {
    fetchCustomFields();
  }, [fetchCustomFields]);

  const dismissBanner = () => {
    setBanner([]);
  };

  const descriptionList = () => {
    const items = [{
      term: t('user.name'),
      description: client.name
    }, {
      term: t('user.email'),
      description: client.email
    }, {
      term: t('address.phone_number'),
      description: client.phoneNumber
    }, {
      term: t('user.dob'),
      description: client.dob ? `${moment(client.dob).format('LL')} (${moment().diff(client.dob, 'years', false)})` : null
    }, {
      term: t('address.addresse'),
      description: client.address && Object.keys(client.address).length ? (
        <>
          <div>{client.address.attentionName}</div>
          <div>{client.address.address}</div>
          <div>{client.address.addressLineTwo}</div>
          <div>{`${client.address.city}, ${client.address.state || ''} ${client.address.zip_code || ''}`}</div>
        </>
      ) : null
    }];

    return items;
  };

  const clientDescriptionList = () => customFields.map((customField) => {
    // eslint-disable-next-line no-underscore-dangle
    if (customField._destroy === '1') return '';
    return {
      term: (
        <Stack spacing="tight">
          <TextStyle>{customField.label}</TextStyle>
          {customField.editable_by_client && <Tooltip content={t('custom_fields.editable')}><Icon source={EditMinor} color="base" /></Tooltip>}
          {customField.viewable_by_client && <Tooltip content={t('custom_fields.viewable')}><Icon source={ViewMinor} color="base" /></Tooltip>}
        </Stack>
      ),
      description: customField.value
    };
  });

  // eslint-disable-next-line
  const portalSession = async (state, data) => {
    if (state === 'manual') {
      // await manualMembershipModalRef.current.open({
      //   data
      // });
    } else {
      axios.get(`/v1/clients/${matchParams.id}/portal_session`)
        .then((response) => {
          window.open(response.data.url);
        });
    }
  };

  const updateClient = (updatedClient, address) => {
    const params = {
      user: {
        first_name: updatedClient.firstName,
        last_name: updatedClient.lastName,
        phone_number: updatedClient.phoneNumber,
        email: updatedClient.email,
        dob: updatedClient.dob
      },
      address: {
        address: address.address,
        address_line_two: address.address_line_two,
        city: address.city,
        state: address.state,
        zip_code: address.zip_code
      }
    };

    axios.patch(`/v1/clients/${updatedClient.id}`, params)
      .then(() => {
        setClient({
          ...client,
          name: `${updatedClient.firstName} ${updatedClient.lastName}`,
          firstName: updatedClient.firstName,
          lastName: updatedClient.lastName,
          phoneNumber: updatedClient.phoneNumber,
          email: updatedClient.email,
          dob: updatedClient.dob,
          parentEmail: updatedClient.parentEmail,
          parentName: updatedClient.parentName,
          emergencyNumber: updatedClient.emergencyNumber,
          address
        });
        setBanner([{ title: t('user.update_successful'), status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  };

  const updateCustomFields = (data) => {
    const params = {
      custom_fields_attributes: data
    };

    axios.patch(`/v1/clients/${matchParams.id}/attributes`, params)
      .then((res) => {
        setCustomFields(res.data.custom_fields);
        setBanner([{ title: t('user.update_successful'), status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  };

  const updateAvatar = useCallback((a) => {
    const formData = new FormData();

    if (a?.size > 0) {
      formData.append('avatar', a);
    }

    if (!a) {
      formData.append('remove_logo', true);
    }

    axios.patch(`/v1/clients/${matchParams.id}/avatar`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        setAvatar(response.data.avatar);
        setBanner([{ title: t('user.update_successful'), status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  }, [matchParams.id, t]);

  const deleteMembership = (token) => {
    const params = {
      token
    };

    axios.post(`/v1/clients/${matchParams.id}/remove_membership`, params)
      .then(() => {
        fetchClient();
      })
      .catch((error) => {
        setMembershipBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  };

  const addMembership = (params) => {
    axios.post(`/v1/clients/${matchParams.id}/add_membership`, params)
      .then(() => {
        fetchClient();
        setShowAddMembershipModal(false);
        setBanner([{ title: t('user.update_successful'), status: 'success' }]);
      })
      .catch((error) => {
        setMembershipBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  };

  const editUserInfo = () => {
    setShowClientEdit(true);
  };

  const editChildrenModal = showEditChildrenModal
    ? (
      <EditChildren
        open={showEditChildrenModal}
        close={() => {
          fetchClient();
          setShowEditChildrenModal(false);
        }}
      />
    ) : null;

  const clientEditModal = showClientEdit
    ? (
      <EditClient
        active={showClientEdit}
        close={() => setShowClientEdit(false)}
        handleSubmit={updateClient}
        client={client}
        title={t('client.edit')}
      />
    ) : null;

  const addMembershipModal = showAddMembershipModal
    ? (
      <AddMembershipModal
        banner={addMembershipBanner}
        show={showAddMembershipModal}
        dismissBanner={() => setMembershipBanner([])}
        title={t('memberships.add_subscription')}
        close={() => setShowAddMembershipModal(false)}
        handleSubmit={addMembership}
        client={client}
      />
    ) : null;

  const clientEditModalAttributes = showUserAttributes
    ? (
      <CustomFields
        active={showUserAttributes}
        close={() => setShowUserAttributes(false)}
        handleSubmit={updateCustomFields}
        client={client}
        customFields={customFields}
        title={t('custom_fields.plural')}
        editableTitle={t('client.client')}
      />
    ) : null;

  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
    } else {
      setAvatar(acceptedFiles[0]);
      updateAvatar(acceptedFiles[0]);
    }
  }, [updateAvatar, t]);
  const uploadedFile = avatar && (
    <Stack alignment="center">
      {avatar?.length ? <Thumbnail size="large" name="avatar" source={avatar} /> : ''}
      <Button
        plain
        onClick={() => {
          setAvatar('');
        }}
      >
        {t('shared.change')}
      </Button>
    </Stack>
  );

  const clientAvatar = !avatar?.length ? (
    <>
      <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleDropZoneDrop}>
        <DropZone.FileUpload />
      </DropZone>
      <br />
    </>
  ) : uploadedFile;

  const displaySignupFields = () => signupFields.map((customField, idx) => (
    customField.value && (
      <Card.Section key={idx}>
        <TextField
          label={(<div dangerouslySetInnerHTML={{ __html: customField.label }} />)}
          value={customField.value}
          disabled
          onChange={() => {}}
        />
      </Card.Section>
    )
  ));

  const detailsTab = (
    loading ? <SkeletonPage /> : (
      <>
        {clientEditModal}
        {clientEditModalAttributes}
        <Banners banners={banner} onDismissBanner={dismissBanner} />
        <Layout>
          <Layout.Section oneThird>
            <Card sectioned title={t('client.personal_info')} actions={{ content: t('shared.edit'), onAction: () => editUserInfo() }}>
              <StackList items={descriptionList()} />
            </Card>
            <Card
              sectioned
              title={(
                <Stack wrap={false} spacing="tight" alignment="center">
                  <Heading>{t('custom_fields.plural')}</Heading>
                  <Tooltip content={t('custom_fields.help')}>
                    <Icon color="highlight" source={InfoMinor} />
                  </Tooltip>
                </Stack>
              )}
              actions={{ content: t('shared.edit'), onAction: () => setShowUserAttributes(true) }}
            >
              <StackList items={clientDescriptionList()} />
              {displaySignupFields()}
            </Card>
            <Card
              sectioned
              title="Tags"
            >
              <Tags
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                tagUrl={`/v1/clients/${matchParams.id}/available_client_account_tags`}
              />
            </Card>
            {client.children?.length ? (
              <Card sectioned title={t('client.children')}>
                {client.children.map((child) => (
                  <Card.Section key={child.id}>
                    <div className="flex flex-1">
                      <div className="flex-1">
                        <Button plain onClick={() => history(`/clients/${child.id}`)}>{child.name}</Button>
                      </div>
                      <Button onClick={() => unassignChild(child.id)}>{t('shared.delete')}</Button>
                    </div>
                  </Card.Section>
                ))}
              </Card>
            ) : ''}
            {client.parent?.id ? (
              <Card sectioned title={t('client.parent')}>
                <Card.Section>
                  <div className="flex flex-1">
                    <div className="flex-1">
                      <Button plain onClick={() => history(`/clients/${client.parent.id}`)}>{client.parent.name}</Button>
                    </div>
                    <Button onClick={() => unassignChild(client.parent.id, true)}>{t('shared.delete')}</Button>
                  </div>
                </Card.Section>
              </Card>
            ) : ''}
          </Layout.Section>
          <Layout.Section oneHalf>
            {/* <SessionHistorySummary remaining_sessions={client.remaining_sessions} hideButton={!subscriptions.length} /> */}
            {subscriptions.length && !loading ? (
              <Memberships
                delete={deleteMembership}
                openSession={portalSession}
                products={subscriptions}
              />
            ) : null}
            <Card
              sectioned
              actions={avatar?.length ? [{
                content: t('shared.change'),
                onAction: () => setAvatar('')
              }, {
                content: t('shared.delete'),
                onAction: () => updateAvatar('')
              }] : ''}
            >
              {avatar ? (
                <img
                  alt=""
                  width="100%"
                  style={{
                    objectFit: 'scale-down',
                    objectPosition: 'center',
                    maxHeight: '400px'
                  }}
                  src={avatar}
                />
              ) : clientAvatar}
            </Card>
            {client.currentYear ? (
              <div style={{ height: '200px' }}>
                <Calendar data={client.currentYear} />
              </div>
            ) : null}
          </Layout.Section>
        </Layout>
      </>
    )
  );

  const invoicesTab = (
    props.canAccessInvoices && props.hasInvoiceAccess ? (
      <Invoices override={[{ key: 'client', value: client.id?.toString() }]} hideStats />
    ) : ''
  );

  return (
    <Page
      title={`${t('client.client')} ${client.name ? '-' : ''} ${client.name || ''}`}
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history('/clients') }]}
      fullWidth
      separator
      secondaryActions={secondaryActions()}
      primaryAction={pageActions()}
    >
      {editChildrenModal}
      {addMembershipModal}
      <ModalService ref={manualMembershipModalRef}>
        <ManualMembershipModal />
      </ModalService>
      <Tabs
        tabs={tabs}
        selected={getTabIndex(tabs, tableFilters.tab)}
        onSelect={(tabIndex) => onTabSelect({ history, location }, tabs, tabIndex, setTableFilters)}
      >
        <br />
        {/* Details */}
        {getTabId(tabs, tableFilters.tab) === 'details' && (
          detailsTab
        )}
        {/* Events */}
        {getTabId(tabs, tableFilters.tab) === 'events' && (
          // client.upcomingEvents ? <SignedInLessons pastEvents={client.pastEvents} upcomingEvents={client.upcomingEvents} /> : null
          <ClientEvents location={location} match={matchParams} history={history} />
        )}
        {/* Invoices */}
        {props.hasInvoiceAccess && (
          getTabId(tabs, tableFilters.tab) === 'invoices' && (
            invoicesTab
          )
        )}
        {/* Notes */}
        {getTabId(tabs, tableFilters.tab) === 'notes' && (
          <Comments
            commentableId={client.id}
            commentablePath="clients"
            tooltip={t('comment.secure')}
          />
        )}
      </Tabs>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  canAccessInvoices: !['', 'Free'].includes(state.auth.plan),
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  unread: state.auth.unread
});

export default connect(mapStateToProps)(Client);
