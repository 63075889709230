import React from 'react';

const SidePanelSkeleton = () => (
  <div className="shadow rounded-md p-4 w-full">
    <div className="animate-pulse flex space-x-4 mb-40">
      <div className="flex-1 space-y-6 py-1">
        <div className="h-8 bg-slate-100 rounded" />
        <div className="space-y-3">
          <div className="grid grid-cols-4 gap-4">
            <div className="h-8 bg-slate-100 rounded col-span-1" />
            <div className="h-8 bg-slate-100 rounded col-span-3" />
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="h-8 bg-slate-100 rounded col-span-1" />
            <div className="h-8 bg-slate-100 rounded col-span-3" />
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="h-8 bg-slate-100 rounded col-span-1" />
            <div className="h-8 bg-slate-100 rounded col-span-3" />
          </div>
        </div>
      </div>
    </div>
    <div className="animate-pulse flex space-x-4">
      <div className="flex-1 space-y-6 py-1">
        <div className="h-8 bg-slate-100 rounded" />
        <div className="space-y-3">
          <div className="grid grid-cols-4 gap-4">
            <div className="h-8 bg-slate-100 rounded col-span-1" />
            <div className="h-8 bg-slate-100 rounded col-span-3" />
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="h-8 bg-slate-100 rounded col-span-1" />
            <div className="h-8 bg-slate-100 rounded col-span-3" />
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="h-8 bg-slate-100 rounded col-span-1" />
            <div className="h-8 bg-slate-100 rounded col-span-3" />
          </div>
        </div>
      </div>
    </div>
    <div className="mt-40 h-20 bg-slate-100 rounded" />
  </div>
);

export default SidePanelSkeleton;
