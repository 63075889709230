/* eslint-disable camelcase */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ChoiceList, Form, FormLayout, Modal, TextField, Select, ButtonGroup, DropZone, Stack, Caption, Label } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import languages from '../../Shared/Constants/Languages';
import Banners from '../../Shared/Banners';

const OtherUserModalForm = (props) => {
  const [userData, setUserData] = useState({
    email: null,
    firstName: null,
    lastName: null,
    role: '',
    password: '',
    passwordConfirmation: '',
    lang: '',
    plan_id: '1'
  });

  const { t } = useTranslation('translation');
  const history = useNavigate();
  const matchParams = useParams();
  const [files, setFiles] = useState([]);
  const [studentFile, setStudentFile] = useState([]);

  const [show, setShow] = useState(true);
  const [mounted] = useState(true);

  const fetchUserData = useCallback(() => {
    axios.get(`/v1/users/${matchParams.id}`)
      .then((response) => {
        if (mounted) {
          const { role, email, firstName, lastName, lang, plan_id } = response.data;
          setUserData((user) => ({ ...user, role, email, firstName, lastName, lang, plan_id: `${plan_id}` }));
        }
      })
      .catch(() => {
        console.log('Can\'t load User');
      });
  }, [mounted, matchParams.id]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleStudentDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles) => setStudentFile((f) => [...f, ...acceptedFiles]),
    []
  );

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles) => setFiles((f) => [...f, ...acceptedFiles]),
    []
  );

  const fileUpload = !files.length && <DropZone.FileUpload />;
  const uploadedFiles = files.length > 0 && (
    <div style={{ padding: '0' }}>
      <Stack vertical>
        {files.map((file, index) => (
          <Stack alignment="center" key={index}>
            <div>
              {file.name}
              {' '}
              <Caption>
                {file.size}
                {' '}
                bytes
              </Caption>
            </div>
          </Stack>
        ))}
      </Stack>
    </div>
  );

  const studentFileUpload = !studentFile.length && <DropZone.FileUpload />;
  const uploadedStudentFiles = studentFile.length > 0 && (
    <div style={{ padding: '0' }}>
      <Stack vertical>
        {files.map((file, index) => (
          <Stack alignment="center" key={index}>
            <div>
              {studentFile.name}
              {' '}
              <Caption>
                {studentFile.size}
                {' '}
                bytes
              </Caption>
            </div>
          </Stack>
        ))}
      </Stack>
    </div>
  );

  let roles = null;
  let plans = null;
  let dataUpload = null;
  let studentUpload = null;

  const handleChange = (field) => (value) => setUserData({ ...userData, [field]: value });

  const handleRoleChange = () => (value) => setUserData({ ...userData, role: value[0] });
  const handlePlanChange = () => (value) => setUserData({ ...userData, plan_id: value[0] });

  if (props.isAdmin) {
    roles = (
      <FormLayout.Group condensed>
        <ChoiceList
          title="Role"
          choices={[
            { label: t('user.admin'), value: 'admin' },
            { label: t('user.instructor'), value: 'instructor' },
            { label: t('client.client'), value: 'client' }
          ]}
          selected={userData.role}
          onChange={handleRoleChange()}
        />
      </FormLayout.Group>
    );

    plans = (
      <FormLayout.Group condensed>
        <ChoiceList
          title="Plan id"
          choices={[
            { label: 'Basic - year', value: '1' },
            { label: 'Basic - month', value: '2' },
            { label: 'Premium - month', value: '3' },
            { label: 'Premium - year', value: '4' },
            { label: 'Free', value: '5' },
            { label: 'Premium Plus - month', value: '6' },
            { label: 'Premium Plus - year <- for demo', value: '7' }
          ]}
          selected={userData?.plan_id}
          onChange={handlePlanChange()}
        />
      </FormLayout.Group>
    );

    dataUpload = (
      <DropZone onDrop={handleDropZoneDrop} allowMultiple={false}>
        {uploadedFiles}
        {fileUpload}
      </DropZone>
    );

    studentUpload = (
      <DropZone onDrop={handleStudentDropZoneDrop} allowMultiple={false}>
        {uploadedStudentFiles}
        {studentFileUpload}
      </DropZone>
    );
  }

  const closeModal = () => {
    setUserData({
      role: '',
      email: null,
      firstName: null,
      lastName: null,
      lang: '',
      password: '',
      passwordConfirmation: ''
    });
    setShow(false);
    props.cleanup();
  };

  const onSubmit = () => {
    const formData = new FormData();

    formData.append('user[email]', userData.email);
    formData.append('user[first_name]', userData.firstName);
    formData.append('user[last_name]', userData.lastName);
    if (userData.password) formData.append('user[password]', userData.password);
    if (userData.password) formData.append('user[password_confirmation]', userData.passwordConfirmation);
    if (userData.lang) formData.append('user[lang]', userData.lang);

    if (props.isAdmin) {
      formData.append('user[role]', userData.role);
      formData.append('user[plan_id]', userData.plan_id);

      if (files[0]?.size > 0) {
        formData.append('user[account_data]', files[0]);
      }

      if (studentFile[0]?.size > 0) {
        formData.append('user[student_data]', studentFile[0]);
      }
    }

    props.handleSubmit(formData);
  };

  const editAddresses = () => {
    history('/profile_settings');
    closeModal();
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title={props.title}
    >
      <Modal.Section>
        <Banners banners={props.banner} onDismissBanner={() => props.dismissBanner()} />
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <TextField label={t('user.first_name')} value={userData.firstName} onChange={handleChange('firstName')} />
            <TextField label={t('user.last_name')} value={userData.lastName} onChange={handleChange('lastName')} />
            <TextField
              label={t('user.password')}
              type="password"
              value={userData.password}
              onChange={handleChange('password')}
              autoComplete="new-password"
            />
            <TextField
              label="Email"
              type="email"
              value={userData.email}
              onChange={handleChange('email')}
            />
            <TextField
              label={t('user.confirm_password')}
              type="password"
              value={userData.passwordConfirmation}
              onChange={handleChange('passwordConfirmation')}
              autoComplete="new-password"
            />
            <Select
              label={t('user.language')}
              value={userData.lang}
              onChange={handleChange('lang')}
              options={languages}
            />
            {roles}
            {plans}
            <Label>Account Data</Label>
            {dataUpload}
            <Label>Student Data</Label>
            {studentUpload}
            <ButtonGroup>
              <Button onClick={() => editAddresses()}>{t('address.manage')}</Button>
              <Button primary submit>{t('shared.save')}</Button>
            </ButtonGroup>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin
});

export default connect(mapStateToProps)(OtherUserModalForm);
