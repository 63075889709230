import React, { useEffect, useState } from 'react';
import { Model, setLicenseKey } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics';
import 'survey-analytics/survey.analytics.css';
import './index.css';

// Register NPS visualizer for rating questions
// VisualizationManager.registerVisualizer('rating', NpsVisualizer);

const vizPanelOptions = {
  allowHideQuestions: false
};

const SurveyDashboardComponent = (props) => {
  setLicenseKey(
    'NmY0MzZiMjctNTk1YS00OGQ2LWFlMDQtYjVjN2YxMDk4ZTNkOzE9MjAyNS0xMC0wMywyPTIwMjUtMTAtMDMsND0yMDI1LTEwLTAz'
  );

  const [survey, setSurvey] = useState(null);
  const [vizPanel, setVizPanel] = useState(null);
  if (!survey) {
    const s = new Model(props.data);
    setSurvey(s);
  }

  if (!vizPanel && !!survey) {
    const v = new VisualizationPanel(
      survey.getAllQuestions(),
      props.replies,
      vizPanelOptions
    );
    setVizPanel(v);
  }

  useEffect(() => {
    vizPanel.render('surveyVizPanel');
  }, [vizPanel]);

  return (
    <div id="surveyVizPanel" />
  );
};

export default SurveyDashboardComponent;
