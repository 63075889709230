/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { CheckCircleIcon, PlayIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

function EndNode({ data }) {
  return (
    <>
      <div className="px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400">
        {/* <button type="button" className="top-1 right-1 absolute opacity-30 hover:opacity-100">
          <CheckCircleIcon className="h-3 w-3 text-gray-700" aria-hidden="true" />
        </button> */}
        <div className="flex">
          <div className="rounded-full w-12 h-12 flex justify-center items-center bg-green-300">
            <CheckCircleIcon className="h-5 w-5 text-green-700" aria-hidden="true" />
          </div>
        </div>
        <Handle type="target" position={Position.Left} className="h-4 bg-teal-500 align-middle" />
        <div className="text-md text-center">{data.type}</div>
      </div>
    </>
  );
}

export default memo(EndNode);
