import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import AvailabilityTable from './AvailabilityTable';

const AvailabilityTab = () => {
  const [staffAvailability, setStaffAvailability] = useState([]);
  const matchParams = useParams();

  const fetchStaffAvailability = useCallback(() => {
    axios.get(`/v1/products/${matchParams.token}/staff_availability`)
      .then((response) => {
        if (response.data.head) {
          setStaffAvailability([response.data.head, ...response.data.staffs]);
        } else {
          setStaffAvailability([...response.data.staffs]);
        }
      })
      .catch(() => {
      });
  }, [matchParams.token]);

  useEffect(() => {
    fetchStaffAvailability();
  }, [fetchStaffAvailability]);

  return (
    staffAvailability.length ? <AvailabilityTable data={staffAvailability} refetch={fetchStaffAvailability} /> : null
  );
};

export default AvailabilityTab;
