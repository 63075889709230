import moment from 'moment';
import { handleFilterChange } from '../FilterEngine';

const createdAtBeforeFilterActive = (t, props, setTableFilters, createdAtBefore, filter = 'event_start_time_lteq') => {
  const labelDate = createdAtBefore.start.toLocaleDateString();
  const valueDate = moment(createdAtBefore.start).format('YYYY-MM-DD');

  return {
    key: 'created-at-before',
    label: `${t('filters.before')}: ${labelDate}`,
    onRemove: handleFilterChange(props, setTableFilters, ['createdAtBefore', 'createdAtBeforeMonthYear'], 'dateReset', ''),
    filter,
    value: valueDate
  };
};

export default createdAtBeforeFilterActive;
