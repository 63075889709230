import { setSearchParams, filterInit, defaultDate, defaultMonthYear } from './filterParams';

export const searchFilterToURL = (props, key, value, action = 'add') => {
  props.history({ search: setSearchParams(props.location.search, key, value, action) });
};

export const appliedFiltersQuery = (filters, addArray = false) => {
  const query = {};
  filters.forEach((appliedFilter) => {
    if (Array.isArray(appliedFilter.filter)) {
      if (addArray) {
        appliedFilter.filter.forEach((filter) => {
          query[filter] = appliedFilter.value;
        });
        query.m = 'or';
      }
    } else if (!addArray) {
      query[appliedFilter.filter] = appliedFilter.value;
    }
  });

  return query;
};

export const handleFilterChange = (props, setTableFilters, field, action = null, resetValue = null) => (value, optionalValue) => {
  switch (action) {
    case 'tags': {
      // searchFilterToURL(props, field, value.map((tag) => tag.label).join(', '));
      setTableFilters((filters) => ({ ...filters, [field]: value }));
      break;
    }
    case 'date': {
      searchFilterToURL(props, field, `${value.start.toDateString()}`);
      setTableFilters((filters) => ({ ...filters, [field]: value }));
      break;
    }
    case 'monthYear': {
      searchFilterToURL(props, field, `${value},${optionalValue}`);
      setTableFilters((filters) => ({ ...filters, [field]: { month: value, year: optionalValue } }));
      break;
    }
    case 'reset': {
      searchFilterToURL(props, field, null, 'delete');
      setTableFilters((filters) => ({ ...filters, [field]: resetValue }));
      break;
    }
    case 'dateReset': {
      searchFilterToURL(props, field, null, 'delete');
      setTableFilters((filters) => ({ ...filters, [field[0]]: defaultDate }));
      setTableFilters((filters) => ({ ...filters, [field[1]]: defaultMonthYear }));
      break;
    }
    case 'flatten': {
      searchFilterToURL(props, field, value);
      setTableFilters((filters) => ({ ...filters, [field]: value[0] }));
      break;
    }
    case 'resetAll': {
      props.history(field);
      setTableFilters({ ...filterInit });
      break;
    }
    default: {
      searchFilterToURL(props, field, value);
      setTableFilters((filters) => ({ ...filters, [field]: value }));
    }
  }
};
