/* eslint-disable */
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Memberships = (props) => {
  const { t } = useTranslation();

  const getStatusColor = (state) => {
    switch (state) {
      case 'active': return 'border-green-500';
      case 'waiting_for_stripe_confirmation': return 'border-green-300';
      case 'inactive': return 'border-sky-500';
      case 'pending': return 'border-gray-500';
      case 'failure': return 'border-red-500';
      default: return 'border-sky-500';
    }
  };

  return (
    <div className="pb-4">
      <ul className="grid grid-cols-1 space-y-2">
        {props.products.map((sub, i) => (
          <button onClick={() => props.openSession(sub.membership.state, sub)} type="button" key={i} className={`border-l-8 ${getStatusColor(sub.membership.state)} text-left relative col-span-1 flex shadow-sm rounded-md`}>
            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
              <div className="flex-1 px-4 py-4 text-sm truncate">
                <div className="text-gray-900 font-medium hover:text-gray-600">
                  {sub.product.title}
                </div>
                <p className="text-gray-500 capitalize mt-1">
                  {`${t('shared.current_state')}: ${t(`membership.states.${sub.membership.state}`)}`}
                </p>
                <p className="text-gray-500 mt-1">
                  {sub.membership.state === 'manual' ? t('client.waiting_for_approval') : (
                    sub.membership.current_period_at ? (sub.membership.cancel_at_period_end ? t('membership.expires_on') : t('membership.renews_on')) + ' ' + moment(sub.membership.current_period_at).format('LL') : ''
                  )}
                </p>
                {sub.membership.state === 'manual' || sub.membership.state === 'failure' ? '' : (
                  sub.membership.cancel_at ? (
                    <p className="text-gray-500 mt-1">
                      {`${t('membership.expires')} ${moment(sub.membership.cancel_at).format('LL')}`}
                    </p>
                  ) : ''
                )}
              </div>
              {sub.membership.state === 'active' && (
                <p className="mr-4 text-xl">{`${sub.membership.session_histories.used * -1}/${sub.membership.session_histories.allowed}`}</p>
              )}
              {sub.membership.state === 'manual' && (
                <button
                  type="button"
                  onClick={() => props.delete(sub.membership.token)}
                  className="mr-4 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  {t('shared.delete')}
                </button>
              )}
            </div>
          </button>
        ))}
      </ul>
    </div>
  );
};

export default Memberships;
