import React from 'react';
import { FormLayout, Stack, TextStyle } from '@shopify/polaris';

const StackList = ({ items }) => {
  const formattedItems = items.map((item, i) => (
    <Stack key={i}>
      <Stack.Item fill>
        <TextStyle variation="strong">{item.term}</TextStyle>
      </Stack.Item>
      <Stack.Item>
        <TextStyle variation="subdued">{item.description}</TextStyle>
      </Stack.Item>
    </Stack>
  ));

  return (
    <FormLayout>
      {formattedItems}
    </FormLayout>
  );
};

export default StackList;
