/* eslint-disable no-param-reassign */
const TagModifier = (newTags, initialTags, language = '') => {
  const finalTags = [];

  newTags.forEach((tag) => {
    if (tag.id === tag.label) {
      tag.id = '';
    }

    finalTags.push(tag);
  });

  initialTags.forEach((tag) => {
    if (language && tag.language !== language) {
      return;
    }

    if (!newTags.some((t) => t.id === tag.id)) {
      // eslint-disable-next-line no-underscore-dangle
      tag._destroy = '1';
      finalTags.push(tag);
    } else if (!newTags.some((t) => t.label === tag.label)) {
      // eslint-disable-next-line no-underscore-dangle
      tag._destroy = '1';
      finalTags.push(tag);
    }
  });

  return finalTags;
};

export default TagModifier;
