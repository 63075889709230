import React from 'react';

const NotificationBanner = (props) => (
  <div className={`${props.color || 'bg-blue-800'} shadow-md rounded-md mb-3 ${props.buttonLabel ? 'pt-1 pb-1' : 'pt-3 pb-3'} align-middle`}>
    <div className="mx-auto py-1 px-3 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between flex-wrap">
        <div className="w-0 flex-1 flex items-center">
          <p className="ml-3 font-medium text-white">
            <span className="md:hidden">{props.label}</span>
            <span className="hidden md:inline">{props.label}</span>
          </p>
        </div>
        <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
          {props.buttonLabel && (
          <button
            type="button"
            className="flex items-center w-full justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
            onClick={props.onClick}
          >
            {props.buttonLabel}
          </button>
          )}
        </div>
        <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
          {/* eslint-disable-next-line */}
          <a
            type="button"
            className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
          >
            {/* <span className="sr-only">Dismiss</span> */}
            {/* <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" /> */}
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default NotificationBanner;
