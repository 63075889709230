import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ForwardBookingPortal = (props) => {
  const history = useNavigate();

  if (props.userUrlName) {
    setTimeout(history, 0, `/booking/${encodeURIComponent(props.userUrlName)}`);
  }

  return <div>redirecting</div>;
};

const mapStateToProps = (state) => ({
  subdomain: state.auth.subdomain,
  userUrlName: state.auth.urlName
});

export default connect(mapStateToProps)(ForwardBookingPortal);
