import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ModalService from '../../components/Shared/Services/ModalService';
import SubscriptionNotificationModal from '../../components/Clients/SubscriptionNotificationModal';
import FormNotificationModal from '../../components/Clients/FormNotificationModal';

const subModalRef = React.createRef();
const formModalRef = React.createRef();

// eslint-disable-next-line no-unused-vars
const GlobalNotification = ({ subscriptions, hasPaymentMethod, forms, assignedWorkflows }) => {
  const openModalSubscriptionModal = async () => {
    await subModalRef.current.open({
      subs: subscriptions,
      hasPaymentMethod
    });
  };

  const openFormsModal = async () => {
    await formModalRef.current.open();
  };

  useEffect(() => {
    if (subscriptions?.some((sub) => sub.state === 'manual')) {
      openModalSubscriptionModal();
    }
    // Check if subs are in failure etc
  // eslint-disable-next-line
  }, [subscriptions]);

  useEffect(() => {
    if (forms > 0) {
      openFormsModal();
    }
    // Check if subs are in failure etc
  // eslint-disable-next-line
  }, [forms]);

  // useEffect(() => {
  //   if (assignedWorkflows > 0) {
  //     openFormsModal();
  //   }
  //   // Check if subs are in failure etc
  // // eslint-disable-next-line
  // }, [assignedWorkflows]);

  return (
    <>
      <ModalService ref={subModalRef}>
        <SubscriptionNotificationModal />
      </ModalService>
      <ModalService ref={formModalRef}>
        <FormNotificationModal />
      </ModalService>
    </>
  );
};

const mapStateToProps = (state) => ({
  forms: state.auth.forms,
  assignedWorkflows: state.auth.assigned_workflows,
  subscriptions: state.auth.subscriptions,
  hasPaymentMethod: state.auth.hasPaymentMethod
});

export default connect(mapStateToProps)(GlobalNotification);
