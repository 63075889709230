/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Page, Toast, ContextualSaveBar, Spinner, Subheading, Stack, Button, TextField } from '@shopify/polaris';
import { PlusIcon, PlusSmallIcon as PlusSmallIconSolid } from '@heroicons/react/20/solid';
import { DeleteMajor } from '@shopify/polaris-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import TinymceEditor from '../../Tinymce/TinymceEditor';
import Templates from './Templates';
import ExternalLink from '../../Shared/ExternalLink';
import EmailTemplateAttachmentModalForm from './EmailTemplateAttachmentModalForm';
import Liquid from './Liquid';

const EmailTemplate = (props) => {
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [banner, setBanner] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState({
    subject: '',
    path: 'user_mailer/generic'
  });
  const { t } = useTranslation();
  const history = useNavigate();
  const matchParams = useParams();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const getEmailTemplate = useCallback((data) => {
    const path = matchParams.path ? decodeURI(matchParams.path) : props.path;
    let template = {};

    if (props.subtype || props.isIndividualProduct) {
      template = data.email_templates.find((temp) => temp.path === path && temp.subtype === props.subtype) || {};
    } else {
      template = data.email_templates.find((temp) => temp.path === path) || {};
    }
    const defaultTemplate = Templates(t, props.subdomain).find((temp) => temp.path === path);

    template.path = path || 'user_mailer/generic';
    template.title = defaultTemplate.name;
    template.receiver = emailTemplate.receiver;
    template.subject = defaultTemplate.default?.subject;
    template.body = defaultTemplate.default?.body;
    template.subtype = props.subtype || 'default'

    return template;
  }, [matchParams.path]);

  const fetchTemplate = useCallback(() => {
    if (emailTemplate.id || matchParams.path) {
      axios.get(`/v1/email_templates/${emailTemplate.id || matchParams.path}`)
        .then((response) => {
          setEmailTemplate((et) => ({ ...et, ...response.data }));
          setAttachments(response.data.attachments);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [emailTemplate.id]);

  const fetchSettings = useCallback(() => {
    axios.get('/v1/email_templates')
      .then((response) => {
        setEmailTemplate(getEmailTemplate(response.data));
        if (!response.data.email_template?.id) {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [getEmailTemplate]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate, emailTemplate.id]);

  const handleTemplateChange = (field) => (value) => {
    setEmailTemplate({ ...emailTemplate, [field]: value });
    setShowSaveBar(true);
  };

  const onSubmit = () => {
    const params = {
      email_template: emailTemplate
    };

    if (emailTemplate.id) {
      axios.patch(`/v1/email_templates/${emailTemplate.id}`, params)
        .then(() => {
          toggleToastActive(t('shared.saved'));
        })
        .catch(() => {})
        .then(() => setShowSaveBar(false));
    } else {
      axios.post('/v1/email_templates', params)
        .then((res) => {
          // const defaultTemplate = Templates(t).find((temp) => temp.path === emailTemplate.path);
          // setEmailTemplate({ title: defaultTemplate.name, ...res.data });
          toggleToastActive(t('shared.saved'));
          history(-1);
        })
        .catch(() => {})
        .then(() => setShowSaveBar(false));
    }
  };

  const deleteTemplate = () => {
    if (!window.confirm(t('calendar.are_you_sure'))) {
      return;
    }

    axios.delete(`/v1/email_templates/${emailTemplate.id}`)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {});
  };

  const handleSubmitFormData = (formData) => {
    setAttachmentLoading(true);
    axios.patch(`/v1/email_templates/${emailTemplate.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(() => {
        fetchTemplate();
        toggleToastActive(t('shared.saved'));
        setAttachmentLoading(false);
        setShowUploadModal(false);
      })
      .catch((error) => {
        setBanner([{ title: 'Cannot update email attachment', status: 'critical', details: error.response.data.errors }]);
        setAttachmentLoading(false);
      });
  };

  const handleSubmit = (formData) => {
    axios.patch(`/v1/email_templates/${emailTemplate.id}`, formData)
      .then(() => {
        toggleToastActive(t('shared.deleted'));
        fetchTemplate();
        setShowUploadModal(false);
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
        setAttachmentLoading(false);
      });
  };

  const handleAttachmentDelete = (attachmentId) => {
    if (!window.confirm(t('shared.are_you_sure'))) {
      return;
    }

    const formData = {
      email_template: {
        email_template_attachments_attributes: [{
          id: attachmentId,
          _destroy: '1'
        }]
      }
    };

    handleSubmit(formData);
  };

  let attachmentForm = null;
  if (showUploadModal) {
    attachmentForm = (
      <EmailTemplateAttachmentModalForm
        title={t('user.add_attachment')}
        handleSubmit={(formData) => handleSubmitFormData(formData)}
        banner={banner}
        loading={attachmentLoading}
        reset={() => setShowUploadModal(false)}
        dismissBanner={() => setBanner([])}
      />
    );
  }

  const attachmentsInfo = () => {
    const items = (attachments || []).map((attachment, index) => (
      <Subheading element="h3" key={index}>
        <Stack>
          <Stack.Item fill>
            <ExternalLink label={attachment.filename} url={attachment.fileUrl} />
          </Stack.Item>
          <Button plain icon={DeleteMajor} onClick={() => handleAttachmentDelete(attachment.id)} />
        </Stack>
      </Subheading>
    ));

    return (attachments || []).length ? items : '';
  };

  return (
    <Page
      title={`Email Template ${emailTemplate?.title ? `- ${emailTemplate?.title}` : ''}`}
      fullWidth
      separator
      breadcrumbs={!props.hideNav  && [{
        content: t('shared.back'),
        onAction: () => history(-1)
      }]}
      secondaryActions={emailTemplate.id && [
        {
          content: t('shared.delete'),
          onAction: () => deleteTemplate()
        }
      ]}
    >
      {showSaveBar && (
        <ContextualSaveBar
          message={t('shared.unsaved_changes')}
          saveAction={{
            onAction: () => onSubmit(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      {toastMarkup}
      {attachmentForm}
      {!loading ? (
        emailTemplate.id || showEditor ? (
          <div>
            {!emailTemplate?.title ? (
              <div className="mb-2">
                <TextField
                  prefix="To"
                  placeholder="Optional"
                  value={emailTemplate.receiver}
                  onChange={handleTemplateChange('receiver')}
                />
              </div>
            ) : ''}
            <div className="mb-2">
              <TextField
                prefix="Subject"
                placeholder="Subject"
                value={emailTemplate.subject}
                onChange={handleTemplateChange('subject')}
              />
            </div>
            <div className="relative z-0 flex flex-wrap overflow-hidden space-x-2">
              <div className="flex-1 bg-white rounded-md border p-4 sm:w-1/2 w-2/3">
                <TinymceEditor
                  value={emailTemplate.body}
                  handleChange={handleTemplateChange('body')}
                />
                {emailTemplate.id && (
                  <div className="bg-white rounded-md border p-4 mt-2 w-full">
                    <div className="flex font-medium text-sm mb-4">
                      <div className="flex-1">Attachments</div>
                      <button
                        type="button"
                        onClick={() => setShowUploadModal(true)}
                        className="inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <PlusSmallIconSolid className="h-3 w-3" aria-hidden="true" />
                      </button>
                    </div>
                    {attachmentsInfo()}
                  </div>
                )}
              </div>
              <div className="bg-white rounded-md border p-4 sm:w-1/3 w-full">
                <div className="font-medium text-sm mb-4">Liquid</div>
                <Liquid type={emailTemplate.path} />
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center mt-40">
            <svg
              className="mx-auto h-20 w-20 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h5 className="mt-2 text-sm font-medium text-gray-900">{t('email_template.no_template')}</h5>
            <p className="mt-1 text-xs text-gray-500">{t('email_template.get_started')}</p>
            <div className="mt-6">
              <button
                type="button"
                onClick={() => setShowEditor(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PlusIcon className="-ml-1 mr-2 h-3 w-3" aria-hidden="true" />
                {t('email_template.new')}
              </button>
            </div>
          </div>
        )
      ) : <div className="mt-40 flex justify-center m-auto"><Spinner /></div>}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  subdomain: state.auth.subdomain
});

export default connect(mapStateToProps)(EmailTemplate);
