/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React, { useEffect, useRef, useState } from 'react';

const ChevronUpIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
  </svg>
);

const Accordion = ({ title, content, open }) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');
  const [init, setInit] = useState(false);
  const [activeStyle, setActiveStyle] = useState('');
  const [rotate, setRotate] = useState('transform duration-700 ease');

  useEffect(() => {
    if (open && init) {
      toggleAccordion();
    }

    setInit(true);
  // eslint-disable-next-line
  }, [open, init]);

  const contentSpace = useRef(null);

  const toggleAccordion = () => {
    setActive(active === false);
    setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`);
    setRotate(active ? 'transform duration-700 ease' : 'transform duration-700 ease rotate-180');
    setActiveStyle(!active ? 'border-l-2 bg-gray-50 border-blue-400' : '');
  };

  return (
    <div className="flex flex-col border rounded w-full max-h-96">
      <button
        className={`${activeStyle} p-2 rounded-t-sm box-border appearance-none cursor-pointer focus:outline-none flex items-center justify-between`}
        onClick={toggleAccordion}
      >
        <p className="flex">
          {title}
        </p>
        <div className={`${rotate} inline-block`}>
          {ChevronUpIcon}
        </div>
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className="overflow-auto transition-max-height duration-700 ease-in-out border-l-2"
      >
        <div className="p-4">{content}</div>
      </div>
    </div>
  );
};

export default Accordion;
