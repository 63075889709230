import React from 'react';
import { Button, Icon, Stack } from '@shopify/polaris';
import { ExternalSmallMinor } from '@shopify/polaris-icons';

const ExternalLink = (props) => {
  const goToLink = () => {
    window.open(props.url);
  };

  return (
    <Button monochrome={props.monochrome} plain destructive={props.destructive} onClick={() => goToLink()} icon={props.buttonIcon}>
      <Stack spacing="extraTight" alignment="center" wrap>
        <div style={{ textAlign: 'left', wordBreak: 'break-word' }}>
          {props.label}
        </div>
        {props.hideIcon ? '' : <Icon source={ExternalSmallMinor} />}
      </Stack>
    </Button>
  );
};

export default ExternalLink;
