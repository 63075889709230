import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, ResourceList, ResourceItem, TextStyle, Icon, Toast, EmptyState } from '@shopify/polaris';
import { ClockMajor } from '@shopify/polaris-icons';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ClientEventEdit from '../Calendar/Client/ClientEventEdit';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import ModalService from './Services/ModalService';

const confirmationModalRef = React.createRef();

const SignedInLessons = (props) => {
  const [showEventEdit, setShowEventEdit] = useState(false);
  const [event, setEvent] = useState({});

  const { t } = useTranslation();
  const location = useLocation();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={10000} />
  ) : null;

  const showModal = (item) => {
    if (location.pathname === '/home') {
      setEvent(item);
      setShowEventEdit(true);
    }
  };

  const closeModal = () => {
    setShowEventEdit(false);
    setEvent({});
  };

  const handleSignUp = (e, participation, category) => {
    const data = {
      category,
      date: e.start_time
    };

    if (!participation) {
      axios.post(`/v1/events/${event.id}/participations`, data)
        .then(() => {
          toggleToastActive(t('calendar.signed_up'));
        })
        .catch((error) => {
          toggleToastActive(error.response?.data?.errors);
        })
        .then(() => {
          props.fetchUserData();
        });
    } else {
      axios.post(`/v1/events/${event.id}/participations/${participation.id}/sign_up`, data)
        .then(() => {
          toggleToastActive(t('shared.saved'));
        })
        .catch((error) => {
          toggleToastActive(error.response.data.errors);
        })
        .then(() => {
          props.fetchUserData();
        });
    }
  };

  const handleSignOut = async (e, participation) => {
    const signOutParticipation = await confirmationModalRef.current.open({
      title: t('calendar.booking_cancellation'),
      description: t('calendar.booking_cancellation_description'),
      cancelButton: t('shared.cancel'),
      submitButton: t('shared.confirm')
    });

    if (!signOutParticipation) {
      return;
    }

    const data = {
      category: 'one_time',
      date: event.start_time
    };

    axios.post(`/v1/events/${e.id}/participations/${participation.id}/sign_out`, data)
      .then(() => {
        toggleToastActive(t('shared.saved'));
      })
      .catch((error) => {
        toggleToastActive(error.response.data.errors);
      })
      .then(() => {
        props.fetchUserData();
      });
  };

  const eventEditModal = showEventEdit
    ? (
      <ClientEventEdit
        active={showEventEdit}
        close={closeModal}
        handleSignUp={handleSignUp}
        handleSignOut={handleSignOut}
        event={event}
      />
    ) : null;

  const resourceItem = (item) => {
    const { id, instructor, title, start } = item;

    let color = 'success';

    switch (item.participation[0].state) {
      case 'no_show': {
        color = 'critical';

        break;
      }
      case 'no_show_no_pay': {
        color = 'warning';

        break;
      }

      default: {
        color = 'success';
      }
    }

    const media = (
      <div style={{ marginTop: '1rem' }}>
        <Icon backdrop color={color} source={ClockMajor} />
      </div>
    );

    return (
      <ResourceItem
        id={id}
        media={media}
        accessibilityLabel={`View details for ${instructor}`}
        onClick={() => showModal(item)}
      >
        <div>
          <TextStyle variation="strong">{title}</TextStyle>
        </div>
        <div>{moment(start || item.start_time).format('LLLL')}</div>
      </ResourceItem>
    );
  };

  return (
    <Card title={t('calendar.events')}>
      <ModalService ref={confirmationModalRef}>
        <ConfirmationModal />
      </ModalService>
      {toastMarkup}
      {eventEditModal}
      {props.upcomingEvents.length || props.pastEvents.length ? (
        <>
          <Card.Section title={t('shared.upcoming_events')}>
            <ResourceList
              resourceName={{ singular: 'event', plural: 'events' }}
              items={props.upcomingEvents}
              renderItem={(item) => resourceItem(item, 'upcoming')}
            />
          </Card.Section>
          <Card.Section title={t('shared.past_events')}>
            <ResourceList
              resourceName={{ singular: 'event', plural: 'events' }}
              items={props.pastEvents}
              renderItem={(item) => resourceItem(item, 'past')}
            />
          </Card.Section>
        </>
      ) : (
        <EmptyState
          heading={t('calendar.no_events_scheduled')}
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          <p>{t('calendar.events_will_show_up_here')}</p>
        </EmptyState>
      )}
    </Card>
  );
};

export default SignedInLessons;
