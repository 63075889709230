/* eslint-disable no-nested-ternary */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, Card, Toast, SkeletonPage, Button, Page, DataTable, EmptyState, Spinner, Badge } from '@shopify/polaris';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { appliedFiltersQuery, handleFilterChange } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import NewProgram from '../PublicProfile/NewProgram';

const ProgramSpaces = (props) => {
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [showNewProgram, setShowNewProgram] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const appliedFilters = useCallback(() => {
    const af = [];

    return af;
  }, []);

  const fetchPrograms = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: appliedFiltersQuery(appliedFilters()),
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/public_profiles/search', params)
      .then((res) => {
        setLastPage(res.data.lastPage);
        setPrograms(res.data.programs);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const filters = [];

  const placeHolderText = t('product.filter');

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/program_spaces', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={placeHolderText}
    />
  );

  useEffect(() => {
    fetchPrograms();
  }, [fetchPrograms]);

  const programHeadings = [
    t('program_spaces.internal_name'),
    t('program_spaces.public_name'),
    t('user.status')
  ];

  const programsTableModifier = (tableData) => tableData.map((row) => {
    const modifiedRow = [];

    modifiedRow.push(
      <Button plain url={`/public_profile_edit?program_space=${row.program_space}`}>{row.program_space}</Button>
    );
    modifiedRow.push(row.name);
    modifiedRow.push(<Badge status={row.active ? 'info' : 'critical'}>{row.active ? t('shared.active') : t('client.inactive')}</Badge>);

    return modifiedRow;
  });

  const addNewProgram = (params) => {
    axios.post('/v1/public_profiles', params)
      .then(() => {
        fetchPrograms();
      })
      .catch(() => {});
  };

  let newProgram = null;

  if (showNewProgram) {
    newProgram = (
      <NewProgram
        title={t('public_profile.add_new_program')}
        handleSubmit={addNewProgram}
        close={() => setShowNewProgram(false)}
      />
    );
  }

  return (
    <Page
      primaryAction={((props.isStaff && props.bookingPageAccess) || !props.isStaff) && { content: t('program_spaces.new_program'), onClick: () => setShowNewProgram(true) }}
    >
      {newProgram}
      {toastMarkup}
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            {!loading ? (
              programs?.length ? (
                <DataTable
                  verticalAlign="middle"
                  columnContentTypes={[
                    'text',
                    'text',
                    'text',
                    'text'
                  ]}
                  headings={programHeadings}
                  rows={programsTableModifier(programs)}
                  footerContent={programs?.length}
                />
              ) : <EmptyState image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png" />
            ) : <Spinner />}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={programs}
        lastPage={lastPage}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isPremiumPlusMember: state.auth.premiumPlusMember,
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  bookingPageAccess: state.auth.bookingPageAccess,
  isStaff: state.auth.role === 'staff'
});

export default connect(mapStateToProps)(ProgramSpaces);
