/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Card } from '@shopify/polaris';
import defaultImage from '../../assets/images/unsplash.jpg';

const ReadOnlyDefaultStyle = (props) => {
  const defaultStyle = (
    <>
      {props.individual?.name && props.publicProfile.staffBackgroundImageUrl ? (
        // eslint-disable-next-line
        <img style={{ objectFit: 'cover', maxHeight: props.publicProfile.staff_background_height || 300, width: '100%', objectPosition: props.publicProfile.background_positioning ? props.publicProfile.background_positioning : '' }} src={props.publicProfile.staffBackgroundImageUrl || defaultImage} alt="Me" />
      ) : (
        <img style={{ objectFit: 'cover', maxHeight: 300, width: '100%', objectPosition: props.publicProfile.background_positioning ? props.publicProfile.background_positioning : '' }} src={props.publicProfile.backgroundImageUrl || defaultImage} alt="Me" />
      )}
      {(props.publicProfile.avatarUrl || props.individual?.product_image) && (
        <div style={{ textAlign: 'center' }} className="flex justify-center">
          <img style={{ objectFit: 'cover', height: '150px', width: '150px', borderRadius: '50%', marginTop: '-75px' }} src={props.individual?.product_image || props.publicProfile.avatarUrl} alt="avatar" />
        </div>
      )}

      <div style={{ marginTop: '-100px' }}>
        <Card>
          <div style={{ paddingTop: '120px', textAlign: 'center' }}>
            {(props.publicProfile.name || props.individual?.name) && <p className="text-2xl font-medium text-gray-900">{props.individual?.name || props.publicProfile.name}</p>}
            {props.publicProfile.description && <h5>{props.publicProfile.description}</h5>}
            <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '900px', textAlign: 'left' }}>
              {props.children}
            </div>
          </div>
        </Card>
      </div>
    </>
  );

  if (props.publicProfile.default_style === 'default') return defaultStyle;
  if (props.publicProfile.default_style === 'home' && props.tabName === 'home') return defaultStyle;

  return (
    props.children
  );
};

export default ReadOnlyDefaultStyle;
