import { handleFilterChange } from '../FilterEngine';

const stateFilterActive = (t, props, setTableFilters, active) => ({
  key: 'state',
  label: `State: ${active}`,
  onRemove: handleFilterChange(props, setTableFilters, 'state', 'reset', ''),
  filter: 'aasm_state_in',
  value: active
});

export default stateFilterActive;
