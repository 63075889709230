import React, { useState, useCallback } from 'react';
import { ResourceList, ResourceItem, Stack, TextStyle, Button } from '@shopify/polaris';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { CashDollarMajor } from '@shopify/polaris-icons';
import Banners from '../Shared/Banners';
import MarkAsPaidModalForm from '../Invoices/MarkAsPaidModalForm';
import PaymentOptions from '../Shared/PaymentOptions';

const ParticipantsResourceList = (props) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [participations, setParticipations] = useState([...props.data]);
  const [markAsPaidModalOptions, setMarkAsPaidModalOptions] = useState({});
  const [banner, setBanner] = useState([]);

  const matchParams = useParams();

  const handleToggle = useCallback((id, action) => {
    const params = {
      participation: {
        no_show: action
      }
    };

    axios.patch(`/v1/events/${matchParams.id}/participations/${id}`, params)
      .then((response) => {
        const index = participations.findIndex((part) => part.id === id);
        participations[index] = response.data.participation;
        setParticipations([...participations]);
      })
      .catch((error) => {
        setBanner([{ title: props.t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  // eslint-disable-next-line
  }, [participations, matchParams.id, props.t]);

  const markAsPaid = (data) => {
    const params = {
      participation_id: data?.participationId,
      payment_method: data.payment_method,
      unpaid: data.unpaid === true
    };

    axios.post(`/v1/events/${matchParams.id}/participations/${data.participationId}/paid_toggle`, params)
      .then((response) => {
        const index = participations.findIndex((part) => part.id === data.participationId);
        participations[index] = response.data.participation;
        setParticipations([...participations]);
      })
      .catch((err) => {
        setBanner([{ title: props.t('shared.something_went_wrong'), status: 'critical', details: err.response.data.errors }]);
      });
  };

  let markAsPaidModalForm = null;

  if (Object.keys(markAsPaidModalOptions).length) {
    markAsPaidModalForm = (
      <MarkAsPaidModalForm
        paymentMethod={markAsPaidModalOptions.paymentMethod}
        handleSubmit={markAsPaid}
        participationId={markAsPaidModalOptions.id}
        paid={markAsPaidModalOptions.paid}
        close={() => setMarkAsPaidModalOptions({})}
      />
    );
  }

  const resourceName = {
    singular: 'Participant',
    plural: 'Participants'
  };

  const renderItem = (item) => {
    const { id, label, state, paid, paymentMethod } = item;

    return (
      <ResourceItem
        id={id}
        showHeader
        accessibilityLabel={`View details for ${item.label}`}
        onClick={() => {}}
      >
        <Stack alignment="center">
          <Stack.Item fill>
            <TextStyle variation="strong">{label}</TextStyle>
            <br />
            {props.hasInvoiceAccess && (
              !paid ? (
                <Button
                  plain
                  destructive
                  icon={CashDollarMajor}
                  onClick={() => setMarkAsPaidModalOptions({ id, paid, paymentMethod })}
                >
                  {props.t('invoices.mark_as_paid')}
                </Button>
              ) : (
                <TextStyle variation="subdued">{PaymentOptions(props.t).find((option) => option.value === paymentMethod)?.label}</TextStyle>
              )
            )}
          </Stack.Item>
        </Stack>
        <Button fullWidth size="slim" primary={state === 'show'} onClick={() => handleToggle(id, 'show')}>{props.t('client.show')}</Button>
        <Button fullWidth size="slim" destructive={state === 'no_show'} onClick={() => handleToggle(id, 'cancel_and_bill')}>{props.t('client.no_show_and_pay')}</Button>
        <Button fullWidth size="slim" destructive={state === 'no_show_no_pay'} onClick={() => handleToggle(id, 'cancel_and_no_bill')}>{props.t('client.no_show_no_po')}</Button>
      </ResourceItem>
    );
  };

  return (
    <>
      {markAsPaidModalForm}
      <Banners banners={banner} onDismissBanner={() => setBanner([])} />
      <ResourceList
        resourceName={resourceName}
        items={participations}
        renderItem={renderItem}
        selectedItems={selectedItems}
        onSelectionChange={setSelectedItems}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  hasInvoiceAccess: state.auth.hasInvoiceAccess
});

export default connect(mapStateToProps)(ParticipantsResourceList);
