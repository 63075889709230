import React from 'react';
import { Icon } from '@shopify/polaris';
import { TickMinor, CancelSmallMinor } from '@shopify/polaris-icons';

const StatusBadge = (props) => {
  const color = props.active ? 'green' : 'redDark';
  const icon = props.active ? TickMinor : CancelSmallMinor;
  return <Icon source={icon} color={color} />;
};

export default StatusBadge;
