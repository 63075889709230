/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { memo } from 'react';
import { Handle, Position, useReactFlow, useStoreApi } from 'reactflow';
import { ArrowPathIcon, LanguageIcon, PlayIcon } from '@heroicons/react/20/solid';

function StaffStatusChangeCondition({ data, value, handleId, nodeId }) {
  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  return (
    <>
      <div className="px-2 py-2 shadow-md rounded-full bg-white border-2 border-stone-400">
        {/* <button type="button" className="top-1 right-1 absolute opacity-30 hover:opacity-100">
          <PencilSquareIcon className="h-3 w-3 text-gray-700" aria-hidden="true" />
        </button> */}
        <div className="flex justify-center">
          <div className="rounded-full w-12 h-12 flex justify-center items-center bg-gray-300">
            <ArrowPathIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
          </div>
        </div>
        <Handle type="target" position={Position.Left} className="h-4 !bg-teal-500" />
        <Handle type="source" position={Position.Right} className="h-4 bg-teal-500" />
        <Handle type="source" position={Position.Right} className="h-4 bg-teal-500 align-middle" />
        <Handle type="source" position={Position.Right} className="h-4 bg-teal-500 align-bottom" />
        <div className="text-md text-center">{data.type}</div>
      </div>
    </>
  );
}

export default memo(StaffStatusChangeCondition);
