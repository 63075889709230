import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Page, Layout, Card, ResourceList, Avatar, ResourceItem, Stack, TextStyle, Filters, Badge } from '@shopify/polaris';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import { handleFilterChange } from '../FilterEngine/FilterEngine';
import Banners from '../Shared/Banners';

const ParticipationManagement = () => {
  const [banner, setBanner] = useState([]);
  const [events, setEvents] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [lastEventPage, setLastEventPage] = useState(null);
  const [lastClientPage, setLastClientPage] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();
  const matchParams = useParams();

  const [eventTableFilters, setEventTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(eventTableFilters, 400);

  const [clientTableFilters, setClientTableFilters] = useState(stateConverter(location));
  const debouncedClientSearch = useDebounce(clientTableFilters, 400);

  const eventSelectionChange = (id) => {
    setSelectedEvent({});
    setSelectedEvents(id);
  };

  const manageClients = (action = 'add') => {
    if (!selectedEvents.length && !Object.keys(selectedEvent).length) {
      setBanner([{ title: t('participation.event_selected'), status: 'critical' }]);
      return;
    }

    if (!selectedClients.length) {
      setBanner([{ title: t('participation.client_selected'), status: 'critical' }]);
      return;
    }

    const params = {
      category: action,
      client_ids: selectedClients,
      event_ids: selectedEvents.length ? selectedEvents : [selectedEvent.id]
    };

    axios.post('/v1/events/participation_management', params)
      .then(() => {
        fetchEvents();
        setSelectedClients([]);
        setSelectedEvents([]);
        setSelectedEvent({});
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  };

  const showParticipations = (eventId) => {
    const tempClients = [];
    const temp = events.find((e) => e.id === eventId);

    setSelectedEvent(events.find((e) => e.id === eventId));

    temp.participations.forEach((p) => {
      const tempClient = clients.find((c) => c.value === p.value);
      if (tempClient) tempClients.push(tempClient.id);
    });

    setSelectedClients(tempClients);
  };

  const fetchEvents = useCallback(() => {
    const params = {
      page: eventTableFilters.page,
      per_page: 100
    };

    axios.post(`/v1/events/${matchParams.id}/show_recurring`, params)
      .then((response) => {
        setEvents(response.data.events);
        setLastEventPage(response.data.lastPage);
      })
      .catch(() => {
      });
  // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  // Start Clients
  const fetchClients = useCallback(() => {
    const params = {
      per_page: 100,
      page: clientTableFilters.page,
      search: clientTableFilters.queryValue,
      q: ''
    };

    axios.post('/v1/clients/search_resource', params)
      .then((res) => {
        // if (mounted) {
        setClients(res.data.clients);
        setLastClientPage(res.data.lastPage);
        // }
      })
      .catch(() => {
        // setBanner([{ title: t('client.cannot_get_list'), status: 'critical', details: err.response.data.error }]);
      });
    // eslint-disable-next-line
  }, [debouncedClientSearch]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const filterControlClients = (
    <Filters
      queryValue={clientTableFilters.queryValue}
      filters={[]}
      onQueryChange={handleFilterChange({ history, location }, setClientTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setClientTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={[]}
      queryPlaceholder={t('client.filter')}
    />
  );

  const resourceNameClients = {
    singular: t('client.client'),
    plural: t('client.clients')
  };

  const renderClient = (item) => {
    const { id } = item;
    const media = <Avatar size="medium" />;

    return (
      <ResourceItem
        id={id}
        showHeader
        accessibilityLabel={`View details for ${item.label}`}
        // onClick={() => history(`/events/${id}`)}
        media={media}
      >
        <Stack>
          <Stack.Item fill>
            <TextStyle variation="strong">{item.label}</TextStyle>
            <br />
            <TextStyle variation="subdued">{item.email}</TextStyle>
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };

  const promotedBulkActionsClient = [
    {
      content: t('client.add_selected_clients'),
      onAction: () => manageClients()
    },
    {
      content: t('client.remove_selected_client'),
      onAction: () => manageClients('remove')
    }
  ];
  // End Clients

  // Start Events
  const resourceName = {
    singular: t('events.event'),
    plural: t('calendar.events')
  };

  const renderItem = (item) => {
    const { id, startTime } = item;

    return (
      <ResourceItem
        id={id}
        showHeader
        accessibilityLabel={`View details for ${item.name}`}
        onClick={showParticipations}
      >
        <Stack>
          <Stack.Item fill>
            <TextStyle variation="strong">{item.title}</TextStyle>
            {selectedEvent.id === id && <Badge status="success">{t('shared.selected')}</Badge>}
            {new Date(startTime) < new Date() && <Badge status="info">{t('calendar.past_event')}</Badge>}
            <br />
            <TextStyle variation="subdued">{moment(startTime).format('LLLL')}</TextStyle>
          </Stack.Item>
          <Stack.Item>
            <TextStyle variation="strong">{t('shared.participants')}</TextStyle>
            <br />
            <TextStyle variation="subdued">{item.participations.length}</TextStyle>
          </Stack.Item>
        </Stack>
      </ResourceItem>
    );
  };

  const promotedBulkActions = [
    {
      content: t('client.add_selected_clients'),
      onAction: () => manageClients()
    },
    {
      content: t('client.remove_selected_client'),
      onActions: () => manageClients('remove')
    }
  ];
  // End Events

  return (
    <Page
      title={t('participation.management')}
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history(`/events/${matchParams.id}?calendar=true`) }]}
      fullWidth
      separator
    >
      <Banners banners={banner} onDismissBanner={() => setBanner([])} />
      <Layout>
        <Layout.Section oneHalf>
          <Card
            sectioned
            title={t('shared.details')}
            // actions={[
            //   { content: 'Edit', onAction: () => setShowEventEdit(true) }
            // ]}
          >
            <ResourceList
              resourceName={resourceName}
              items={events}
              renderItem={renderItem}
              selectedItems={selectedEvents}
              onSelectionChange={eventSelectionChange}
              promotedBulkActions={promotedBulkActions}
            />
            <TablePagination
              resetOnRefresh
              pageFilter={eventTableFilters.page}
              setTableFilters={setEventTableFilters}
              records={events}
              lastPage={lastEventPage}
              length={100}
            />
          </Card>
        </Layout.Section>
        <Layout.Section oneHalf>
          <Card sectioned>
            <Card.Subsection>
              {filterControlClients}
            </Card.Subsection>
            <ResourceList
              resourceName={resourceNameClients}
              items={clients}
              renderItem={renderClient}
              selectedItems={selectedClients}
              onSelectionChange={setSelectedClients}
              promotedBulkActions={promotedBulkActionsClient}
            />
            <TablePagination
              resetOnRefresh
              pageFilter={clientTableFilters.page}
              setTableFilters={setClientTableFilters}
              records={clients}
              lastPage={lastClientPage}
              length={100}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default ParticipationManagement;
