import React, { useCallback, useState, useEffect } from 'react';
import { Autocomplete, Tag, TextContainer, Stack, Icon } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';

const Search = (props) => {
  const [selectedOptions, setSelectedOptions] = useState(props.selectedData || []);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(props.deselectedOptions);
  const [availableOptions] = useState(props.deselectedOptions);

  const updateText = useCallback((value) => {
    setInputValue(value);

    setTimeout(() => {
      if (value === '') {
        setOptions(props.deselectedOptions);
        return;
      }
      const filterRegex = new RegExp(value, 'i');
      const resultOptions = options.filter((option) => option.label.match(filterRegex));
      setOptions(resultOptions);
    }, 300);
  }, [options, props.deselectedOptions]);

  useEffect(() => {
    if (props.showInputValue && props.selectedData.length) {
      selectedOptions.map((option) => {
        let label = '';
        const matchedOption = options.find((opt) => opt.value === option);
        label = matchedOption ? matchedOption.name : '';
        return setInputValue(label);
      });
    }
    // eslint-disable-next-line
  }, [props.deselectedOptions, props.selectedOptions]);

  useEffect(() => {
    setSelectedOptions(props.selectedData || []);
  }, [props.selectedData]);

  const removeTag = useCallback((tag) => () => {
    const tempOptions = [...selectedOptions];
    tempOptions.splice(tempOptions.indexOf(tag), 1);
    setSelectedOptions(tempOptions);
    props.handleChange(tempOptions);
  }, [selectedOptions, props]);

  const hasSelectedOptions = selectedOptions?.length;

  const tagsMarkup = hasSelectedOptions ? selectedOptions.map((option) => {
    let tagLabel = '';
    const matchedOption = availableOptions.find((opt) => opt.value === option);
    tagLabel = matchedOption ? matchedOption.label : '';
    return <Tag key={`option${option}`} onRemove={removeTag(option)}>{tagLabel}</Tag>;
  }) : null;

  const emptyState = (
    <>
      <Icon source={SearchMinor} />
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Could not find any results</TextContainer>
      </div>
    </>
  );

  const updateSelection = useCallback((selected) => {
    if (props.showInputValue) {
      const selectedValue = selected.map((selectedItem) => {
        const matchedOption = options.find((option) => option.name === selectedItem);
        return matchedOption && matchedOption.label;
      });
      setInputValue(selectedValue);
    }
    setSelectedOptions(selected);
    props.handleChange(selected);
  }, [props, options]);

  const textField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateText}
      label={props.label}
      value={inputValue}
      prefix={props.prefix}
      placeholder={props.placeholder}
    />
  );

  const tagsContainer = (
    <>
      <TextContainer>
        <Stack>{tagsMarkup}</Stack>
      </TextContainer>
      <br />
    </>
  );

  return (
    <>
      {props.showTags ? tagsContainer : null}
      <Autocomplete
        allowMultiple={props.allowMultiple}
        options={options}
        selected={selectedOptions}
        onSelect={updateSelection}
        emptyState={emptyState}
        textField={textField}
      />
    </>
  );
};

export default Search;
