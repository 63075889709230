import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArchiveBoxIcon,
  CalendarIcon
  // ArrowPathIcon
} from '@heroicons/react/24/outline';
import { Card, Page } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PnbBadge from '../Tailwind/PnbBadge';

const NewProduct = () => {
  const { t } = useTranslation();
  const history = useNavigate();

  const items = [
    {
      title: t('events.event'),
      description: t('products.event_description'),
      path: '/products/new_event',
      icon: CalendarIcon,
      background: 'bg-green-500',
      comingSoon: false,
      enabled: true
    },
    // {
    //   title: 'Recurring event',
    //   description: 'Offer recurring events for your clients to book. For example, a summer course that takes place every Tuesday from 3 to 4 pm.',
    //   path: '/recurring_event/new',
    //   icon: CalendarIcon,
    //   background: 'bg-green-300',
    //   comingSoon: false,
    //   enabled: true
    // },
    // {
    //   title: t('memberships.name'),
    //   description: t('memberships.description'),
    //   path: '/memberships/new',
    //   icon: ArrowPathIcon,
    //   background: 'bg-blue-500',
    //   comingSoon: false,
    //   enabled: props.hasStripe
    // },
    {
      title: t('products.lesson_package'),
      description: t('products.lesson_package_desc'),
      path: '/event_package/new',
      icon: ArchiveBoxIcon,
      background: 'bg-blue-300',
      enabled: true
    }
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <Page
      title={t('products.create')}
      separator
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history('/products') }]}
    >
      <Card sectioned>
        <ul className="p-4 grid grid-cols-1 gap-6 sm:grid-cols-1">
          {items.map((item, itemIdx) => (
            <li key={itemIdx} className="flow-root">
              <div className="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                <div
                  className={classNames(
                    item.background,
                    'flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg'
                  )}
                >
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <div>
                  <button type="button" onClick={() => (!item.comingSoon && item.enabled) && history(item.path)} className="focus:outline-none">
                    <h3 className="text-base font-medium text-gray-900">
                      <span className="absolute inset-0" aria-hidden="true" />
                      {item.title}
                      {item.comingSoon && (
                        <PnbBadge title={t('shared.coming_soon')} color="ml-2 bg-blue-300 text-blue-800" />
                      )}
                      {!item.enabled && (
                        <PnbBadge title={t('shared.requires_stripe_setup')} color="ml-2 bg-blue-300 text-blue-800" />
                      )}
                    </h3>
                  </button>
                  <p className="text-sm text-gray-500">{item.description}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Card>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  hasStripe: state.auth.hasStripe
});

export default connect(mapStateToProps)(NewProduct);
