import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { FormLayout, TextField } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

const Address = forwardRef((props, ref) => {
  const [cityErrorMessage, setCityErrorMessage] = useState(null);
  const [addressErrorMessage, setAddressErrorMessage] = useState(null);
  const [stateErrorMessage, setStateErrorMessage] = useState(null);
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState(null);

  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    checkErrorMessages: () => {
      let errors = false;
      resetErrorMessages();
      if (!props.address.address) {
        setAddressErrorMessage(t('address.validations.address'));
        errors = true;
      }
      if (!props.address.city) {
        setCityErrorMessage(t('address.validations.city'));
        errors = true;
      }
      if (!props.address.zipCode) {
        setZipCodeErrorMessage(t('address.validations.zip_code'));
        errors = true;
      }
      return errors;
    }
  }));

  const resetErrorMessages = () => {
    setAddressErrorMessage(null);
    setStateErrorMessage(null);
    setCityErrorMessage(null);
    setZipCodeErrorMessage(null);
  };

  return (
    <FormLayout>
      <TextField
        type="text"
        placeholder={t('address.address')}
        value={props.address.address}
        onChange={props.handleChange('address')}
        error={addressErrorMessage}
      />
      {!props.hideAdditional && (
        <TextField
          type="text"
          placeholder={t('address.additional_address_info')}
          value={props.address.addressLineTwo}
          onChange={props.handleChange('addressLineTwo')}
          maxLength={25}
        />
      )}
      <TextField
        type="text"
        placeholder={t('address.city')}
        value={props.address.city}
        onChange={props.handleChange('city')}
        error={cityErrorMessage}
      />
      {!props.hideState && (
        <TextField
          placeholder={t('address.state')}
          value={props.address.state}
          onChange={props.handleChange('state')}
          error={stateErrorMessage}
        />
      )}
      <TextField
        type="text"
        placeholder={t('address.zip_code')}
        value={props.address.zipCode}
        onChange={props.handleChange('zipCode')}
        error={zipCodeErrorMessage}
      />
    </FormLayout>
  );
});

export default Address;
