import React, { useEffect, useState } from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getDaysArrayByMonth, monthName } from './utilities';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const DatePicker = ({
  isLoading,
  timezoneSelect,
  selected,
  month,
  year,
  timeSlotsMonth,
  onMonthChange,
  onChange,
  displaySlots
}) => {
  const [dates, setDates] = useState(getDaysArrayByMonth(year, month, timeSlotsMonth));

  const { t } = useTranslation();

  useEffect(() => {
    setDates(getDaysArrayByMonth(year, month, timeSlotsMonth));
  }, [year, month, timeSlotsMonth]);

  const nextMonth = () => {
    const m = moment().set({ year, month });

    m.add(1, 'month');

    const mo = m.month();
    const y = parseInt(m.format('YYYY'), 10);

    onMonthChange(mo, y);
  };

  const previousMonth = () => {
    const m = moment().set({ year, month });

    m.subtract(1, 'month');

    const mo = m.month();
    const y = parseInt(m.format('YYYY'), 10);

    onMonthChange(mo, y);
  };

  const handleChange = (selection) => {
    const temp = [...dates];
    // eslint-disable-next-line
    temp.forEach((t) => (t.isSelected = false));
    const index = temp.findIndex((day) => day.date === selection.date);
    temp[index].isSelected = true;

    setDates([...temp]);

    const currentDate = moment(selection.date, 'YYYY-MM-DD').toDate();

    onChange({ start: currentDate, end: currentDate });
  };

  const calendarHeader = () => {
    const mName = monthName(month);

    return `${t(`date_picker.months.long.${mName}`)} ${year}`;
  };

  return (
    <div className="relative md:grid md:grid-cols-2 md:divide-x md:divide-gray-200 mx-4">
      <div className="md:pr-14">
        {isLoading && (
          <div className="absolute flex z-50 bg-opacity-30 bg-gray-300 top-0 left-0 right-0 w-full h-full">
            <div className="animate-spin h-10 w-10 m-auto">
              <ArrowPathIcon />
            </div>
          </div>
        )}
        <div className="flex items-center">
          <h5 className="flex-auto font-semibold text-gray-900">{calendarHeader()}</h5>
          <button
            type="button"
            className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            onClick={() => previousMonth()}
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            onClick={() => nextMonth()}
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-10 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
          <div>{t('date_picker.days.short.monday')}</div>
          <div>{t('date_picker.days.short.tuesday')}</div>
          <div>{t('date_picker.days.short.wednesday')}</div>
          <div>{t('date_picker.days.short.thursday')}</div>
          <div>{t('date_picker.days.short.friday')}</div>
          <div>{t('date_picker.days.short.saturday')}</div>
          <div>{t('date_picker.days.short.sunday')}</div>
        </div>
        <div className="mt-2 grid grid-cols-7 text-sm">
          {dates.map((day, dayIdx) => (
            <div key={day.date} className={classNames(dayIdx > 6 && 'border-t border-gray-200', 'py-2')}>
              <button
                type="button"
                onClick={() => handleChange(day)}
                className={classNames(
                  day.isSelected && 'text-white',
                  !day.isSelected && day.isToday && 'text-indigo-500',
                  !day.isSelected && !day.isToday && day.isCurrentMonth && 'text-gray-900',
                  !day.isSelected && !day.isToday && !day.isCurrentMonth && 'text-gray-400',
                  day.isSelected && day.isToday && 'bg-indigo-600',
                  day.isSelected && !day.isToday && 'bg-gray-900',
                  !day.isSelected && 'hover:bg-gray-200',
                  (day.isSelected || day.isToday) && 'font-semibold',
                  'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                )}
              >
                <time dateTime={day.date}>{day.date.split('-').pop().replace(/^0/, '')}</time>
              </button>
              <div className="flex items-center justify-center">
                {Array(day.slots).fill().map(() => (
                  <div className="bg-indigo-600 border-2 w-2 h-2 rounded-full" />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="items-center justify-center w-full mt-4 z-0">
          {timezoneSelect}
        </div>
      </div>
      <section className="mt-12 md:mt-0 md:pl-14">
        <h5 className="font-semibold text-gray-900">
          {t('date_picker.schedule_for')}
          {' '}
          <time dateTime="2022-01-21">{moment(new Date(selected.start)).format('LL')}</time>
        </h5>
        <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500">
          {displaySlots()}
        </ol>
      </section>
    </div>
  );
};

export default DatePicker;
