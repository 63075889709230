/* eslint-disable no-unused-vars */
import React, { useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { Loading } from '@shopify/polaris';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from './containers/Layout/Layout';
import * as actions from './store/actions/index';
import AdminRoutes from './Routes/AdminRoutes';
import InstructorRoutes from './Routes/InstructorRoutes';
import ClientRoutes from './Routes/ClientRoutes';
import UnauthenticatedRoutes from './Routes/UnauthenticatedRoutes';
import NewUserRoutes from './Routes/NewUserRoutes';
import AccountantRoutes from './Routes/AccountantRoutes';
import PendingClientRoutes from './Routes/PendingClientRoutes';

import Tennisjessen from './Routes/PremiumPlusClients/Tennisjessen';

import '@shopify/polaris/build/esm/styles.css';

import './index.css';
import './App.scss';
import i18n from './i18n';
import MissingPlanRoutes from './Routes/MissingPlanRoutes';
import PendingInstructorRoutes from './Routes/PendingInstructorRoutes';

// eslint-disable-next-line no-unused-vars
const App = ({ unlockscreen, checkAuthState, onTryAutoSignin, userRole, isAuthenticated, accountState, loading, lang, isNew }) => {
  useEffect(() => {
    onTryAutoSignin();
  }, [onTryAutoSignin]);

  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const history = useNavigate();

  i18n.changeLanguage(lang);

  let routes = null;
  const activeState = ['active', 'trial', 'pending', 'denied', 'new_registration', 'new_application', 'approved', 'on_hold', 'completed', 'cancelled', 'validated'];
  const activeAccount = activeState.includes(accountState);
  const authenticatedAndComplete = isAuthenticated && activeAccount && !isNew;

  const userRoutes = () => {
    if (unlockscreen) {
      return <UnauthenticatedRoutes />;
    }

    switch (userRole) {
      case 'admin': return <AdminRoutes />;
      case 'staff': {
        if (accountState === 'pending' || accountState === 'new_registration' || accountState === 'cancelled' || accountState === 'on_hold' || accountState === 'denied' || accountState === 'approved') {
          return <PendingInstructorRoutes />;
        }

        return <InstructorRoutes />;
      }
      case 'instructor': return <InstructorRoutes />;
      case 'client': {
        if (accountState === 'pending' || accountState === 'declined' || accountState === 'new_application') {
          return <PendingClientRoutes />;
        }

        return <ClientRoutes />;
      }
      case 'accountant': return <AccountantRoutes />;
      default: return <UnauthenticatedRoutes />;
    }
  };

  // Routing for Premium Plus Clients
  if (window.location.host === 'tennisjessen.com' || window.location.host === 'www.tennisjessen.com') {
    routes = <Tennisjessen />;
  } else if (window.location.host === 'app.localhost:3000') {
    routes = <Tennisjessen />;
  } else {
    // Regular Routing
    // eslint-disable-next-line no-lonely-if
    if (authenticatedAndComplete) routes = userRoutes();
    else if (!loading) {
      if (isNew) routes = <NewUserRoutes />;
      else if (accountState === 'missing_plan') routes = <MissingPlanRoutes />;
      else routes = <UnauthenticatedRoutes />;
    }
  }

  const queryString = window.location.search;
  const query = new URLSearchParams(queryString);
  let next = query.get('next');

  if (localStorage.getItem('next')) {
    next = `/${localStorage.getItem('next')}`;
  }

  if (authenticatedAndComplete && next) {
    localStorage.removeItem('next');

    checkAuthState(next, history);
  }

  const getAdditionalBookingStyles = () => {
    switch (window.location.hostname) {
      case 'bookyourexpert.planubo.com': return 'bookyourexpert twp';
      default: return 'twp';
    }
  };

  return (
    <div className={getAdditionalBookingStyles()}>
      <Layout withNavigation={authenticatedAndComplete && (!location.pathname.includes('/booking/') && !location.pathname.includes('/embeddedbooking/'))}>
        <Suspense fallback={<Loading />}>
          {routes}
        </Suspense>
      </Layout>
    </div>
  );
};

const mapPropsToDispatch = (dispatch) => ({
  onTryAutoSignin: () => dispatch(actions.authCheckState()),
  checkAuthState: (url, history) => dispatch(actions.authCheckState(url, history))
});

const mapStateToProps = (state) => ({
  accountState: state.auth.accountState,
  userRole: state.auth.role,
  isInstructor: state.auth.instructor,
  isNew: state.auth.isNew,
  isAuthenticated: state.auth.token !== null,
  loading: state.auth.loading,
  lang: state.auth.lang,
  unlockscreen: state.auth.unlockscreen
});

export default connect(mapStateToProps, mapPropsToDispatch)(App);
