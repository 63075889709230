/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Page, Toast, Spinner } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../Shared/FormBuilder/scss/application.scss';
// import { ReactFormGenerator } from '../Shared/FormBuilder';
import NotificationBanner from '../Shared/NotificationBanner/NotificationBanner';
import DisplayForm from '../Shared/FormBuilderV2/DisplayForm';
import { ReactFormGenerator } from '../Shared/FormBuilder';

const FillOutForm = ({ token, callback }) => {
  const [survey, setSurvey] = useState({
    data: []
  });
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { t } = useTranslation();
  const matchParams = useParams();
  // eslint-disable-next-line no-unused-vars
  const history = useNavigate();

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);
  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const fetchSurvey = useCallback(() => {
    axios.get(`/v1/surveys/${matchParams.token || token}`)
      .then((response) => {
        const data = JSON.parse(response.data.data);
        setSurvey({ ...response.data, data });
      })
      .catch(() => {
        history('/home');
      })
      .then(() => {
        setLoading(false);
      });

    setLoading(false);
  // eslint-disable-next-line
  }, [matchParams.token, token]);

  useEffect(() => {
    fetchSurvey();
  }, [fetchSurvey]);

  useEffect(() => {
    if (formSubmitted) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [formSubmitted]);

  const onSubmit = (data, _options) => {
    const params = {
      data: JSON.stringify(data)
    };

    axios.post(`/v1/surveys/${matchParams.token || token}/capture_answer`, params)
      .then(() => {
        setFormSubmitted(true);
        // options.callback(data.saveNo, true);

        if (callback) {
          callback(true);
        }
      })
      .catch(() => {
        // options.callback(data.saveNo, false);
      });
  };

  const chooseFormGenerator = () => {
    if (window.location.host === 'bookyourexpert.planubo.com') {
      return new Date(survey.createdAt) > new Date('2024-11-3');
    }

    return new Date(survey.createdAt) > new Date('2024-10-4');
  };

  return (
    <Page
      fullWidth
      separator
    >
      {toastMarkup}
      {formSubmitted ? (
        <NotificationBanner label={t('survey.thank_you_for_submitting')} color="bg-green-400" />
      ) : (
        <Card sectioned>
          {loading ? <Spinner /> : (
            chooseFormGenerator() ? (
              <div>
                <DisplayForm theme={survey.theme} finalSubmit={(data, options) => onSubmit(data, options)} form_data={survey.data} />
              </div>
            ) : (
              <ReactFormGenerator
                actionName={t('shared.submit')}
                onSubmit={(data) => onSubmit(data)}
                data={survey.data}
              />
            )
          )}
        </Card>
      )}
    </Page>
  );
};

export default FillOutForm;
