const languages = [
  {
    label: 'DE',
    value: 'de'
  },
  {
    label: 'EN',
    value: 'en'
  },
  {
    label: 'FR',
    value: 'fr'
  }
];

export default languages;
