import React from 'react';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const SessionHistorySummary = (props) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const matchParams = useParams();

  return (
    <div
      className={`mb-4 relative bg-white pt-5 px-4 ${props.hideButton ? '' : 'pb-12'} sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden`}
    >
      <dt>
        <div className="absolute bg-indigo-500 rounded-md p-3">
          <BookOpenIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 text-sm font-medium text-gray-500 truncate">{t('client.remaining_sessions')}</p>
      </dt>
      <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
        <p className="text-2xl font-semibold text-gray-900">{props.remaining_sessions}</p>
        {!props.hideButton ? (
          <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <button type="button" className="font-medium text-indigo-600 hover:text-indigo-500" onClick={() => history(`/clients/${matchParams.id}/session_histories`)}>
                {t('shared.view_all')}
              </button>
            </div>
          </div>
        ) : ''}
      </dd>
    </div>
  );
};

export default SessionHistorySummary;
