import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ResponsiveLine } from '@nivo/line';
import { ResponsiveBar } from '@nivo/bar';
import { Page, Layout, Card, ActionList, Popover, Button } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import HomeCard from './HomeCard';
import Banners from '../Shared/Banners';
import { stateConverter } from '../FilterEngine/filterParams';
import useDebounce from '../../hooks/useDebounce';
// import GettingStarted from '../GettingStarted/GettingStarted';

const Home = (props) => {
  const [banner, setBanner] = useState([]);
  const [data, setData] = useState({
    product_bookings_last_30_days: [],
    product_booking_last_year: [],
    booking_data_by_user_and_month: [],
    booking_data_by_client_and_month: []
  });

  const [last30daysactive, setLast30daysActive] = useState(false);
  const [last30daysFilter, setLast30daysFilter] = useState(30);
  const toggleLast30daysActive = useCallback(() => setLast30daysActive((active) => !active), []);

  const [top10InstructorBookingsActive, setTop10InstructorBookingsActive] = useState(false);
  const [top10InstructorBookingsFilter, setTop10InstructorBookingsFilter] = useState(12);
  const toggleTop10InstructorBookingsActive = useCallback(() => setTop10InstructorBookingsActive((active) => !active), []);

  const [top10ClientBookingsActive, setTop10ClientBookingsActive] = useState(false);
  const [top10ClientBookingsFilter, setTop10ClientBookingsFilter] = useState(12);
  const toggleTop10ClientBookingsActive = useCallback(() => setTop10ClientBookingsActive((active) => !active), []);

  const { t } = useTranslation();
  const location = useLocation();

  const [tableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const fetchNotifications = useCallback(() => {
    const params = {
      page: tableFilters.page,
      product_bookings_filter: last30daysFilter,
      top_10_instructor_filter: top10InstructorBookingsFilter,
      top_10_client_filter: top10ClientBookingsFilter
    };

    axios.post('/v1/notifications/search', params)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical' }]);
      });
  // eslint-disable-next-line
  }, [debouncedSearch, last30daysFilter, top10InstructorBookingsFilter, top10ClientBookingsFilter]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const last30DaysActivator = (
    <Button plain onClick={toggleLast30daysActive} disclosure>
      {last30daysFilter === 7 ? t('events.last_7_days') : t('events.last_30_days')}
    </Button>
  );

  const last30DaysPopover = (
    <Popover
      active={last30daysactive}
      activator={last30DaysActivator}
      autofocusTarget="first-node"
      onClose={toggleLast30daysActive}
    >
      <ActionList
        actionRole="menuitem"
        items={[
          {
            content: t('events.last_7_days'),
            active: last30daysFilter === 7,
            onAction: () => setLast30daysFilter(7)
          },
          {
            content: t('events.last_30_days'),
            active: last30daysFilter === 30,
            onAction: () => setLast30daysFilter(30)
          }
        ]}
      />
    </Popover>
  );

  const top10InstructorBookingsName = () => {
    switch (top10InstructorBookingsFilter) {
      case (1): return t('events.last_month');
      case (3): return t('events.last_3_months');
      case (6): return t('events.last_6_months');
      case (12): return t('events.last_12_months');
      default: return t('events.last_12_months');
    }
  };

  const top10InstructorBookingsActivator = (
    <Button plain onClick={toggleTop10InstructorBookingsActive} disclosure>
      {top10InstructorBookingsName()}
    </Button>
  );

  const top10InstructorBookings = (
    <Popover
      active={top10InstructorBookingsActive}
      activator={top10InstructorBookingsActivator}
      autofocusTarget="first-node"
      onClose={toggleTop10InstructorBookingsActive}
    >
      <ActionList
        actionRole="menuitem"
        items={[
          {
            content: t('events.last_12_months'),
            active: top10InstructorBookingsFilter === 12,
            onAction: () => setTop10InstructorBookingsFilter(12)
          },
          {
            content: t('events.last_6_months'),
            active: top10InstructorBookingsFilter === 6,
            onAction: () => setTop10InstructorBookingsFilter(6)
          },
          {
            content: t('events.last_3_months'),
            active: top10InstructorBookingsFilter === 3,
            onAction: () => setTop10InstructorBookingsFilter(3)
          },
          {
            content: t('events.last_month'),
            active: top10InstructorBookingsFilter === 1,
            onAction: () => setTop10InstructorBookingsFilter(1)
          }
        ]}
      />
    </Popover>
  );

  const top10ClientBookingsName = () => {
    switch (top10ClientBookingsFilter) {
      case (1): return t('events.last_month');
      case (3): return t('events.last_3_months');
      case (6): return t('events.last_6_months');
      case (12): return t('events.last_12_months');
      default: return t('events.last_12_months');
    }
  };

  const top10ClientBookingsActivator = (
    <Button plain onClick={toggleTop10ClientBookingsActive} disclosure>
      {top10ClientBookingsName()}
    </Button>
  );

  const top10ClientBookings = (
    <Popover
      active={top10ClientBookingsActive}
      activator={top10ClientBookingsActivator}
      autofocusTarget="first-node"
      onClose={toggleTop10ClientBookingsActive}
    >
      <ActionList
        actionRole="menuitem"
        items={[
          {
            content: t('events.last_12_months'),
            active: top10ClientBookingsFilter === 12,
            onAction: () => setTop10ClientBookingsFilter(12)
          },
          {
            content: t('events.last_6_months'),
            active: top10ClientBookingsFilter === 6,
            onAction: () => setTop10ClientBookingsFilter(6)
          },
          {
            content: t('events.last_3_months'),
            active: top10ClientBookingsFilter === 3,
            onAction: () => setTop10ClientBookingsFilter(3)
          },
          {
            content: t('events.last_month'),
            active: top10ClientBookingsFilter === 1,
            onAction: () => setTop10ClientBookingsFilter(1)
          }
        ]}
      />
    </Popover>
  );

  return (
    <Page
      // title={customTitle()}
      subtitle={t('shared.what_is_happening')}
      separator
      fullWidth
    >
      {banner.length ? <Banners banners={banner} onDismissBanner={() => setBanner([])} /> : null}
      <div className="mb-4">
        <Layout>
          <Layout.Section oneThird>
            <HomeCard url="/calendar" subTitle={t('events.last_week')} number={data.eventCounts?.lastSeven} />
          </Layout.Section>
          <Layout.Section oneThird>
            <HomeCard url="/calendar" subTitle={t('events.events_today')} number={data.eventCounts?.today} />
          </Layout.Section>
          <Layout.Section oneThird>
            <HomeCard url="/calendar" subTitle={t('events.events_tomorrow')} number={data.eventCounts?.tomorrow} />
          </Layout.Section>
        </Layout>
      </div>
      {!props.isStaff ? (
        <div className="mb-4">
          <Layout>
            <Layout.Section oneHalf>
              <Card
                title={t('events.product_bookings')}
                actions={[
                  {
                    content: last30DaysPopover
                  }
                ]}
              >
                <Card.Section>
                  <div className="w-full h-80">
                    <ResponsiveLine
                      curve="monotoneX"
                      data={[{
                        id: 'prod_booking_last_30_days',
                        data: data.product_bookings_last_30_days
                      }]}
                      axisLeft={{ format: (e) => (Math.floor(e) === e ? e : '') }}
                      margin={{ top: 30, right: 25, bottom: 70, left: 30 }}
                      tooltip={(args) => `${args.point.data.x}: ${args.point.data.y}`}
                      pointSize={4}
                      colors={{ scheme: 'paired' }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -45,
                        legendOffset: 36,
                        legendPosition: 'middle'
                      }}
                      enableTouchCrosshair
                      useMesh
                    />
                  </div>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneHalf>
              <Card title={t('events.product_bookings_last_12_months')}>
                <Card.Section>
                  <div className="w-full h-80">
                    <ResponsiveLine
                      curve="monotoneX"
                      data={[{
                        id: 'prod_booking_last_year',
                        data: data.product_booking_last_year
                      }]}
                      margin={{ top: 30, right: 25, bottom: 70, left: 35 }}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -45,
                        legendOffset: 36,
                        legendPosition: 'middle'
                      }}
                      tooltip={(args) => `${args.point.data.x}: ${args.point.data.y}`}
                      pointSize={4}
                      colors={{ scheme: 'paired' }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      enableTouchCrosshair
                      useMesh
                    />
                  </div>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
          <div className="mt-4">
            <Layout>
              <Layout.Section>
                <Card
                  title={t('events.top_10_instructors_bookings')}
                  actions={[
                    {
                      content: top10InstructorBookings
                    }
                  ]}
                >
                  <Card.Section>
                    <div className="w-full h-80">
                      <ResponsiveBar
                        data={data.booking_data_by_user_and_month}
                        indexBy="email"
                        keys={['count']}
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'paired' }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        layout="vertical"
                      />
                    </div>
                  </Card.Section>
                </Card>
              </Layout.Section>
              <Layout.Section>
                <Card
                  title={t('events.top_10_client_bookings')}
                  actions={[
                    {
                      content: top10ClientBookings
                    }
                  ]}
                >
                  <Card.Section>
                    <div className="w-full h-80">
                      <ResponsiveBar
                        data={data.booking_data_by_client_and_month}
                        indexBy="email"
                        keys={['count']}
                        margin={{ top: 5, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ scheme: 'paired' }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        layout="vertical"
                      />
                    </div>
                  </Card.Section>
                </Card>
              </Layout.Section>
            </Layout>
          </div>
        </div>
      ) : ''}
      {/* <GettingStarted /> */}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  name: state.auth.name,
  isStaff: state.auth.role === 'staff'
});

export default connect(mapStateToProps)(Home);
