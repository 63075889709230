import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Badge, Card, DescriptionList, Icon, Layout, Loading, Page, ProgressBar, SkeletonPage, Stack, TextStyle, Tooltip } from '@shopify/polaris';
import { connect } from 'react-redux';
import { AlertMinor, CalendarMajor, HideMinor, ReturnMinor, ViewMinor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import FlashBanners from '../Shared/Banners';
import OtherUserModalForm from './Edit/OtherUserModalForm';
import { authCheckState } from '../../store/actions';

const User = (props) => {
  const [banner, setBanner] = useState([]);
  const [modalBanner, setModalBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(true);
  const [clientUsage, setClientUsage] = useState({});
  const [storageUsage, setStorageUsage] = useState({});
  const [user, setUser] = useState({
    savedAddresses: []
  });
  const [modals, setModals] = useState({
    showUserModalForm: false
  });

  const { t } = useTranslation();
  const history = useNavigate();
  const matchParams = useParams();

  const fetchUser = useCallback(() => {
    axios.get(`/v1/users/${matchParams.id}`)
      .then((res) => {
        if (mounted) {
          setClientUsage(res.data.clientUsage);
          setStorageUsage(res.data.storageUsage);
          setUser(res.data);
          setLoading(false);
        }
      })
      .catch(() => {
        setBanner([{ title: 'Cannot fetch user', status: 'critical' }]);
      });
  }, [mounted, matchParams.id]);

  const handleActiveToggle = (token, action) => {
    if (!window.confirm(`Are you sure you wish to ${action} this user?`)) {
      return;
    }

    axios.post(`/v1/users/${token}/${action}`)
      .then(() => {
        setUser({ ...user, accountState: user.active ? 'inactive' : 'active', active: !user.active });
        setBanner([{ title: `User ${action}d`, status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setBanner([{ title: `Cannot ${action} user`, status: 'critical', details: error.response.data.errors }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      });
  };

  const closeModals = () => {
    setModals({
      showUserModalForm: false
    });
    setModalBanner([]);
  };

  const handleSubmit = (userData) => {
    axios.patch(`/v1/users/${user.token}`, userData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        setUser({ ...user, ...response.data });
        closeModals();
        setBanner([{ title: 'User updated successfully', status: 'success' }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      })
      .catch((error) => {
        setModalBanner([{ title: 'Cannot update user', status: 'critical', details: error.response.data.errors }]);
        setTimeout(() => {
          setBanner([]);
        }, [3000]);
      });
  };

  useEffect(() => {
    fetchUser();
    return () => {
      setMounted(false);
    };
  }, [fetchUser]);

  let loadingContainer = null;
  if (loading) {
    loadingContainer = <Loading />;
  }

  const userInfo = () => {
    const items = [
      { term: 'Name', description: user.name },
      { term: 'Email', description: user.email },
      { term: 'Role', description: user.role },
      { term: 'Account State', description: user.accountState },
      { term: 'Created At', description: moment(user.createdAt).format('LLLL') },
      { term: 'Updated At', description: moment(user.updatedAt).format('LLLL') },
      { term: 'Last Sign In At', description: user.lastSignInAt && moment(user.lastSignInAt).format('LLLL') },
      { term: 'Last Sign in IP', description: user.lastSignInIp },
      { term: 'Sign In Count', description: user.signInCount },
      { term: 'Plan', description: user.plan }
    ];
    return (
      <Card
        sectioned
        title="Basic Info"
        actions={[{ content: 'Edit', onAction: (() => setModals({ ...modals, showUserModalForm: true })) }]}
      >
        <DescriptionList items={items} />
      </Card>
    );
  };

  const performImportJob = () => {
    axios.post(`/v1/users/${user.token}/run_import_job`)
      .then(() => {
        setBanner([{ title: 'Job is enqueued', status: 'success' }]);
      })
      .catch(() => {});
  };

  const handleLogin = () => {
    axios.post(`/v1/users/${user.token}/admin_sign_in`)
      .then(() => {
        history('/');
        props.checkAuthState();
      })
      .catch(() => {
      });
  };

  const pageActions = () => {
    const content = user.active ? 'Inactivate' : 'Activate';

    const actions = [{
      content: 'Login',
      onAction: () => handleLogin()
    }, {
      icon: user.active ? HideMinor : ViewMinor,
      content,
      onAction: () => handleActiveToggle(user.token, content.toLowerCase())
    }];

    if (user.instructor) {
      actions.push({
        icon: CalendarMajor,
        content: 'Calendar',
        onAction: () => history(`/instructors/${user.token}`)
      });
    }

    if (user.hasAccountSheet) {
      actions.push({
        icon: ReturnMinor,
        content: 'Run import job',
        onAction: () => performImportJob()
      });
    }

    return actions;
  };

  const cleanup = () => {
    setModals({ ...modals, showUserModalForm: false });
    setModalBanner([]);
  };

  let userForm = null;
  if (modals.showUserModalForm) {
    userForm = (
      <OtherUserModalForm
        title="Editing User"
        banner={modalBanner}
        user={user}
        editOtherUser
        handleSubmit={(formData) => handleSubmit(formData)}
        cleanup={cleanup}
      />
    );
  }

  const allowedClients = (c) => (c > 10000 ? t('plan.unlimited') : c);

  const studentUsageText = () => {
    if (clientUsage.clients / clientUsage.allowed_clients >= 1.0) {
      return (
        <Stack>
          <Stack.Item>{`${clientUsage.clients} / ${allowedClients(clientUsage.allowed_clients)}`}</Stack.Item>
          <Tooltip content={t('plan.usage_limit_reached')}>
            <Icon color="critical" source={AlertMinor} />
          </Tooltip>
        </Stack>
      );
    }

    return <Stack.Item>{`${clientUsage.clients} / ${allowedClients(clientUsage.allowed_clients)}`}</Stack.Item>;
  };

  const storageUsageText = () => {
    if (storageUsage.used_storage / storageUsage.allowed_storage >= 1.0) {
      return (
        <Stack>
          <Stack.Item>{`${storageUsage.used_storage} / ${storageUsage.allowed_storage} GB`}</Stack.Item>
          <Tooltip content={t('plan.usage_limit_reached')}>
            <Icon color="critical" source={AlertMinor} />
          </Tooltip>
        </Stack>
      );
    }

    return <Stack.Item>{`${storageUsage.used_storage} / ${storageUsage.allowed_storage} GB`}</Stack.Item>;
  };

  return (
    <Page
      title="User Details"
      breadcrumbs={[{ content: 'Back', onAction: () => history(-1) }]}
      secondaryActions={pageActions()}
      separator
    >
      {loadingContainer}
      {userForm}
      {banner.length ? <FlashBanners banners={banner} onDismissBanner={() => setBanner([])} /> : null}
      {!loading ? (
        <Layout>
          <Layout.Section>
            {userInfo()}
            <Card>
              <Card.Section>
                <Stack>
                  <Stack.Item fill>{t('plan.student_usage')}</Stack.Item>
                  {studentUsageText()}
                </Stack>
                <ProgressBar progress={clientUsage.percentage} />
              </Card.Section>
              <Card.Section>
                <Stack>
                  <Stack.Item fill>{t('plan.storage_usage')}</Stack.Item>
                  {storageUsageText()}
                </Stack>
                <ProgressBar progress={storageUsage.percentage} />
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section secondary>
            <Card title="Saved Addresses">
              <Card.Section />
              {user.savedAddresses.map((a, i) => (
                <Card.Section
                  title={(
                    <Stack>
                      <Stack.Item>
                        <TextStyle variation="strong">{a.label}</TextStyle>
                      </Stack.Item>
                      {a.preferred && <Badge status="info">{t('address.preferred')}</Badge>}
                    </Stack>
                  )}
                  key={i}
                >
                  <div>{a.address?.attentionName}</div>
                  <div>{a.address?.address}</div>
                  <div>{a.address?.addressLineTwo}</div>
                  <div>{`${a.address?.city}, ${a.address?.state || ''} ${a.address?.zipCode}`}</div>
                </Card.Section>
              ))}
            </Card>
          </Layout.Section>
        </Layout>
      ) : <SkeletonPage />}
    </Page>
  );
};

const mapPropsToDispatch = (dispatch) => ({ checkAuthState: (url, history) => dispatch(authCheckState(url, history)) });

export default connect(null, mapPropsToDispatch)(User);
