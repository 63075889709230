/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Profile from '../components/Settings/Profile';
import Help from '../components/Help/Help';
import Integrations from '../components/Settings/Integrations';
import CalendarSettings from '../components/Settings/CalendarSettings';
import Documentation from './Documentation';
import ZoomDocumentation from './ZoomDocumentation';
import Google from '../components/Settings/Integrations/Google';
// import Planning from '../components/Planning/Planning';
// import Messages from '../components/Messages/Messages';

const InstructorRoutes = () => (
  <Routes>
    <Route exact path="/help" element={<Help />} />
    <Route exact path="/zoom_documentation" element={<ZoomDocumentation />} />
    <Route exact path="/documentation" element={<Documentation />} />
    <Route exact path="/integrations" element={<Integrations />} />
    <Route exact path="/integrations/google" element={<Google />} />
    <Route exact path="/calendar_settings" element={<CalendarSettings />} />
    <Route exact path="/profile_settings" element={<Profile />} />
    <Route path="*" element={<Navigate to="/profile_settings" />} />
  </Routes>
);

const mapStateToProps = (state) => ({
  isFreeMember: state.auth.freeMember,
  isBasicMember: state.auth.basicMember,
  isPremiumMember: state.auth.premiumMember,
  isPremiumPlusMember: state.auth.premiumPlusMember,
  isStaff: state.auth.role === 'staff',
  hasStaffAccounts: state.auth.instructor || state.auth.admin,
  bookingPageAccess: state.auth.bookingPageAccess,
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  hasStripe: state.auth.hasStripe
});

export default connect(mapStateToProps)(InstructorRoutes);
