import React, { useState } from 'react';
import { Form, FormLayout, Modal, Select } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';
import PaymentOptions from '../Shared/PaymentOptions';

const MarkAsPaidModalForm = (props) => {
  const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod || 'cash');
  const [show, setShow] = useState(true);

  const { t } = useTranslation();

  const closeModal = () => {
    setShow(false);
    props.close();
  };

  const onSubmit = (unpaid = false) => {
    props.handleSubmit({
      id: props.optionalId,
      unpaid,
      payment_method: paymentMethod,
      participationId: props.participationId
    });
    props.close();
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title={t('invoices.payment_method')}
      primaryAction={!props.paid && {
        content: t('shared.confirm'),
        onAction: onSubmit
      }}
      secondaryActions={props.paid && [
        {
          destructive: true,
          content: t('invoices.mark_as_unpaid'),
          onAction: () => onSubmit(true)
        }
      ]}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <FormLayout>
            <Select
              disabled={props.paid}
              options={PaymentOptions(t)}
              onChange={setPaymentMethod}
              value={paymentMethod}
            />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

export default MarkAsPaidModalForm;
