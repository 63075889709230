import React from 'react';
import Switch from 'react-switch';

const SwitchToggle = (props) => {
  const handleChange = (data) => {
    props.handleChange(data);
  };

  return (
    <Switch
      id={Math.random().toString()}
      disabled={props.disabled}
      onChange={handleChange}
      checked={props.checked || false}
      onColor={props.onColor || '#86d3ff'}
      onHandleColor={props.onHandleColor || '#2693e6'}
      handleDiameter={30}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48}
    />
  );
};

export default SwitchToggle;
