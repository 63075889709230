import React from 'react';

const Emoji = (props) => (
  <span
    style={{ paddingLeft: '6px', paddingRight: '6px' }}
    role="img"
    aria-label={props.label || ''}
    aria-hidden={Boolean(props.label)}
  >
    {props.symbol}
  </span>
);
export default Emoji;
