import React from 'react';
import Select from 'react-select';
import spacetime from 'spacetime';
import soft from 'timezone-soft';
import allTimezones from './TimezoneList';

export default function TimezoneSelect({
  value,
  onBlur,
  onChange,
  labelStyle = 'original',
  timezones,
  ...props
}) {
  // eslint-disable-next-line no-param-reassign
  timezones = allTimezones;
  const getOptions = React.useMemo(() => Object.entries(timezones)
    .reduce((selectOptions, zone) => {
      const now = spacetime.now(zone[0]);
      const tz = now.timezone();
      const tzStrings = soft(zone[0]);

      let label = '';
      const abbr = now.isDST()
        // eslint-disable-next-line operator-linebreak
        ?
        tzStrings[0].daylight?.abbr
        // eslint-disable-next-line operator-linebreak
        :
        tzStrings[0].standard?.abbr;
      const altName = now.isDST()
        ? tzStrings[0].daylight?.name
        : tzStrings[0].standard?.name;

      const min = tz.current.offset * 60;
      // eslint-disable-next-line no-bitwise
      const hr = `${(min / 60) ^ 0}:${min % 60 === 0 ? '00' : Math.abs(min % 60)}`;
      const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`;

      switch (labelStyle) {
        case 'original':
          label = prefix;
          break;
        case 'altName':
          label = `${prefix} ${altName?.length ? `(${altName})` : ''}`;
          break;
        case 'abbrev':
          label = `${prefix} ${abbr?.length < 5 ? `(${abbr})` : ''}`;
          break;
        default:
          label = `${prefix}`;
      }

      selectOptions.push({
        value: tz.name,
        label,
        offset: tz.current.offset,
        abbrev: abbr,
        altName
      });

      return selectOptions;
    }, [])
    .sort((a, b) => a.offset - b.offset), [labelStyle, timezones]);

  const handleChange = (tz) => {
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(tz);
  };

  const findFuzzyTz = (zone) => {
    let currentTime = spacetime.now('GMT');
    try {
      currentTime = spacetime.now(zone);
    } catch (err) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return getOptions
      .filter(
        (tz) => tz.offset === currentTime.timezone().current.offset
      )
      .map((tz) => {
        let score = 0;
        if (
          currentTime.timezones[tz.value.toLowerCase()]
          && !!currentTime.timezones[tz.value.toLowerCase()].dst
            === currentTime.timezone().hasDst
        ) {
          if (
            tz.value
              .toLowerCase()
              .indexOf(
                currentTime.tz.substr(currentTime.tz.indexOf('/') + 1)
              ) !== -1
          ) {
            score += 8;
          }
          if (
            tz.label
              .toLowerCase()
              .indexOf(
                currentTime.tz.substr(currentTime.tz.indexOf('/') + 1)
              ) !== -1
          ) {
            score += 4;
          }
          if (
            tz.value
              .toLowerCase()
              .indexOf(currentTime.tz.substr(0, currentTime.tz.indexOf('/')))
          ) {
            score += 2;
          }
          score += 1;
        } else if (tz.value === 'GMT') {
          score += 1;
        }
        return { tz, score };
      })
      .sort((a, b) => b.score - a.score)
      .map(({ tz }) => tz)[0];
  };

  // eslint-disable-next-line consistent-return
  const parseTimezone = (zone) => {
    if (typeof zone === 'object' && zone.value && zone.label) return zone;
    if (typeof zone === 'string') {
      return (
        getOptions.find((tz) => tz.value === zone)
        || (zone.indexOf('/') !== -1 && findFuzzyTz(zone))
      );
    } if (zone.value && !zone.label) {
      return getOptions.find((tz) => tz.value === zone.value);
    }
  };

  return (
    <Select
      value={parseTimezone(value)}
      onChange={handleChange}
      options={getOptions}
      onBlur={onBlur}
      {...props}
    />
  );
}
