import React from 'react';
import { Select } from '@shopify/polaris';
import { handleFilterChange } from '../FilterEngine';

const productTypeFilterDefinition = (t, props, setTableFilters, options, selected) => ({
  key: 'product_type',
  label: t('shared.type'),
  filter: (
    <Select
      label={t('shared.type')}
      labelHidden
      options={options}
      value={selected}
      onChange={handleFilterChange(props, setTableFilters, 'product_type')}
    />
  ),
  shortcut: true
});

export default productTypeFilterDefinition;
