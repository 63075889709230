/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Banner, Card, Checkbox, Form, FormLayout, Label, Modal, Stack, TextField } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

const CustomField = (props) => {
  const [show, setShow] = useState(true);
  const [error, setError] = useState(false);
  const [customField, setCustomField] = useState(props.customField || {});

  const handleCustomFieldChange = (field) => (value) => {
    const temp = { ...customField };
    temp[field] = value;
    if (field === 'editable_by_client' && value) {
      temp.viewable_by_client = true;
    }

    if (field === 'viewable_by_client' && !value && temp.editable_by_client === true) {
      temp.editable_by_client = false;
    }
    setCustomField(temp);
  };

  const { t } = useTranslation();

  const closeModal = () => {
    setShow(false);
    props.close();
  };

  const onSubmit = () => {
    setError(false);

    if (!customField.label && customField._destroy !== '1') {
      setError(true);
      return;
    }

    props.handleSubmit(customField);
    props.close();
  };

  return (
    <Modal
      open={show}
      onClose={closeModal}
      title={props.title}
      primaryAction={{
        content: t('shared.save'),
        onAction: onSubmit
      }}
      secondaryActions={props.handleDelete ? [
        {
          destructive: true,
          content: t('shared.delete'),
          onAction: props.handleDelete
        }
      ] : []}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <FormLayout>
            {error ? (
              <Banner title="Error" status="warning" onDismiss={() => setError(false)}>
                <p>{t('custom_fields.labels_error')}</p>
              </Banner>
            ) : null}
            <Card>
              <Card.Section>
                <Stack alignment="center">
                  <TextField
                    type="text"
                    label={t('shared.label')}
                    value={customField.label}
                    onChange={handleCustomFieldChange('label')}
                  />
                  <TextField
                    type="text"
                    label={t('shared.value')}
                    value={customField.value}
                    onChange={handleCustomFieldChange('value')}
                  />
                  <Stack.Item fill>
                    <Label>{t('client.client')}</Label>
                    <Checkbox
                      checked={customField.editable_by_client}
                      onChange={handleCustomFieldChange('editable_by_client')}
                      label={t('custom_fields.editable')}
                    />
                    <br />
                    <Checkbox
                      checked={customField.viewable_by_client}
                      onChange={handleCustomFieldChange('viewable_by_client')}
                      label={t('custom_fields.viewable')}
                    />
                  </Stack.Item>
                </Stack>
              </Card.Section>
            </Card>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

export default CustomField;
