/* eslint-disable consistent-return */

import moment from 'moment';

/* eslint-disable default-case */
export function monthName(month) {
  switch (month) {
    case 0:
      return 'january';
    case 1:
      return 'february';
    case 2:
      return 'march';
    case 3:
      return 'april';
    case 4:
      return 'may';
    case 5:
      return 'june';
    case 6:
      return 'july';
    case 7:
      return 'august';
    case 8:
      return 'september';
    case 9:
      return 'october';
    case 10:
      return 'november';
    case 11:
      return 'december';
  }
}

export function weekdayName(weekday) {
  switch (weekday) {
    case 0:
      return 'sunday';
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
  }
}

// eslint-disable-next-line no-unused-vars
export function getDaysArrayByMonth(year, month, timeSlotsMonth) {
  const m = moment();

  m.set({ year, month });

  const daysInMonth = m.daysInMonth();
  const arrDays = [];

  let a = m.startOf('month').isoWeekday();

  if (a === 1) a = 8;

  // get days of month before
  const numberOfDaysInLastMonth = m.subtract(1, 'months').daysInMonth();
  const startEndOfMonth = numberOfDaysInLastMonth - a + 2; // weekday starts at 1

  for (let i = startEndOfMonth; i <= numberOfDaysInLastMonth; i++) {
    const current = m.date(i);
    const day = current.format('YYYY-MM-DD');
    const isToday = moment().format('YYYY-MM-DD') === day;

    arrDays.push({
      date: day,
      isSelected: isToday,
      slots: 0,
      isToday
    });
  }

  // get days of month
  m.add(1, 'months'); // add month back

  for (let i = 1; i <= daysInMonth; i++) {
    const current = m.date(i);
    const day = current.format('YYYY-MM-DD');
    const isToday = moment().format('YYYY-MM-DD') === day;

    arrDays.push({
      date: day,
      isCurrentMonth: true,
      isSelected: isToday,
      slots: 0,
      isToday
    });
  }

  const currentArrDaysLength = arrDays.length;
  const fillDays = 42 - currentArrDaysLength;

  // get days of month after
  m.add(1, 'months');

  for (let i = 1; i <= fillDays; i++) {
    const current = m.date(i);
    const day = current.format('YYYY-MM-DD');
    const isToday = moment().format('YYYY-MM-DD') === day;

    arrDays.push({
      date: day,
      isSelected: isToday,
      slots: 0,
      isToday
    });
  }

  // fill slots
  timeSlotsMonth.forEach((k) => {
    let dateIndex = null;

    const keys = Object.keys(k); // array always only length 1
    dateIndex = arrDays.findIndex((d) => d.date === keys[0]);

    if (dateIndex !== -1) {
      arrDays[dateIndex].slots = k[keys[0]];
    }
  });

  return arrDays;
}
