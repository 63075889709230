import { Layout, Page } from '@shopify/polaris';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HomeCard from '../Home/HomeCard';

const CreateInvoice = () => {
  const { t } = useTranslation();
  const history = useNavigate();

  return (
    <Page
      separator
      title={t('invoices.create')}
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history(-1) }]}
    >
      <Layout>
        <Layout.Section>
          <HomeCard url="/invoices/new" subTitle={t('invoices.manual_description')} text={t('invoices.manual')} />
        </Layout.Section>
        <Layout.Section>
          <HomeCard url="/invoices/auto" subTitle={t('invoices.automatic_description')} text={t('invoices.automatic')} />
        </Layout.Section>
      </Layout>
      <img width="100%" src="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg" alt="Invoice" />
    </Page>
  );
};

export default CreateInvoice;
