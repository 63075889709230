import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, FormLayout, Modal, TextField } from '@shopify/polaris';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import axios from 'axios';
import useDebounce from '../../hooks/useDebounce';

const NewEventPackage = (props) => {
  const [eventPackageData, setEventPackageData] = useState({});
  const [showNewClient, setShowNewClient] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [inputValue, setValue] = useState('');
  const [selected, setSelected] = useState({});
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const closeModal = () => {
    setEventPackageData({});
    setShowNewClient(false);
    props.close();
  };

  const debouncedSearch = useDebounce(inputValue, 400);

  // Start Clients
  const fetchClients = useCallback(() => {
    setLoading(true);

    const params = {
      per_page: 30,
      page: '',
      search: inputValue,
      q: ''
    };

    axios.post('/v1/clients/search_resource', params)
      .then((res) => {
        setDefaultOptions(res.data.clients);
      })
      .catch(() => {
        // setBanner([{ title: t('client.cannot_get_list'), status: 'critical', details: err.response.data.error }]);
      })
      .then(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  const handleChange = (field) => (value) => setEventPackageData({ ...eventPackageData, [field]: value });

  const onSubmit = () => {
    setFormErrors({});

    if (!formValid()) return;

    const params = {
      event_package: eventPackageData,
      client_token: selected.id
    };

    props.handleSubmit(params);
    props.close();
  };

  const formValid = () => {
    const e = {};
    if (!eventPackageData.original_value) {
      e.original_value = t('shared.validations.required');
    }
    if (!eventPackageData.name) {
      e.name = t('shared.validations.required');
    }

    setFormErrors(e);
    return Object.entries(e).length === 0;
  };

  return (
    <Modal
      open={showNewClient}
      onClose={closeModal}
      title={props.title}
    >
      <Modal.Section>
        <Form onSubmit={onSubmit}>
          <FormLayout>
            {/* <TextField error={formErrors.client} label="Client" value={eventPackageData.client} onChange={handleChange('client')} /> */}
            <div className={`react-select ${selected && Object.keys(selected).length ? 'mb-4' : 'h-96'}`}>
              <label htmlFor="select">{t('shared.select_client')}</label>
              <Select
                // cacheOptions
                inputValue={inputValue}
                isClearable
                onInputChange={setValue}
                value={selected}
                isDisabled={selected && Object.keys(selected).length}
                onChange={setSelected}
                // noOptionsMessage="Loading"
                isLoading={loading}
                defaultOptions={defaultOptions}
                options={defaultOptions}
                getOptionValue={(user) => user.id}
                getOptionLabel={(user) => user.label}
              />
            </div>
            {selected && Object.keys(selected).length ? (
              <FormLayout>
                <TextField error={formErrors.name} label={t('user.name')} value={eventPackageData.name} onChange={handleChange('name')} />
                <TextField error={formErrors.original_value} type="number" label={t('eventpackages.number_of_events')} value={eventPackageData.original_value} onChange={handleChange('original_value')} />
                <TextField error={formErrors.note} label={t('comment.comment')} value={eventPackageData.note} onChange={handleChange('note')} />
                <Button primary submit>{t('shared.add')}</Button>
              </FormLayout>
            ) : null}
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isInstructor: state.auth.instructor
});

export default connect(mapStateToProps)(NewEventPackage);
