/* eslint-disable eqeqeq */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Settings } from 'luxon';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import allLocales from '@fullcalendar/core/locales-all';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import luxon2Plugin from '@fullcalendar/luxon2';
import { Toast } from '@shopify/polaris';
import EventEdit from './EventEdit';
import NewEvent from './NewEvent';
import ModalService from '../Shared/Services/ModalService';
import ConfirmationModal from '../Shared/ConfirmationModal/ConfirmationModal';

// Used for dialog Modal
const confirmationModalRef = React.createRef();

const HolidayCalendar = (props) => {
  const [calendarViewDates, setCalendarViewDates] = useState('');
  const [showEventEdit, setShowEventEdit] = useState(false);
  const [displayEventDetails, setDisplayEventDetails] = useState(false);
  const [showNewEvent, setShowNewEvent] = useState(false);
  const [newEventData, setNewEventData] = useState({});
  const [eventToEdit, setEventToEdit] = useState({});
  const [events, setEvents] = useState([]);
  const [calendarSettings, setCalendarSettings] = useState({});

  const calendarRef = useRef();

  const { t } = useTranslation();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={3000} />
  ) : null;

  const eventModifier = (e) => {
    e.start = new Date(e.start);
    e.end = new Date(e.end);
    return e;
  };

  useEffect(() => {
    Settings.defaultZoneName = calendarSettings.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  }, [calendarSettings.timeZone]);

  const fetchEvents = (start, end) => {
    axios.post('/v1/holiday_events/search', { start, end })
      .then((response) => {
        setCalendarSettings(response.data.calendarSettings);
        response.data.events.forEach((e) => {
          eventModifier(e);
        });
        setEvents(response.data.events);
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  const newEvent = (event) => {
    setNewEventData(event);
    setShowNewEvent(true);
  };

  const handleCreateNewEvent = async (event) => {
    const data = {
      event: { ...event },
      start: calendarViewDates.start,
      end: calendarViewDates.end
    };

    if (data.event.allDay) {
      data.event.all_day = true;
    }

    data.event.start_time = data.event.start;
    data.event.end_time = data.event.end;

    axios.post('/v1/holiday_events', data)
      .then(() => {
        fetchEvents(calendarViewDates.start, calendarViewDates.end);
        toggleToastActive(t('calendar.new_event_created'));
      })
      .catch((err) => {
        if (err.response.data?.exception?.includes('Select less')) {
          toggleToastActive(t('calendar.create_less_events'));
        } else {
          toggleToastActive(t('shared.something_went_wrong'));
        }
      });
  };

  const updateEvent = async (event) => {
    if (event.allDay) {
      // eslint-disable-next-line
      event.all_day = true;
    }

    axios.patch(`/v1/holiday_events/${event.token}`, { event })
      .then(() => {
        fetchEvents(calendarViewDates.start, calendarViewDates.end);
        toggleToastActive(t('shared.success'));
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      });
  };

  const handleDeleteEvent = async (event) => {
    axios.delete(`/v1/holiday_events/${event.token}`, { data: { notify: false } })
      .then(() => {
        fetchEvents(calendarViewDates.start, calendarViewDates.end);
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      });
  };

  const handleDelete = (event) => {
    handleDeleteEvent(event);
  };

  const editEvent = (event) => {
    if (!event.event.extendedProps.isEditable) return;

    const updatable = {
      start: event.event.start,
      end: event.event.end,
      token: event.event.extendedProps.token,
      title: event.event.title
    };
    setEventToEdit(updatable);
    setDisplayEventDetails(true);
  };

  const closeModal = () => {
    setShowNewEvent(false);
    setShowEventEdit(false);
    setDisplayEventDetails(false);
  };

  const displayEventModal = displayEventDetails
    ? (
      <EventEdit
        active={displayEventDetails}
        close={closeModal}
        handleSubmit={updateEvent}
        handleDelete={handleDelete}
        event={eventToEdit}
      />
    ) : null;

  const eventEditModal = showEventEdit
    ? (
      <EventEdit
        active={showEventEdit}
        close={closeModal}
        handleSubmit={updateEvent}
        handleDelete={handleDelete}
        event={eventToEdit}
      />
    ) : null;

  const newEventModal = showNewEvent
    ? (
      <NewEvent
        active={showNewEvent}
        close={closeModal}
        handleSubmit={handleCreateNewEvent}
        event={newEventData}
      />
    ) : null;

  const resizeEvent = (info) => {
    const data = {
      token: info.event.extendedProps.token,
      start_time: info.event.start,
      end_time: info.event.end
    };

    updateEvent(data);
  };

  const viewWillUnmount = () => {
    setEvents([]);
  };

  const initialView = () => 'dayGridMonth';

  // const setCalendarNavigation = () => 'dayGridMonth';

  const updateCalendarDates = (data) => {
    const start = data.dig('view', 'activeStart');
    const end = data.dig('view', 'activeEnd');
    setCalendarViewDates({ start, end });
    fetchEvents(start, end);
  };

  const viewDidMount = (data) => {
    const start = data.dig('view', 'activeStart');
    const end = data.dig('view', 'activeEnd');
    setCalendarViewDates({ start, end });
    fetchEvents(start, end);
  };

  const setCalendarRange = (data) => {
    setCalendarViewDates({
      start: data.view.activeStart,
      end: data.view.activeEnd
    });
  };

  const moveEvent = (info) => {
    const { extendedProps, start, end } = info.event;

    const data = {
      token: extendedProps.token,
      start_time: start,
      end_time: end
    };
    updateEvent(data);
  };

  const calendarForm = (
    <FullCalendar
      ref={calendarRef}
      schedulerLicenseKey="0924439612-fcs-253339312338"
      timeZone={calendarSettings.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone}
      resourceGroupField="groupId"
      resourceOrder=""
      firstDay={parseInt((calendarSettings.firstDay || '0'), 10)}
      weekends={calendarSettings.weekends}
      nowIndicator
      viewWillUnmount={viewWillUnmount}
      viewDidMount={viewDidMount}
      height="auto"
      datesSet={updateCalendarDates}
      slotLabelDidMount={setCalendarRange}
      locales={allLocales}
      locale={props.lang}
      initialView={initialView()}
      headerToolbar={{
        left: 'title',
        center: 'dayGridMonth,multiMonthYear',
        right: 'today prev,next'
      }}
      plugins={[
        dayGridPlugin,
        luxon2Plugin,
        interactionPlugin,
        resourceTimelinePlugin,
        multiMonthPlugin
      ]}
      editable
      selectable
      select={newEvent}
      eventResize={resizeEvent}
      events={events}
      eventClick={editEvent}
      eventDrop={moveEvent}
      eventContent={(args) => (
        args.event.extendedProps.staffName ? (
          <div style={{ overflow: 'hidden' }}>
            {`${args.event.extendedProps.staffName}: ${args.event.title}`}
          </div>
        ) : args.event.title
      )}
    />
  );

  return (
    <div className="bg-white">
      <ModalService ref={confirmationModalRef}>
        <ConfirmationModal />
      </ModalService>
      {toastMarkup}
      {displayEventModal}
      {eventEditModal}
      {newEventModal}
      {calendarForm}
      {!props.isStaff && (
        <footer className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 items-center justify-between">
            <div className="mt-8 md:mt-0">
              <p className="text-center text-base text-gray-400">{t('calendar.time_off_calendar')}</p>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  name: state.auth.name,
  token: state.auth.token,
  lang: state.auth.lang,
  timezone: state.auth.timezone,
  isStaff: state.auth.role === 'staff'
});

export default connect(mapStateToProps)(HolidayCalendar);
