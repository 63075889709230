import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, OptionList, Spinner, Stack, Scrollable } from '@shopify/polaris';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import classes from './SearchResults.module.scss';
import TextBadgeStatus from '../Shared/TextBadgeStatus';

const SearchResults = (props) => {
  const [mounted, setMounted] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [results, setResults] = useState({});

  const history = useNavigate();
  const { t } = useTranslation();

  const fetchResults = useCallback(() => {
    const params = {
      search: props.searchValue
    };

    axios.post('/v1/search/global', params)
      .then((res) => {
        if (mounted) {
          setResults(res.data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage('Cannot get search results');
      });
  }, [mounted, props.searchValue]);

  useEffect(() => {
    setMounted(true);
    setLoading(true);
    setErrorMessage(null);
    setResults({});
    if (props.searchValue.length) {
      fetchResults();
    }
    return () => {
      setMounted(false);
    };
  }, [fetchResults, props.searchValue]);

  const renderClients = () => {
    if (results.clients && results.clients.length) {
      return results.clients.map((data) => ({
        value: data.url,
        label: (
          <Stack>
            <div>
              {`${data.name} - ${data.email}`}
            </div>
            <TextBadgeStatus active={data.active} />
          </Stack>
        )
      }));
    }

    return [];
  };

  const renderStaffs = () => {
    if (results.staffs && results.staffs.length) {
      return results.staffs.map((data) => ({
        value: data.url,
        label: (
          <Stack>
            <div>
              {`${data.name} - ${data.email}`}
            </div>
            <TextBadgeStatus active={data.active} />
          </Stack>
        )
      }));
    }

    return [];
  };

  const renderProducts = () => {
    if (results.products && results.products.length) {
      return results.products.map((data) => ({
        value: data.url,
        label: (
          <Stack>
            <div>
              {data.name}
            </div>
            <TextBadgeStatus active={data.active} />
          </Stack>
        )
      }));
    }

    return [];
  };

  const sections = () => {
    const list = [];
    const requestItems = renderClients();
    const staffItems = renderStaffs();
    const productItmes = renderProducts();

    if (requestItems.length) {
      list.push({ title: t('client.clients'), options: requestItems });
    }

    if (staffItems.length) {
      list.push({ title: t('staffs.plural'), options: staffItems });
    }

    if (productItmes.length) {
      list.push({ title: t('products.singular'), options: productItmes });
    }

    return list;
  };

  const handleSelection = (value) => {
    history(value[0]);
    props.onSelection();
  };

  const cardBody = () => {
    if (loading) {
      return <div className={classes.CenteredResult}><Spinner size="large" color="teal" /></div>;
    }

    if (errorMessage) {
      return <div className={classes.CenteredResult}>{errorMessage}</div>;
    }

    const list = sections();

    if (!list.length) {
      return <div className={classes.CenteredResult}>No Results Found</div>;
    }

    return (
      <OptionList
        onChange={handleSelection}
        sections={list}
        selected=""
      />
    );
  };

  return (
    <Scrollable>
      <Card sectioned>
        {cardBody()}
      </Card>
    </Scrollable>
  );
};

export default SearchResults;
