import { handleFilterChange } from '../FilterEngine';

const unpaidInvoiceFilterActive = (t, props, setTableFilters, active) => ({
  key: 'unpaid',
  label: `Status: ${active.map((el) => t(`invoices.${el}`)).join(', ')}`,
  onRemove: handleFilterChange(props, setTableFilters, 'unpaid', 'reset', ''),
  filter: 'aasm_state_in',
  value: active
});

export default unpaidInvoiceFilterActive;
