import React from 'react';
import { RadioGroup } from '@headlessui/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PnbBadge from './PnbBadge';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const StackedCards = (props) => {
  const { t } = useTranslation();

  return (
    <RadioGroup value={props.selection} onChange={props.setSelection}>
      <RadioGroup.Label className="sr-only">Membership</RadioGroup.Label>
      <div className="space-y-4">
        {props.data.map((plan) => (
          <RadioGroup.Option
            key={plan.token}
            value={plan.token}
            className={({ checked, active }) => classNames(
              checked ? 'border-transparent' : 'border-gray-300',
              active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
              'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none'
            )}
          >
            {({ active, checked }) => (
              <>
                <div className="flex items-center">
                  <div className="text-sm">
                    <RadioGroup.Label as="p" className="font-medium text-gray-900">
                      {plan.title}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="div" className="text-gray-500">
                      <p className="sm:inline">
                        {plan.description}
                      </p>
                      {plan.start_date ? (
                        <PnbBadge color="bg-green-200 text-green-800" title={`${t('shared.start_date')}: ${moment(plan.start_date).format('LL')}`} />
                      ) : ''}
                      {plan.cancel_at ? (
                        <PnbBadge color="bg-red-200 text-red-800" title={`${t('shared.cancel_at')}: ${moment(plan.cancel_at).format('LL')}`} />
                      ) : ''}
                      {plan.sessions ? (
                        <PnbBadge color="bg-blue-200 text-blue-800 my-1" title={`${t('membership.sessions')}: ${plan.sessions}`} />
                      ) : ''}
                      {plan.renewable_sessions ? (
                        <PnbBadge color="bg-blue-200 text-blue-800 my-1" title={t('membership.sessions_will_renew')} />
                      ) : ''}
                    </RadioGroup.Description>
                  </div>
                </div>
                <RadioGroup.Description as="div" className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                  <div className="font-medium text-gray-900 whitespace-nowrap">{plan.price}</div>
                  {plan.pricing_desc !== '/' && <div className="ml-1 text-gray-500 sm:ml-0">{plan.pricing_desc}</div>}
                </RadioGroup.Description>
                <div
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-indigo-500' : 'border-transparent',
                    'absolute -inset-px rounded-lg pointer-events-none'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default StackedCards;
