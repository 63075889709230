/* eslint-disable */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FolderIcon, DocumentTextIcon, ShareIcon as ShareIconSolid } from '@heroicons/react/20/solid';

// import {
//   draggable,
//   draggableHorizontal,
//   draggableVertical
// } from './draggable-svg';
import styles from './Draggable.module.scss';
import { ShareIcon } from '@heroicons/react/24/outline';
// import { Handle } from '../../SideMenu/components/TreeItem/components';

export const Draggable = forwardRef(
  (
    {
      childOnly,
      axis,
      dragOverlay,
      dragging,
      handle,
      label,
      name,
      isShared,
      listeners,
      translate,
      item,
      isContainer,
      handleSingleClick,
      handleDoubleClick,
      ...props
    },
    ref
  ) => {
    // const [currentName] = useState(name);

    const numClicks = useRef(0);
    const singleClickTimer = useRef();

    const [shiftHeld, setShiftHeld] = useState(false);

    const downHandler = ({ key }) => {
      if (key === 'Shift') {
        setShiftHeld(true);
      }
    };

    const upHandler = ({ key }) => {
      if (key === 'Shift') {
        setShiftHeld(false);
      }
    };

    useEffect(() => {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
      return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
      };
    }, []);

    const handleClick = () => {
      numClicks.current++;

      // if (shiftHeld && numClicks.current === 1) {
      // implement multiple selected items
      if (numClicks.current === 1) {
        singleClickTimer.current = setTimeout(() => {
          numClicks.current = 0;
          handleSingleClick(item);
        }, 400);
      } else if (numClicks.current === 2) {
        clearTimeout(singleClickTimer.current);
        numClicks.current = 0;
        handleSingleClick(item);
      }
    };

    return (
      <div
        role="button"
        onKeyDown={() => {}}
        tabIndex={0}
        cursor="grab"
        {...listeners}
        ref={ref}
        onClick={handleClick}
        className={classNames(
          'm-1',
          'bg-white',
          isContainer ? 'border hover:border-indigo-500/100 rounded-lg px-2 min-w-[180px] max-w-[180px]' : 'border hover:border-indigo-500/100 rounded-lg min-w-[140px] max-w-[140px] min-h-[140px] max-h-[140px]',
          styles.Draggable,
          dragOverlay && styles.dragOverlay,
          dragging && styles.dragging,
          dragging && 'opacity-50'
        )}
      >
        {isContainer ? (
          !childOnly ? (
            <div>
              <button
                {...props}
                className="truncate min-w-min"
                aria-label="Draggable"
                tabIndex={handle ? -1 : undefined}
              >
                <FolderIcon className="h-9 w-9 fill-zinc-600 hover:fill-zinc-500" />
                {label ? <label className="max-w-[150px] truncate text-xs">{label}</label> : null}
                {isShared && <ShareIconSolid className="h-3 w-3 absolute top-5 left-4 text-white" />}
              </button>
            </div>
          ) : props.children
        ) : (
          <div className="bg-white">
            <button
              ref={ref}
              // {...props}
              aria-label="Draggable"
              {...(handle ? {} : listeners)}
              tabIndex={handle ? -1 : undefined}
              className="w-full py-8"
            >

              {isShared && <ShareIcon className="h-3 w-3 absolute top-0 right-2" />}


              <div className="bg-white">
                <DocumentTextIcon className="h-10 w-10 fill-sky-500 hover:fill-cyan-500" />
              </div>

              {/* <FolderOpenIcon className="h-20 w-20 fill-cyan-400 hover:fill-cyan-500" /> */}
              {/* {axis === 'Vertical'
                ? draggableVertical
                : axis === 'Horizontal'
                  ? draggableHorizontal
                  : draggable} */}
              {/* {handle ? <Handle {...(handle ? listeners : {})} /> : null} */}
            </button>
            {label ? <label className="truncate border-t text-xs">{label}</label> : null}
          </div>
        )}
      </div>
    );
  }
);
