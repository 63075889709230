import React, { forwardRef, useImperativeHandle } from 'react';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/defaultV2.min.css';
import showdown from 'showdown';

const DisplayForm = forwardRef((props, ref) => {
  const survey = new Model(props.form_data);
  if (props.theme) survey.applyTheme(JSON.parse(props.theme));
  // survey.showCompletedPage = false;
  survey.showCompleteButton = props.showCompleteButton ?? true;
  // eslint-disable-next-line no-unused-vars
  survey.onComplete.add((sender, options) => {
    // set data to parent
    // console.log(JSON.stringify(sender.data, null, 3));

    props.finalSubmit(JSON.stringify(sender.data), options);
  });

  const handleCustomSubmit = () => {
    survey.completeLastPage();
  };

  survey.onTextMarkdown.add((_survey, options) => {
    const converter = new showdown.Converter();
    const html = converter.makeHtml(options.text);
    // eslint-disable-next-line no-param-reassign
    options.html = html;
  });

  // Use imperative handle to expose the method to the parent
  useImperativeHandle(ref, () => ({
    handleCustomSubmit
  }));

  return (
    <div className="no-twp">
      <Survey model={survey} />
    </div>
  );
});

export default DisplayForm;
