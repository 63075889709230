import { connect } from 'react-redux';

const CurrencyFormatter = (props) => {
  const format = (props.productCurrency || props.currency) === 'eur' ? 'de' : props.lang;

  const formatter = new Intl.NumberFormat(format, {
    style: 'currency',
    currency: (props.productCurrency || props.currency) || 'USD'
  });

  return formatter.format(props.value);
};

const mapStateToProps = (state) => ({
  currency: state.auth.currency,
  lang: state.auth.lang
});

export default connect(mapStateToProps)(CurrencyFormatter);
