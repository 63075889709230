/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters, Card, Toast, SkeletonPage, Button, IndexTable, EmptySearchResult, Page } from '@shopify/polaris';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Banners from '../Shared/Banners';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';

const EventPackagesClientView = () => {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventPackages, setEventPackages] = useState([]);
  const [lastPage, setLastPage] = useState(null);

  const [toastActive, setToastActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const fetchEventPackages = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      q: '',
      s: { sorts: tableFilters.sorts }
    };

    axios.post('/v1/event_packages/search', params)
      .then((res) => {
        setLastPage(res.data.lastPage);
        setEventPackages(res.data.event_packages);
        setLoading(false);
      })
      .catch((err) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: err.response }]);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const filters = [];

  const placeHolderText = t('user.search');

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={() => {}}
      appliedFilters={[]}
      queryPlaceholder={placeHolderText}
    />
  );

  useEffect(() => {
    fetchEventPackages();
  }, [fetchEventPackages]);

  const dismissBanner = () => {
    setBanner([]);
  };

  const headings = [
    { title: t('user.name') },
    { title: t('user.email') },
    { title: t('comment.comment') },
    { title: t('shared.created_at') },
    { title: t('eventpackages.initial_event_count') },
    { title: t('eventspackages.remaining_event_count') }
  ];

  const resourceName = {
    singular: t('staff.staff'),
    plural: t('staff.staff')
  };

  const rowMarkup = eventPackages.map(({ id, client, name, note, token, original_value, current_value, created_at }) => (
    <IndexTable.Row
      id={id}
      key={id}
    >
      <IndexTable.Cell>
        <Button plain url={`/event_packages/${token}/event_package_transactions`}>{name}</Button>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {client.email}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {note}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {moment(created_at).format('LLLL')}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {original_value}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {current_value}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  const emptyStateMarkup = (
    <EmptySearchResult
      title={t('eventpackages.no_event_packages_available')}
      withIllustration
    />
  );

  // eslint-disable-next-line no-unused-vars
  const content = (
    <IndexTable
      resourceName={resourceName}
      headings={headings}
      rows={eventPackages}
      itemCount={eventPackages.length}
      selectable={false}
      emptyState={emptyStateMarkup}
    >
      {rowMarkup}
    </IndexTable>
  );

  return (
    <Page
      title={t('eventpackages.event_packages')}
      fullWidth
      separator
    >
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      {toastMarkup}
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            {content}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={eventPackages}
        lastPage={lastPage}
      />
    </Page>
  );
};

export default EventPackagesClientView;
