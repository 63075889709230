import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Card, Banner } from '@shopify/polaris';
import axios from 'axios';
import moment from 'moment';
import ExternalLink from '../../Shared/ExternalLink';

const ClientEventEdit = (props) => {
  const [event] = useState({
    ...props.event,
    start_time: props.event.start || props.event.start_time,
    end_time: props.event.end || props.event.end_time
  });
  const [participations, setParticipations] = useState([]);
  const [newEvent, setNewEvent] = useState({});
  const [spotsRemaining, setSpotsRemaining] = useState(null);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const signUp = (participation, category = '') => {
    props.close();
    props.handleSignUp(event, participation, category);
  };

  const signOut = (participation, category = '') => {
    props.close();
    props.handleSignOut(event, participation, category);
  };

  const fetchParticipation = useCallback(() => {
    const params = {
      date: event.start_time
    };

    axios.post(`/v1/events/${event.id}/participations/search`, params)
      .then((res) => {
        setParticipations(res.data.participations);
        setNewEvent(res.data.event);
        setSpotsRemaining(res.data.spotsRemaining);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [event.id, event.start_time]);

  useEffect(() => {
    fetchParticipation();
  }, [fetchParticipation]);

  const actions = (participation) => {
    let action = {};

    if (participation?.delete_event_on_cancel) {
      action = {
        destructive: true,
        content: t('invoices.cancel_it'),
        onAction: () => signOut(participation, 'all')
      };

      return action;
    }

    if (participation?.aasm_state === 'no_show' || participation?.aasm_state === 'no_show_no_pay') {
      action = {
        content: t('calendar.sign_in'),
        onAction: () => signUp(participation, 'all')
      };
    }

    if (participation?.aasm_state === 'show') {
      action = {
        destructive: true,
        content: t('calendar.sign_out'),
        onAction: () => signOut(participation, 'all')
      };
    }

    return action;
  };

  const getRegisteredText = (participation) => {
    switch (participation.aasm_state) {
      case 'no_show': return (
        <Banner title={`${participation.client_name} - ${t('client.no_show_and_pay')}`} status="critical">
          <p>{`${t('shared.deregistered_on')} ${moment(participation.updated_at).format('LLLL')}`}</p>
        </Banner>
      );
      case 'no_show_no_pay': return (
        <Banner title={`${participation.client_name} - ${t('client.no_show_no_po')}`} status="warning">
          <p>{`${t('shared.deregistered_on')} ${moment(participation.updated_at).format('LLLL')}`}</p>
        </Banner>
      );
      case 'show': return (
        <Banner title={`${participation.client_name} - ${t('calendar.signed_up')}`} status="success">
          <p>{`${t('calendar.registered_on')} ${moment(participation.updated_at).format('LLLL')}`}</p>
        </Banner>
      );
      default: return '';
    }
  };

  return (
    <Modal
      open={props.active}
      onClose={() => props.close()}
      title={event.title || newEvent.title}
    >
      {participations.map((participation) => (
        <Card.Section key={participation.id} actions={!loading && actions(participation)}>
          <Form onSubmit={() => props.close()}>
            {participation ? (
              getRegisteredText(participation)
            ) : ''}
            <Card.Section title={t('shared.start')}>
              {moment(event.start_time).format('LLLL')}
            </Card.Section>
            <Card.Section title={t('shared.end')}>
              {moment(event.end_time).format('LLLL')}
            </Card.Section>
            {event.price ? (
              <Card.Section title={t('shared.price')}>
                {event.price}
              </Card.Section>
            ) : ''}
            {event.description ? (
              <Card.Section title={t('shared.description')}>
                {event.description}
              </Card.Section>
            ) : ''}
            {Number.isInteger(spotsRemaining) ? (
              <Card.Section title={t('events.spots_remaining')}>
                {spotsRemaining}
              </Card.Section>
            ) : ''}
            {newEvent.location ? (
              <Card.Section title={t('address.location')}>
                {newEvent.location}
              </Card.Section>
            ) : ''}
            {newEvent.online ? (
              <Card.Section title="Online">
                <ExternalLink url={newEvent.conference_url} label={newEvent.conference_url} />
              </Card.Section>
            ) : ''}
          </Form>
        </Card.Section>
      ))}
    </Modal>
  );
};

export default ClientEventEdit;
