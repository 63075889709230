import React from 'react';
import logo from '../../assets/images/planubo-blue.png';
import Emoji from '../Shared/Emoji';

const PublicProfileFooter = (props) => {
  const goToLink = () => {
    window.open('https://www.planubo.com');
  };

  return (
    <div
      style={{
        marginTop: '0px',
        textAlign: 'center',
        backgroundColor: '#474747',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        minHeight: '6rem',
        fontSize: '12px',
        lineHeight: '6rem',
        color: 'white'
      }}
    >
      {props.footer ? (
        <div dangerouslySetInnerHTML={{ __html: props.footer }} />
      ) : (
        <div className="flex justify-center">
          Made with
          <Emoji symbol="💛" />
          by
          <span onClick={() => goToLink()} onKeyDown={() => {}} role="link" style={{ cursor: 'pointer' }} tabIndex={0}>
            <img src={logo} alt="planubo" width="100px" className="pt-9 pl-4" />
          </span>
        </div>
      )}
    </div>
  );
};

export default PublicProfileFooter;
