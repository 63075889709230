import React from 'react';
import { Select } from '@shopify/polaris';

const programSpaceFilterDefinition = (t, props, setTableFilters, options, selected) => {
  const goToProgramSpace = (value) => {
    const selectedFilter = options.find((o) => o.value === value);

    props.history(`/booking/${selectedFilter.url_name}?program_space=${selectedFilter.value}`);
    // window.location.reload();
    window.open(window.location.href, '_blank');
  };

  return {
    key: 'program_space',
    label: t('public_profile.program'),
    filter: (
      <Select
        label={t('public_profile.program')}
        labelHidden
        options={options}
        value={selected}
        onChange={goToProgramSpace}
      />
    ),
    shortcut: true
  };
};

export default programSpaceFilterDefinition;
