// import { ChoiceList } from '@shopify/polaris';
import React from 'react';
import Select from 'react-select';
import { handleFilterChange } from '../FilterEngine';

// eslint-disable-next-line
const tagFilterDefinition = (t, props, setTableFilters, options, selected, { inputOptions = {} }) => ({
  key: 'tag',
  label: 'Tags',
  filter: (
    <div className="w-80 h-80 react-select">
      <Select
        // cacheOptions
        placeholder={t('user.search')}
        menuPlacement="bottom"
        inputValue={inputOptions.value}
        isClearable
        onInputChange={inputOptions.setValue}
        value={selected}
        onChange={handleFilterChange(props, setTableFilters, 'tags', 'tags')}
        // noOptionsMessage="Loading"
        defaultOptions={options}
        menuIsOpen
        isMulti
        options={options}
        getOptionValue={(user) => user.value}
        getOptionLabel={(user) => user.label}
      />
    </div>
    // <ChoiceList
    //   title="Status"
    //   titleHidden
    //   choices={options}
    //   selected={selected}
    //   onChange={handleFilterChange(props, setTableFilters, 'status')}
    //   allowMultiple
    // />
  ),
  shortcut: true
});

export default tagFilterDefinition;
