import { handleFilterChange } from '../FilterEngine';

const statusFilterActive = (t, props, setTableFilters, status = []) => ({
  key: 'status',
  label: `Status: ${status.join(', ')}`,
  onRemove: handleFilterChange(props, setTableFilters, 'status', 'reset', ''),
  filter: 'status_in',
  value: status
});

export default statusFilterActive;
