import React from 'react';
import { Select } from '@shopify/polaris';
import { handleFilterChange } from '../FilterEngine';

const staffFilterDefinition = (t, props, setTableFilters, options, selected) => ({
  key: 'staff',
  label: t('staff.staff'),
  filter: (
    <Select
      label={t('staff.staff')}
      labelHidden
      options={options}
      value={selected}
      onChange={handleFilterChange(props, setTableFilters, 'staff')}
    />
  ),
  shortcut: true
});

export default staffFilterDefinition;
