import React, { useState } from 'react';
import { TopBar } from '@shopify/polaris';
import { connect } from 'react-redux';
import { ArrowLeftMinor, EditMajor } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as actions from '../../store/actions/index';

const BookingAvatar = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { t } = useTranslation();
  const history = useNavigate();
  const toggleUserMenu = () => setMenuOpen(!menuOpen);

  const logout = () => {
    if (!window.confirm(t('shared.are_you_sure_to_logout'))) {
      return;
    }

    props.onLogout();
  };

  const menuActions = () => {
    const items = [{
      content: t('user.edit_profile'),
      icon: EditMajor,
      onAction: () => {
        props.setUnlockScreen(false);
        history('/profile_settings');
      }
    },
    {
      content: t('user.sign_out'),
      destructive: true,
      icon: ArrowLeftMinor,
      onAction: logout
    }];

    return items;
  };

  return (
    props.user.token && (
      <TopBar.UserMenu
        actions={[{
          items: menuActions()
        }]}
        avatar={props.user.avatar}
        name={props.user.name}
        detail={props.user.email}
        initials={props.user.initials}
        open={menuOpen}
        onToggle={() => toggleUserMenu()}
      />
    )
  );
};

const mapStateToProps = (state) => ({
  user: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.logout()),
  setUnlockScreen: (bool) => dispatch(actions.setUnlockScreen(bool))
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingAvatar);
