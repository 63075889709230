export const defaultDate = { start: new Date(), end: new Date(), default: true };
export const defaultMonthYear = { month: new Date().getMonth(), year: new Date().getFullYear() };

export const filterInit = {
  active: [],
  tab: '',
  createdAtAfter: defaultDate,
  createdAtAfterMonthYear: defaultMonthYear,
  createdAtBefore: defaultDate,
  createdAtBeforeMonthYear: defaultMonthYear,
  selectedTab: 0,
  client: '',
  staff: '',
  product: '',
  status: [],
  page: 1,
  program_space: '',
  queryValue: '',
  role: [],
  tags: [],
  languages: [],
  sorts: '',
  unpaid: '',
  product_type: []
};

// eslint-disable-next-line default-param-last
export const setSearchParams = (search, key, value = '', action) => {
  const values = new URLSearchParams(search);
  if (action === 'add') {
    if (value.toString().length) {
      values.set(key, value);
    } else {
      values.delete(key);
    }
  } else if (action === 'delete') {
    if (Array.isArray(key)) {
      key.forEach((k) => {
        values.delete(k);
      });
    } else {
      values.delete(key);
    }
  }
  return values.toString();
};

export const stateConverter = (location, presetFilter = []) => {
  const temp = {};
  const tempFilters = {};

  new URLSearchParams(location.search).forEach((value, key) => { temp[key] = value; });

  tempFilters.createdAtAfter = defaultDate;
  tempFilters.createdAtAfterMonthYear = defaultMonthYear;
  tempFilters.createdAtBefore = defaultDate;
  tempFilters.createdAtBeforeMonthYear = defaultMonthYear;
  tempFilters.client = temp.client || '';
  tempFilters.staff = temp.staff || '';
  tempFilters.product = temp.product || '';
  tempFilters.program_space = temp.program_space || '';
  tempFilters.active = temp.active ? temp.active.split(',').map((value) => JSON.parse(value)) : [];
  tempFilters.tab = temp.tab || '';
  tempFilters.queryValue = temp.queryValue || '';
  tempFilters.unpaid = temp.unpaid || '';
  tempFilters.role = temp.role ? temp.role.split(',') : [];
  tempFilters.product_type = temp.product_type || '';
  tempFilters.tags = temp.tags ? temp.tags.split(',') : [];
  tempFilters.languages = temp.languages ? temp.languages.split(',') : [];
  tempFilters.status = temp.status ? temp.status.split(',') : [];
  tempFilters.selectedTab = parseInt(temp.selectedTab, 10) || 0;
  tempFilters.sorts = temp.sorts || 'id desc';
  tempFilters.page = parseInt(temp.page, 10) || 1;

  if (temp.createdAtAfter) {
    tempFilters.createdAtAfter = { start: new Date(temp.createdAtAfter), end: new Date(temp.createdAtAfter) };
  }
  if (temp.createdAtAfterMonthYear) {
    const monthYear = temp.createdAtAfterMonthYear.split(',');
    tempFilters.createdAtAfterMonthYear = { month: monthYear[0], year: monthYear[1] };
  }

  if (temp.createdAtBefore) {
    tempFilters.createdAtBefore = { start: new Date(temp.createdAtBefore), end: new Date(temp.createdAtBefore) };
  }
  if (temp.createdAtBeforeMonthYear) {
    const monthYear = temp.createdAtBeforeMonthYear.split(',');
    tempFilters.createdAtBeforeMonthYear = { month: monthYear[0], year: monthYear[1] };
  }

  if (presetFilter.length) {
    presetFilter.forEach((f) => {
      tempFilters[f.key] = f.value;
    });
  }

  return tempFilters;
};
