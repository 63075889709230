/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import axios from 'axios';
import styles from './ReactTags.module.scss';

const Tags = (props) => {
  const [tags, setTags] = useState([]);

  const KeyCodes = {
    comma: 188,
    enter: 13
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i, f) => {
    if (props.language) {
      const temp = props.selectedTags.filter((t) => t.language === props.language);
      const tag = temp[i];
      props.setSelectedTags(props.selectedTags.filter((_tag, index) => _tag.id !== tag.id));
    } else {
      props.setSelectedTags(props.selectedTags.filter((_tag, index) => index !== i));
    }
  };

  const handleAddition = (tag) => {
    // eslint-disable-next-line
    tag.language = props.language;

    props.setSelectedTags([...props.selectedTags.filter((t) => t.label !== tag.label), tag]);
  };

  const fetchTags = useCallback(() => {
    axios.get(props.tagUrl)
      .then((res) => {
        setTags(res.data.tags);
      })
      .catch(() => {});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  const filteredTags = () => {
    // filter tags if language is provided
    if (props.language) {
      return props.selectedTags.filter((tag) => tag.language === props.language);
    }

    return props.selectedTags;
  };

  return (
    <div className={styles.ReactTags}>
      <ReactTags
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        delimiters={delimiters}
        suggestions={tags}
        placeholder="Tags"
        labelField="label"
        minQueryLength={1}
        maxLength={30}
        autofocus={false}
        allowDragDrop={false}
        allowDeleteFromEmptyInput
        autocomplete={false}
        readOnly={false}
        allowAdditionFromPaste
        tags={filteredTags()}
      />
    </div>
  );
};

export default Tags;
