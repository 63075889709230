import React, { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormLayout, TextField, TextStyle, Banner, Spinner } from '@shopify/polaris';
import axios from 'axios';
import { connect } from 'react-redux';
import classes from './Auth.module.scss';
import logo from '../../assets/images/planubo-blue.png';

const ResetPassword = (props) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState('');

  const location = useLocation();
  const history = useNavigate();

  const query = new URLSearchParams(location.search);
  const resetPasswordToken = query.get('reset_password_token');

  const { t } = useTranslation('translation');

  const handleSubmit = (event) => {
    const params = {
      password,
      password_confirmation: passwordConfirmation,
      reset_password_token: resetPasswordToken
    };

    event.preventDefault();
    axios.post('/v1/passwords/reset_password', params)
      .then(() => {
        history('/?process=passwordreset');
      })
      .catch(() => {
        setMessage(
          <Banner title={t('user.unable_to_reset_password')} status="critical" />
        );
        setPassword('');
        setPasswordConfirmation('');
      });
  };

  const handlePasswordChange = useCallback((value) => setPassword(value), []);
  const handlePasswordConfirmationChange = useCallback((value) => setPasswordConfirmation(value), []);

  if (!resetPasswordToken) {
    history('/');
    return null;
  }

  return (
    <div className={classes.Auth}>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
          <div className="flex justify-center">
            {!props.loadingLogo ? <img src={props.brand?.loginLogoUrl || logo} alt="planubo" /> : (
              <Spinner />
            )}
          </div>
          {message}
          <TextField
            value={password}
            onChange={handlePasswordChange}
            placeholder={t('user.password')}
            type="password"
          />
          <TextField
            value={passwordConfirmation}
            onChange={handlePasswordConfirmationChange}
            placeholder={t('user.confirm_password')}
            type="password"
          />
          <Button primary submit>{t('user.password_reset')}</Button>
          <Button plain url="/">{t('user.sign_in')}</Button>
          <TextStyle variation="subdued">© 2024 Planubo. All Rights Reserved.</TextStyle>
        </FormLayout>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  brand: state.auth.brand,
  loading: state.auth.loading,
  loadingLogo: state.auth.loadingLogo
});

export default connect(mapStateToProps)(ResetPassword);
