import React from 'react';
import { Modal, Stack, Card, Layout, FormLayout, Button } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

const RecurringEventDeleteConfirmation = (props) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={props.active}
      onClose={() => props.close()}
      title={t('shared.confirmation')}
    >
      <Modal.Section>
        <Layout>
          <Layout.Section>
            <Stack vertical>
              <>
                {t('calendar.delete_all')}
              </>
              <FormLayout>
                <FormLayout.Group>
                  <Card sectioned>
                    <Stack vertical>
                      <Button
                        fullWidth
                        primary
                        onClick={() => props.handleChangeDeleteAll()}
                      >
                        {t('shared.delete_all')}
                      </Button>
                    </Stack>
                  </Card>
                  <Card sectioned>
                    <Stack vertical>
                      <Button
                        fullWidth
                        primary
                        onClick={() => props.handleChangeDeleteOnlyThisOne()}
                      >
                        {t('shared.delete_this')}
                      </Button>
                    </Stack>
                  </Card>
                </FormLayout.Group>
              </FormLayout>
            </Stack>
          </Layout.Section>
        </Layout>
      </Modal.Section>
    </Modal>
  );
};

export default RecurringEventDeleteConfirmation;
