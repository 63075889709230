import moment from 'moment';
import { handleFilterChange } from '../FilterEngine';

const createdAtAfterFilterActive = (t, props, setTableFilters, createdAtAfter, filter = 'event_start_time_gteq') => {
  const labelDate = createdAtAfter.start.toLocaleDateString();
  const valueDate = moment(createdAtAfter.start).format('YYYY-MM-DD');

  return {
    key: 'created-at-after',
    label: `${t('filters.after')}: ${labelDate}`,
    onRemove: handleFilterChange(props, setTableFilters, ['createdAtAfter', 'createdAtAfterMonthYear'], 'dateReset', ''),
    filter,
    value: valueDate
  };
};

export default createdAtAfterFilterActive;
