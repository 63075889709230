const loadCrisp = (_callback, data) => {
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = '3b9392b3-0a63-4c19-91ef-d0e061da4964';
  window.CRISP_TOKEN_ID = data.token;
  window.CRISP_RUNTIME_CONFIG = {
    locale: data.lang
  };

  // eslint-disable-next-line func-names
  (function () {
    const d = document;
    const s = d.createElement('script');

    s.src = 'https://client.crisp.chat/l.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
  }());

  window.CRISP_READY_TRIGGER = () => {
    window.$crisp.push(['set', 'user:email', [data.email]]);
    window.$crisp.push(['do', 'chat:close']);
  };
};

export default loadCrisp;
