import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// import Instructors from '../components/Instructors/Instructors';
import Home from '../components/Clients/Home';
import Help from '../components/Help/Help';
import Documentation from './Documentation';
import Profile from '../components/Settings/Profile';

const PendingClientRoutes = () => (
  <Routes>
    <Route path="/home" element={<Home />} />
    {/* <Route exact path="/instructors" element={<Instructors />} /> */}
    <Route exact path="/help" element={<Help />} />
    <Route exact path="/documentation" element={<Documentation />} />
    <Route exact path="/profile_settings" element={<Profile />} />
    <Route path="*" element={<Navigate to="/home" />} />
  </Routes>
);

export default PendingClientRoutes;
