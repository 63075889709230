/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { Filters, Card, SkeletonPage, Page, DataTable, EmptyState, Button, Popover, ActionList } from '@shopify/polaris';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { CollectionsMajor, DeleteMajor, MobileCancelMajor, MobileVerticalDotsMajor, ShareIosMinor } from '@shopify/polaris-icons';
import useDebounce from '../../hooks/useDebounce';
import { stateConverter } from '../FilterEngine/filterParams';
import { handleFilterChange, searchFilterToURL, appliedFiltersQuery } from '../FilterEngine/FilterEngine';
import TablePagination from '../FilterEngine/TablePagination/TablePagination';
import { defaultSortDirection, handleSort, initialSortColumnIndex } from '../FilterEngine/Sort/sort';
import ModalService from '../Shared/Services/ModalService';
import ShareModal from './ShareModal';
import Pie from '../Graphs/Pie';

const shareModalRef = React.createRef();

const Surveys = () => {
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [init, setInit] = useState(false);
  const [lastPage, setLastPage] = useState(null);

  const { t } = useTranslation();
  const history = useNavigate();
  const location = useLocation();

  const [tableFilters, setTableFilters] = useState(stateConverter(location));
  const debouncedSearch = useDebounce(tableFilters, 400);

  const filters = [];

  const appliedFilters = useCallback(() => {
    const af = [];

    return af;
  }, []);

  const [rowActionsActive, setRowActionsActive] = useState({});
  const toggleRowActionsActive = (i) => setRowActionsActive({ [i]: !rowActionsActive[i] });

  const openShareModal = async (s) => {
    setRowActionsActive({});

    const response = await shareModalRef.current.open({
      cancelButton: t('shared.ok'),
      submitButton: t('shared.send_notification_via_email'),
      timestamp: new Date(),
      data: s,
      title: t('media_library.share'),
      ref: shareModalRef.current
    });

    if (response) {
      axios.post(`/v1/surveys/${s.token}/notification`, { message: response })
        .then(() => { })
        .catch(() => { });
    }

    fetchData();
  };

  function isWhatPercentOf(numA, numB) {
    if (!numB) return '0%';

    return `${((numA / numB) * 100).toFixed(0)}%`;
  }

  const modifier = (tableData) => tableData.map((row, i) => {
    const modifiedRow = [];

    modifiedRow.push(<Button plain url={`/surveys/${row.token}`}>{row.title}</Button>);
    modifiedRow.push(moment(row.createdAt).format('LLLL'));
    modifiedRow.push(moment(row.updatedAt).format('LLLL'));
    modifiedRow.push(row.assignedCount);
    modifiedRow.push(row.answerCount);

    const pieData = [];

    if (row.answerCount !== 0) {
      pieData.push({
        id: 'answered',
        label: 'Answered',
        value: row.answerCount,
        color: 'hsl(143, 90%, 49%)'
      });
    }

    if (row.assignedCount - row.answerCount > 0) {
      pieData.push({
        id: 'unanswered',
        label: 'Unanswered',
        value: row.assignedCount - row.answerCount,
        color: 'hsl(23, 100%, 52%)'
      });
    }

    const actions = [];
    actions.push({
      content: t('media_library.share'),
      icon: ShareIosMinor,
      onAction: () => openShareModal(row)
    }, {
      content: t('survey.responses'),
      icon: CollectionsMajor,
      onAction: () => history(`/surveys/${row.token}/replies`)
    }, {
      content: t('shared.delete'),
      destructive: true,
      icon: DeleteMajor,
      onAction: () => destroySurvey(row)
    });

    modifiedRow.push(
      <Popover
        active={rowActionsActive[i]}
        activator={<Button plain onClick={() => toggleRowActionsActive(i)} icon={MobileVerticalDotsMajor} />}
        onClose={() => toggleRowActionsActive(i)}
      >
        <ActionList items={actions} />
      </Popover>
    );

    return modifiedRow;
  });

  const fetchData = useCallback(() => {
    const params = {
      per_page: 30,
      page: tableFilters.page,
      search: tableFilters.queryValue,
      status: tableFilters.tab === 'All' ? '' : tableFilters.tab,
      q: appliedFiltersQuery(appliedFilters())
    };

    axios.post('/v1/surveys/search', params)
      .then((res) => {
        if (mounted) {
          setRecords(res.data.surveys);
          setLastPage(res.data.lastPage);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const destroySurvey = (s) => {
    if (!window.confirm(`${t('shared.are_you_sure')} ${s.title}`)) {
      return;
    }

    axios.delete(`/v1/surveys/${s.token}`)
      .then(() => {
        fetchData();
      })
      .catch(() => {
      });
  };

  const filterControl = (
    <Filters
      queryValue={tableFilters.queryValue}
      filters={filters}
      onQueryChange={handleFilterChange({ history, location }, setTableFilters, 'queryValue')}
      onQueryClear={handleFilterChange({ history, location }, setTableFilters, 'queryValue', 'reset', '')}
      onClearAll={handleFilterChange({ history, location }, setTableFilters, '/surveys', 'resetAll')}
      appliedFilters={appliedFilters()}
      queryPlaceholder={t('user.search')}
    />
  );

  useEffect(() => {
    setMounted(true);
    fetchData();
    return () => {
      setMounted(false);
    };
  }, [fetchData]);

  useEffect(() => {
    if (init) {
      setTableFilters((f) => ({ ...f, page: 1 }));
      searchFilterToURL({ history, location }, 'page', null, 'delete');
    }
    setInit(true);
    // eslint-disable-next-line
  }, [tableFilters.queryValue, tableFilters.role, tableFilters.userType]);

  const indexSortMapping = {
    status: true,
    created_at: false
  };

  const headings = [
    t('shared.title'),
    t('shared.created_at'),
    t('shared.updated_at'),
    t('staff.assigned'),
    t('shared.answers'),
    ''
  ];

  const content = (
    <DataTable
      columnContentTypes={[
        'text',
        'text',
        'text',
        'text',
        'text',
        'text'
      ]}
      verticalAlign="middle"
      headings={headings}
      rows={modifier(records)}
      sortable={Object.values(indexSortMapping)}
      defaultSortDirection={defaultSortDirection(tableFilters.sorts)}
      initialSortColumnIndex={initialSortColumnIndex(indexSortMapping, tableFilters.sorts)}
      onSort={handleSort({ location, history }, setTableFilters, indexSortMapping)}
    />
  );

  return (
    <Page
      title={t('survey.survey')}
      fullWidth
      separator
      primaryAction={{ content: t('survey.new'), onAction: () => history('/surveys/new') }}
    >
      {!loading ? (
        <Card sectioned>
          <Card.Subsection>
            {filterControl}
          </Card.Subsection>
          <Card.Subsection>
            {content}
            {!records.length && (
              <EmptyState
                heading={t('survey.no_surveys_yet')}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              />
            )}
          </Card.Subsection>
        </Card>
      ) : <SkeletonPage />}
      <TablePagination
        pageFilter={tableFilters.page}
        setTableFilters={setTableFilters}
        records={records}
        lastPage={lastPage}
      />
      <ModalService ref={shareModalRef}>
        <ShareModal />
      </ModalService>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin
});

export default connect(mapStateToProps)(Surveys);
