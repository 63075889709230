import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Bars3Icon } from '@heroicons/react/24/outline';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DragnDropArray = (props) => {
  const [dropDisabled, setDropDisabled] = useState(false);

  const getItemClasses = (isDragging) => {
    const classes = 'h-12 my-1 flex inline-block align-middle rounded-md p-3 bg-white';

    if (isDragging) {
      return `${classes} border-dotted border-2 border-sky-500 ${dropDisabled ? 'border-rose-500' : ''}`;
    }

    return `${classes} border-2`;
  };

  const handleDragEnd = (result) => {
    if (!result?.destination) return;

    if (props.lockIndexes?.length && props.lockIndexes.includes(result.destination?.index)) {
      return;
    }

    // perform reorder
    const cfs = reorder(
      props.data,
      result.source.index,
      result.destination.index
    );

    setDropDisabled(false);
    props.onChange(cfs);
  };

  const dragUpdate = (obj) => {
    if (!obj?.destination) return;

    if (props.lockIndexes?.length && props.lockIndexes.includes(obj.destination.index)) {
      setDropDisabled(true);
      return;
    }
    setDropDisabled(false);
  };

  const displayArray = () => props.data?.map((entry, idx) => (
    <Draggable draggableId={entry.id.toString()} index={idx} isDragDisabled={entry.disabled} key={entry.id.toString()}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={getItemClasses(snapshot.isDragging, provided)}
        >
          <div className="flex flex-auto">
            <div className="flex-auto pl-4">
              {entry[props.labelKey]}
            </div>
            {!entry.disabled && (
              <div className="flex pr-4">
                <Bars3Icon />
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  ));

  return (
    <div className="bg-slate-50 p-2 sm:px-6 sm:pt-4 sm:pb-6 lg:p-2 xl:px-6 xl:pt-4 xl:pb-6 grid gap-2 text-sm leading-4">
      <DragDropContext onDragUpdate={dragUpdate} onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {displayArray()}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DragnDropArray;
