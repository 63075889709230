import React from 'react';
import { connect } from 'react-redux';
import { Navigation } from '@shopify/polaris';
import InstructorNavigation from './Roles/InstructorNavigation';
import ClientNavigation from './Roles/ClientNavigation';
import AdminNavigation from './Roles/AdminNavigation';
import AccountantNavigation from './Roles/AccountantNavigation';
import PendingInstructorNavigation from './Roles/PendingInstructorNavigation';
import PendingClientNavigation from './Roles/PendingClientNavigation';

const MainNavigation = (props) => {
  const { isInstructor, isClient, isAdmin, isAccountant, isStaff } = props;
  let navigation = null;

  if (isAdmin) {
    navigation = <AdminNavigation toggle={props.toggle} />;
  } else if (isInstructor || isStaff) {
    if (props.accountState === 'pending' || props.accountState === 'new_registration' || props.accountState === 'cancelled' || props.accountState === 'on_hold' || props.accountState === 'denied' || props.accountState === 'approved') {
      navigation = <PendingInstructorNavigation toggle={props.toggle} />;
    } else {
      navigation = <InstructorNavigation toggle={props.toggle} />;
    }
  } else if (isClient) {
    if (props.accountState === 'pending' || props.accountState === 'new_application' || props.accountState === 'declined') {
      navigation = <PendingClientNavigation toggle={props.toggle} />;
    } else {
      navigation = <ClientNavigation toggle={props.toggle} />;
    }
  } else if (isAccountant) {
    navigation = <AccountantNavigation toggle={props.toggle} />;
  } else {
    navigation = (
      <Navigation location="/" />
    );
  }
  return navigation;
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin,
  isInstructor: state.auth.instructor,
  isStaff: state.auth.role === 'staff',
  isAccountant: state.auth.role === 'accountant',
  state: state.auth,
  isClient: state.auth.client,
  accountState: state.auth.accountState,
  active: state.auth.active,
  isNew: state.auth.isNew
});

export default connect(mapStateToProps)(MainNavigation);
