import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, Card, Layout, Button, TextField, FormLayout, DataTable, Stack, Select, Checkbox, Thumbnail, TextStyle } from '@shopify/polaris';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { DeleteMajor, MobileCancelMajor, SaveMinor } from '@shopify/polaris-icons';
import InvoiceAddress from '../Address/InvoiceAddress';
import Search from '../Shared/Search';
import LineItems from './LineItems';
import Banners from '../Shared/Banners';
import CancelReasonModalForm from './CancelReasonModalForm';

const NewInvoice = (props) => {
  const [banner, setBanner] = useState([]);
  const [recipient, setRecipient] = useState('');
  const [dueDate, setDueDate] = useState(null);
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [instructorAddress, setInstructorAddress] = useState({});
  const [logoURL, setLogoURL] = useState('');
  const [invoiceSettings, setInvoiceSettings] = useState({ footer: '', generalInfo: '', currency: 'EUR' });
  const [notes, setNotes] = useState('');
  const [showAdditionalClientInfo, setShowAdditionalClientInfo] = useState(false);
  const [additionalClientInfo, setAdditionalClientInfo] = useState('');
  const [showCancelModalForm, setShowCancelModalForm] = useState(false);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState({});
  const [persisted, setPersisted] = useState(false);
  const [discountType, setDiscountType] = useState('%');
  const [billSettings, setBillSettings] = useState({
    includedTax: false,
    showTax: false,
    showDiscount: false,
    showShipping: false
  });
  const [invoice, setInvoice] = useState({
    taxRate: 0.00,
    discount: 0.00,
    shipping: 0.00,
    paid: 0.00,
    lineItems: [
      {
        id: 'initial',
        name: '',
        description: '',
        quantity: 0,
        price: 0.00
      }
    ]
  });

  const allowNavigation = useRef();

  const { t } = useTranslation();
  const history = useNavigate();
  const matchParams = useParams();

  const handleNotesChange = () => (value) => setNotes(value);

  const prettyCurrency = () => {
    switch (invoiceSettings.currency) {
      case 'EUR': return '€';
      case 'USD': return '$';
      default: return invoiceSettings.currency;
    }
  };

  const fetchInvoiceSettings = useCallback((options = {}) => {
    axios.get('/v1/settings/invoice_settings')
      .then((response) => {
        if (!response.data.invoiceSettings.currency) {
          history('/invoice_settings');
        }
        if (!response.data.invoiceSettings.instructorAddress) {
          history('/profile_settings');
        }
        setInvoiceSettings({
          currency: response.data.invoiceSettings.currency,
          footer: response.data.invoiceSettings.footer,
          generalInfo: response.data.invoiceSettings.generalInfo
        });
        if (options.assignTax) {
          setBillSettings({ ...billSettings, includedTax: response.data.invoiceSettings.taxIncluded, showTax: response.data.invoiceSettings.taxRate > 0 });
          setInvoice((i) => ({ ...i, taxRate: response.data.invoiceSettings.taxRate }));
        }
        setLogoURL(response.data.logoURL);
        setInstructorAddress(response.data.invoiceSettings.instructorAddress);
      })
      .catch(() => {});
  // eslint-disable-next-line
  }, []);

  const changeTaxDiscountType = (field) => (value) => {
    if (field === 'discountType') setDiscountType(value);
  };

  const changeBillSettings = (field) => setBillSettings({ ...billSettings, [field]: !billSettings[field] });

  const locale = props.locale;

  const handleInvoiceChange = (field) => (value) => {
    setInvoice({ ...invoice, [field]: value });
  };

  const handleAddressChange = () => (value) => setAddress(value);

  const handleDueDateChange = (value) => setDueDate(value);

  const handleDateChange = (value) => setDate(value);

  const handleLineItemChange = (elementIndex) => (event) => {
    const lineItems = invoice.lineItems.map((item, i) => {
      if (elementIndex !== i) return item;
      return { ...item, [event.target.name]: event.target.value };
    });
    setInvoice({ ...invoice, lineItems });
  };

  const handleAddLineItem = () => {
    setInvoice({ ...invoice,
      lineItems: invoice.lineItems.concat(
        [{ id: uuidv4(), name: '', description: '', quantity: 0, price: 0.00 }]
      )
    });
  };

  const handleRemoveLineItem = (elementIndex) => () => {
    setInvoice({ ...invoice,
      lineItems: invoice.lineItems.filter((item, i) => elementIndex !== i)
    });
  };

  const handleReorderLineItems = (newLineItems) => {
    setInvoice({ ...invoice, lineItems: newLineItems });
  };

  const handleFocusSelect = (event) => {
    event.target.select();
  };

  const formatCurrency = (amount) => (new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: invoiceSettings.currency
  }).format(amount));

  const calcShipping = () => parseFloat(invoice.shipping, 10);

  const calcDiscount = () => {
    let discount = 0;

    if (discountType === '%') {
      discount = calcLineItemsTotal() * (invoice.discount / 100);
    } else {
      discount = parseFloat(invoice.discount, 10);
    }

    return discount;
  };

  const calcLineItemsTotal = () => invoice.lineItems.reduce((prev, cur) => (prev + (cur.quantity * cur.price)), 0);

  const calcTaxTotal = () => {
    let tax = 0;

    tax = calcLineItemsTotal() * (invoice.taxRate / 100);

    return tax;
  };

  const calcSubTotal = () => {
    if (billSettings.includedTax) {
      return calcLineItemsTotal() / ((invoice.taxRate / 100) + 1);
    }

    return calcLineItemsTotal();
  };

  const calcMidTotal = () => {
    if (billSettings.includedTax) {
      return calcLineItemsTotal() - calcDiscount() + calcShipping();
    }

    return calcLineItemsTotal() + calcTaxTotal() - calcDiscount() + calcShipping();
  };

  const calcGrandTotal = () => {
    if (billSettings.includedTax) {
      return calcLineItemsTotal() - calcDiscount() - (parseFloat(invoice.paid, 10)) + calcShipping();
    }

    return calcLineItemsTotal() + calcTaxTotal() - calcDiscount() - (parseFloat(invoice.paid, 10)) + calcShipping();
  };

  const dataTableRows = () => {
    const rows = [[t('invoices.subtotal'), formatCurrency(calcSubTotal())]];

    if (billSettings.showTax) {
      rows.push([t('invoices.tax'), (
        <>
          <TextField
            type="number"
            value={invoice.taxRate}
            onChange={handleInvoiceChange('taxRate')}
            prefix="%"
          />
          <Checkbox
            checked={billSettings.includedTax}
            onChange={() => changeBillSettings('includedTax')}
            label={t('invoices.incl_tax')}
          />
        </>
      )]);
    }

    if (billSettings.showDiscount) {
      rows.push(
        [
          t('invoices.discount'), (
            <TextField
              type="number"
              value={invoice.discount}
              onChange={handleInvoiceChange('discount')}
              connectedLeft={(
                <Select
                  value={discountType}
                  onChange={changeTaxDiscountType('discountType')}
                  labelHidden
                  options={['%', prettyCurrency()]}
                />
              )}
            />
          )
        ]
      );
    }

    if (billSettings.showShipping) {
      rows.push([t('invoices.shipping'), <TextField prefix={prettyCurrency()} type="number" value={invoice.shipping} onChange={handleInvoiceChange('shipping')} />]);
    }

    rows.push(
      [t('shared.total'), formatCurrency(calcMidTotal())],
      [t('shared.paid'), <TextField prefix={prettyCurrency()} type="number" value={invoice.paid} onChange={handleInvoiceChange('paid')} />]
    );

    return rows;
  };

  const handleAdditionalClientInfoChange = (value) => {
    setAdditionalClientInfo(value);
  };

  const handleClientChange = (data) => {
    const selected = clients.find((c) => c.value === data[0]);
    setClient(selected);
  };

  const fetchClients = useCallback(() => {
    const params = {
      per_page: 1000,
      page: 1,
      search: '',
      q: ''
    };

    axios.post('/v1/clients/invoice_recipient', params)
      .then((res) => {
        setClients(res.data);
      })
      .catch(() => {})
      .then(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (recipient === 'client') {
      setLoading(true);
      fetchClients();
    }
  }, [fetchClients, recipient]);

  const loadExistingInvoice = useCallback(() => {
    axios.get(`/v1/invoices/${matchParams.id}`)
      .then((response) => {
        setPersisted(true);

        if (response.data.client.value) {
          setClient(response.data.client);
          setRecipient('selected');
        } else {
          setRecipient('custom');
          setAddress(response.data.invoice.to);
        }

        const items = [];
        response.data.invoice.items.forEach((item) => {
          items.push({
            id: item.id,
            name: item.name,
            description: item.description,
            quantity: parseInt(item.quantity, 10),
            price: item.price
          });
        });

        setInvoice({
          taxRate: response.data.invoice.tax,
          discount: response.data.invoice.discounts,
          shipping: response.data.invoice.shipping,
          paid: response.data.invoice.amount_paid,
          lineItems: items
        });

        if (response.data.invoice.fields.discounts) {
          setDiscountType(response.data.invoice.fields.discounts);
        }

        setBillSettings({
          includedTax: response.data.invoice.fields.includedTax,
          showTax: response.data.invoice.fields.tax,
          showDiscount: response.data.invoice.fields.discounts,
          showShipping: response.data.invoice.fields.shipping
        });

        setNotes(response.data.invoice.notes);

        const aCInfo = response.data.invoice.dig('custom_fields', 'additional_info');

        if (aCInfo) {
          setAdditionalClientInfo(aCInfo);
          setShowAdditionalClientInfo(true);
        }

        if (response.data.invoice.date) {
          setDate(moment(new Date(response.data.invoice.date)).format('YYYY-MM-DD'));
        } else {
          setDate(moment(new Date()).format('YYYY-MM-DD'));
        }

        if (response.data.invoice.due_date) {
          setDueDate(moment(new Date(response.data.invoice.due_date)).format('YYYY-MM-DD'));
        }

        fetchInvoiceSettings();
      })
      .catch((error) => {
        setBanner([{ title: t('shared.something_went_wrong'), status: 'critical', details: error.response.data.errors }]);
      });
  // eslint-disable-next-line
  }, [matchParams.id]);

  const deleteInvoice = useCallback((cancelReason) => {
    axios.delete(`/v1/invoices/${matchParams.id}`, { data: cancelReason })
      .then(() => {
        history('/invoices');
      })
      .catch(() => {
      });
  }, [history, matchParams.id]);

  let cancellationModalForm = null;

  if (showCancelModalForm) {
    cancellationModalForm = (
      <CancelReasonModalForm
        handleSubmit={deleteInvoice}
        close={() => setShowCancelModalForm(false)}
      />
    );
  }

  useEffect(() => {
    if (matchParams.dig('id') !== 'new') {
      loadExistingInvoice();
    } else {
      fetchInvoiceSettings({ assignTax: true });
    }
  // eslint-disable-next-line
  }, [matchParams, loadExistingInvoice, fetchInvoiceSettings]);

  const reset = () => {
    setRecipient('');
    setClient({});
  };

  const prettyAddress = () => {
    if (client.address) {
      return `${client.address.address},${client.address.address_line_two || ''},${client.address.zip_code} ${client.address.city} ${client.address.state}`;
    }

    return address;
  };

  const createInvoice = (create) => {
    if (create && !window.confirm(t('invoices.create_irreversible'))) {
      return;
    }

    let from = '';
    if (Object.keys(instructorAddress).length) {
      from += props.name;
      from += `,${instructorAddress.address},${instructorAddress.address_line_two || ''},${instructorAddress.zip_code} ${instructorAddress.city} ${instructorAddress.state},`;
      from += invoiceSettings.generalInfo;
    }

    const params = {
      invoice: {
        from,
        to: prettyAddress(),
        client_id: client.id || client.value,
        currency: invoiceSettings.currency,
        date,
        due_date: dueDate,
        items: invoice.lineItems,
        custom_fields: {
          additional_info: additionalClientInfo
        },
        fields: {
          includedTax: billSettings.includedTax,
          tax: billSettings.showTax,
          discounts: billSettings.showDiscount ? discountType : false,
          shipping: billSettings.showShipping
        },
        tax: invoice.taxRate,
        shipping: invoice.shipping,
        discounts: invoice.discount,
        amount_paid: invoice.paid,
        notes,
        terms: invoiceSettings.footer
      },
      create
    };

    allowNavigation.current = true;
    if (persisted) {
      axios.patch(`/v1/invoices/${matchParams.id}`, params)
        .then(() => {
          history('/invoices');
        })
        .catch(() => {
          setBanner([{ title: t('shared.something_went_wrong'), status: 'critical' }]);
        });
    } else {
      axios.post('/v1/invoices', params)
        .then(() => {
          history('/invoices');
        })
        .catch(() => {
          setBanner([{ title: t('shared.something_went_wrong'), status: 'critical' }]);
        });
    }
  };

  return (
    <Page
      title={t('invoices.new')}
      breadcrumbs={[{ content: t('shared.back'), onAction: () => history(-1) }]}
      separator
      primaryAction={{ content: t('invoices.create'), onAction: () => createInvoice(true) }}
      secondaryActions={
        matchParams.dig('id') !== 'new' ? [
          {
            icon: MobileCancelMajor,
            content: t('invoices.cancel_it'),
            onAction: () => {
              allowNavigation.current = true;
              setShowCancelModalForm(true);
            }
          },
          { icon: SaveMinor, content: t('invoices.save_for_later'), onAction: () => createInvoice(false) }
        ] : [{ icon: SaveMinor, content: t('invoices.save_for_later'), onAction: () => createInvoice(false) }]
      }
    >
      {cancellationModalForm}
      <Card sectioned>
        <Banners banners={banner} onDismissBanner={() => setBanner([])} />
        <Layout>
          <Layout.Section>
            {logoURL ? <Thumbnail size="large" name="invoice_logo" source={logoURL} /> : null}
            <br />
            <div style={{ maxWidth: '30rem' }}>
              <Card sectioned subdued title={t('invoices.recipient')} actions={recipient && [{ content: t('shared.change'), onAction: reset }]}>
                {!recipient && (
                  <>
                    <Button plain onClick={() => setRecipient('client')}>{t('client.choose')}</Button>
                    <br />
                    <Button plain onClick={() => setRecipient('custom')}>{t('invoices.custom_recipient')}</Button>
                  </>
                )}
                {recipient === 'custom' && (
                  <InvoiceAddress
                    handleChange={handleAddressChange}
                    showAttentionName
                    address={address}
                  />
                )}
                {recipient === 'client' && !loading && !Object.keys(client).length && (
                  <Search
                    showTags
                    allowMultiple
                    selectedData={[]}
                    handleChange={handleClientChange}
                    deselectedOptions={clients}
                    placeholder={t('client.clients')}
                  />
                )}
                {client.label && client.address && (
                  <>
                    <div>{client.label}</div>
                    <div>{client.address.attentionName}</div>
                    <div>{client.address.address}</div>
                    <div>{client.address.addressLineTwo}</div>
                    <div>{`${client.address.city}, ${client.address.state || ''} ${client.address.zip_code}`}</div>
                  </>
                )}
                {!client.address && client.label && (
                  <div>{client.label}</div>
                )}
              </Card>
              {!showAdditionalClientInfo && <Button plain onClick={() => setShowAdditionalClientInfo(!showAdditionalClientInfo)}>{`+ ${t('invoices.additional_info')}`}</Button>}
              {showAdditionalClientInfo && (
                <Card
                  sectioned
                  subdued
                  title={t('client.additional_info')}
                  actions={[{ content: t('shared.remove'), onAction: () => setShowAdditionalClientInfo(!showAdditionalClientInfo) }]}
                >
                  <TextField
                    multiline={2}
                    value={additionalClientInfo}
                    onChange={handleAdditionalClientInfoChange}
                  />
                </Card>
              )}
            </div>
          </Layout.Section>
          <Layout.Section secondary>
            <h3 style={{ fontSize: '2rem' }}>{t('invoices.invoice')}</h3>
            <br />
            <Card sectioned subdued title={t('instructor.info')}>
              <TextStyle>{props.name}</TextStyle>
              {instructorAddress && (
                <>
                  <div>{instructorAddress.address}</div>
                  <div>{instructorAddress.addressLineTwo}</div>
                  <div>{`${instructorAddress.zip_code} ${instructorAddress.city} ${instructorAddress.state || ''}`}</div>
                  <div>{invoiceSettings.generalInfo}</div>
                </>
              )}
            </Card>
            <Card sectioned subdued>
              <FormLayout>
                <TextField
                  label={t('shared.date')}
                  type="date"
                  onChange={handleDateChange}
                  value={date}
                  connectedRight={(
                    <Button icon={DeleteMajor} onClick={() => setDate(null)} />
                  )}
                />
                <TextField
                  label={t('invoices.due')}
                  type="date"
                  onChange={handleDueDateChange}
                  value={dueDate}
                  connectedRight={(
                    <Button icon={DeleteMajor} onClick={() => setDueDate(null)} />
                  )}
                />
              </FormLayout>
            </Card>
          </Layout.Section>

        </Layout>
        <br />
        <LineItems
          items={invoice.lineItems}
          currencyFormatter={formatCurrency}
          addHandler={handleAddLineItem}
          changeHandler={handleLineItemChange}
          focusHandler={handleFocusSelect}
          deleteHandler={handleRemoveLineItem}
          reorderHandler={handleReorderLineItems}
        />
        <Layout>
          <Layout.Section />
          <Layout.Section secondary>
            <DataTable
              verticalAlign="middle"
              columnContentTypes={[
                'text',
                'numeric'
              ]}
              headings={['', '']}
              rows={dataTableRows()}
              showTotalsInFooter
              totals={['', formatCurrency(calcGrandTotal())]}
              totalsName={{
                singular: t('invoices.balance'),
                plural: t('invoices.balance')
              }}
            />
            {!billSettings.showTax && (
              <Stack>
                <Stack.Item fill />
                <Stack.Item>
                  <Button plain onClick={() => changeBillSettings('showTax')}>{`+ ${t('invoices.add_tax')}`}</Button>
                </Stack.Item>
              </Stack>
            )}
            {!billSettings.showDiscount && (
              <Stack>
                <Stack.Item fill />
                <Stack.Item>
                  <Button plain onClick={() => changeBillSettings('showDiscount')}>{`+ ${t('invoices.discount')}`}</Button>
                </Stack.Item>
              </Stack>
            )}
            {!billSettings.showShipping && (
              <Stack>
                <Stack.Item fill />
                <Stack.Item>
                  <Button plain onClick={() => changeBillSettings('showShipping')}>{`+ ${t('invoices.shipping')}`}</Button>
                </Stack.Item>
              </Stack>
            )}
          </Layout.Section>
        </Layout>

        <TextField
          label={t('invoices.notes')}
          value={notes}
          onChange={handleNotesChange()}
          multiline={2}
        />
        <div dangerouslySetInnerHTML={{ __html: invoiceSettings.footer }} />
      </Card>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  name: state.auth.name,
  lastName: state.auth.lastName,
  locale: state.auth.lang
});

export default connect(mapStateToProps)(NewInvoice);
