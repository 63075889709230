/* eslint-disable arrow-body-style */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Toast } from '@shopify/polaris';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import PnbBadge from '../Tailwind/PnbBadge';

const SubscriptionNotificationModal = (props) => {
  const [subs, setSubs] = useState([]);
  const [stripePromise, setStripePromise] = useState('');

  const { t } = useTranslation();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  useEffect(() => {
    setStripePromise(loadStripe(process.env.NODE_ENV === 'development' ? process.env.REACT_APP_STRIPE_DEV : process.env.REACT_APP_STRIPE_PROD));
  }, []);

  const fetchSubs = useCallback(() => {
    axios.get('/v1/users/subscriptions?state=manual')
      .then((res) => {
        setSubs(res.data.subscriptions);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    fetchSubs();
  }, [fetchSubs]);

  const handleStripe = async () => {
    const stripe = await stripePromise;

    axios.get('/v1/users/stripe_payment_session')
      .then(async (response) => {
        if (response.data.session_id) {
          const result = await stripe.redirectToCheckout({
            sessionId: response.data.session_id
          });

          if (result.error) {
            toggleToastActive(t('shared.something_went_wrong'));
          }
        } else if (response.data.payment_method === 'used_previous') {
          toggleToastActive(t('shared.success'));
          props.onOk();
        }
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  const buttonRef = useRef(null);

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" initialFocus={buttonRef} onClose={() => {}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          {toastMarkup}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {t('membership.approval_required')}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {t('membership.assigned_sub')}
                    </p>
                  </div>
                  <div>
                    {subs.map((sub, i) => (
                      <div key={i} className="my-2">
                        <div className="text-sm border rounded-lg border-gray-300 text-left p-2">
                          <p className="font-medium text-gray-900p">
                            {sub.title}
                          </p>
                          <div className="text-gray-600">
                            <div className="sm:inline">
                              {sub.description}
                            </div>
                          </div>
                          {sub.start_date ? (
                            <PnbBadge color="bg-green-200 text-green-800" title={`${t('shared.start_date')}: ${moment(sub.start_date).format('LL')}`} />
                          ) : ''}
                          {sub.cancel_at ? (
                            <PnbBadge color="bg-red-200 text-red-800" title={`${t('shared.cancel_at')}: ${moment(sub.cancel_at).format('LL')}`} />
                          ) : ''}
                          {sub.sessions ? (
                            <PnbBadge color="bg-blue-200 text-blue-800 my-1" title={`${t('membership.sessions')}: ${sub.sessions}`} />
                          ) : ''}
                          {sub.renewable_sessions ? (
                            <PnbBadge color="bg-blue-200 text-blue-800 my-1" title={t('membership.sessions_will_renew')} />
                          ) : ''}
                          <div className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                            <div className="font-medium text-gray-900 whitespace-nowrap">{sub.price}</div>
                            <div className="ml-1 text-gray-500 sm:ml-0">{sub.pricing_desc}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <p>{t('membership.agreement')}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                  onClick={() => handleStripe()}
                  ref={buttonRef}
                >
                  {t('shared.approve_and_pay')}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => props.onCancel()}
                >
                  {t('shared.cancel')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SubscriptionNotificationModal;
