import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BookOpenIcon, CalendarIcon, CalculatorIcon, CloudIcon, CogIcon, HomeIcon, InformationCircleIcon, UserCircleIcon, UserGroupIcon, UsersIcon, BuildingLibraryIcon } from '@heroicons/react/20/solid';
import { BanknotesIcon, ClipboardDocumentListIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import Menu from './Menu/Menu';

const AdminNavigation = (props) => {
  const { t } = useTranslation('translation');
  const location = useLocation();

  const activeRoute = (route) => location.pathname.split('/')[1] === route;

  const openDocumentation = () => {
    window.open('https://www.notion.so/Planubo-Help-Doc-63b7b34ee37c402383211473b58be8fb');
  };

  const adminItems = [
    {
      url: '/users',
      label: 'Users',
      icon: CloudIcon,
      selected: activeRoute('users'),
      condition: true
    }
  ];

  const settingsItems = [
    {
      url: '/profile_settings',
      label: t('navigation.profile'),
      selected: activeRoute('profile_settings'),
      condition: true
    },
    {
      url: '/client_settings',
      label: t('navigation.client_settings'),
      selected: activeRoute('client_settings'),
      condition: true
    },
    {
      url: '/calendar_settings',
      label: t('navigation.calendar_settings'),
      selected: activeRoute('calendar_settings'),
      condition: true
    },
    {
      url: '/invoice_settings',
      label: t('navigation.invoice_settings'),
      selected: activeRoute('invoice_settings'),
      condition: true
    },
    {
      url: '/custom_settings',
      label: t('custom_domain.custom_settings'),
      selected: activeRoute('custom_settings'),
      condition: true
    },
    {
      url: '/email_settings',
      label: t('email_settings.main'),
      selected: activeRoute('email_settings'),
      condition: true
    },
    {
      url: '/integrations',
      label: t('navigation.integrations'),
      selected: activeRoute('integrations'),
      condition: true
    },
    {
      url: '/plans',
      label: t('navigation.plans'),
      selected: activeRoute('plans'),
      condition: true
    }
  ];

  const publicProfileItems = [{
    url: '/public_profile_edit',
    label: t('public_profile.edit_booking_portal'),
    selected: activeRoute('public_profile_edit'),
    condition: true
  },
  {
    url: '/products',
    label: t('products.singular'),
    selected: activeRoute('products'),
    condition: true
  }];
  // {
  //   url: '/memberships',
  //   label: 'Memberships',
  //   selected: activeRoute('memberships'),
  //   condition: true
  // }];

  const navigation = [
    { label: 'Home', icon: HomeIcon, selected: activeRoute('home'), url: '/home', condition: true },
    {
      label: t('navigation.calendar'),
      icon: CalendarIcon,
      selected: activeRoute('calendar'),
      url: '/calendar',
      condition: true,
      boarderTop: true
    },
    {
      label: t('public_profile.portal'),
      icon: BookOpenIcon,
      selected: publicProfileItems.some((h) => h.url.split('/')[1] === location.pathname.split('/')[1]),
      children: publicProfileItems,
      condition: true
    },
    {
      label: t('calendar.events'),
      icon: UserGroupIcon,
      selected: activeRoute('events'),
      url: '/events',
      condition: true
    },
    {
      label: 'Media Library',
      icon: BuildingLibraryIcon,
      selected: activeRoute('media_library'),
      url: '/media_library',
      condition: true
    },
    {
      label: t('survey.survey'),
      icon: ClipboardDocumentListIcon,
      selected: activeRoute('surveys'),
      url: '/surveys',
      condition: true
    },
    {
      label: t('invoices.invoices'),
      icon: CalculatorIcon,
      selected: activeRoute('invoices'),
      condition: true,
      boarderTop: true,
      url: '/invoices'
    },
    {
      label: t('memberships.memberships'),
      icon: PencilSquareIcon,
      selected: activeRoute('subscriptions'),
      url: '/subscriptions',
      condition: true
    },
    {
      label: t('navigation.clients'),
      icon: UserCircleIcon,
      selected: activeRoute('clients'),
      url: '/clients',
      condition: true,
      boarderTop: true
    },
    {
      label: t('staff-accounts'),
      icon: UsersIcon,
      selected: activeRoute('staff_accounts'),
      url: '/staff_accounts',
      condition: true
    },
    {
      label: t('accountant.singular'),
      icon: BanknotesIcon,
      selected: activeRoute('accountants'),
      url: '/accountants',
      condition: true
    },
    {
      label: t('settings.settings'),
      selected: settingsItems.some((h) => h.url.split('/')[1] === location.pathname.split('/')[1]),
      children: settingsItems,
      icon: CogIcon,
      condition: true,
      boarderTop: true
    },
    {
      label: 'Planubo Admin',
      selected: adminItems.some((h) => h.url.split('/')[1] === location.pathname.split('/')[1]),
      condition: true,
      icon: CloudIcon,
      children: adminItems
    },
    {
      label: 'Help Center',
      selected: activeRoute('help'),
      condition: true,
      icon: InformationCircleIcon,
      children: [
        {
          url: '/help',
          label: t('help.help'),
          selected: activeRoute('help'),
          condition: true
        },
        {
          label: t('help.documentation'),
          onClick: () => openDocumentation(),
          condition: true
        }
      ]
    }
  ];

  return (
    <Menu toggle={props.toggle} navigation={navigation} />
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.admin,
  unread: state.auth.unread,
  userUrlName: state.auth.urlName
});

export default connect(mapStateToProps)(AdminNavigation);
