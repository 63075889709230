/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Viewer from 'react-viewer';
import { useTranslation } from 'react-i18next';
import { Banner, Button, Card, FormLayout, Page, Stack, TextField, TextStyle, Toast } from '@shopify/polaris';

const Pay = () => {
  const [invoice, setInvoice] = useState({});
  const [visible, setVisible] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [giftCardValue, setGiftCardValue] = useState(0);
  const [rawGiftCardValue, setRawGiftCardValue] = useState(0);
  const [expired, setExpired] = useState(false);
  const [paid, setPaid] = useState(false);

  const { t } = useTranslation();
  const matchParams = useParams();
  // eslint-disable-next-line no-unused-vars
  const history = useNavigate();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const fetchInvoice = useCallback(() => {
    axios.get(`/v1/invoices/${matchParams.id}/payment_info`)
      .then((response) => {
        setInvoice(response.data);
        setPaid(response.data.paid);
        setProcessing(response.data.processing);
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  }, [matchParams.id, t, toggleToastActive]);

  const getCheckoutSession = async () => {
    setLoading(true);

    const params = {
      id: matchParams.id,
      code: textFieldValue
    };

    const response = await axios.post('/v1/stripe_payments/checkout', params);

    if (!response.data.session_id) {
      setLoading(false);
    }

    if (response.data.paid) {
      setPaid(true);
    } else {
      window.location.replace(response.data.session_id);
    }
  };

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  const goToLink = () => {
    window.open(invoice.fileUrl);
  };

  const [textFieldValue, setTextFieldValue] = useState('');

  const handleTextFieldChange = useCallback(
    (value) => setTextFieldValue(value),
    []
  );

  const checkGiftCard = () => {
    axios.post('/v1/invoices/check_gift_card', { code: textFieldValue, invoice_id: matchParams.id })
      .then((res) => {
        setGiftCardValue(res.data.value);
        setRawGiftCardValue(res.data.raw_value);
        setExpired(res.data.expired);
      })
      .catch(() => {
        setRawGiftCardValue(0);
        setGiftCardValue(0);
        toggleToastActive(t('shared.invalid_code'));
      });
  };

  const calc = () => {
    if (expired) {
      return invoice.prettyTotal;
    }

    const result = (Number(invoice.total) - rawGiftCardValue).toPrecision(3);

    if (result < 0) return 0;

    return result;
  };

  return (
    <Page
      title={t('payments.pay')}
      separator
    >
      {toastMarkup}
      <Card sectioned subdued actions={!paid ? [{ content: 'Download', onAction: () => goToLink() }] : []}>
        {paid ? (
          processing ? (
            <div className="mb-4">
              <Banner status="info">
                <p>{t('payments.pending')}</p>
              </Banner>
              <div className="my-2">
                <Button primary onClick={() => history('/invoices')}>{t('shared.back_to_invoices')}</Button>
              </div>
            </div>
          ) : (
            <div className="mb-4">
              <Banner status="success">
                <p>{t('payments.successfull')}</p>
              </Banner>
              <div className="my-2">
                <Button primary onClick={() => history('/invoices')}>{t('shared.back_to_invoices')}</Button>
              </div>
            </div>
          )
        ) : (
          <FormLayout>
            {invoice.previewUrl && (
              <Button onClick={() => setVisible(true)}>{t('invoices.view_invoice')}</Button>
            )}
            <TextField
              label={t('gift_cards.singular')}
              value={textFieldValue}
              onChange={handleTextFieldChange}
              autoComplete="off"
              connectedRight={<Button onClick={() => checkGiftCard()}>{t('shared.submit')}</Button>}
            />
            {giftCardValue ? (
              <Stack>
                <Stack.Item fill><TextStyle variation="strong">{t('payments.gift_card_balance')}</TextStyle></Stack.Item>
                <TextStyle subdued>{expired ? <div className="inline-flex items-center rounded-md bg-red-50 text-red-700 ring-red-600/20 px-2 py-1 text-xs font-medium ring-1 ring-inset">Expired</div> : giftCardValue}</TextStyle>
              </Stack>
            ) : null}
            <Stack>
              <Stack.Item fill><TextStyle variation="strong">{t('payments.amount_due')}</TextStyle></Stack.Item>
              <TextStyle subdued>{invoice.prettyTotal}</TextStyle>
            </Stack>
            <hr />
            {giftCardValue ? (
              <Stack>
                <Stack.Item fill><TextStyle variation="strong">{t('payments.total_due')}</TextStyle></Stack.Item>
                <TextStyle subdued>{calc()}</TextStyle>
              </Stack>
            ) : null}
            <Button loading={loading} primary fullWidth onClick={() => getCheckoutSession()}>{t('payments.pay_now')}</Button>
          </FormLayout>
        )}
      </Card>
      <Viewer
        visible={visible}
        onClose={() => setVisible(false)}
        images={[{ src: invoice.previewUrl, alt: 'Invoice' }]}
      />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.token
});

export default connect(mapStateToProps)(Pay);
