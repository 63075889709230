import { handleFilterChange } from '../FilterEngine';

export const contentLabel = (label, statusCounts) => {
  if (statusCounts) {
    if (statusCounts[label]) {
      return statusCounts[label];
    }
  }

  return '0';
};

export const getQueryStatus = (tabs, tab) => {
  if (tabs[tab].accessibilityLabel === 'All') {
    return '';
  }

  return tabs[tab].accessibilityLabel;
};

export const getTabIndex = (tabs, tab) => {
  const index = tabs.findIndex((t) => t.accessibilityLabel.toLowerCase() === tab.toLowerCase());
  return index > -1 ? index : 0;
};

export const getTabId = (tabs, tab) => {
  const ta = tabs.find((t) => t.accessibilityLabel === tab);
  return ta?.id || tabs[0]?.id;
};

export const onTabSelect = (props, tabs, tabIndex, setTableFilters, callback) => {
  if (tabs[tabIndex]?.externalURL) return;
  if (tabs[tabIndex]?.hide) return;

  const query = getQueryStatus(tabs, tabIndex);

  const { history, matchParams } = props;

  handleFilterChange(props, setTableFilters, 'tab')(query);

  if ((query === 'Booking' || query === 'booking') && matchParams) {
    history(`/booking/${matchParams.name}?tab=booking`);
    if (callback) {
      callback();
    }
  }
};
