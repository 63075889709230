import React from 'react';
import { useTranslation } from 'react-i18next';

const Liquid = (props) => {
  const { t } = useTranslation();

  const data = {
    'booking_mailer/instructor_new_booking': [{
      name: t('email_template.user_name'),
      liquid: '{{instructor_name}}'
    }, {
      name: t('email_template.user_email'),
      liquid: '{{instructor_email}}'
    }, {
      name: t('client.email'),
      liquid: '{{client_email}}'
    }, {
      name: t('email_template.client_name'),
      liquid: '{{client_name}}'
    }, {
      name: t('email_template.start_time'),
      liquid: '{{start_time}}'
    }, {
      name: t('email_template.end_time'),
      liquid: '{{end_time}}'
    }, {
      name: t('email_template.product_title'),
      liquid: '{{product_title}}'
    }, {
      name: t('email_template.product_description'),
      liquid: '{{product_description}}'
    }, {
      name: t('email_template.event_location'),
      liquid: '{{event_location}}'
    }, {
      name: t('email_template.event_price'),
      liquid: '{{event_price}}'
    }, {
      name: t('email_template.event_online'),
      liquid: '{{event_online}}'
    }, {
      name: t('email_template.online_url'),
      liquid: '{{event_conference_url}}'
    }, {
      name: t('email_template.manage_participation'),
      liquid: '{{manage_booking_url}}'
    }, {
      name: t('email_template.custom_booking_fields'),
      liquid: '{{custom_booking_fields}}'
    }],
    'booking_mailer/client_confirmation': [{
      name: t('email_template.user_name'),
      liquid: '{{instructor_name}}'
    }, {
      name: t('email_template.user_email'),
      liquid: '{{instructor_email}}'
    }, {
      name: t('email_template.client_name'),
      liquid: '{{client_name}}'
    }, {
      name: t('email_template.start_time'),
      liquid: '{{start_time}}'
    }, {
      name: t('email_template.end_time'),
      liquid: '{{end_time}}'
    }, {
      name: t('email_template.product_title'),
      liquid: '{{product_title}}'
    }, {
      name: t('email_template.product_description'),
      liquid: '{{product_description}}'
    }, {
      name: t('email_template.event_location'),
      liquid: '{{event_location}}'
    }, {
      name: t('email_template.event_price'),
      liquid: '{{event_price}}'
    }, {
      name: t('email_template.event_online'),
      liquid: '{{event_online}}'
    }, {
      name: t('email_template.online_url'),
      liquid: '{{event_conference_url}}'
    }, {
      name: t('email_template.manage_participation'),
      liquid: '{{manage_booking_url}}'
    }, {
      name: t('email_template.custom_booking_fields'),
      liquid: '{{custom_booking_fields}}'
    }],
    'user_mailer/event_registration': [{
      name: t('email_template.user_name'),
      liquid: '{{instructor_name}}'
    }, {
      name: t('email_template.client_name'),
      liquid: '{{client_name}}'
    }, {
      name: t('email_template.start_time'),
      liquid: '{{start_time}}'
    }, {
      name: t('email_template.end_time'),
      liquid: '{{end_time}}'
    }, {
      name: t('email_template.event_title'),
      liquid: '{{event_title}}'
    }, {
      name: t('email_template.event_description'),
      liquid: '{{event_description}}'
    }, {
      name: t('email_template.event_location'),
      liquid: '{{event_location}}'
    }, {
      name: t('email_template.event_price'),
      liquid: '{{event_price}}'
    }, {
      name: t('email_template.manage_participation'),
      liquid: '{{manage_booking_url}}'
    }],
    'user_mailer/event_cancellation': [{
      name: t('email_template.user_name'),
      liquid: '{{instructor_name}}'
    }, {
      name: t('email_template.client_name'),
      liquid: '{{client_name}}'
    }, {
      name: t('email_template.start_time'),
      liquid: '{{start_time}}'
    }, {
      name: t('email_template.end_time'),
      liquid: '{{end_time}}'
    }, {
      name: t('email_template.event_title'),
      liquid: '{{event_title}}'
    }, {
      name: t('email_template.event_description'),
      liquid: '{{event_description}}'
    }, {
      name: t('email_template.event_location'),
      liquid: '{{event_location}}'
    }, {
      name: t('email_template.event_price'),
      liquid: '{{event_price}}'
    }],
    'user_mailer/welcome_email': [{
      name: t('email_template.user_name'),
      liquid: '{{instructor_name}}'
    }, {
      name: t('email_template.client_name'),
      liquid: '{{client_name}}'
    }, {
      name: t('user.password'),
      liquid: '{{password}}'
    }],
    'user_mailer/welcome_staff_email': [{
      name: t('email_template.user_name'),
      liquid: '{{instructor_name}}'
    }, {
      name: t('email_template.staff_name'),
      liquid: '{{staff_name}}'
    }, {
      name: t('email_template.staff_email'),
      liquid: '{{staff_email}}'
    }, {
      name: t('user.password'),
      liquid: '{{password}}'
    }],
    'user_mailer/event_update': [{
      name: t('email_template.user_name'),
      liquid: '{{instructor_name}}'
    }, {
      name: t('email_template.client_name'),
      liquid: '{{client_name}}'
    }, {
      name: t('email_template.start_time'),
      liquid: '{{start_time}}'
    }, {
      name: t('email_template.end_time'),
      liquid: '{{end_time}}'
    }, {
      name: t('email_template.event_title'),
      liquid: '{{event_title}}'
    }, {
      name: t('email_template.event_description'),
      liquid: '{{event_description}}'
    }, {
      name: t('email_template.event_location'),
      liquid: '{{event_location}}'
    }, {
      name: t('email_template.event_price'),
      liquid: '{{event_price}}'
    }],
    'user_mailer/event_client_cancellation_notification': [{
      name: t('email_template.user_name'),
      liquid: '{{instructor_name}}'
    }, {
      name: t('email_template.client_name'),
      liquid: '{{client_name}}'
    }, {
      name: t('client.email'),
      liquid: '{{client_email}}'
    }, {
      name: t('email_template.start_time'),
      liquid: '{{start_time}}'
    }, {
      name: t('email_template.end_time'),
      liquid: '{{end_time}}'
    }, {
      name: t('email_template.event_title'),
      liquid: '{{event_title}}'
    }, {
      name: t('email_template.event_description'),
      liquid: '{{event_description}}'
    }, {
      name: t('email_template.event_location'),
      liquid: '{{event_location}}'
    }, {
      name: t('email_template.event_price'),
      liquid: '{{event_price}}'
    }],
    'user_mailer/send_gift_card_receipt': [{
      name: t('email_template.client_name'),
      liquid: '{{client_name}}'
    }, {
      name: 'Client email',
      liquid: '{{client_email}}'
    }, {
      name: 'Note',
      liquid: '{{note}}'
    }, {
      name: 'Code',
      liquid: '{{code}}'
    }, {
      name: 'Value',
      liquid: '{{value}}'
    }, {
      name: 'Expires at date',
      liquid: '{{expires_at}}'
    }],
    generic: [{
      name: t('email_template.user_name'),
      liquid: '{{instructor_name}}'
    }, {
      name: t('email_template.staff_name'),
      liquid: '{{staff_name}}'
    }, {
      name: t('email_template.staff_email'),
      liquid: '{{staff_email}}'
    }, {
      name: t('email_template.client_name'),
      liquid: '{{client_name}}'
    }, {
      name: 'Client email',
      liquid: '{{client_email}}'
    }, {
      name: 'Note',
      liquid: '{{note}}'
    }, {
      name: 'Code',
      liquid: '{{code}}'
    }, {
      name: 'Value',
      liquid: '{{value}}'
    }]
  };

  return (
    <div className="border-t border-gray-200 py-2">
      <dl className="divide-y divide-gray-200">
        {(data[props.type] || data.generic).map((entry, i) => (
          <div key={i} className="py-3 grid grid-cols-3 gap-4">
            <dt className="text-sm font-medium text-gray-500">{entry.name}</dt>
            <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2">{entry.liquid}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default Liquid;
