import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Auth from '../containers/Auth/Auth';
import Signup from '../containers/Auth/Signup';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import ResetPassword from '../containers/Auth/ResetPassword';
import AppSumo from '../containers/Auth/AppSumo';
import ReadOnly from '../components/PublicProfile/ReadOnly';
import PublicProduct from '../components/Products/PublicProduct';
import Documentation from './Documentation';
import ZoomDocumentation from './ZoomDocumentation';
import PublicBooking from '../components/Products/PublicBooking';

const UnauthenticatedRoutes = () => (
  <Routes>
    <Route path="/" exact element={<Auth />} />
    <Route exact path="/v1/zoom" element={<Auth />} />
    <Route path="/appsumo" exact element={<AppSumo />} />
    <Route path="/signup" exact element={<Signup />} />
    <Route path="/forgot-password" exact element={<ForgotPassword />} />
    <Route path="/reset-password" exact element={<ResetPassword />} />
    <Route path="/manage_booking/:token" exact element={<PublicBooking />} />
    <Route exact path="/booking/:name" element={<ReadOnly />} />
    <Route exact path="/embeddedbooking/:token" element={<PublicProduct />} />
    <Route exact path="/documentation" element={<Documentation />} />
    <Route exact path="/zoom_documentation" element={<ZoomDocumentation />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default UnauthenticatedRoutes;
