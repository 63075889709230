import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  ContextualSaveBar, Page, Layout, Card, FormLayout, Form, Toast, TextField, TextStyle, Stack, Label, Button
} from '@shopify/polaris';
import { connect } from 'react-redux';
import { EditMinor } from '@shopify/polaris-icons';
import StatusBadge from '../Shared/StatusBadge';
import ExternalLink from '../Shared/ExternalLink';
import EmailTemplates from './EmailTemplates/EmailTemplates';

const EmailSettings = (props) => {
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wasBlank, setWasBlank] = useState(false);
  const [editPass, setEditPass] = useState(false);
  // const [banner, setBanner] = useState([]);
  const [emailSettings, setEmailSettings] = useState({});
  const { t } = useTranslation();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const handleEmailSettingsChange = (field) => (data) => {
    setShowSaveBar(true);
    setEmailSettings({ ...emailSettings, [field]: data });
  };

  const updateSettings = () => {
    setLoading(true);

    const params = {
      email_setting: {
        domain: emailSettings.domain,
        address: emailSettings.address,
        user_name: emailSettings.user_name,
        password: emailSettings.password,
        port: emailSettings.port
      }
    };

    if (wasBlank) {
      axios.post('/v1/email_settings', params)
        .then((response) => {
          toggleToastActive(t('shared.saved'));
          setEmailSettings(response.data);
          setLoading(false);
          setShowSaveBar(false);
          setEditPass(false);
          setWasBlank(false);
        })
        .catch(() => {
          toggleToastActive(t('shared.something_went_wrong'));
          setLoading(false);
        });
    } else {
      axios.patch(`/v1/email_settings/${emailSettings.id}`, params)
        .then((response) => {
          toggleToastActive(t('shared.saved'));
          setEmailSettings(response.data);
          setLoading(false);
          setShowSaveBar(false);
          setEditPass(false);
          setWasBlank(false);
        })
        .catch(() => {
          toggleToastActive(t('shared.something_went_wrong'));
          setLoading(false);
        });
    }
  };

  const deleteSettings = () => {
    if (!window.confirm(`${t('shared.are_you_sure')} Email Settings`)) return;

    axios.delete(`/v1/email_settings/${emailSettings.id}`)
      .then(() => {
        setWasBlank(true);
        setEmailSettings({});
      })
      .catch(() => {});
  };

  const fetchSettings = useCallback(() => {
    axios.get('/v1/email_settings')
      .then((response) => {
        setEmailSettings(response.data);
        if (!response.data.user_name) {
          setWasBlank(true);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const settingsForm = (
    <Form onSubmit={updateSettings}>
      <FormLayout>
        <Layout>
          {!props.isStaff && (
            <Layout.AnnotatedSection
              title={t('email_templates.email_templates')}
              description={t('email_templates.email_templates_description')}
            >
              <Card sectioned>
                <EmailTemplates />
              </Card>
            </Layout.AnnotatedSection>
          )}
          <Layout.AnnotatedSection
            title={t('email_settings.main')}
            description={(
              <>
                <div>
                  {t('email_settings.desc')}
                </div>
                <br />
                {t('email_settings.info')}
                <ExternalLink url="https://support.google.com/mail/answer/7126229/check-gmail-through-other-email-platforms?hl=en." label="Google Mail" />
              </>
            )}
          >
            <Card sectioned>
              <Card.Section>
                <TextField
                  autoComplete="new-password"
                  label={t('email_settings.username')}
                  placeholder={t('email_settings.username_desc')}
                  value={emailSettings.user_name}
                  onChange={handleEmailSettingsChange('user_name')}
                />
              </Card.Section>
              <Card.Section>
                {editPass || wasBlank ? (
                  <TextField
                    autoComplete="new-password"
                    label={t('user.password')}
                    type="password"
                    placeholder={t('email_settings.password_desc')}
                    value={emailSettings.password}
                    onChange={handleEmailSettingsChange('password')}
                  />
                ) : (
                  <>
                    <Label>{t('user.password')}</Label>
                    <Stack>
                      <Stack.Item fill>
                        <TextStyle>***********</TextStyle>
                      </Stack.Item>
                      <Button plain icon={EditMinor} onClick={() => setEditPass(!editPass)} />
                    </Stack>
                  </>
                )}
              </Card.Section>
              <Card.Section>
                <TextField
                  autoComplete="new-password"
                  label={t('email_settings.domain')}
                  value={emailSettings.domain}
                  placeholder={t('email_settings.domain_desc')}
                  onChange={handleEmailSettingsChange('domain')}
                />
              </Card.Section>
              <Card.Section>
                <TextField
                  autoComplete="new-password"
                  label={t('email_settings.address')}
                  placeholder={t('email_settings.address_desc')}
                  value={emailSettings.address}
                  onChange={handleEmailSettingsChange('address')}
                />
              </Card.Section>
              <Card.Section>
                <TextField
                  autoComplete="new-password"
                  label={t('email_settings.port')}
                  placeholder={t('email_settings.port_desc')}
                  value={emailSettings.port}
                  onChange={handleEmailSettingsChange('port')}
                />
              </Card.Section>
              <Card.Section>
                <Stack alignment="center" spacing="tight">
                  <StatusBadge active={emailSettings.verified} />
                  <TextStyle>{t('shared.verified')}</TextStyle>
                  <br />
                  {!emailSettings.verified ? (
                    <TextStyle variation="negative">{emailSettings.status_message}</TextStyle>
                  ) : ''}
                </Stack>
              </Card.Section>
              <Stack>
                <Stack.Item fill />
                {emailSettings.user_name ? <Button destructive onClick={() => deleteSettings()}>{t('shared.delete')}</Button> : null}
              </Stack>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
      </FormLayout>
    </Form>
  );

  return (
    <Page
      title={t('email_settings.main')}
      separator
    >
      {showSaveBar && (
        <ContextualSaveBar
          message={t('shared.unsaved_changes')}
          saveAction={{
            onAction: () => updateSettings(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      {toastMarkup}
      {settingsForm}
    </Page>
  );
};

const mapStateToProps = (state) => ({
  isStaff: state.auth.role === 'staff'
});

export default connect(mapStateToProps)(EmailSettings);
