import React from 'react';
import { useTranslation } from 'react-i18next';

const New = (props) => {
  const { t } = useTranslation('translation');

  return (
    <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
      <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
        <circle cx={4} cy={4} r={3} />
      </svg>
      {props.title || t('shared.new')}
    </span>
  );
};

export default New;
