import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLayout, Modal, TextField, Form, Badge, Stack, TextStyle, Icon, Button, Tooltip } from '@shopify/polaris';
import Datetime from 'react-datetime';
import { AlertMinor, EditMinor, InfoMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import Search from '../Shared/Search';
import RecurrenceWizard from './RecurrenceWizard';
import { rruleToString } from '../Shared/RRuleCreator';
import SwitchToggle from '../Shared/SwitchToggle';
import Banners from '../Shared/Banners';

const NewEvent = (props) => {
  const [event, setEvent] = useState({ ...props.event });
  const [showLocation, setShowLocation] = useState(false);
  const [showAddParticipations, setShowAddParticipations] = useState(false);
  const [clients, setClients] = useState([]);
  const [showTitle, setShowTitle] = useState(false);
  const [onlineEvent, setOnlineEvent] = useState(false);
  const [checked, setChecked] = useState((props.event.meta || {}).checked || false);
  const [showRecurringEventOptions, setShowRecurringEventOptions] = useState(false);
  const [banner, setBanner] = useState([]);
  const [showParticipationLimit, setShowParticipationLimit] = useState(false);
  const [rruleData, setRruleData] = useState({
    frequency: 'weekly',
    byweekday: (props.event.meta || {}).byweekday || [],
    interval: (props.event.meta || {}).interval || '1',
    showCount: (props.event.meta || {}).showCount || true,
    count: (props.event.meta || {}).count || '',
    duration: (props.event.meta || {}).duration || '1:00',
    startTime: (props.event.meta || {}).startTime || props.event.start,
    endTime: (props.event.meta || {}).endTime || ''
  });

  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    if (event.participation_limit && (event.signedInParticipations || []).length > event.participation_limit) {
      setBanner([{ title: t('calendar.increase_participation_limit'), status: 'critical' }]);
      return;
    }

    if (checked) {
      const meta = { ...rruleData, checked, show_title: showTitle };
      if (!rruleData.byweekday.length) {
        setBanner([{ title: t('calendar.setup_recurring'), status: 'critical' }]);
        return;
      }
      if (!meta.showCount) {
        meta.count = null;
      }
      const temp = { ...event, meta };
      temp.online = onlineEvent;
      temp.meta.show_title = showTitle;
      temp.meta.rrule = rruleToString(temp);
      if (showLocation) temp.meta.location = showLocation;
      props.handleSubmit(temp);
    } else {
      event.meta = {
        location: showLocation,
        show_title: showTitle
      };
      event.online = onlineEvent;
      props.handleSubmit(event);
    }
    props.close();
  }, [event, checked, props, t, rruleData, showTitle, showLocation, onlineEvent]);

  const handleCheck = useCallback(() => setChecked(!checked), [checked]);

  const handleChange = (key) => (value) => {
    setEvent({ ...event, [key]: value });
  };

  const handleTimeChange = (key) => (value) => {
    setEvent({ ...event, [key]: new Date(value) });
  };

  const participationBadges = () => {
    const badges = (event.participations || []).map((p) => (
      <Badge key={p.id}>{p.label}</Badge>
    ));

    return <Stack>{badges}</Stack>;
  };

  const fetchClients = useCallback(() => {
    const params = {
      per_page: 1000,
      page: 1,
      search: '',
      q: ''
    };

    axios.post('/v1/clients/event_search', params)
      .then((res) => {
        setClients(res.data);
      })
      .catch(() => {});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showAddParticipations) {
      fetchClients();
    }
  }, [showAddParticipations, fetchClients]);

  const participationAdd = clients.length ? (
    <Search
      showTags
      allowMultiple
      selectedData={event.signedInParticipations}
      handleChange={handleChange('signedInParticipations')}
      deselectedOptions={clients}
      placeholder={t('client.clients')}
    />
  ) : null;

  const recurringEventOptions = (
    <Stack vertical>
      <Stack alignment="center">
        <Stack.Item fill>
          <TextStyle>{t('shared.recurring_event')}</TextStyle>
        </Stack.Item>
        <Stack.Item>
          <SwitchToggle
            checked={checked}
            handleChange={handleCheck}
          />
        </Stack.Item>
      </Stack>
      {checked && <Button plain onClick={() => setShowRecurringEventOptions(true)}>{t('events.recurrence')}</Button>}
      {showRecurringEventOptions ? (
        <RecurrenceWizard
          open={showRecurringEventOptions}
          rruleData={rruleData}
          event={event}
          handleRruleDataChange={setRruleData}
          dismissBanner={setBanner}
          close={() => setShowRecurringEventOptions(false)}
        />
      ) : null}
    </Stack>
  );

  const renderInput = (_, openCalendar) => (
    <Button plain type="button" onClick={openCalendar}>{t('shared.change')}</Button>
  );

  const toggleParticipationLimit = () => {
    if (showParticipationLimit) {
      handleChange('participation_limit')('');
    }

    setShowParticipationLimit(!showParticipationLimit);
  };

  const toggleShowLocation = () => {
    if (showLocation) {
      handleChange('location')('');
    }

    setShowLocation(!showLocation);
  };

  const eventOptions = () => (
    <FormLayout>
      <div className="mb-8">
        <Stack>
          <TextStyle>{t('shared.participants')}</TextStyle>
          {!showAddParticipations && (
          <Tooltip content={t('events.client_info')}>
            <Icon color="critical" source={InfoMinor} />
          </Tooltip>
          )}
          {!showAddParticipations ? (
            <Button plain onClick={() => setShowAddParticipations(true)}>
              <Icon color="success" source={EditMinor} />
            </Button>
          ) : null}
        </Stack>
        {showAddParticipations ? participationAdd : participationBadges()}
      </div>
      {!checked ? (
        <FormLayout>
          <TextStyle>{t('shared.start')}</TextStyle>
          <Stack>
            <TextStyle>{moment(event.start).format('LLLL')}</TextStyle>
            <Datetime
              renderInput={renderInput}
              timeConstraints={{ minutes: { step: 15 } }}
              value={new Date(event.start)}
              onChange={handleTimeChange('start')}
            />
          </Stack>
          <TextStyle>{t('shared.end')}</TextStyle>
          <Stack>
            <TextStyle>{moment(event.end).format('LLLL')}</TextStyle>
            <Datetime
              renderInput={renderInput}
              timeConstraints={{ minutes: { step: 15 } }}
              value={new Date(event.end)}
              onChange={handleTimeChange('end')}
            />
          </Stack>
        </FormLayout>
      ) : null}
      {props.hasInvoiceAccess && !showRecurringEventOptions && (
      <FormLayout>
        <br />
        <Stack vertical spacing="tight">
          <Stack alignment="center">
            <Stack.Item fill>
              <Stack wrap={false} spacing="tight" alignment="center">
                <TextStyle>{t('shared.price')}</TextStyle>
                <Tooltip content={t('events.price_info')}>
                  <Icon color="critical" source={AlertMinor} />
                </Tooltip>
              </Stack>
            </Stack.Item>
          </Stack>
          <TextField
            value={event.price}
            onChange={handleChange('price')}
            helpText={t('invoices.each_participant_gets_billed')}
          />
        </Stack>
        <TextField
          multiline={1}
          label={t('shared.description')}
          value={event.description}
          onChange={handleChange('description')}
        />
      </FormLayout>
      )}
      {recurringEventOptions}
      {!showRecurringEventOptions && (
      <FormLayout>
        <Stack alignment="center">
          <Stack.Item fill>
            <Stack wrap={false} spacing="tight" alignment="center">
              <TextStyle>{t('shared.title')}</TextStyle>
              <Tooltip content={t('events.title_info')}>
                <Icon color="highlight" source={InfoMinor} />
              </Tooltip>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <SwitchToggle
              handleChange={() => {
                handleChange('title')('');
                setShowTitle(!showTitle);
              }}
              checked={showTitle}
            />
          </Stack.Item>
        </Stack>
        {showTitle ? (
          <TextField
            value={event.title}
            onChange={handleChange('title')}
          />
        ) : null}
        <Stack alignment="center">
          <Stack.Item fill>
            <TextStyle>{t('shared.participation_limit')}</TextStyle>
          </Stack.Item>
          <Stack.Item>
            <SwitchToggle
              handleChange={() => toggleParticipationLimit()}
              checked={showParticipationLimit}
            />
          </Stack.Item>
        </Stack>
        {showParticipationLimit && (
        <>
          <TextField
            type="number"
            min={0}
            value={(event.participation_limit || '').toString()}
            onChange={handleChange('participation_limit')}
          />
          <br />
        </>
        )}
        <Stack alignment="center">
          <Stack.Item fill>
            <TextStyle>{t('address.location')}</TextStyle>
          </Stack.Item>
          <Stack.Item>
            <SwitchToggle
              handleChange={() => toggleShowLocation()}
              checked={showLocation}
            />
          </Stack.Item>
        </Stack>
        {showLocation && (
        <>
          <TextField
            type="string"
            value={event.location || ''}
            onChange={handleChange('location')}
          />
          <br />
        </>
        )}
        <Stack alignment="center" spacing="tight">
          <Stack.Item fill>
            <Stack wrap={false} spacing="tight" alignment="center">
              <TextStyle>Online</TextStyle>
              {!props.canDoOnlineEvent ? (
                <Tooltip
                  content={(
                    <FormLayout>
                      {t('settings.google_calendar_info')}
                    </FormLayout>
                      )}
                >
                  <Icon color="highlight" source={InfoMinor} />
                </Tooltip>
              ) : ''}
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <SwitchToggle
              disabled={!props.canDoOnlineEvent}
              handleChange={setOnlineEvent}
              checked={onlineEvent}
            />
          </Stack.Item>
        </Stack>
      </FormLayout>
      )}
    </FormLayout>
  );

  return (
    <Modal
      open={props.active}
      onClose={() => props.close()}
      title={t('shared.event_edit')}
      primaryAction={{
        content: t('shared.save'),
        onAction: handleSubmit
      }}
    >
      <Modal.Section>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <Banners banners={banner} onDismissBanner={() => setBanner([])} />
            {eventOptions()}
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  canDoOnlineEvent: state.auth.canDoOnlineEvent,
  hasInvoiceAccess: state.auth.hasInvoiceAccess
});

export default connect(mapStateToProps)(NewEvent);
