import React from 'react';
import { Stack, Banner, Button } from '@shopify/polaris';
import { ArrowLeftMinor } from '@shopify/polaris-icons';
import { useTranslation } from 'react-i18next';
import BookingAvatar from './BookingAvatar';

const ProhibitedPage = (props) => {
  const { t } = useTranslation();

  const navigateBackAction = () => {
    props.hideSearch(false);
    props.setSelected('');
  };

  return (
    <>
      <Stack>
        <Stack.Item fill>
          <Button icon={ArrowLeftMinor} onClick={() => navigateBackAction()} />
        </Stack.Item>
        <BookingAvatar />
      </Stack>
      <Banner
        status="critical"
        title={t('product.access_denied')}
      >
        <p>{t('product.access_denied_desc')}</p>
      </Banner>
    </>
  );
};

export default ProhibitedPage;
